import { delOrder, delSaleOrder, submitOrderForSecondUser, getPayMoneysByOrderNos, creditCtrlUse, checkInventory, confirmOrder, opposeOrder } from '@/api/order';
import _ from 'lodash';
import util from '@/libs/util';
const mixin = {
  data() {
    return {
      dnumberboxChangeTimeMark: null,
      allOrderGroupIndex: null,
      iEntityGiveawayPreId: null,
      iEntityMoneyPreId: null,
      isGetDisIng: null,
      showBtn: {
        isGetDisIng: null
      },
      order: null,
      gc: {},
      dt: {},
      allSkuIds: null,
      oridinalOrder: null,
      a: {
        countTotal: null,
        unitTotal: null,
        auxTotal: null
      },
      totalRebateMoney: 0
    };
  },
  mounted() {},
  computed: {},

  methods: {
    vueToAugular(url) {
      if (window !== top) {
        top.location.href = url;
      }
    },
    mixin_buyAgain(id, cOrderNo, _this) {
      const route = {
        name: 'aorder',
        query: {
          source: 'copyOrder',
          srcbillType: _this.data.srcbillType,
          id,
          salesOrgId: _this.data.salesOrgId
        }
      };
      if (window.top === window.self) {
        this.$util.agentOpenNewTabEdit(this, route, 'add');
      } else {
        let obj = {
          cOrderNo,
          id,
          to: 'aorder',
          salesOrgId: _this.data.salesOrgId
        };
        window.parent.postMessage(obj, '*');
        window.localStorage.setItem('toUrlPage', true);
      }
    },
    mixin_secondUserSubmitOrder(order, that, isList = true, key) {
      let postData = {
        cOrderNo: order.cOrderNo
      };
      if (!isList) {
        that.loadingMap[key] = true;
      }
      submitOrderForSecondUser(postData).then((data) => {
        this.$Message.success(this.mlang('operateSuccess'));
        if (isList) {
          that.dispatch('VoucherList', 'reload');
        } else {
          that.submitCallback({}, 'submit');
        }
      }).finally(() => {
        if (!isList) {
          that.loadingMap[key] = true;
        }
      });
    },
    mixin_agentPay(cOrderNo, orderId) { // 订单详情中的 付款 按钮, 资金账户付款功能-新增cOrderId
      this.agentLink({
        type: 'payDetail',
        newWindow: false,
        query: {
          titleType: 'editor',
          orderno: cOrderNo,
          orderId,
          isGuarantee: true
        }
      });
    },
    mixin_agentReturn(cOrderNo, _this, returnAll = true) {
      // returnAll 区分退货/部分退货
      this.agentLink({
        type: 'saleReturnDetail',
        newWindow: false,
        query: {
          titleType: 'editor',
          cOrderNo,
          salesOrgId: _this.data.salesOrgId,
          returnAll
        }
      });
    },
    mixin_delOrder(cOrderNo, that, isList = true, key, order) {
      // 订单删除的话cOrderNo传过来的是order的id而不是cOrderNo
      let vm = this;
      let fn = that.currentUser.__isU8C ? delSaleOrder : delOrder;
      let postData = that.currentUser.__isU8C ? order : { cOrderNo };
      if (that.currentUser.__isU8C) {
        postData.agentId = postData.iAgentId;
        postData.rebateMoney = postData.fRebateMoney;
        postData.rebateCashMoney = postData.fRebateCashMoney;
        postData.code = postData.cOrderNo;
        if (!postData.orderPrices)postData.orderPrices = {};
        postData.orderPrices.currency = postData.originalPk;
        postData.realMoney = postData.fRealMoney;
        postData.orderPrices.originalCode = postData.originalCode;
        postData.orderPrices.originalName = postData.originalName;
        postData.transactionTypeId = postData.iTransactionTypeId;
      }
      this.$Modal.confirm({
        content: this.mlang('sureToDeleteOrder'),
        onOk: () => {
          if (!isList) {
            that.loadingMap[key] = true;
          }
          fn(postData).then((data) => {
            vm.$Message.success(this.mlang('delSuccess'));
            // 刷新页面
            if (isList) {
              that.dispatch('VoucherList', 'reload');
            } else {
              const route = {
                name: 'orders'
              };
              that.$router.replace(route);
            }
          }).finally(() => {
            if (!isList) {
              that.loadingMap[key] = true;
            }
          });
        }
      });
    },
    mixin_submit(order, _vm) {
      this.checkInventoryAgent(order, _vm);
    },
    checkInventoryAgent(order, _vm, confirm, isDetail = false) {
      let _this = this;
      // 防止重复提交
      // _vm.secondUserisGetDisIng = true;
      let postData = {
        cOrderNo: order.cOrderNo,
        isUpdate: false,
        cAction: confirm ? 'CONFIRM' : 'SAVE',
        loading: true
      };
      checkInventory(postData).then((data) => {
        data.message = _this.mlang('stockNotEoughForEx') + '<br/>';
        data.data && data.data.forEach((v, k) => {
          data.message += `(${k + 1}). ${v.productName}【${v.productCode}】${v.cSpecDescription}<br/>`;
        });
        if (confirm) {
          if (!data) {
            _this.checkCredit(order, _vm, confirm, isDetail);
            return;
          }
          if (data.isExceed) {
            _this.$Modal.confirm({
              content: data.message,
              onOk: () => {
                _this.checkCredit(order, _vm, confirm, isDetail);
              }

            });
          } else {
            _this.checkCredit(order, _vm, confirm, isDetail);
          }
        } else {
          if (data.isControl === 'none') {
            // 不做任何处理
            _this.checkCredit(order, _vm, confirm); // 提交订单
            return;
          }
          if (data.isControl === 'true' && data.isExceed) {
            // 严格控制下单量不能超过库存量,并且库存已超，做提醒，并且不再提交订单。
            _this.$Message.warning(data.message);
            _vm.secondUserisGetDisIng = false;
          } else if (data.isControl === 'false' && data.isExceed) {
            // 能超过库存量,并且库存已超，做提醒，并提交订单
            _this.$Modal.confirm({
              content: data.message,
              onOk: () => {
                _this.checkCredit(order, _vm, confirm);
              }

            });
          } else {
            _this.checkCredit(order, _vm, confirm); // 提交订单
          }
        }
      });
    },
    // 检查信用额度提醒
    checkCredit(order, that, confirm, isDetail = false) {
      const vm = this;
      // url: `/agent/infrastructure/creditCtrlUse?iAgentId=${order.oAgent.id}&bizId=${order.bizId}&orgId=${order.salesOrgId}`,
      let postData = {
        iAgentId: order.oAgent.id,
        bizId: order.bizId
      };
      if (order.salesOrgId) {
        postData.orgId = order.salesOrgId;
      }
      creditCtrlUse(postData).then((data) => {
        if (!data.creditCtrl) {
          // 不控制
          if (confirm) {
            vm.confirm(order, that, isDetail);
          } else {
            vm.submitOrder(order); // 提交订单
          }
        } else {
          let creditworthCode = data.value;
          // 额度
          let careditWorth = !order.oAgent.iCusCreLine ? 0 : order.oAgent.iCusCreLine;
          // 余额
          let remainderCred = !order.oAgent.iCreditValue ? 0 : order.oAgent.iCreditValue;
          // 余额<订单金额 超信用额度，根据业务选项，做相应操作
          // let money = !detail ? params.fPayMoney : detail.fSalePayMoney;
          if (remainderCred < order.fPayMoney) {
            if (creditworthCode === 'false' || creditworthCode === 'DELIVERYCONTROL') {
              // “不控制”或者“发货严格控制”
              if (confirm) {
                vm.confirm(order, that, isDetail);
              } else {
                vm.submitOrder(order); // 提交订单
              }
            } else if (creditworthCode === 'true') {
              // “仅订单上提示”
              let msg = `${vm.mlang('yourCreditTipA')}${careditWorth}${vm.mlang('surplusTipB')}${remainderCred}${confirm ? vm.mlang('continueConfirmTipC') : vm.mlang('continueSubmitTipC')}`;
              vm.$Modal.confirm({
                content: msg,
                onOk: () => {
                  if (confirm) {
                    vm.confirm(order, that, isDetail);
                  } else {
                    vm.submitOrder(order, that); // 提交订单
                  }
                }
              });
            } else if (creditworthCode === 'ALLCONTROL' || creditworthCode === 'ORDERCONTROL') {
              // “订单严格控制”或者“全部严格控制”
              vm.$Message.warning(`${vm.mlang('yourCreditTipA')}${careditWorth}${vm.mlang('surplusTipB')}${remainderCred}${confirm ? vm.mlang('continueConfirmTipC') : vm.mlang('continueSubmitTipC')}`);
            } else {
              vm.$Message.warning(vm.mlang('orders.warning'));
            }
          } else {
            if (confirm) {
              vm.confirm(order, that, isDetail);
            } else {
              vm.submitOrder(order, that); // 提交订单
            }
          }
        }
      });
    },

    submitOrder(order, that) {
      submitOrderForSecondUser({
        cOrderNo: order.cOrderNo
      }).then((data) => {
        this.$Message.success(this.mlang('subSuccess'));
        // 刷新页面
        this.dispatch('VoucherList', 'reload');
      });
    },

    mixin_discount({ fTransactionPrice, fSalePrice }) {
      if (fSalePrice === 0) {
        return '';
      } else {
        let discount = _.round(_.divide(fTransactionPrice, fSalePrice), 4);
        return _.round(_.multiply(discount, 100), 2) + '%';
      }
    },

    // 批量支付
    mixin_batchPay: (batchPayArr, vm) => {
      let nos = [];
      let totalMoneys = 0;
      let payMoneys = 0;
      _.forEach(batchPayArr, (v, k) => {
        nos.push({
          voucherNo: v.cOrderNo,
          voucherType: 'ORDER'
        });
        let fRealMoney = 0;
        if (v.originalCurrencyVo) {
          fRealMoney = vm.$util.toFixed(v.fRealMoney, v.originalCurrencyVo.moneyDigit, v.originalCurrencyVo.moneyRount);
        } else {
          fRealMoney = vm.$util.toFixed(v.fRealMoney, 2, '');
        }
        totalMoneys = totalMoneys + parseFloat(fRealMoney);
      });
      getPayMoneysByOrderNos(nos).then((data) => {
        payMoneys = data.paidMoney + data.unConfirmMoney;
        // 实付金额-支付金额
        var _paymoney = util.numSub(totalMoneys, payMoneys);
        vm.$Modal.confirm({
          title: ' ',
          content: vm.mlang('batchPayTipOne') + batchPayArr.length + vm.mlang('batchPayTipTwo') + _paymoney + vm.mlang('batchPayTipThree'),
          onOk: () => {
            var ordernos = '';
            for (var i = 0; i < batchPayArr.length; i++) {
              if (i > 0) {
                ordernos = ordernos + ',' + batchPayArr[i].cOrderNo;
              } else {
                ordernos = batchPayArr[i].cOrderNo;
              }
            }
            vm.agentLink({
              type: 'payDetail',
              query: {
                orderno: ordernos,
                isGuarantee: true
              },
              newWindow: false
            });
          }
        });
      });
    },
    mixin_agentSure(order, vm, isDetail = false) {
      if (order.cNextStatus === 'CONFIRMORDER') {
        this.checkInventoryAgent(order, vm, 'confirm', isDetail);
      }
    },
    confirm(order, vm, isDetail) {
      vm.$Modal.confirm({
        title: ' ',
        content: `${vm.mlang('sureToConfrim')}${order.cOrderNo}${vm.mlang('askEnd')}`,
        onOk: () => {
          const postData = {
            orderNos: [order.cOrderNo]
          };
          confirmOrder(postData).then((data) => {
            if (data) {
              if (isDetail === true) {
                vm.broadcast('Voucher', 'refresh');
              } else {
                vm.dispatch('VoucherList', 'reload');
              }
            }
          });
        }
      });
    },
    mixin_agentOppose(params, vm, isDetail = false) {
      vm.$Modal.prompt({
        content: vm.mlang('opposeMsg'),
        okText: vm.mlang('sure'),
        cancelText: vm.mlang('cancel'),
        tip: vm.mlang('orders.oTip'),
        title: vm.mlang('orders.oTitle'),
        onOk: (res, instance) => {
          const postData = {
            cOrderNo: params.cOrderNo,
            opposeMemo: res
          };
          if (!res) {
            vm.$Message.error(vm.mlang('orders.oRejectReason'));
            return false;
          }

          opposeOrder(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            if (isDetail === true) {
              vm.broadcast('Voucher', 'refresh');
            } else {
              vm.dispatch('VoucherList', 'reload');
            }
            instance.remove();
          });
        }
      });
    }
  }
};

export default mixin;

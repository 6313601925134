<template>
  <div class="sales-quotation-ref">
    <Row>
      <!-- 左侧销售报价列表 -->
      <div class="pull-left product-ref-center" style="width:200px">
        <Input
          ref="input"
          slot="input"
          v-model="searchSalesQuotationKeyword"
          placeholder="销售报价名称/编码"
          icon="md-search"
          class="contractQueryInput"
          @on-enter="salesQuotationSearch" />
        <Table
          ref="salesQuotationTableRef"
          :meta-setting="bizMeta"
          :width="500"
          :height="434"
          :columns="salesQuotationColumns"
          :data="salesQuotationData"
          :loading="salesQuotationLoading"
          @on-row-click="salesQuotationRowClick">
          <div slot="footer">
            <div style="float: right;">
              <Page
                :total="pager.totalCount"
                :current.sync="pager.currentPage"
                simple
                @hook:mounted="pageMounted"
                @on-change="changePage"></Page>
            </div>
          </div>
        </Table>
      </div>
      <!-- 右侧销售报价中的商品列表 -->
      <div
        v-if="cloneMeta.isAllowSelectSku && !cloneMeta.hideSkuTable"
        class="pull-right product-ref-right"
        style="width:600px; border-left: 0;">
        <QuerySchema
          v-if="false"
          ref="queryschema2"
          :b-refer="true"
          :biz-meta="cloneMeta"
          class="queryschema2"
          :filters="productListQueryInfo"
          @on-queryschema-search="productSearch" />
        <div v-else style="height: 68px;"></div>
        <Table
          :meta-setting="bizMeta"
          :columns="productColumn"
          :loading="productLoading"
          :data="productData"
          @on-selection-change="subTableSectionChange">
          <div slot="footer">
            <div style="float: right;">
              <Page
                :total="pager2.totalCount"
                :current.sync="pager2.currentPage"
                simple
                @on-change="changePage2"></Page>
            </div>
          </div>
        </Table>
      </div>
    </Row>

    <!-- 选取规格弹窗 -->
    <Modal
      v-model="isShowSkuTable"
      class-name="orderSelected-modal"
      :width="450"
      :title="mlang('selectSku')">
      <!-- 规格列表 -->
      <Table
        :columns="skuTableHeader"
        :loading="skuTableLoading"
        :highlight-row="true"
        :data="skuTableData"
        :width="450"
        :height="434"
        @on-selection-change="skuTableSectionChange">
      </Table>
      <div slot="footer">
        <Button
          type="text"
          class="cancel-btn"
          @click="selectSkuCancel">
          {{ mlang('cancel') }}
        </Button>
        <Button
          type="primary"
          @click="selectSkuSubmit">
          {{ mlang('confirmation') }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { oneOf, deepCopy } from '@/u-components/utils/assist';
import CommonMixin from '@/mixin/common.js';
import PropsyncMixin from '@/mixin/propsync.js';
import Mixin from '@/mixin/archiveList.js';
import _ from 'lodash';
import { salesQuotationList, getProductListByQuotationId } from '@/api/refer-modal';
import { getSkuAndParametersById } from '@/api/product';
export default {
  name: 'SalesQuotationProductTable',
  mixins: [CommonMixin, PropsyncMixin, Mixin],
  props: {
    selectedProductGroupId: Number,
    bizMeta: {
      type: Object,
      required: true
    },
    referType: {
      type: String,
      default: 'Table',
      validator(value) {
        return oneOf(value, ['Table', 'TreeTable']);
      }
    },
    isQueryschema: {
      type: [Boolean],
      default: true
    },
    billMeta: {
      default: {},
      type: Object
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: ''
    },
    showInput: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      selectedSalesQuotationRow: null, // 被点击销售报价的行信息
      searchSalesQuotationKeyword: '', // 查询销售报价搜索关键字
      searchProductKeyword: '', // 查询右侧商品列表关键字
      productListQueryInfo: [
        {
          name: 'searchProductKeyword',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          conditionType: 'eq',
          isShowIt: true,
          placeholder: this.mlang('orders.searchSrcDetailCode')
        }
      ], // 查询右侧商品列表搜索条件信息
      salesQuotationData: [], // 销售报价列表的数据
      productData: [], // 右侧商品列表数据
      subTableCheckedArr: [],
      cloneMeta: null,
      salesQuotationLoading: false,
      productLoading: false,
      pager: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      }, // 销售报价分页信息
      pager2: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      }, // 右侧商品列表分页信息
      productPage: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      },
      isShowSkuTable: false, // 是否展示选择规格弹窗
      skuTableLoading: false, // 是否启动规格弹窗加载动画
      selectedSkuList: [], // 被选中的规格列表数据
      productColumn: [], // 右侧商品的表头
      skuTableHeader: [], // 选择规格表头
      skuTableData: [], // 选择规格数据
      tempSkuDetailsArr: [], // 临时sku规格数组信息（当点击选择规格时如果已经选择过sku，sku列表应该进行勾选）
      clickRowIndex: 0, // 被点击的合同的数组下标
      tempSubTableDataArr: [], // 临时的存储的每一页合同明细
      rowno: 0, // 临时没有选去准确sku的明细标识
      cacheProductList: [], // 缓存的报价中的商品信息
      cacheSactId: 0 // 记录已经在下单好的销售报价id
    };
  },
  watch: {
    billMeta: {
      handler() {
        this.init();
      },
      deep: true
    }
  },
  created() {
    this.init();
  },
  mounted() {
    const iconSearch = document.querySelector('.product-ref-center .ivu-icon-md-search ');
    iconSearch.addEventListener('click', this.salesQuotationSearch);
  },
  methods: {
    init() {
      this.cloneMeta = deepCopy(this.billMeta);
      // 获取销售报价数据
      this.getSalesQuotationData();
      // 设置销售报价表头
      this.setSalesQuotationColumns();
      // 设置右侧商品列表表头
      this.setProductColumns();
    },
    pageMounted() {
      let vm = this;
      if (document.getElementsByClassName('ivu-page-simple-pager') && document.getElementsByClassName('ivu-page-simple-pager')[0]) {
        document.getElementsByClassName('ivu-page-simple-pager')[0].firstChild.onblur = function(e) {
          vm.pager.pageIndex = Number(e.target.value) || 1;
          vm.getSalesQuotationData();
        };
      }
    },
    // 获取左侧销售报价列表数据
    getSalesQuotationData() {
      // this.getCacheOrder();
      let query = {
        conditions: [],
        pager: {
          pageIndex: this.pager.pageIndex,
          pageSize: this.pager.pageSize
        }
      };
      query.conditions.push({
        name: 'keyWord',
        controlType: 'String',
        groupType: 2,
        valueType: 'STRING',
        conditionType: 'eq',
        isShowIt: true,
        v1: this.searchSalesQuotationKeyword
      });
      query.conditions.push({
        name: 'agentId',
        controlType: 'String',
        valueType: 'STRING',
        groupType: 2,
        conditionType: 'eq',
        v1: this.$store.getters.currentUser.agentId
      });
      query.conditions.push({
        name: 'saleOrgId',
        controlType: 'String',
        groupType: 2,
        valueType: 'STRING',
        conditionType: 'eq',
        v1: this.$getBO('ORDERSHOWWAY') ? this.cloneMeta?.order?.oAgent?.orgId : localStorage.getItem('upOrgId')
      });
      query.conditions.push({
        name: 'bizId',
        controlType: 'String',
        groupType: 2,
        valueType: 'STRING',
        conditionType: 'eq',
        v1: this.cloneMeta?.order?.oAgent?.bizId
      });

      this.salesQuotationLoading = true;
      salesQuotationList(query).then((res) => {
        this.salesQuotationData = res.data;
        this.pager.totalCount = res.totalCount;
        this.salesQuotationLoading = false;
      });
    },
    // 设置销售报价表头
    setSalesQuotationColumns() {
      const columns = [
        {
          showCaption: this.mlang('orders.salesQuotationName'),
          isShowIt: true,
          fieldName: 'name',
          width: 220
        },
        {
          showCaption: this.mlang('orders.salesQuotationCode'),
          fieldName: 'code',
          width: 220,
          isShowIt: true
        },
        {
          key: 'nowidth',
          title: ''
        }
      ];
      this.salesQuotationColumns = columns;
    },
    // 设置右侧商品列表表头
    setProductColumns() {
      const vm = this;
      let column = [
        {
          align: 'center',
          type: 'selection',
          width: 40
        },
        {
          showCaption: vm.mlang('orders.productName'), // 商品名称
          isShowIt: true,
          width: 150,
          fieldName: 'productId_name'
        },
        {
          showCaption: vm.mlang('productCode'), // 商品编码
          isShowIt: true,
          width: 130,
          fieldName: 'productId_code'
        },
        {
          showCaption: vm.mlang('guige'), // 规格
          isShowIt: true,
          width: 150,
          fieldName: 'specNames',
          render(_, params) {
            return (
              params.row.productId ? (
                params.row.skuId ? (
                  <div>{ params.row.specNames || '_' }</div>
                ) : (
                  <div onClick={() => vm.specReferClick(params)} style="cursor: pointer;">
                    选择规格
                    <Icon type="navicon-round" style="color: #808695; paddingLeft: 10px" />
                  </div>
                )
              ) : ''
            );
          }
        },
        {
          showCaption: vm.mlang('orders.skuName'), // sku名称
          isShowIt: true,
          width: 150,
          fieldName: 'skuId_name'
        },
        {
          showCaption: vm.mlang('skuCode'), // sku编码
          isShowIt: true,
          width: 150,
          fieldName: 'skuId_code'
        },
        {
          showCaption: vm.mlang('orders.priceQty'), // 计价数量
          isShowIt: true,
          width: 100,
          fieldName: 'priceQty',
          render(_, params) {
            return (
              params.row._disabled || vm.$getBO('CHOICEUNITTYPE') === 'AUXUNIT' ? (
                <div style="padding-left: 7px">
                  {params.row.subQty}
                </div>
              ) : (
                <input
                  class="ivu-input-number-input"
                  value={params.row.priceQty}
                  onChange={(e) => vm.changeSubQtyValue(e, params, true)}
                  type="number"
                  spellcheck="false"
                  autocomplete="off"
                />
              )
            );
          }
        },
        {
          showCaption: vm.mlang('orders.cqtUnitName'), // 计价单位
          isShowIt: true,
          width: 100,
          fieldName: 'productUnitModelVo.cName'
        },
        {
          showCaption: vm.mlang('orders.subQty'), // 销售数量
          isShowIt: true,
          width: 100,
          fieldName: 'subQty',
          render(_, params) {
            return (
              params.row._disabled || vm.$getBO('CHOICEUNITTYPE') === 'ONLYUNIT' ? (
                <div style="padding-left: 7px">
                  {params.row.subQty}
                </div>
              ) : (
                <input
                  class="ivu-input-number-input"
                  value={params.row.subQty}
                  onChange={(e) => vm.changeSubQtyValue(e, params, false)}
                  type="number"
                  spellcheck="false"
                  autocomplete="off"
                />
              )
            );
          }
        },
        {
          showCaption: vm.mlang('orders.saleunitName'), // 销售单位
          isShowIt: true,
          width: 100,
          fieldName: 'productAuxUnitModelVo.cName'
        },
        {
          showCaption: vm.mlang('orders.finalOffer'), // 最终报价
          isShowIt: true,
          width: 100,
          fieldName: 'oriTaxUnitPrice'
        }
      ];
      // 启用批发销售单位停用隐藏销售数量和销售单位
      if (!this.$getBO('OPENAUXUNIT')) {
        column = column.filter(item => item.fieldName !== 'subQty');
        column = column.filter(item => item.fieldName !== 'productAuxUnitModelVo.cName');
      }
      this.productColumn = column;
    },
    // 销售报价顶部搜索事件
    salesQuotationSearch() {
      this.pager.pageIndex = 1;
      this.pager.currentPage = 1;
      this.getSalesQuotationData();
    },
    // 右侧商品列表顶部搜索事件
    productSearch(queryschema) {
      if (queryschema && queryschema.conditions && queryschema.conditions.length) {
        queryschema.conditions.forEach((item) => {
          if (item.name === 'searchProductKeyword') {
            this.searchProductKeyword = item.v1;
          }
        });
      }
      this.pager2.pageIndex = 1;
      this.pager2.currentPage = 1;
      this.getProductList();
    },
    // 是否有销售报价明细缓存
    getCacheOrder() {
      this.cacheProductList = [];
      let order = this.cloneMeta.order;
      order && order.oOrderDetails.map((v) => {
        delete (v.id);
        if (v.actionStatus !== 'D') {
          this.cacheProductList.push(v);
        }
      });
      if (this.cacheProductList.length > 0) {
        this.cacheSactId = this.cacheProductList[0].sactId;
      }
    },

    // 左侧销售报价列表行点击事件
    salesQuotationRowClick(currentRow, index) {
      // 每次切换合同重制合左侧合同明细分页
      this.pager2.pageIndex = 1;
      this.pager2.currentPage = 1;
      // TODO 暂不支持商品搜索功能UDH-81391
      // this.$refs.queryschema2.resetViewData();
      this.searchProductKeyword = '';
      if (this.subTableCheckedArr.length > 0 && this.selectedSalesQuotationRow.id !== this.cacheSactId) {
        if (this.selectedSalesQuotationRow.id !== currentRow.id) {
          this.$Modal.confirm({
            title: '',
            content: this.mlang('orders.changeSalesQuotationWarning'),
            onOk: () => {
              // 切换销售报价清除临时的存储的每一页合同明细
              this.subTableCheckedArr = [];
              this.tempSubTableDataArr = [];
              this.$refs.salesQuotationTableRef.handleCurrentRow('highlight', index);
              this.tempSkuDetailsArr = [];
              this.getProductList(currentRow.id);
            },
            onCancel: () => {}
          });
        }
      } else {
        this.$refs.salesQuotationTableRef.handleCurrentRow('highlight', index);
        if (this.selectedSalesQuotationRow) {
          if (this.selectedSalesQuotationRow.id !== currentRow.id) {
            this.getProductList(currentRow.id);
          }
        } else {
          this.getProductList(currentRow.id);
        }
      }
      this.selectedSalesQuotationRow = deepCopy(currentRow);
    },
    // 获取右侧商品列表
    getProductList(id) {
      this.getCacheOrder();
      let params = {
        conditions: [
          {
            name: 'quoteId',
            controlType: 'String',
            valueType: 'STRING',
            groupType: 2,
            conditionType: 'eq',
            isShowIt: true,
            v1: id || ''
          },
          {
            name: 'keyWord',
            controlType: 'String',
            groupType: 2,
            valueType: 'STRING',
            conditionType: 'eq',
            v1: this.searchProductKeyword
          }
        ],
        pager: {
          pageIndex: this.pager2.pageIndex,
          pageSize: this.pager2.pageSize
        }
      };

      // // 如果临时的存储的合同明细存在直接用临时存储的数据就行返回
      // const flag = this.tempSubTableDataArr.find(item => item.page == this.pager2.pageIndex);
      // if (flag) {
      //   this.productData = flag.productData;
      //   return
      // }

      this.productLoading = true;
      getProductListByQuotationId(params).then((res) => {
        if (this.cacheProductList.length > 0) {
          const processedData = this.assemblyData(this.cacheProductList, res.data);
          this.productData = processedData;
          let selectedArr = this.productData.filter(item => item._checked);
          this.subTableSectionChange(selectedArr, _, true); // 手动触发合同明细的被选中事件
        } else {
          res.data.forEach(item => {
            item.page = this.pager2.pageIndex;
          });
          this.productData = res.data;
        }

        this.productData.forEach(item => {
          if (!item.productId || !item.skuId) {
            item._disabled = true;
          }
          // 对被选中的合同明细进行查找如果存在将合同明细进行勾选
          const flag = this.subTableCheckedArr.find(selectedItem => selectedItem.skuId === item.skuId);
          if (flag) {
            item._checked = true;
          } else {
            item._checked = false;
          }
        });
        this.pager2.totalCount = res.totalElements;
        this.productLoading = false;
      });
    },
    // 右侧销售报价中的商品列表被选中事件
    subTableSectionChange(selection, row, status) {
      selection.forEach(item => {
        item.page = this.pager2.pageIndex;
      });
      this.subTableCheckedArr = this.subTableCheckedArr.filter(item => item.page !== this.pager2.pageIndex);
      this.subTableCheckedArr.push(...selection);
      // 以分页数据进行升序排列
      this.subTableCheckedArr = _.sortBy(this.subTableCheckedArr, (item) => {
        return item.page;
      });
      this.selectedSubTableData(this.subTableCheckedArr);
    },
    // 设置规格弹窗数据
    setSkuTableData(params) {
      this.skuTableLoading = true;
      const order = this.cloneMeta.order;
      getSkuAndParametersById({
        id: params.row.productId,
        orgId: order.salesOrgId,
        bizId: order.bizId
      }).then((res) => {
        // 设置规格表头
        this.skuTableHeader = [{ type: 'selection', width: 40, align: 'center', isHideCheckAll: false }, { title: this.mlang('guige'), key: 'cSpecDescription' }];
        // 设置规格表体数据
        this.skuTableData = res.lsProductSkus || [];
        this.skuTableData.forEach(item => {
          item.productName = res.cName;
          const tempObj = this.tempSkuDetailsArr.find(ContractDetailsItem => ContractDetailsItem.rowno === this.rowno);
          if (tempObj) {
            tempObj.skuList && tempObj.skuList.forEach(cItem => {
              if (cItem.skuId === item.id) {
                item._checked = true;
              }
            });
          }
        });
        this.selectedSkuList = this.skuTableData.filter(item => item._checked);
        this.skuTableLoading = false;
      });
    },
    // 选择规格弹窗的取消操作
    selectSkuCancel() {
      this.isShowSkuTable = false;
    },
    // 勾选规格事件 selection 被选中的行 row当前选择的行 status（true/false）
    skuTableSectionChange(selection, row, status) {
      this.selectedSkuList = selection;
      this.selectedSkuList.forEach(item => {
        item._checked = true;
      });
    },
    // 规格参照点击
    async specReferClick(params) {
      this.rowno = params.row.rowno;
      if (!this.tempSkuDetailsArr.find(item => item.rowno === params.row.rowno)) {
        this.tempSkuDetailsArr.push({ rowno: params.row.rowno });
      }
      this.clickRowIndex = params.index;
      this.isShowSkuTable = true;
      // 每次弹窗弹起规格列表表头、规格列表数据设置为空
      this.selectedSkuList = [];
      this.skuTableHeader = [];
      this.skuTableData = [];
      // 选择规格
      this.setSkuTableData(params);
    },
    // 选择规格的弹窗提交操作
    selectSkuSubmit() {
      const obj = this.productData[this.clickRowIndex];
      let newArr = [];
      // 点击的是商品参照按钮还是规格参照的按钮
      if (this.selectedSkuList.length) {
        this.selectedSkuList.forEach(item => {
          newArr.push({
            ...obj,
            productId: item.iProductId || '',
            productName: item.productName || '',
            skuId: item.id || '',
            specNames: item.cSpecDescription || '',
            skuName: item.name || '',
            priceQty: 0,
            iConversionRate: item.iConversionRate || 1,
            invExchRate: item.invExchRate || 1,
            invPriceExchRate: item.invPriceExchRate || 1,
            subQty: 0,
            bizId: item.bizId || '',
            bizProductId: item.bizProductId || '',
            modelDescription: item.modelDescription || '',
            model: item.model || '',
            _checked: true,
            _disabled: false
          });
        });
      } else {
        this.$Message.warning({ content: this.mlang('pleaseSelectSku') });
        return;
      }
      let rownoArr = this.productData.filter(item => item.rowno === this.rowno).slice(1);
      newArr.forEach((newItem, index) => {
        if (rownoArr.length) {
          let pacth = rownoArr.filter(item => item.skuId === newItem.skuId);
          if (pacth.length) {
            newArr[index] = pacth[0];
          }
        }
      });
      this.productData.splice(this.clickRowIndex + 1, rownoArr.length, ...newArr);
      this.tempSkuDetailsArr.forEach(item => {
        if (this.rowno === item.rowno) {
          item.skuList = newArr;
        }
      });
      let selectedArr = this.productData.filter(item => item._checked);
      this.subTableSectionChange(selectedArr, _, true); // 手动触发合同明细的被选中事件
      this.isShowSkuTable = false;
    },
    // 对销售合同明细从被选中的明细中进行查询判断其是否勾选
    selectedSubTableData(selectedArr) {
      this.productData.forEach(item => {
        // 查询当前销售合同明细是否在临时的被选中的skuId数组中
        let flag = selectedArr.find(subitem => item.skuId === subitem.skuId && item.rowno === subitem.rowno);
        if (flag) {
          item._checked = true;
        } else {
          item._checked = false;
        }
      });
    },
    // 手动修改销售数量并通过主辅计算率对计价数量进行赋值
    // isPriceQuantity是否是计价数量输入框
    changeSubQtyValue(e, params, isPriceQuantity) {
      const { target } = e;
      if (!Number(target.value)) {
        target.value = 0;
        this.productData.forEach((item, index) => {
          if (params.index === index) {
            item.subQty = target.value;
            item.priceQty = target.value;
          }
        });
        this.$Message.warning({ content: this.mlang('PleaseFillSalesQuantity') });
        return false;
      }
      this.productData.forEach((item, index) => {
        if (params.index === index) {
          if (e.target.value >= 0) {
            if (isPriceQuantity) {
              item.priceQty = +e.target.value;
              item.subQty = this.$util.toFixed(this.$util.numDiv(this.$util.numMulti(+e.target.value, item.invPriceExchRate), item.invExchRate), item.productAuxUnitModelVo.unitPrecision, item.productAuxUnitModelVo.unitRoundType);
            } else {
              item.subQty = +e.target.value;
              item.priceQty = this.$util.toFixed(this.$util.numDiv(this.$util.numMulti(+e.target.value, item.invExchRate), item.invPriceExchRate), item.productUnitModelVo.unitPrecision, item.productUnitModelVo.unitRoundType);
            }
          } else {
            item.subQty = 0;
            item.priceQty = 0;
            this.$Message.warning({ content: this.mlang('PleaseFillSalesQuantity') });
          }
        }
      });
      this.selectedSubTableData(this.subTableCheckedArr);
      let selected = this.productData.filter(item => item._checked);
      this.subTableSectionChange(selected, _, true); // 手动触发合同明细的被选中事件
    },
    // 提交销售报价时的校验  refer.vue文件 sureClick方法里面进行的调用
    getSelectRow() {
      if (!this.selectedSalesQuotationRow) {
        this.$Message.warning({ content: this.mlang('orders.pleaseSelectSalesQuotation') });
        return false;
      }
      if (!this.subTableCheckedArr.length) {
        this.$Message.warning({ content: this.mlang('pleaseCheckProduct') });
        return false;
      }
      // 检查被选中的销售合同是否没有选择规格/商品
      const ifHasProdductOrSku = this.subTableCheckedArr.some(item => {
        return item.productId === undefined || item.skuId === undefined;
      });
      if (ifHasProdductOrSku) {
        this.$Message.warning({ content: this.mlang('pleaseSelectSku') });
        return false;
      }

      // 是否没有填写商品数量
      const ifFillSalesQuantity = this.subTableCheckedArr.some(item => {
        return item.priceQty === 0;
      });

      if (ifFillSalesQuantity) {
        this.$Message.warning({ content: this.mlang('orders.PleaseProductQuantity') });
        return false;
      }
      return this.getSingleSelectRow();
    },
    getSingleSelectRow() {
      const srcBillOrder = this.selectedSalesQuotationRow;
      Object.assign(srcBillOrder, { bizId: this.billMeta.bizId });
      const srcBillDetails = this.subTableCheckedArr;
      srcBillOrder.orderDetails = srcBillDetails;
      return [srcBillOrder];
    },
    changePage(page) {
      this.pager.pageIndex = page;
      this.getSalesQuotationData();
    },
    changePage2(page) {
      // 存储每一页销售合同明细数据
      const flag = this.tempSubTableDataArr.find(item => item.page === this.pager2.pageIndex);
      if (!flag) {
        this.tempSubTableDataArr.push({ page: this.pager2.pageIndex, productData: this.productData });
      }
      this.pager2.pageIndex = page;
      this.getProductList();
    },
    // 合同参照下单再次点击销售合同参照将原有数据组装
    assemblyData(cacheData, newData) {
      this.tempSkuDetailsArr = [];
      const cloneNewData = JSON.parse(JSON.stringify(newData));

      newData.forEach(newItem => {
        const hasCache = cacheData.findIndex(cacheItem => newItem.id === cacheItem.sourceautoid);
        if (hasCache !== -1) {
          if (newItem.productId && newItem.skuId) {
            const cloneMacthIndex = cloneNewData.findIndex(item => item.id === newItem.id);
            const cacheMacthIndex = cacheData.findIndex(cacheItem => cacheItem.sourceautoid === newItem.id);
            cloneNewData[cloneMacthIndex]._checked = true;
            cloneNewData[cloneMacthIndex].subQty = cacheData[cacheMacthIndex].iAuxUnitQuantity;
            cloneNewData[cloneMacthIndex].priceQty = cacheData[cacheMacthIndex].iQuantity;
          }
          if (newItem.productId === undefined || newItem.skuId === undefined) {
            const macthIndex = cloneNewData.findIndex(item => item.id === newItem.id);
            const filterCacheData = cacheData.filter(cacheItem => cacheItem.sourceautoid === newItem.id);
            const MapFilterCacheData = filterCacheData.map(item => {
              return {
                ...newItem,
                productId: item.iProductId || '',
                productName: item.cProductName || '',
                productCode: item.cProductCode || '',
                skuId: item.iSKUId || '',
                specNames: item.cSpecDescription || '',
                skuName: item.oSKU?.name || '',
                iConversionRate: item.iConversionRate || 1,
                invExchRate: item.invExchRate || 1,
                invPriceExchRate: item.invPriceExchRate || 1,
                subQty: item.iAuxUnitQuantity, // 销售数量
                priceQty: item.iQuantity || '', // 计价数量
                cqtUnitName: item.cProductUnitName || '', // 计价单位
                saleunitName: item.cProductAuxUnitName || '', // 销售单位
                bizId: item.bizId || '',
                bizProductId: item.bizProductId || '',
                oriTaxUnitPrice: item.fSalePrice, // 含税单价
                oriUnitPrice: item.salePriceOrigTaxfree, // 无税单价
                oriSum: item.fSalePayMoney, // 含税金额
                oriMoney: item.salePayMoneyOrigTaxfree, // 无税金额
                model: (item.oSKU && item.oSKU.oProduct.model) || '', // 型号
                modelDescription: (item.oSKU && item.oSKU.oProduct.modelDescription) || '', // 规格说明
                _checked: true,
                _disabled: false
              };
            });
            cloneNewData.splice(macthIndex + 1, 0, ...MapFilterCacheData);
          }
        }
      });

      cloneNewData.forEach(newItem => {
        if (!this.tempSkuDetailsArr.find(item => item.rowno === newItem.rowno)) {
          this.tempSkuDetailsArr.push({ rowno: newItem.rowno });
        }
        if (newItem.productId === undefined || newItem.skuId === undefined) {
          const fData = cloneNewData.filter(fItem => fItem.id === newItem.id && fItem.productId && fItem.skuId);
          if (fData.length > 0) {
            this.tempSkuDetailsArr.forEach(item => {
              if (newItem.rowno === item.rowno) {
                item.skuList = fData;
              }
            });
          }
        }
      });
      return cloneNewData;
    }
  }
};
</script>
<style lang="less" scoped>
.sales-quotation-ref{
  .product-ref-center{
    border-right:transparent !important;
  }
  .contractQueryInput{
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 16px;
  }

  .orderSelected-modal {
    /deep/ .ivu-modal-content {
      .ivu-modal-body {
        height: 514px;
      }
    }
  }
  .pull-right {
    .ivu-table-wrapper {
      border-left: 1px solid #f0f0f0;
      border-top: 1px solid !important;
    }
  }
}

/deep/ .ivu-modal-content {
  .ivu-modal-body {
    display: flex;
  }
}
.product-ref-right {
  /deep/ .main-body {
    height: 340px;
  }
}
</style>

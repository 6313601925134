export default {
  banlanceTotal: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006DA","本次使用金额") /* "本次使用金额" */,
  banlanceSum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006DB","预付款余额") /* "预付款余额" */,
  normal: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006DC","普通") /* "普通" */,
  qichu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006DD","期初") /* "期初" */,
  payMoneysBeyondUnpaid: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006DE","支付金额大于待支付金额，请重新填写！") /* "支付金额大于待支付金额，请重新填写！" */,
  cpayNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006DF","支付单号") /* "支付单号" */,
  attachment: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E0","附件信息") /* "附件信息" */,
  backToConfirm: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E1","使用该支付方式支付成功后,请返回本页面对支付结果进行确认!") /* "使用该支付方式支付成功后,请返回本页面对支付结果进行确认!" */,
  noOpenQPay: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E2","您未开通快捷支付!") /* "您未开通快捷支付!" */,
  errorAndContactAdmin: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E3","发生未知支付错误,请联系管理员!") /* "发生未知支付错误,请联系管理员!" */,
  amountOfDocuments: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E4","单据金额") /* "单据金额" */,
  sumPayable: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E5","应付金额") /* "应付金额" */,
  settlementorg: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580004E","财务组织") /* "财务组织" */,
  payFinishDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E6","支付时间") /* "支付时间" */,
  accountOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E7","会计组织") /* "会计组织" */,
  billsDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E8","单据日期") /* "单据日期" */,
  pleaseSelectOnlinePaytype: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006E9","请选择网银付款方式!") /* "请选择网银付款方式!" */,
  pleaseSetPaytype: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006EA","请先设置付款方式!") /* "请先设置付款方式!" */,
  paymentForm: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006EB","支付单") /* "支付单" */,
  payFail: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006EC","支付失败") /* "支付失败" */,
  isPaySuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006ED","是否支付成功?") /* "是否支付成功?" */,
  settlementCanNotNull: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580004D","结算方式不能为空!") /* "结算方式不能为空!" */,
  pleaseCorrectEnterIAmout: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006EE","请正确填写付款金额!") /* "请正确填写付款金额!" */,
  fpayMoneyCanNotFu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006EF","订单支付金额不能为负!") /* "订单支付金额不能为负!" */,
  onlyQCCanFu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F0","只有期初付款单可以为负!") /* "只有期初付款单可以为负!" */,
  agentBankCanNotNull: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F1","付款银行不能为空!") /* "付款银行不能为空!" */,
  corpBankCanNotNull: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F2","收款银行不能为空!") /* "收款银行不能为空!" */,
  dateControl: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F3","到账时间不能小于支付时间!") /* "到账时间不能小于支付时间!" */,
  attachmentCanNotNull: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F4","附件信息不能为空!") /* "附件信息不能为空!" */,
  moneyControl: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F5","付款金额不能小于已核销金额【") /* "付款金额不能小于已核销金额【" */,
  billSuccessAndSure: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F6","支付单提交成功，请确保已线下支付") /* "支付单提交成功，请确保已线下支付" */,
  notFindOrder: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F7","没有找到订单信息!") /* "没有找到订单信息!" */,
  pleaseEnterIAmout: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F8","请填写付款金额") /* "请填写付款金额" */,
  paymoneySuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006F9","付款成功") /* "付款成功" */,
  continueToPay: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006FA","继续支付") /* "继续支付" */,
  pleaseSelectAgentBank: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006FB","请选择付款银行!") /* "请选择付款银行!" */,
  pleaseEnterIAmoutCorrect: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006FC","请输入正确的支付金额!") /* "请输入正确的支付金额!" */,
  paid: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006FD","已支付") /* "已支付" */,
  unpaid: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006FE","待支付") /* "待支付" */,
  unConfirm: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006FF","待审核") /* "待审核" */,
  frontUnPaid: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000700","定金待支付") /* "定金待支付" */,
  tailUnPaid: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000701","尾款待支付") /* "尾款待支付" */,
  enterPaid: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000702","已输入付款金额") /* "已输入付款金额" */,
  lowerthanNeedPaid: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000703","比本次待支付金额少") /* "比本次待支付金额少" */,
  equalthanNeedPaid: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000704","等于本次待支付金额") /* "等于本次待支付金额" */,
  higherthanNeedPaid: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000705","比本次支付金额多") /* "比本次支付金额多" */,
  selectPaid: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000706","已选支付总金额") /* "已选支付总金额" */,
  sweepCodePayment: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000707","扫码付款") /* "扫码付款" */,
  sweepCodePay: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000708","扫码支付") /* "扫码支付" */,
  pleaseUse: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000709","请使用") /* "请使用" */,
  sweep: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500070A","扫一扫扫描二维码支付") /* "扫一扫扫描二维码支付" */,
  paySuc: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500070B","支付成功") /* "支付成功" */,
  multiOrgTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500070C","付款金额不等于各行金额合计！") /* "付款金额不等于各行金额合计！" */

};

import { render, staticRenderFns } from "./promotionPoptip.vue?vue&type=template&id=67564bc7&scoped=true"
import script from "./promotionPoptip.vue?vue&type=script&lang=js"
export * from "./promotionPoptip.vue?vue&type=script&lang=js"
import style0 from "./promotionPoptip.vue?vue&type=style&index=0&id=67564bc7&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67564bc7",
  null
  
)

export default component.exports
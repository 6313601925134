import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import user from './modules/user';
import bank from './modules/bank';
import delivery from './modules/delivery';
import order from './modules/order';
import payment from './modules/payment';
// import getters from './getters';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    //
  },
  mutations: {
    //
  },
  actions: {
    //
  },
  modules: {
    bank,
    user,
    app,
    delivery,
    order,
    payment
  }
});

export default store;

<template>
  <div
    id="agent-middle-main"
    class="agent-middle-main">
    <!-- U订货轮播 -->
    <div v-if="agentIndex" class="agent-banner">
      <div class="agent-content">
        <div class="agent-content-center">
          <!-- 轮播 -->
          <Carouse />
          <!-- 热销商品 -->
          <HotProduct />
        </div>
        <div class="agent-content-right">
          <!-- 订单数据 -->
          <TradingData />
          <!-- 订货、退货、付款、退款金额 -->
          <OrderAmount />
          <!-- 公告列表 -->
          <NoticeList />
        </div>
      </div>
    </div>
    <!-- U订货-推荐商品 -->
    <ProductList
      v-if="recommondProduct.length && agentIndex"
      id="recommond-product"
      child-id="recommond-child"
      :products="recommondProduct"
      :promotion-flags="promotionFlags"
      :products-info="recommondProductInfo" />
    <!-- U订货-全部商品 -->
    <ProductList
      v-if="allProduct.length && agentIndex"
      id="all-product"
      child-id="all-child"
      :products="allProduct"
      :promotion-flags="promotionFlags"
      :products-info="allProductInfo" />
    <!-- 回到顶部 -->
    <BackToTop
      v-if="endFlag"
      ref="backToTop"
      :all-products="allProduct"
      :recommend-products="recommondProduct" />
    <div v-if="agentIndex" class="agent-footer-title">
      <P class="w w-left"></P>
      <P class="text">
        {{ mlang('hasOver') }}
      </P>
      <P class="w w-right"></P>
    </div>
    <Modal
      v-model="isShow"
      :title="modal.title"
      width="600"
      :mask-closable="false"
      class="home-modal-class"
      @on-cancel="cancel">
      <div
        class="modal-class"
        v-html="modal.noticeDetail && modal.noticeDetail.content">
      </div>
      <template v-if="modal.attachments && modal.attachments.length > 0">
        <p class="attach">
          {{ mlang('attachment') }}:
        </p>
        <VoucherPreviewUpload
          :form-data="modal"
          :editable="false"
          :form-file-data="modal.attachments" />
      </template>
      <a v-if="showPromotion" class="toPromotion" @click="toPromotion">
        {{ mlang('watchPromotionGoods') }}
      </a>
      <div slot="footer">
        <div class="notice-footer">
          <div class="nextAndPre">
            <i
              v-if="modal.index !== 0"
              class="iconfont zuojiantou "
              @click="preNotice"></i>
            <i v-else class="iconfont zuojiantoubukedian notClick"></i>
            <i
              v-if="modal.index !== popNoticeList.length - 1"
              class="iconfont youjiantou"
              @click="nextNotice"></i>
            <i v-else class="iconfont youjiantoubukedian notClick"></i>
          </div>
          <Checkbox v-model="hopeNotShowNotice">
            {{ mlang('bencibuzaitishi') }}
          </Checkbox>
          <a
            v-if="!modal.isReaded"
            class="active"
            @click="setRead">{{ mlang('setToReaded') }}</a>
          <a v-else class="notActive">{{ mlang('hasReaded') }}</a>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="modalShow"
      :mask-closable="false"
      :closable="false"
      class="agreement-modal-class"
      class-name="vertical-center-modal">
      <div class="agreement-content">
        <h2>{{ agreementModel.title }}</h2>
        <p class="agreementModelContent" v-html="agreementModel.content"></p>
        <a class="agree-btn" @click="agree()">同意</a>
      </div>
    </Modal>
  </div>
</template>

<script>
import '_assets/styles/agent-common.less';
import ajax from '@/libs/ajax';
import { getRecommendedProducts, getUpProducts, getProductPageCount } from '@/api/product.js';
import { getAgentPopNotice, confirmAgentNotice } from '@/api/productNotice';
import { getIsConfirmedServiceTerms, confirmServiceTerms } from '@/api/agents.js';
import { editProductPromotion } from '@/api/promotionZTE/productPromotion';
import { editSingleDiscountShow } from '@/api/promotionZTE/singleDiscountListShow';
import { getCombineDetail } from '@/api/promotionZTE/combineSetting';
import Carouse from './components/carouse'; // 轮播
import HotProduct from './components/hotProduct';
import TradingData from './components/tradingData';
import OrderAmount from './components/orderAmount';
import NoticeList from './components/noticeList';
import ProductList from './components/productList';
import BackToTop from './components/backToTop';
import VoucherPreviewUpload from '@/components/common/voucher/voucher-preview-upload.vue';
import UploadMixin from '@/mixin/upload.js';
export default {
  name: 'Home',
  components: {
    Carouse,
    HotProduct,
    TradingData,
    OrderAmount,
    NoticeList,
    ProductList,
    BackToTop,
    VoucherPreviewUpload
  },
  mixins: [UploadMixin],
  data() {
    return {
      recommondProduct: [],
      allProduct: [],
      promotionFlags: {
        discount: false,
        sale: false,
        gifts: false
      },
      recommondProductInfo: {
        ch_title: this.mlang('recommandProduct'),
        en_title: 'PICK OF THE WEEK',
        moreLink: ''
      },
      allProductInfo: {
        ch_title: this.mlang('allProducts'),
        en_title: 'All MERCHANDISE',
        moreLink: ''
      },
      isShow: false,
      hopeNotShowNotice: false,
      modal: {
        title: '',
        noticeDetail: {
          content: ''
        }
      },
      popNoticeList: [],
      endFlag: false, // 判断全部商品和推荐商品数据是否回来
      agentIndex: this.$hasAuth('agentIndex'),
      modalShow: false, // 是否显示协议框
      agreementModel: {}, // 显示后端传来的协议title和content
      isEnd: false,
      serviceTab: 0,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    showPromotion() {
      return (this.modal.productPromotionId || this.modal.entirePromotionId || this.modal.combinationPromotionId) && this.currentUser.__isU8C;
    },
    demensionType() {
      if (this.modal.productPromotionId) {
        return 7;
      } else if (this.modal.entirePromotionId) {
        return 8;
      } else if (this.modal.combinationPromotionId) {
        return 9;
      } else {
        return 0;
      }
    },
    ifProductionOptimize() {
      // UDH-55941-0金额商品不显示
      return this.$getBO('OPENPAGINATION');
    }
  },
  created() {
    if (this.$route.query.id && window.localStorage.getItem('toUrlPage') === 'true' && this.$route.query.bizProductId  && this.$route.query.to === 'productDetail') {
      let query = {};
      query = {
        id: this.$route.query.id,
        bizProductId: this.$route.query.bizProductId
      };
      this.$router.push({
        name: 'productDetail',
        query
      });
    }
    getIsConfirmedServiceTerms().then((res) => {
      if (res) {
        if (!res.confirmed) {
          // false 还没确认过
          this.modalShow = true;
          if (res.terms.title) {
            this.agreementModel = res.terms;
          }
        } else {
          this.modalShow = false;
        }
      } else {
        this.modalShow = false;
      }
    });
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
    if ((!user.relations || user.relations.length <= 0) && (!localStorage.getItem('tourist') || localStorage.getItem('tourist') === 'false')) {
      return;
    }
    if (this.agentIndex) {
      this.init();
    } else {
      this.$Message.warning('当前用户无权操作');
    }

    if (this.currentUser && !this.currentUser.__isRepresent && this.currentUser.__isAgentSide && !sessionStorage.getItem('hopeNotShowNotice')) {
      getAgentPopNotice().then((res) => {
        if (res && res.length > 0) {
          res.map((v, index) => {
            v.index = index;
            v.isReaded = false;
          });
          this.popNoticeList = res;
          this.modal = res[0];
          this.isShow = true;
        }
      });
    }
  },
  methods: {
    init() {
      // 缓存处理
      if (!this.$util.getLocalStorageAndTime('recoProducts') || !this.$util.getLocalStorageAndTime('allProducts') || !this.$getBO('ORDERSHOWWAY')) {
        this.getNewProductData();
      } else {
      // 获取推荐商品
        this.recommondProduct = this.$util.getLocalStorageAndTime('recoProducts').data;
        this.formatProducts(this.recommondProduct);
        // 全部商品
        this.allProduct = this.$util.getLocalStorageAndTime('allProducts').data.splice(0, 10);
        this.formatProducts(this.allProduct);
        this.endFlag = true;
        this.getNewProductData();
      }
    },
    async getNewProductData() {
      if (!window.localStorage.getItem('productPageSize')) {
        let pageSize = await getProductPageCount();
        window.localStorage.setItem('productPageSize', pageSize);
      }
      let data = {
        pageSize: Number(window.localStorage.getItem('productPageSize')) > 10 ? Number(window.localStorage.getItem('productPageSize')) : 10
      };
      if (this.ifProductionOptimize) {
        data.serviceTab = this.serviceTab;
        data.isEnd = this.isEnd;
      }
      const querys = [getRecommendedProducts(), getUpProducts(data, this.ifProductionOptimize)];
      ajax.all(querys)
        .then(ajax.spread((reco, all) => {
          this.$util.setLocalStorageAndTime('recoProducts', reco);
          this.$util.setLocalStorageAndTime('allProducts', all);
          // 获取推荐商品
          this.recommondProduct = reco.data;
          this.formatProducts(reco.data);
          // 全部商品
          this.allProduct = all.data.splice(0, 10);
          this.formatProducts(all.data);
          this.endFlag = true;
        }));
    },

    toPromotion() {
      let vm = this;
      let func = null;
      let postData = {
        id: this.modal.productPromotionId || this.modal.entirePromotionId || this.modal.combinationPromotionId,
        isCopy: !!this.isCopy
      };
      if (this.demensionType === 7) {
        func = editProductPromotion;
      } else if (this.demensionType === 8) {
        func = editSingleDiscountShow;
      } else if (this.demensionType === 9) {
        func = getCombineDetail;
      }
      func(postData).then((res) => {
        if ((res.isValid || res.pValid) && this.compareDate(res.startDate || res.pStartDate, res.endDate || res.pEndDate)) {
          vm.$router.push({
            name: 'products',
            query: {
              fromNotice: true,
              preId: this.modal.productPromotionId || this.modal.entirePromotionId || this.modal.combinationPromotionId,
              demensionType: this.demensionType
            }
          });
        } else {
          vm.$Message.warning(vm.mlang('promotionFinish'));
        }
      });
    },
    compareDate(d1, d2) {
      let curDate = new Date();
      let date1 = new Date(d1);
      let date2 = new Date(d2);
      return curDate >= date1 && curDate <= date2;
    },
    cancel() {
      this.isShow = false;
      this.popNoticeList = [];
      if (this.hopeNotShowNotice) {
        sessionStorage.setItem('hopeNotShowNotice', true);
      }
    },
    // 处理商品数据
    formatProducts(products) {
      const lsPromotionFlags = products && products.lsPromotionFlags;
      if (lsPromotionFlags) {
        for (let i = 0; i < lsPromotionFlags.length; i++) {
          switch (lsPromotionFlags[i]) {
            case this.mlang('dazhe'):
              this.promotionFlags.discount = true;
              break;
            case this.mlang('jianjia'):
              this.promotionFlags.sale = true;
              break;
            case this.mlang('zengpin'):
              this.promotionFlags.gifts = true;
              break;
            default:
              break;
          }
        }
      }
      products.map((v)=>{
        if(v.iMaxPrice === undefined){
          v.iMaxPrice = v.fSalePrice;
        }
        if(v.iMinPrice === undefined){
          v.iMinPrice = v.fSalePrice;
        }
      })
    },
    setRead() {
      this.modal.isReaded = true;
      this.popNoticeList[this.modal.index] = this.modal;
      confirmAgentNotice(this.modal.id).then((res) => {
      });
    },
    preNotice() {
      this.modal = this.popNoticeList[this.modal.index - 1];
    },
    nextNotice() {
      this.modal = this.popNoticeList[this.modal.index + 1];
    },
    // 用户同意协议内容
    agree() {
      confirmServiceTerms().then((res) => {
        this.modalShow = false;
      });
    }

  }
};
</script>
<style scoped lang="less">
  .home-modal-class /deep/ .ivu-modal-body{
    min-height:250px;
    max-height:543px;
    overflow-y: auto;
  }
  .home-modal-class /deep/ .modal-class{
    display: inline-block;
  }
  .home-modal-class /deep/ .toPromotion{
    color:#588CE9
  }
  .notice-footer{
    .active{
      color: #588CE9
    }
    .notActive{
      color: #999999
    }
    .nextAndPre{
      float: left;
      i{
        padding:5px;
        border: 1px solid #E4E4E4;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
          border: 1px solid #505766;
        }
      }
      .notClick{
        background-color: #E4E4E4;
        &:hover{
          border: 1px solid #E4E4E4;
        }
      }
    }
  }
  .agent-middle-main{
    min-height: 500px;
  }

/* 协议 */
.agreement-modal-class{
  width: 100%;
  z-index: 10001;
  top: 0;
}
.agreement-content{
  width: 1000px;
  height: 80%;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 10%;
  margin-left: -500px;
  z-index: 10002;
  border-radius: 10px;
  padding: 0 30px;
}
.agreement-content>h2{
  line-height: 60px;
  text-align: center;
  font-size: 20px;
}
.agreement-content>p{
  text-indent: 2em;
  overflow-y: auto;
  height: calc(~'100% - 140px');
}
.agreement-content a.agree-btn{
  width: 130px;
  height: 35px;
  border: 2px solid #28c4b2;
  border-radius: 30px;
  overflow: hidden;
  text-align: center;
  line-height: 31px;
  color: #28c4b2;
  display: block;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  margin-left: -65px;
  margin-top: 15px;
}
.agreement-content a.agree-btn:hover{
  background-color: #28c4b2;
  color: #fff;
}
.youjiantoubukedian, .zuojiantoubukedian{
  color: #e4e4e4;
}
</style>

<template>
  <div
    :style="styles"
    class="bg-white border-r">
    <Row v-if="!hideSearch" class-name="search-tree-2">
      <Col
        span="24"
        class-name="refer-tree-search-col">
      <AutoComplete
        ref="autocomplete"
        v-model="treeInput"
        :placeholder="mlang('nameOrCode')"
        :style="autoCompleteStyles"
        :transfer="false"
        icon="md-search"
        @on-search="handleSearchTree"
        @on-select="handleSearchSelect">
        <div
          v-if="searchDataArr.length"
          style="max-height:300px">
          <Option
            v-for="(item) in searchDataArr"
            :key="item.id"
            :title="item.title"
            :value="item.title"
            class="too-long-hide">
            {{ item.title }}
          </Option>
        </div>
      </AutoComplete>
      </Col>
    </Row>
    <Row
      :style="treeStyle"
      class-name="search-tree-3">
      <Col
        span="24"
        class-name="refer-tree-tree-col">
      <Tree
        ref="refer-tree"
        :data="cloneData"
        :multiple="multiple"
        :show-checkbox="multiple"
        :auto-check="autoCheck"
        @on-check-change="handleTreeChecked"
        @on-select-change="handleTreeSelect"></Tree>
      </Col>
    </Row>
  </div>
</template>

<script>
import { deepCopy } from '@/u-components/utils/assist';
import util from '@/libs/util.js';
import _ from 'lodash';
const prefixCls = 'ivu-select';
export default {
  name: 'ReferTree',
  components: {},
  props: {
    bizMeta: {
      type: Object
    },
    isFilter: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: []
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 455
    },
    multiple: {
      type: Boolean,
      default: false
    },
    autoCheck: {
      // 勾选父级 自动勾选子
      type: Boolean,
      default: true
    },
    hideSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cloneData: [],
      // flattenData: [],
      searchDataArr: [],
      treeInput: '',
      hasMouseHoverHead: false,
      prefixCls: prefixCls,
      showValue: ''
    };
  },
  computed: {
    styles() {
      return {
        width: this.width + 'px'
      };
    },
    autoCompleteStyles() {
      return {
        width: this.width - 40 + 'px'
      };
    },
    treeSpan() {
      return this.referType === 'TreeTable' ? 6 : 0;
    },
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-single`]: true,
          [`${prefixCls}-show-clear`]: this.showCloseIcon
        }
      ];
    },
    treeStyle() {
      return {
        height: this.height + 'px',
        overflow: 'auto'
      };
    },
    showPlaceholder() {
      let status = false;
      if (typeof this.value === 'string') {
        if (this.value === '') {
          status = true;
        }
      } else if (Array.isArray(this.value)) {
        if (!this.value.length) {
          status = true;
        }
      } else if (this.value === null) {
        status = true;
      }
      return status;
    },
    hasValue() {
      return !this.showPlaceholder;
    }
  },
  watch: {
    data: {
      handler() {
        this.setCloneData();
        // this.flattenData = this.flattenTreeData();
      },
      deep: true
    }
  },
  mounted() {
    // this.flattenData = this.flattenTreeData();
  },
  created() {
    this.setCloneData();
  },
  methods: {
    handleFocus(event) {
      this.$emit('on-focus', event);
    },
    handleBlur(event) {
      this.$emit('on-blur', event);
    },
    setCloneData() {
      const all = { title: this.mlang('ALL'), id: '' };
      if (this.isFilter) {
        this.cloneData = [all, ...this.data];
      } else {
        this.cloneData = this.data;
      }
    },
    setTreeExpand(selectData) {
      let expandIds = selectData.cPath || selectData.path;
      if (expandIds) {
        expandIds = _.split(expandIds, '|');
      }
      const mainKey = (this.bizMeta && this.bizMeta.delIdKey) || 'id';
      const reductExpand = (items) => {
        items.map((item) => {
          if (selectData[mainKey] === item[mainKey]) {
            this.$set(item, 'selected', true);
            this.$emit('on-tree-selected', [item]);
          } else {
            this.$set(item, 'selected', false);
          }
          if (_.includes(expandIds, item.id.toString())) {
            this.$set(item, 'expand', true);
          }
          if (item.children && item.children.length) {
            reductExpand(item.children);
          }
        });
      };
      reductExpand(this.cloneData);
    },
    handleSearchSelect(value) {
      if (value) {
        const selectData = this.findObjByTrue(this.data, value);
        selectData && this.emitTreeSelected([selectData]);
        this.setTreeExpand(selectData);
      }
    },
    handleTreeSelect(selectArr) {
      this.emitTreeSelected(selectArr);
    },
    handleTreeChecked() {
      this.$emit('on-check-change', this.getCheckedNodes());
    },
    getCheckedNodes() {
      const checked = this.$refs['refer-tree'].getCheckedNodes();
      return checked;
    },
    emitTreeSelected(nodes) {
      this.$emit('on-tree-selected', nodes);
    },
    handleSearchTree(query) {
      if (query === '') {
        this.searchDataArr = [];
      } else {
        this.searchDataArr = this.findObjsByTrue(this.data, query);
      }
      const inputField = this.$refs.autocomplete.$refs['select'];
      this.$nextTick(() => {
        inputField.broadcast('Drop', 'on-update-popper');
      });
    },
    // flattenTreeData() {
    //   return util.flattenDeepData(this.cloneData);
    // },
    // 不在存值每次从数据里直接找
    findObjByTrue(array = [], value, childKey = 'children') {
      let obj = null;
      if (array.length) {
        const reduce = (items, parent) => {
          items.map((item) => {
            if (item.title === value) {
              delete item[childKey];
              obj = item;
            }
            if (item[childKey]) {
              item[childKey].length && reduce(item[childKey], item);
            }
          });
        };
        reduce(array);
      }
      delete obj[childKey];
      return obj;
    },
    findObjsByTrue(array = [], query, childKey = 'children') {
      const flattenData = [];
      if (array.length) {
        const reduce = (items, parent) => {
          items.map((item) => {
            const cloneItem = deepCopy(item);
            delete cloneItem[childKey];
            if ((item.title && item.title.indexOf(query) > -1) || (item.cCode && item.cCode.indexOf(query) > -1) || (item.code && item.code.indexOf(query) > -1)) {
              flattenData.push(cloneItem);
            }
            if (item[childKey]) {
              item[childKey].length && reduce(item[childKey], item);
            }
          });
        };
        reduce(array);
      }
      return flattenData;
    }
  }
};
</script>

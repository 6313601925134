import ajax from '@/libs/ajax';
import { pathStringToArry, combDistrictStr } from '@/libs/district.js';

// 为收货地址、银行列表、发票列表添加 region
const setDistrictStr = res => { res && combDistrictStr(res); };

const batchSetDistrictStr = res => {
  // eslint-disable-next-line no-console
  console.log(res);
  res && res.content && res.content.forEach(setDistrictStr);
  return res;
};

// 零售相关
export const findURetail = () => {
  return ajax.request({
    url: '/pc/corp/CorprationSrv/findURetail',
    method: 'get'
  });
};

// 客户相关
export const isCreditworthermindUse = () => {
  return ajax.request({
    url: '/agent/infrastructure/isCreditworthermindUse?isArchive=1',
    method: 'get'
  });
};

export const getAgentInfoVo = (data) => {
  return ajax.request({
    url: `/agent/infrastructure/${data.agentRelationId}/agentinfovo?isArchive=1`,
    method: 'get'
  });
};
export const getTransfer = (data) => {
  return ajax.request({
    url: `/agent/infrastructure/${data.potentialAgentId}/potentialagent/transfer?isArchive=1`,
    method: 'get'
  });
};

export const saveAgentInfoVo = (data) => {
  delete data.erpCode;
  return ajax.request({
    url: '/agent/infrastructure/agentinfovo',
    method: 'post',
    data: {
      datatype: 'json',
      isArchive: 1,
      data
    }
  });
};

export const checkAuth = () => {
  return ajax.request({
    url: '/authority/function/checkAuth?auth=agentEdit&isArchive=1',
    method: 'get'
  });
};

// 新增状态或潜在客户转正时，为客户编码设置默认值
export const setDefaultCustomerCode = () => {
  return ajax.request({
    url: '/agent/infrastructure/agent/newcode?isArchive=1',
    method: 'get'
  });
};

export const getCorpAgentSize = () => {
  return ajax.request({
    url: '/passport/tenant/agentsize?isArchive=1',
    method: 'get'
  });
};

// 手机校验
export const checkMobile = (data) => {
  return ajax.request({
    url: `/passport/person/validkey/${data.mobile}?isArchive=1&countryCode=${data.countryCode}`,
    method: 'get'
  });
};

// 用户是否存在
export const isUserExist = (data) => {
  return ajax.request({
    url: '/pc/RegisterSrv/isUserExistInYht',
    method: 'post',
    data
  });
};

export const getNewAgentInfoVo = () => {
  return ajax.request({
    url: '/agent/infrastructure/agentinfovo/new',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 积分
export const getTotalPoint = (agentId) => {
  return ajax.request({
    url: `/uhy/member/${agentId}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 积分明细

export const getPointDetails = (mid, pager, params = {}) => {
  const data = {
    mid,
    ...params,
    ...pager
  };
  return ajax.request({
    url: '/uhy/point/query',
    method: 'post',
    data: {
      datatype: 'json',
      isArchive: 1,
      data
    }
  });
};

export const getCorpRelationEnable = (agentId) => {
  return ajax.request({
    url: `/agent/infrastructure/${agentId}/relation/exist`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 获取发票列表
export const getInvoices = (query) => {
  return ajax.request({
    url: '/agent/infrastructure/invoice/query',
    method: 'post',
    data: {
      data: query,
      datatype: 'json',
      isArchive: 1
    }
  }).then(batchSetDistrictStr);
};

// 获取发票列表
export const getInvoice = (id) => {
  return ajax.request({
    url: `/agent/infrastructure/invoice/id/${id}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  }).then(res => { setDistrictStr(res); return res; });
};
// 获取发票详情
export const getInvoiceDetail = (param) => {
  return ajax.request({
    url: `/agent/infrastructure/invoice/byTitleAndType?invoiceTypeId=${param.invoiceTypeId}&invoiceTitle=${param.invoiceTitle}&agentId=${param.agentId}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  }).then(res => { setDistrictStr(res); return res; });
};
// 删除发票
export const deleteInvoice = (id) => {
  return ajax.request({
    url: `/agent/infrastructure/invoice/${id}`,
    method: 'delete',
    data: {
      isArchive: 1
    }
  });
};

// 保存发票
export const saveInvoices = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/invoice',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const setDefaultInvoice = (id) => {
  return ajax.request({
    url: `/agent/infrastructure/invoice/setdefault/${id}`,
    method: 'get',
    data: {
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 客户联系人
// 获取联系人列表
export const getContacts = (data) => {
  return ajax.request({
    url: '/orginazation/agent/contacter/query/list',
    method: 'post',
    data: {
      data: data.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 获取联系人
export const getContact = (id) => {
  return ajax.request({
    url: `/orginazation/contacter/${id}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 获取账户信息里的联系人
export const getContactForAgent = (id, contacterType) => {
  return ajax.request({
    url: `/orginazation/contacter/${id}/${contacterType}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 保存联系人
export const saveContact = (data) => {
  return ajax.request({
    url: '/orginazation/contacter',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 删除联系人
export const deleteContact = (id) => {
  return ajax.request({
    url: `/orginazation/contacter/${id}`,
    method: 'delete',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const setDefaultContact = (id) => {
  return ajax.request({
    url: `/orginazation/agent/contacter/default/${id}`,
    method: 'put',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const setDefaultContactSub = (id) => {
  return ajax.request({
    url: `/orginazation/subagent/contacter/default/${id}`,
    method: 'put',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 获门店列表
export const getStoreShippingAddrs = (data) => {
  return ajax.request({
    url: '/retail/price/storeterminal',
    method: 'post',
    data: {
      data: data.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  }).then(batchSetDistrictStr);
};

// 客户收货地址
// 获取收货地址列表
export const getShippingAddrs = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/shiptoaddress/queryschema/list',
    method: 'post',
    data: {
      data: data.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  }).then(batchSetDistrictStr);
};

// 获取收货地址
export const getShippingAddr = (id) => {
  return ajax.request({
    url: `/agent/infrastructure/shiptoaddress/${id}/id`,
    method: 'get',
    data: {
      isArchive: 1
    }
  }).then(res => {
    return res && pathStringToArry(res);
  });
};

// 删除收货地址
export const deleteShippingAddr = (id) => {
  return ajax.request({
    url: `/agent/infrastructure/shiptoaddress/${id}`,
    method: 'delete',
    data: {
      isArchive: 1
    }
  });
};

// 保存收货地址
export const saveShippingAddr = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/shiptoaddress',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const setDefaultShippingAddr = (id) => {
  return ajax.request({
    url: `/agent/infrastructure/shiptoaddress/defaultaddress/${id}`,
    method: 'post',
    data: {
      isArchive: 1
    }
  });
};

// 银行账号
// 获取客户账号列表
export const getBanks = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/financial/query',
    method: 'post',
    data: {
      data: data.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  }).then(batchSetDistrictStr);
};

// 获取银行账号
export const getBank = (id) => {
  return ajax.request({
    url: `/agent/infrastructure/financial/${id}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  }).then(res => { setDistrictStr(res); return res; });
};

// 删除银行账号
export const deleteBank = (id) => {
  return ajax.request({
    url: `/agent/infrastructure/financial/${id}`,
    method: 'delete',
    data: {
      isArchive: 1
    }
  });
};

// 保存银行账号
export const saveBank = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/financial',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 启用停用银行账号
export const switchBankStatus = (id, status) => {
  return ajax.request({
    url: '/agent/infrastructure/financial/enabled',
    method: 'post',
    data: {
      data: {
        id,
        enabled: status
      },
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const setDefaultBank = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/financial/setdefault',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 针对特殊的客户，某些字段需根据此接口返回的数据判断可否修改
export const getAgentMainance = (data = [], agentId) => {
  let params = '';
  if (agentId) {
    params = '?agentId=' + agentId;
  }
  return ajax.request({
    url: `/agent/infrastructure/agent/adaptable${params}`,
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 获取客户子账号联系人列表
export const getSubAgentContacts = (data) => {
  return ajax.request({
    url: '/orginazation/subagent/contacter/query/list',
    method: 'post',
    data: {
      data: data.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};

<template>
  <div :class="isEletron ? 'electron-con' : 'main-con'">
    <Spin v-if="loading" size="large" fix></Spin>
    <SelectAgent
      ref="selectAgent"
      :multiple="false"
      @on-selected="$util.onSelectedAgent"></SelectAgent>
    <div
      v-show="!loading"
      v-if="page"
      ref="agentMain"
      :class="['agent-main', {'layout-color':layoutColor}]">
      <div ref="agentMainBox">
        <!-- U订货头部导航栏 -->
        <div id="top"></div>
        <AgentHeader />
        <!-- 中间 -->
        <div class="agent-middle">
          <!-- <transition name="fade"> -->
          <router-view :key="routerKey" ref="agentView" @hook:mounted="childMounted"></router-view>
        <!-- </transition> -->
        </div>

        <div v-if="isShowLimitInfo" class="pay-list">
          <div class="rowup">
            <span
              v-for="item in limitOrderInfo"
              :key="item.limitId">
              <span>{{ mlang('limitBizName') }}: {{ item.limtShopName }}</span>
              |<span class="time_limit">{{ mlang('limitOrderTime') }}: </span>
              <span v-for="item1 in item.limitOrderInfoTime" :key="item1.id">
                <span v-if="item1.limtDateDay">{{ mlang('monthly') }}{{ item1.limtDateDay }}{{ mlang('sun') }}</span>
                <span v-if="item1.limitWeekDay">{{ mlang('weekly') }}{{ weekMap.get(item1.limitWeekDay) }}</span>
                <span>{{ item1.limtStartTime }}</span>
                <span>{{ mlang('limitToTime') }}</span>
                <span>{{ item1.limtEndTime }}</span>|
              </span>
            </span>
          </div>
        </div>
        <div
          ref="agentFooter"
          class="agent-footer">
          <!-- U订货底部 -->
          <AgentFooter />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '_assets/styles/agent-common.less';
import { checkAuth, getHeartbeat, getLimitTime } from '@/api/auth/auth';
import { mapGetters, mapActions } from 'vuex';
import { findComponentDownward } from '@/u-components/utils/assist';
import AgentHeader from './components/header/header';
import LayoutMixin from '@/mixin/layout.js';
import AgentMid from './home/home';
import AgentFooter from './components/footer/footer';
import SelectAgent from '@/components/select-agent/select-agent.vue';
import { initorderheaders } from '@/api/order/order';
import { tokenAlive, getAgentFlag, clearYHTtoken } from '@/api/order';
import Cookies from 'js-cookie';
import util from '@/libs/util.js';
import { getAllRegion } from '@/api/region';
import printCookie from '@/components/print-ctrl/print-cookie.js';

export default {
  name: 'AgentMain',
  components: {
    AgentHeader,
    AgentFooter,
    AgentMid,
    SelectAgent
  },
  mixins: [LayoutMixin],
  provide() {
    return {
      mainResize: this.mainResize,
      showSelectAgent: this.showSelectAgent
    };
  },
  inject: ['getTheme', 'broadcastToExtend'],
  data() {
    return {
      limitOrderInfo: [],
      isShowLimitInfo: false,
      page: false,
      loading: false,
      layoutColor: false, // 中间内容边框颜色，true白色，false灰色
      timer: null,
      tokenAliveTimer: null,
      weekMap: new Map([
        ['1', this.mlang('mon')],
        ['2', this.mlang('tue')],
        ['3', this.mlang('wed')],
        ['4', this.mlang('thu')],
        ['5', this.mlang('fri')],
        ['6', this.mlang('sat')],
        ['7', this.mlang('sun')]
      ])
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    routerKey() {
      let key = this.$route.fullPath;
      if (this.$route.name === 'products') {
        key = this.$route.name;
      }
      return key;
    },
    isEletron() {
      return navigator.userAgent.indexOf('Electron') > 0;
    }
  },
  watch: {
    '$route.name'() {
      this.init();
    }
  },
  async created() {
    if (window.__app.urlMapping[window.location.host] && window.top === window.self) {
      this.$Modal.error({
        content: this.mlang('urlReplace') + window.__app.urlMapping[window.location.host] + '，' + this.mlang('favoriteUrl'),
        onOk: async function() {
          if (Cookies.get('hasAgentToken') === 'true') {
            // 清除原token
            await clearYHTtoken();
            printCookie.clearToken();
          }
          window.location.replace(window.__app.urlMapping[window.location.host]);
        }
      });
      return;
    }
    const self = this;
    Cookies.set('isAgent', 'true');
    if (this.$route.query.isYxyLogin) {
      localStorage.setItem('isCorpToOrder', true);
      window.localStorage.removeItem('initOrderHeaders');
      localStorage.removeItem('curAccount');
    }
    let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
    localStorage.yhtTenantId = user.yhtTenantId;
    localStorage.yhtUserId = user.yhtUserId;
    localStorage.enterpriseNature = user.enterpriseNature;
    let isAgent = await getAgentFlag();

    if (!isAgent && Cookies.get('hasAgentToken') === 'true' && user && !user.relations.length && !user.agentId) {
      this.$util.goLogin();
      return false;
    }
    if (user && JSON.stringify(user) !== '{}' && (!user.relations || user.relations.length <= 0) && (!localStorage.getItem('tourist') || localStorage.getItem('tourist') === 'false')) {
      Cookies.remove('userId');
      Cookies.remove('tenantId');
      this.$Modal.error({
        content: self.mlang('notHasRelationsMesage'),
        onOk: () => {
          this.$util.goLogin();
        }
      });
      return false;
    }
    if (user && user.corpName && window.top !== window.self) {
      window.parent.postMessage({ key: 'changeAccount', tenantName: user.corpName }, '*');
    }
    if (user && user.userId) {
      Cookies.set('userId', user.userId);
    }
    let agentArr = [];
    let bizIdArr = [];
    let saleOrgIds = [];
    if (this.$getBO('ORDERSHOWWAY')) {
      user.relations.map((v) => {
        bizIdArr.push(v.bizId);
        saleOrgIds.push(v.orgId);
      });
      saleOrgIds.push('666666');
    } else {
      if (this.currentUser.singleBizRelation && this.currentUser.singleBizRelation.currentTradingBiz && this.currentUser.singleBizRelation.currentTradingBiz.agentRelationId) {
        agentArr.push(this.currentUser.singleBizRelation.currentTradingBiz.agentRelationId);
        bizIdArr.push(this.currentUser.singleBizRelation.currentTradingBiz.bizId);
        saleOrgIds.push(localStorage.getItem('upOrgId'));
      }
    }
    bizIdArr.length > 0 && this.getBizLimitTime(bizIdArr, saleOrgIds);

    // 设置初始化订单表头，只单商家模式调用，标识存储为2小时
    if (!this.$getBO('ORDERSHOWWAY') && !util.getStorageExpire('initOrderHeaders')) {
      initorderheaders(agentArr).then(() => {
        util.setStorageExpire('initOrderHeaders', 'true', 1000 * 60 * 2);
      });
    }

    sessionStorage.isAgentSide = true;
    this.$store.dispatch('getUeConfig');
    this.getTheme();
    this.determineCurrency();
    // 服务心跳在ys环境下有
    if (this.currentUser.__isU8C) {
      this.serviceHeart();
    }
    this.init();
  },
  async mounted() {
    window.addEventListener('scroll', this.scollHandle, true);
    // 快速下单加入购物车和新界面购物车数量联动
    window.getShoppingCount = this.getShoppingCount;
    this.tokenAliveTimer = setInterval(function() {
      if (window.localStorage.getItem('yhtRefreshTime')) {
        var s1 = new Date();
        var s2 = new Date(window.localStorage.getItem('yhtRefreshTime'));
        var space = (s1 - s2) / 1000 / 60 / 60;
        if (space && space >= 0.5) {
          tokenAlive().then((data) => {
            window.localStorage.setItem('yhtRefreshTime', new Date());
          }).catch((err) => {
            clearInterval(this.tokenAliveTimer);
            if (err && err.code === 401) {
              clearInterval(this.timer);
            }
          });
        }
      }
    }, 1000 * 60 * 10);
    if (!window.YYCooperationBridge) {
      util.loadScript(window.__app.context.uploadServerUrl + '?locale=' + window.globalization.locale);
    }
    const dbManager = await util.getIndexedDBManager();
    let regionList = await dbManager.getItem('regionList');
    !regionList && getAllRegion().then(async(res) => {
      await dbManager.setItem('regionList', JSON.stringify(res));
    });
  },
  updated() {},
  beforeDestory() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.tokenAliveTimer);
    this.tokenAliveTimer = null;
  },
  methods: {
    ...mapActions(['setUserInfo']),
    // 根据所在页面，加样式
    childMounted() {
      this.loading = false;
      const vm = this.$refs['agentView'];
      this.broadcastToExtend({ action: 'agentVueMounted', params: { vm } });
    },
    async getBizLimitTime(param, saleOrgIds) {
      let _this = this;
      const params = {
        bizIds: param,
        orgIds: saleOrgIds,
        agentId: this.currentUser.agentId
      };
      await getLimitTime(params).then(res => {
        if (res.length > 0) {
          let limitOrderInfo = [];
          params?.orgIds?.map(item => {
            let _index = limitOrderInfo.filter(val => {
              return String(val.limitId) === item;
            });
            if (_index.length === 0) {
              let limitOrderInfo1 = [];
              let limitOrderInfoTime = [];
              let limitOrderInfo2 = res.filter(val => {
                return val.orgId === item;
              });

              limitOrderInfo2 && limitOrderInfo2.map(val2 => {
                limitOrderInfoTime.push({
                  limtDateDay: val2.dateDay,
                  limitWeekDay: val2.weekDay,
                  limtStartTime: val2.startTime,
                  limtEndTime: val2.endTime
                });
              });
              if (limitOrderInfo2.length > 0 || limitOrderInfoTime > 0) {
                limitOrderInfo1.push({
                  limitId: item,
                  limtShopName: limitOrderInfo2[0].shopName,
                  limitOrderInfoTime: limitOrderInfoTime
                });
              }
              limitOrderInfo = limitOrderInfo.concat(limitOrderInfo1);
            }
          });
          _this.isShowLimitInfo = true;
          _this.limitOrderInfo = limitOrderInfo;
        } else {
          _this.isShowLimitInfo = false;
        }
        // window.localStorage.setItem('limitOrderInfo', JSON.stringify(res));
      }).catch(() => {
        _this.isShowLimitInfo = false;
      });
    },
    getShoppingCount() {
      this.$store.dispatch('GetShoppingNum');
    },
    scollHandle(e) {
      if (this.$refs.agentMainBox && this.$refs.agentFooter.offsetHeight) {
        if (this.$refs.agentMainBox.offsetHeight - document.documentElement.scrollTop - document.body.clientHeight <= this.$refs.agentFooter.offsetHeight) {
          const Home = findComponentDownward(this, 'Home');
          if (Home) {
            if (Home.$refs.backToTop) {
              Home.$refs.backToTop.bottom = (document.body.clientHeight - this.$refs.agentFooter.getBoundingClientRect().top + 5) + 'px';
            }
          }
        } else {
          const Home = findComponentDownward(this, 'Home');
          if (Home) {
            if (Home.$refs.backToTop) {
              Home.$refs.backToTop.bottom = '64px';
            }
          }
        }
      }
    },
    init() {
      // 调整父组件颜色
      const routerName = this.$route.name;
      switch (routerName) {
        case 'products':
          this.layoutColor = true;
          break;
        default:
          this.layoutColor = false;
          break;
      }
      this.checkInsteadAgent();
    },
    /**
     * 该方法用于检测 其他页签 换账号 或者换代下单的客户
     */
    async checkInsteadAgent() {
      const storeUserId = this.currentUser.userId;
      let currentUser = {};
      try {
        currentUser = JSON.parse(localStorage.defaultUserInfo);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.page = true;
        return;
      }
      const currentUserId = currentUser.userId;
      const currentIsAgent = currentUser.isAgent;
      if (storeUserId === currentUserId) {
        this.page = true;
      } else {
        if (currentIsAgent) {
          window.location.reload();
        } else {
          const hasAuth = await checkAuth('insteadOrder').catch(e => { this.page = true; });
          if (hasAuth) {
            this.page = false;
            this.showSelectAgent();
          } else {
            this.$Modal.error({
              content: this.mlang('noinsteadOrderauth'),
              onOk: () => {
                window.location.href = '/corp';
              }
            });
          }
        }
      }

      if (storeUserId !== currentUserId) {
        const hasAuth = await checkAuth('insteadOrder');
        if (hasAuth) {
          const storeUpAgentId = this.currentUser.__upAgentId;
          const currentUpAgentId = localStorage.upAgentId;
          if (storeUpAgentId !== currentUpAgentId || currentUpAgentId === undefined) {
            this.showSelectAgent();
            this.page = false;
          } else {
            this.page = true;
          }
        } else {
          this.$Modal.error('noinsteadOrderauth');
        }
      }
    },
    showSelectAgent() {
      this.$refs.selectAgent.showRefModal();
    },
    resetPage() {
      let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
      let bizIdArr = [];
      let saleOrgIds = [];
      if (this.$getBO('ORDERSHOWWAY')) {
        user.relations.map((v) => {
          bizIdArr.push(v.bizId);
          saleOrgIds.push(v.orgId);
        });
        saleOrgIds.push('666666');
      } else {
        bizIdArr.push(localStorage.upBizId);
        saleOrgIds.push(localStorage.getItem('upOrgId'));
      }
      this.loading = true;
      this.page = false;
      setTimeout(() => {
        this.page = true;
        bizIdArr.length > 0 && this.getBizLimitTime(bizIdArr, saleOrgIds);
      }, 10);
    },
    mainResize() {},
    // 判断当前客户关系是否有币种
    determineCurrency() {
      let upAgentRelationId = localStorage.getItem('upAgentRelationId');
      this.currentUser.relations.forEach((item) => {
        if (upAgentRelationId === (item.id + '') && !item.currencyId) {
          this.$Modal.error({
            content: this.mlang('notHasCurrencyMessage')
          });
        }
      });
    },
    // 服务心跳
    serviceHeart() {
      var that = this;
      // 如果自己配置，在配置中定的是分钟。默认是5秒
      let time = window.__app.context.heartbeatTime ? (window.__app.context.heartbeatTime * 60 * 1000) : (600000);
      this.timer = setInterval((function fn() {
        getHeartbeat().then((res) => {
          let { status, message } = res;
          if (status === 'EXCEEDED' || status === 'EXPIRED') {
            clearInterval(that.timer);
            that.$Modal.error({
              content: message,
              onOk: () => {
                Cookies.remove('userId');
                Cookies.remove('tenantId');
                window.location.href = '/logout';
              }
            });
          }
        }).catch(() => {
          clearInterval(that.timer);
          clearInterval(that.tokenAliveTimer);
        });
        return fn;
      }()), time);
    }
  }

};
</script>

<style lang="less" scoped>
.main-con{
  width:100%;
  height:100%
}
.electron-con{
  width:100%;
  height:100%;
  overflow-y: scroll;
}
.agent-middle {
  width: 1200px;
  margin:0 auto;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.pay-list {
  width:100%;
  height:30px;
  background-image: linear-gradient(180deg, #FFF8EA 0%, #FFFBF4 100%);
  box-shadow: 0px -2px 16px 0px rgba(80,87,102,0.1);
  color: #C69C54;
  margin:30px 0 -30px;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden
}
.pay-list .rowup {
    display: inline-block;
    white-space: nowrap;
    -webkit-animation: 15s rowup linear infinite normal;
    animation: 15s rowup linear infinite normal;
    position: relative;
    text-align: right;
    line-height: 20px;
    letter-spacing: 3px;
    width: 70%;
    height: 30px;
    box-sizing: border-box;
    padding: 5px;
}
.pay-list .rowup h3 .time_limit{
  margin-left: 30px;
}
.pay-list .rowup h3 .time_limit .time_limit_2{
  margin-right: 10px;

}
@keyframes rowup {
  0% {
			transform: translateX(100%);
			-webkit-transform: translateX(100%);
		}
		100% {
			transform: translateX(-100%);
			-webkit-transform: translateX(-100%);
		}
}
</style>

import { invoiceAgentName } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const InvoiceAgentNameRefer = (ctrl, vm, params) => {
  const control = Object.assign(
    {},
    {
      page: false,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'invoicingCustomersId',
      refName: 'invoiceAgentName',
      columns: [
        {
          showCaption: vm.mlang('agentCode'),
          isShowIt: true,
          fieldName: 'invoicingCustomersCode',
          width: 120
        },
        {
          showCaption: vm.mlang('agentName'),
          fieldName: 'invoiceAgentName',
          width: 180,
          isShowIt: true
        }
      ],
      actions: { queryList: () => invoiceAgentName({ agentId: params.agentId, orgId: params.orgId }) },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default InvoiceAgentNameRefer;

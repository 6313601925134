<template>
  <div :class="wrapClasses" >
    <div v-if="showUpDown" :class="handlerClasses">
      <a
        :class="upClasses"
        @click="up">
        <span :class="innerUpClasses" @click="preventDefault"></span>
      </a>
      <a
        :class="downClasses"
        @click="down">
        <span :class="innerDownClasses" @click="preventDefault"></span>
      </a>
    </div>
    <div
      :class="inputWrapClasses"
      :title="disabled ? formatterValue : ''">
      <input
        :id="elementId"
        ref="input"
        :class="inputClasses"
        :disabled="disabled"
        :autofocus="autofocus"
        :readonly="readonly || !editable"
        :name="name"
        :maxlength="maxlength"
        :value="formatterValue"
        :placeholder="placeholder"
        autocomplete="off"
        spellcheck="false"
        :fieldid="fieldid"
        @focus="focus"
        @blur="blur"
        @keydown.stop="keyDown"
        @keyup.enter="handleEnter"
        @input="change"
        @mouseup="preventDefault"
        @change="change">
    </div>
  </div>
</template>
<script>
import { oneOf } from '../../utils/assist';
import Emitter from '../../mixins/emitter';
import util from '@/libs/util';

import _ from 'lodash';
const prefixCls = 'ivu-input-number';
const iconPrefixCls = 'ivu-icon';

function addNum(num1, num2) {
  let sq1, sq2, m;
  try {
    sq1 = num1.toString().split('.')[1].length;
  } catch (e) {
    sq1 = 0;
  }
  try {
    sq2 = num2.toString().split('.')[1].length;
  } catch (e) {
    sq2 = 0;
  }
  m = Math.pow(10, Math.max(sq1, sq2));
  return (Math.round(num1 * m) + Math.round(num2 * m)) / m;
}

export default {
  name: 'InputNumber',
  mixins: [Emitter],
  props: {
    fieldid:{
      type: String,
      default: 'inputNumber'
    },
    max: {
      type: Number,
      default: Infinity
    },
    min: {
      type: Number,
      default: -Infinity
    },
    maxlength: {
      type: Number,
      default: 9
    },
    step: {
      type: Number,
      default: 1
    },
    value: {
      type: [Number, String],
      default: ''
    },
    size: {
      type: String,
      validator(value) {
        return oneOf(value, ['small', 'large', 'default']);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    canNull: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    precision: {
      type: Number
    },
    elementId: {
      type: String
    },
    parser: {
      type: Function
    },
    maxFn: {
      type: Function
    },
    placeholder: {
      type: String,
      default: ''
    },
    unit: {
      type: String
    },
    roundType: {
      type: Number,
      default: 4
    },
    string: {
      type: Boolean,
      default: false
    },
    showUpDown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focused: false,
      upDisabled: false,
      downDisabled: false,
      formatter: util.formatNumberFn,
      currentValue: this.value
    };
  },
  computed: {
    wrapClasses() {
      return [
        `${prefixCls}`,
        'ivu-input-wrapper',
        {
          [`${prefixCls}-${this.size}`]: !!this.size,
          [`${prefixCls}-disabled`]: this.disabled,
          [`${prefixCls}-focused`]: this.focused
        }
      ];
    },
    handlerClasses() {
      return `${prefixCls}-handler-wrap`;
    },
    upClasses() {
      return [
        `${prefixCls}-handler`,
        `${prefixCls}-handler-up`,
        {
          [`${prefixCls}-handler-up-disabled`]: this.upDisabled
        }
      ];
    },
    innerUpClasses() {
      return `${prefixCls}-handler-up-inner ${iconPrefixCls} ${iconPrefixCls}-ios-arrow-up`;
    },
    downClasses() {
      return [
        `${prefixCls}-handler`,
        `${prefixCls}-handler-down`,
        {
          [`${prefixCls}-handler-down-disabled`]: this.downDisabled
        }
      ];
    },
    innerDownClasses() {
      return `${prefixCls}-handler-down-inner ${iconPrefixCls} ${iconPrefixCls}-ios-arrow-down`;
    },
    inputWrapClasses() {
      return `${prefixCls}-input-wrap`;
    },
    inputClasses() {
      return `${prefixCls}-input`;
    },
    precisionValue() {
      // can not display 1.0
      if (!this.currentValue) {
        return this.currentValue;
      }
      if (!_.isUndefined(this.precision)) {
        return this.fixNum(this.currentValue + '');
      } else {
        return this.currentValue;
      }
    },
    formatterValue() {
      if (this.formatter && this.precisionValue !== null) {
        if (this.focused) {
          return this.precisionValue;
        }
        return this.formatter(this.precisionValue);
      } else {
        return this.precisionValue;
      }
    }
  },
  watch: {
    value(val) {
      if (this.string) {
        val = val + '';
      }
      this.currentValue = val;
    },
    currentValue(val) {
      this.changeVal(val);
    },
    min() {
      this.changeVal(this.currentValue);
    },
    max() {
      this.changeVal(this.currentValue);
    }
  },
  mounted() {
    this.changeVal(this.currentValue);
    // 添加单位所占据的宽度，避免挡住输入框
    if (this.unit) this.addUnitwith();
  },
  methods: {
    preventDefault(e) {
      e.preventDefault();
    },
    up(e) {
      const targetVal = Number(e.target.value);
      if (this.upDisabled && isNaN(targetVal)) {
        return false;
      }
      this.changeStep('up', e);
    },
    down(e) {
      const targetVal = Number(e.target.value);
      if (this.downDisabled && isNaN(targetVal)) {
        return false;
      }
      this.changeStep('down', e);
    },
    changeStep(type, e) {
      if (this.disabled || this.readonly) {
        return false;
      }

      const targetVal = Number(e.target.value);
      let val = Number(this.currentValue);
      const step = Number(this.step);
      if (isNaN(val)) {
        return false;
      }

      // input a number, and key up or down
      if (!isNaN(targetVal)) {
        if (type === 'up') {
          if (addNum(targetVal, step) <= this.max) {
            val = targetVal;
          } else {
            return false;
          }
        } else if (type === 'down') {
          if (addNum(targetVal, -step) >= this.min) {
            val = targetVal;
          } else {
            return false;
          }
        }
      }

      if (type === 'up') {
        val = addNum(val, step);
      } else if (type === 'down') {
        val = addNum(val, -step);
      }
      this.$nextTick(() => {
        this.$emit(type, val);
      });
      this.setValue(val);
    },
    setValue(val) {
      // 如果 step 是小数，且没有设置 precision，是有问题的
      // if (val && !isNaN(this.precision)) val = Number(Number(val).toFixed(this.precision));
      // 如果 step 是小数，且没有设置 precision，是有问题的
      if (val && !isNaN(this.precision)) {
        val = this.fixNum(val + '');
      } else if (val === null) {
        if (this.canNull) {
          val = '';
        } else {
          val = 0;
        }
      } else {
        val = Number(val);
      }

      this.$nextTick(() => {
        this.currentValue = val;
        if (this.string) {
          val = val + '';
        }
        this.$emit('input', val);
        this.$emit('on-change', val);
        this.dispatch('FormItem', 'on-form-change', val);
      });
    },
    focus(event) {
      this.focused = true;
      this.$emit('on-focus', event);
    },
    handleEnter(event) {
      this.change(event);
      this.$emit('on-enter', this.currentValue);
    },
    blur() {
      this.focused = false;
      this.$nextTick(() => {
        this.$emit('on-blur', this.currentValue);
        this.dispatch('FormItem', 'on-form-blur', this.currentValue);
      });
    },
    keyDown(e) {
      if (e.keyCode === 38) {
        e.preventDefault();
        this.up(e);
      } else if (e.keyCode === 40) {
        e.preventDefault();
        this.down(e);
      }
    },
    change(event) {
      let val = event.target.value.trim();

      if (event.type === 'input' && val.match(/^\-?\.?$|\.$/)) return; // prevent fire early if decimal. If no more input the change event will fire later

      const { min, max } = this;
      const isEmptyString = val.length === 0;
      val = Number(val);

      if (isEmptyString) {
        this.setValue(null);
        return;
      }
      if (event.type === 'change') {
        if (val === this.currentValue && val > min && val < max) return; // already fired change for input event
      }

      if (!isNaN(val) && !isEmptyString) {
        this.currentValue = val;

        if (event.type === 'input' && val < min) return; // prevent fire early in case user is typing a bigger number. Change will handle this otherwise.
        if (val > max) {
          if (this.maxFn) this.maxFn();
          this.setValue(max);
        } else if (val < min) {
          this.setValue(min);
        } else {
          this.setValue(val);
        }
      } else {
        event.target.value = this.currentValue;
      }
    },
    changeVal(val) {
      val = Number(val);
      if (!isNaN(val)) {
        const step = this.step;

        this.upDisabled = val + step > this.max;
        this.downDisabled = val - step < this.min;
      } else {
        this.upDisabled = true;
        this.downDisabled = true;
      }
    },
    fixNum(val) {
      val = this.$util.revertFormatNumber(val);
      let pointIndex = val.indexOf('.');
      if (pointIndex !== -1 && pointIndex + 1 < val.length - this.precision) {
        return parseFloat(this.$util.toFixed(val, this.precision, this.roundType));
      } else if (pointIndex !== -1 && val.length === pointIndex + 1) {
        return parseFloat(val);
      }
      return parseFloat(val);
    },
    setFocus() {
      this.$refs['input-number'].focus();
    },
    addUnitwith() {
      const unitDom = document.querySelector('.ivu-input-number-handler-wrap');
      let inputNumber = document.querySelector('.ivu-input-number-input');
      inputNumber.style = `padding-right:${unitDom.offsetWidth}px`;
    }
  }
};
</script>

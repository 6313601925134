/* eslint-disable no-undef */
import config from '@/config';
import { objEqual } from '@/libs/tools';
import { deepCopy } from '@/u-components/utils/assist';
import Cookies from 'js-cookie';
import _ from 'lodash';
import agentLink from './link';
import JSEncrypt from 'jsencrypt';
import CryptoJS from 'crypto-js';

const acFormat = require('ac-format'); // ys formatNumber
const { useI18n } = config;
// 系统用常量
const constant = {
  // table-edit用
  TRANSFERDIV: [
    'ivu-select-dropdown ivu-date-picker-transfer',
    'ivu-select-dropdown ivu-select-dropdown-transfer',
    'ivu-select-dropdown ivu-dropdown-transfer',
    'ivu-select-dropdown ivu-select-dropdown-transfer ivu-auto-complete',
    'ivu-modal-wrap refer-modal',
    'vue-treeselect__list'
  ],
  FORMAT: {
    UTC: 'UTC+08:00',
    Date: 'YYYY-MM-DD',
    DateTime: 'YYYY-MM-DD HH:mm:ss'
  }
};
// ys 多语映射成订货key
/**
 * CN("zh-cn","中文（简体）",(short)1, "zh_CN"),
 * 中文繁体
  *TW("zh-tw","中文（繁体）",(short)2, "zh_TW"),
 * 英文
 *EN("en-us", "English", (short) 3, "en_US"),
 * 印尼
 *IDN("id-id", "Indonesian", (short) 4, "id_ID"),
 * 法语
  FRE("fr-fr", "French", (short) 5, "fr_FR");
 */

const localeKeyMapping = {
  zh_CN: 'zh-cn',
  zh_TW: 'zh-tw',
  en_US: 'en-us',
  id_ID: 'id-id',
  fr_FR: 'fr-fr'
};

/**
 * 生成uuid
 */
const uuid = (len = 8, radix) => {
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  let uuid = [];
  let i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return `pc-${uuid.join('')}`;
};
var encodeReserveRE = /[!'()*]/g;
var encodeReserveReplacer = function(c) { return '%' + c.charCodeAt(0).toString(16); };
var commaRE = /%2C/g;
const encode = function(str) {
  return encodeURIComponent(str)
    .replace(encodeReserveRE, encodeReserveReplacer)
    .replace(commaRE, ',');
};

function stringifyQuery(obj) {
  var res = obj ? Object.keys(obj).map(function(key) {
    var val = obj[key];

    if (val === undefined) {
      return '';
    }

    if (val === null) {
      return encode(key);
    }

    if (Array.isArray(val)) {
      var result = [];
      val.forEach(function(val2) {
        if (val2 === undefined) {
          return;
        }
        if (val2 === null) {
          result.push(encode(key));
        } else {
          result.push(encode(key) + '=' + encode(val2));
        }
      });
      return result.join('&');
    }

    return encode(key) + '=' + encode(val);
  }).filter(function(x) { return x.length > 0; }).join('&') : null;
  return res || '';
}
/**
 * url参数转对象
 */
const param2Obj = (url = '') => {
  const search = url.split('?')[1];
  if (!search) {
    return;
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
};
function getIndexedDBManager() {
  const DATA_BASE_NAME = 'KeyValuePairDB'
  const TABLE_NAME = 'KeyValuePair'
  const UNIQ_KEY = 'key'

  let dataBase = null
  function getDataBase() {
      if (dataBase) {
          return dataBase
      }
      return new Promise(resolve => {
          const request = indexedDB.open(DATA_BASE_NAME)
          request.onupgradeneeded = e => {
              const db = e.target.result
              if (!db.objectStoreNames.contains(TABLE_NAME)) {
                  db.createObjectStore(TABLE_NAME, { keyPath: UNIQ_KEY })
              }
          }
          request.onsuccess = e => {
              const db = e.target.result
              dataBase = db
              resolve(db)
          }
      })
  }
  return {
      async setItem(key, value) {
          const dataBase = await getDataBase()
          return new Promise(resolve => {
              const request = dataBase.transaction(TABLE_NAME, 'readwrite')
                  .objectStore(TABLE_NAME)
                  .put({ data: value, [UNIQ_KEY]: key })
              request.onsuccess = resolve('success')
          })
      },
      async getItem(key) {
          const dataBase = await getDataBase()
          return new Promise(resolve => {
              const request = dataBase.transaction(TABLE_NAME)
                  .objectStore(TABLE_NAME)
                  .get(key)
              request.onsuccess = () => {
                  resolve(request.result?.data)
              }
          })
      },
      async keys() {
          const keys = []
          return new Promise(resolve => {
              const request = dataBase.transaction(TABLE_NAME)
                  .objectStore(TABLE_NAME)
                  .openCursor()

              request.onsuccess = () => {
                  const cursor = request.result;
                  if (cursor) {
                      cursor.continue()
                      keys.push(cursor.value[UNIQ_KEY])
                  } else {
                      resolve(keys)
                  }
              }
          })
      },
      async removeItem(key) {
        const dataBase = await getDataBase();
        return new Promise(resolve => {
          const request = dataBase.transaction(TABLE_NAME, 'readwrite')
            .objectStore(TABLE_NAME)
            .delete(key);
          request.onsuccess = resolve('success');
        });
      },
      async clearIndexedDB() {
        const dataBase = await getDataBase();
        return new Promise(resolve => {
          const request = dataBase.transaction(TABLE_NAME, 'readwrite')
            .objectStore(TABLE_NAME)
            .clear();
          request.onsuccess = resolve('success');
        });
      }
  }
}

/**
 *
 * 获取页面title
 * @param {*} title
 * @param {*} vm
 */
function title(to, vm) {
  let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
  window.document.title = to.name === 'login' ? vm.mlang('dinghuoTitle') : ((user && user.corpName) || vm.mlang('dinghuoTitle'));
}
/**
 * menu,Route相关
 */
const getMenuItemByName = function(menuList, name) {
  if (!name || !menuList || !menuList.length) {
    return null;
  }
  let menuItem = null;
  for (let item of menuList) {
    if (item.name === name) {
      return item;
    }
    menuItem = getMenuItemByName(item.children, name);
    if (menuItem) {
      return menuItem;
    }
  }
  return null;
};
const clearStorage = ()=>{
  let arr = ['iPaytype', 'recoProducts', 'productPageSize', 'allProducts', 'pcScartSelected', 'scartInvaild', 'storeOrder', 'regionList','upAgentRelationId', 'storageRelationId','defaultUserInfo'];
  if (arr.length) {
    arr.map((v) => {
      if (window.localStorage.getItem(v)) {
        window.localStorage.removeItem(v);
      }
    });
  }
  Cookies.remove('udhTenantId');
  Cookies.remove('userId');
}

const getMenuItemTitleByName = (menuList, data) => {
  let name = data.name;
  if (!name || !menuList || !menuList.length) {
    return null;
  }
  let menuItem = null;
  for (let item of menuList) {
    if (item.name === name) {
      if (data.meta) {
        data.meta.title = item.caption;
      } else {
        data.meta = {
          module: name,
          title: item.caption
        };
      }
    }
    menuItem = getMenuItemTitleByName(item.children, data);
    if (menuItem) {
      if (data.meta) {
        data.meta.title = menuItem.caption;
      } else {
        data.meta = {
          module: name,
          title: menuItem.caption
        };
      }
    }
  }
};
const getRouterObjByName = function(routers, name) {
  if (!name || !routers || !routers.length) {
    return null;
  }
  let routerObj = null;
  for (let item of routers) {
    if (item.name === name) {
      return item;
    }
    routerObj = getRouterObjByName(item.children, name);
    if (routerObj) {
      return routerObj;
    }
  }
  return null;
};
const isPromise = function(obj) {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
};
const setCurrentPath = function(vm, name) {
  // let title = '';
  // let isOtherRouter = false;
  vm.$store.state.app.routers.forEach((item) => {
    if (item.children.length === 1) {
      if (item.children[0].name === name) {
        if (item.name === 'otherRouter') {
          // isOtherRouter = true;
        }
      }
    } else {
      item.children.forEach((child) => {
        if (child.name === name) {
          if (item.name === 'otherRouter') {
            // isOtherRouter = true;
          }
        }
      });
    }
  });
  let currentPathArr = {};
  const allRouters = vm.$store.state.app.routers;
  for (let index = 0; index < allRouters.length; index++) {
    const firstRouter = allRouters[index];
    if (firstRouter.name === 'singlepage') {
      continue;
    }
    if (firstRouter.name === name) {
      currentPathArr = firstRouter;
      break;
    } else {
      const secondRouters = firstRouter.children || [];
      for (let index2 = 0; index2 < secondRouters.length; index2++) {
        const secondRouter = secondRouters[index2];
        if (secondRouter.name === name) {
          currentPathArr = firstRouter;
          break;
        } else {
          const thirdRouters = secondRouter.children || [];
          for (let index3 = 0; index3 < thirdRouters.length; index3++) {
            const thirdRouter = thirdRouters[index3];
            if (thirdRouter.name === name) {
              currentPathArr = secondRouter;
              break;
            } else {
              const fourthRouters = thirdRouter.children || [];
              for (let index4 = 0; index4 < fourthRouters.length; index4++) {
                const fourthRouter = fourthRouters[index4];
                if (fourthRouter.name === name) {
                  currentPathArr = secondRouter;
                  break;
                }
              }
            }
          }
        }
      }
    }
  }
  return currentPathArr;
};
const openNewPage = function(vm, name, argu, query) {
  let pageOpenedList = vm.$store.state.app.pageOpenedList;
  let openedPageLen = pageOpenedList.length;
  let i = 0;
  let tagHasOpened = false;
  while (i < openedPageLen) {
    if (name === pageOpenedList[i].name) {
      // 页面已经打开
      vm.$store.commit('pageOpenedList', {
        index: i,
        argu: argu,
        query: query
      });
      tagHasOpened = true;
      break;
    }
    i++;
  }
  if (!tagHasOpened) {
    let tag = vm.$store.state.app.tagsList.filter((item) => {
      if (item.children && item.children.length) {
        return name === item.children[0].name;
      } else {
        return name === item.name;
      }
    });
    tag = tag[0];
    if (tag) {
      tag = tag.children ? tag.children[0] : tag;
      if (argu) {
        tag.argu = argu;
      }
      if (query) {
        tag.query = query;
      }
      vm.$store.commit('increateTag', tag);
    }
  }
  vm.$store.commit('setCurrentPageName', name);
};
/**
 * 设置tab的title
 * @param {*} item
 * @param {*} vm
 */
const showTitle = (item, vm) => {
  let { __titleIsFunction__ } = item.meta;
  let title = '';
  // if (!title) return;
  if (item.meta && item.meta.title) {
    title = item.meta.title;
  } else {
    if (useI18n) {
      if (title.includes('{{') && title.includes('}}') && useI18n) title = title.replace(/({{[\s\S]+?}})/, (m, str) => str.replace(/{{([\s\S]*)}}/, (m, _) => vm.$t(_.trim())));
      else if (__titleIsFunction__) title = item.meta.title;
      else title = vm.$t(`title.${item.name}`);
    } else {
      title = (item.meta && item.meta.title) || item.name;
    }
  }
  let add = vm.mlang('add');
  let edit = vm.mlang('edit');
  let detail = vm.mlang('detail');

  if (item && item.query && !item.meta.notAutoTitle) {
    if (item.query.titleType === 'detail') {
      title = title + detail;
    } else if (item.query.titleType === 'add') {
      title = add + title;
    } else if (item.query.titleType === 'editor' || item.query.isEdit) {
      title = edit + title;
    }
  }
  return title;
};
/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
const routeEqual = (route1, route2) => {
  const params1 = route1.params || {};
  const params2 = route2.params || {};
  const query1 = route1.query || {};
  const query2 = route2.query || {};
  let tmp = route1.name === route2.name;
  if (!params2.forceReload && !params1.forceReload) {
    tmp = tmp && objEqual(params1, params2) && objEqual(query1, query2);
  }
  return tmp;
};
const routeEqualNew = (route1, route2) => {
  // const module1 = route1.meta && route1.meta.module;
  // const module2 = route2.meta && route2.meta.module;
  // || (module1 === module2 && module1 !== undefined)
  return route1.name === route2.name;
};
// 设置route 的uuid ，hash
const getRouteTitleHandled = (route) => {
  let router = { ...route };
  let meta = { ...route.meta };
  let title = '';
  // if (meta.title) {
  if (typeof meta.title === 'function') {
    meta.__titleIsFunction__ = true;
    title = meta.title(router);
  } else if (route.query && route.query.title) {
    title = route.query.title;
  } else {
    title = meta.title;
  }
  // }
  meta.title = title;
  router.meta = meta;
  if (!router.meta.uuid) {
    router.meta.uuid = _.uniqueId(router.name);
  }
  if (!router.meta.hash) {
    router.meta.hash = getCurrentRouteHash(router);
  }
  return router;
};

/**
 * 字符串拼接方法
 * @param {*} baseurl
 * @param {*} pathname
 */
const combine = (baseurl, pathname) => {
  const separator = /\/$/.test(baseurl) === false && /^\//.test(pathname) === false ? '/' : '';
  return Array.prototype.slice.call(arguments, 0).join(separator);
};
/**
 * @param {Number} times 回调函数需要执行的次数
 * @param {Function} callback 回调函数
 */
const doCustomTimes = (times, callback) => {
  let i = -1;
  while (++i < times) {
    callback(i);
  }
};
/**
 * 判断打开的标签列表里是否已存在这个新添加的路由对象
 */
const routeHasExist = (tagNavList, routeItem, MANYTAB) => {
  let len = tagNavList.length;
  let res = false;
  let eqMethod = routeEqualNew;
  if (MANYTAB) {
    eqMethod = routeEqual;
  }
  let findIndex = -1;
  doCustomTimes(len, (index) => {
    if (eqMethod(tagNavList[index], routeItem)) {
      findIndex = index;
      res = true;
    }
  });
  if (res) {
    return findIndex;
  } else {
    return false;
  }
};

/**
 * 判断打开的标签列表里是否已存在这个新添加的路由对象的相同module
 */
const routeExistModule = (tagNavList, routeItem, MANYTAB) => {
  const nextModule = routeItem.meta && routeItem.meta.module;
  let res = tagNavList.find((v) => {
    return v.meta && v.meta.module === nextModule && nextModule !== undefined && v.name !== routeItem.name;
  });
  if (MANYTAB && routeItem.meta.bill) {
    res = false;
  }
  return res;
};

const getNewTagList = (list, newRoute) => {
  const { name, path, meta } = newRoute;
  let newList = [...list];
  if (newList.findIndex((item) => item.name === name) >= 0) {
    return newList;
  } else {
    newList.push({ name, path, meta });
  }
  return newList;
};
// 获取 homeRoute
const getHomeRoute = (routers, homeName = 'home') => {
  let i = -1;
  let len = routers.length;
  let homeRoute = {};
  while (++i < len) {
    let item = routers[i];
    if (item.children && item.children.length) {
      let res = getHomeRoute(item.children, homeName);
      if (res.name) return res;
    } else {
      if (item.name === homeName) homeRoute = item;
    }
  }
  return homeRoute;
};
/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
const getNextRoute = (list, route) => {
  let res = {};
  if (list.length === 2) {
    res = getHomeRoute(list);
  } else {
    const index = list.findIndex((item) => routeEqual(item, route));
    if (index === list.length - 1) res = list[list.length - 2];
    else res = list[index + 1];
  }
  return res;
};
const getCurrentRouteHash = (route) => {
  let query = '';
  if (route.query && !_.isEmpty(route.query)) {
    query = JSON.stringify(route.query);
  }
  return route.path + query;
};
/**
 * 跳转旧页面方法
 */
const menuClickOldPage = (url, vm, menuItem = {}, replace = false) => {
  let splieChar = '&';
  let endStr = '';
  let menuid = '';
  let title = '详情';
  if (!menuItem.route) {
    if (url.indexOf('#/') > -1) {
      let index = url.indexOf('#/');
      endStr = url.substring(index);
      url = url.substring(0, index);
    }
  }
  if (url.indexOf('?') === -1) {
    splieChar = '?';
  }
  url += splieChar + 'isFrame=1&isLoaded=1';
  if (menuItem.sysCode) {
    menuid = menuItem.sysCode;
  } else {
    const search = param2Obj(url);
    if (search && search.menuid) {
      menuid = search.menuid;
    }
  }
  if (menuItem.parentSysCode) {
    url += `&menuMainId=${menuItem.parentSysCode}`;
  }
  url += '&menuid=' + menuid;

  if (menuItem.route) {
    url += '#' + menuItem.route;
  } else {
    if (endStr) {
      url += endStr;
    }
  }
  if (menuItem.title) {
    title = menuItem.title;
  }
  // window.location.href = url;
  const frameRoute = {
    name: 'frame',
    query: {
      title,
      uri: url,
      menuid: menuid,
      menuMainId: menuItem.parentSysCode
    }
  };
  if (replace) {
    vm.$router.replace(frameRoute);
  } else {
    vm.$router.push(frameRoute);
  }
};
// 处理树
const reduceChildren = (array = [], childKey, nameKey, codeKey, valueKey, idKey) => {
  if (array && array.length) {
    const reduce = (items, parent) => {
      items.map((item) => {
        if (nameKey) {
          item.title = item[nameKey];
          item.name = item[nameKey];
          item.label = item[nameKey];
        }
        if (codeKey) {
          item.code = item[codeKey];
        }
        if (valueKey) {
          item.value = item[valueKey];
        }
        if (idKey) {
          item.id = item[idKey];
        }
        if (item[childKey]) {
          if (item[childKey] && item[childKey].length === 0) {
            delete item[childKey];
          } else {
            item.children = item[childKey];
            item[childKey].length && reduce(item[childKey], item);
          }
        }
        if (parent) {
          const copyParent = Object.assign({}, parent);
          delete copyParent[childKey];
          delete copyParent.children;
          item.parent = copyParent;
          item.parentName = copyParent.cName;
        }
      });
    };
    reduce(array);
  }
  return array;
};

const clearUpAgentInfo = () => {
  localStorage.removeItem('upAgentId');
  localStorage.removeItem('upBizId');
  localStorage.removeItem('upAgentRelationId');
};
// 为单据设置特殊日期字段
const createDateRange = (data = [], childKey) => {
  data[childKey].map((v) => {
    v.dateRange = [v.startDate, v.endDate];
  });
  return data;
};
// 拍平树结构
const compileFlatState = (array = [], childrenKey = 'children') => {
  let keyCounter = 0;
  const flatTree = [];
  function flattenChildren(node, parent) {
    node.nodeKey = keyCounter++;
    node.text = node.name;
    flatTree[node.nodeKey] = { node: node, nodeKey: node.nodeKey, text: node.name };
    if (typeof parent !== 'undefined') {
      flatTree[node.nodeKey].parent = parent.nodeKey;
      flatTree[parent.nodeKey][childrenKey].push(node.nodeKey);
    }

    if (node[childrenKey]) {
      flatTree[node.nodeKey][childrenKey] = [];
      node[childrenKey].forEach((child) => flattenChildren(child, node));
    }
  }
  array.forEach((rootNode) => {
    flattenChildren(rootNode);
  });
  return flatTree;
};
// old
const flattenDeepData = (array = [], childKey = 'children') => {
  const flattenData = [];
  if (array.length) {
    const reduce = (items, parent) => {
      items.map((item) => {
        const cloneItem = deepCopy(item);
        delete cloneItem[childKey];
        flattenData.push(cloneItem);
        if (item[childKey]) {
          item[childKey].length && reduce(item[childKey], item);
        }
      });
    };
    reduce(array);
  }
  return flattenData;
};
// 生成随机数
const random = () => {
  return (Math.random() * 1000).toFixed(2) - 0;
};
/**
 * get设备id
 */
const getDeviceId = () => {
  if (localStorage) {
    return localStorage.getItem('udh_device_uuid') || uuid();
  } else {
    return uuid();
  }
};
/**
 * set设备id
 */
const setDeviceUUid = () => {
  if (localStorage) {
    if (!localStorage.getItem('udh_device_uuid')) {
      localStorage.setItem('udh_device_uuid', uuid());
    }
  }
};

/**
 * 获取url中参数
 */
const getUrlParam = (key, url) => {
  // 通过key获取url中的参数值
  var strHref = url || window.location.href.toString();
  var intPos = strHref.indexOf('?');
  var strRight = strHref.substr(intPos + 1); // ==========获取到右边的参数部分
  var arrTmp = strRight.split('&'); // =============以&分割成数组

  for (
    var i = 0;
    i < arrTmp.length;
    i++ // ===========循环数组
  ) {
    var dIntPos = arrTmp[i].indexOf('=');
    var paraName = arrTmp[i].substr(0, dIntPos);
    var paraData = arrTmp[i].substr(dIntPos + 1);
    if (paraName.toUpperCase() === key.toUpperCase()) {
      return paraData;
    }
  }
  return '';
  //  return this.getQueryString(key);
};
/**
 * 订货端页面超链接
 */
const getAgentUrl = (url = '/agent/') => {
  if (localStorage.getItem('isNewVersion') === 'true' && url) {
    let params = 'redirectNew=true';
    let idx = url.indexOf('?');
    if (idx === -1) params = '?' + params;
    else params = '&' + params;
    url = url + params;
  }
  return url;
};

/**
 * 获取getRedirectUrl
 */
const getRedirectUrl = () => {
  return decodeURIComponent(getUrlParam('redirectUrl') || '');
};
// 获取excel模板地址
const getExcelFilePath = (fileName) => {
  return window.location.origin + '/public/files/' + fileName + '?rand=' + Math.random() + '&langCode=' + (localStorage.lang || 'zh-cn');
  // return window.__app.context.vendorsHome.replace('/g', '/files') + '/' + fileName + '?rand=' + Math.random();
};
/**
 * 订货端订单详情跳转
*/
const agentOrderDetailHref = (vm, query) => {
  const route = {
    name: 'aorder',
    query
  };
  agentOpenNewTabEdit(vm, route, 'detail');
};
const toFrameProductDetail = (id, bizProductId) => {
  let obj = {
    id,
    bizProductId,
    to: 'productDetail'
  };
  window.parent.postMessage(obj, '*');
  window.localStorage.setItem('toUrlPage', true);
};
/**
 * 订货端点击编辑打开新页签
 */
const agentOpenNewTabEdit = (vm, router, titleType = 'editor') => {
  if (router.query) {
    router.query.titleType = titleType;
  }
  const route = vm.$router.matcher.match(router);
  if (route) {
    window.open(`/v#${route.fullPath}`);
  }
};
/**
 * 跳转登录，用于被T或者服务异常情况的情况
 */
const goLogin = () => {
  if (window === top) {
    window.localStorage.removeItem('defaultUserInfo');
    window.location.href = `/logout?redirectUrl=${encodeURIComponent(location.href)}`;
  } else {
    window.parent.postMessage({ key: 'logout' }, '*');
  }
};

/**
 * 切换代下单客户
 */

const onSelectedAgent = (row) => {
  localStorage.upAgentId = row.agentId;
  localStorage.upBizId = row.bizId;
  localStorage.upAgentRelationId = row.agentRelationId;
  window.location.href = getAgentUrl();
};

const isNoValue = (val, isNotDebarSpace) => {
  if (!isNotDebarSpace && _.isString(val)) {
    val = val.replace(/(^\s*)|(\s*$)/g, '');
  }
  return _.isUndefined(val) || val === null || val === '' || val === 'ALL';
};
// 是否展示币种符号
const isShowCurrency = () => {
  const app = window.__app.mainV;
  let userSettingPrice = app.$store.getters.userSettingPrice;
  if (userSettingPrice.length > 0) {
    return false;
  } else {
    return true;
  }
};
/**
 * 数字操作
 */
const numAdd = (dataOne, dataTwo) => {
  // +
  let dataOneInt = dataOne.toString().split('.')[0];
  let dataOneFloat = '';
  let dataTwoInt = dataTwo.toString().split('.')[0];
  let dataTwoFloat = '';
  let lengthOne = 0;
  let lengthTwo = 0;

  if (dataOne.toString().split('.').length === 2) {
    dataOneFloat = dataOne.toString().split('.')[1];
    lengthOne = dataOneFloat.toString().length;
  }
  if (dataTwo.toString().split('.').length === 2) {
    dataTwoFloat = dataTwo.toString().split('.')[1];
    lengthTwo = dataTwoFloat.toString().length;
  }

  let maxLength = Math.max(lengthOne, lengthTwo);
  for (let i = 0; i < maxLength - lengthOne; i++) {
    dataOneFloat += '0';
  }
  for (let i = 0; i < maxLength - lengthTwo; i++) {
    dataTwoFloat += '0';
  }

  /**
   *对两个数据进行倍数放大
   *使其都变为整数。因为整数计算
   *比较精确。
   */
  let one = dataOneInt + '' + dataOneFloat;
  let two = dataTwoInt + '' + dataTwoFloat;

  /**
   *数据扩大倍数后，经计算的到结果，
   *然后在缩小相同的倍数
   *进而得到正确的结果
   */
  let result = (Number(one) + Number(two)) / Math.pow(10, maxLength);
  return result;
};

const numMulti = (num1, num2) => {
  if (isNoValue(num1)) {
    num1 = 0;
  }
  if (isNoValue(num2)) {
    num2 = 0;
  }
  var baseNum = 0;
  try {
    baseNum += num1.toString().split('.')[1].length;
  } catch (e) {} //eslint-disable-line
  try {
    baseNum += num2.toString().split('.')[1].length;
  } catch (e) {} //eslint-disable-line
  return (Number(num1.toString().replace('.', '')) * Number(num2.toString().replace('.', ''))) / Math.pow(10, baseNum);
};
const numSub = (arg1, arg2) => {
  // -
  return numAdd(arg1, -arg2);
};

const numDiv = (arg1, arg2) => {
  arg2 = arg2 || 1;
  if (!(arg1 === 0 || arg1)) {
    return undefined;
  }
  // ÷
  var t1 = 0;
  var t2 = 0;
  var r1;
  var r2;
  try {
    t1 = arg1.toString().split('.')[1].length;
  } catch (e) {} //eslint-disable-line
  try {
    t2 = arg2.toString().split('.')[1].length;
  } catch (e) {} //eslint-disable-line
  r1 = Number(arg1.toString().replace('.', ''));
  r2 = Number(arg2.toString().replace('.', ''));
  return (r1 / r2) * Math.pow(10, t2 - t1);
};

const _toFixed = (length, val) => {
  return _.round(val, length);
};
const toFixed = (val, exact, type, toString) => {
  var _this = parseFloat(val);
  let valStr = _this.toString();
  var pointIndex = valStr.indexOf('.');
  var result = '';
  switch (type) {
    case 4: // 四舍五入
      result = _toFixed(exact, val);
      break;
    case 1: // 舍位
      valStr = _this.toString();
      result = pointIndex > -1 ? valStr.substr(0, pointIndex + 1 + exact) : _this;
      break;
    case 0: // 入位
      valStr = _this.toString();
      var pointCount = valStr.length - pointIndex - 1;
      if (pointCount > exact) {
        // 利用入位取整
        valStr = _toFixed(exact + 1, valStr).toString(); // 多一位的中间值
        pointIndex = valStr.indexOf('.');
        pointCount = valStr.length - pointIndex - 1;
        var powVal = Math.pow(10, pointCount - 1);
        result = Math.ceil(pointCount > 1 ? valStr * powVal : valStr) / powVal;
      } else {
        result = _this;
      }
      break;
    default:
      // 四舍五入
      result = _toFixed(exact, val);
      break;
  }
  let res = parseFloat(result);
  if (toString) {
    res = res.toFixed(exact);
  }
  return res;
};
const getIAuxUnitQuantity = (iQuantity, sku, oriInvPriceExchRate, oriInvExchRate, flag) => {
  let oProduct = sku.oProduct || sku.product;
  let oProductAuxUnit = sku.oProductAuxUnit || sku.productAuxUnit || (oProduct && oProduct.oProductAuxUnit) || (oProduct && oProduct.productAuxUnit);
  let unit = oProduct ? (oProduct.oUnit || oProduct.unit) : (sku.oUnit || sku.unit);
  let auxUnit = oProductAuxUnit.oUnit || oProductAuxUnit.unit;
  let invPriceExchRate = oriInvPriceExchRate || (oProduct && oProduct.invPriceExchRate) || sku.invPriceExchRate;
  let invExchRate = oriInvExchRate || (oProduct && oProduct.invExchRate) || sku.invExchRate;
  if(!auxUnit){
    auxUnit = {
      unitPrecision: 2,
      unitRoundType: 4
    }
  }
  if(unit && auxUnit && unit.id && auxUnit.id && unit.id === auxUnit.id){
    return flag ===  false ? iQuantity: toFixed(iQuantity, (auxUnit && auxUnit.unitPrecision) || 2, (auxUnit && auxUnit.unitRoundType) || 4)
  }else{
    return flag ===  false ? numDiv(numMulti(iQuantity, invPriceExchRate), invExchRate) : toFixed(numDiv(numMulti(iQuantity, invPriceExchRate), invExchRate), auxUnit && auxUnit.unitPrecision, auxUnit && auxUnit.unitRoundType)
  }

};

const getIQuantity = (iAuxUnitQuantity, sku, oriInvPriceExchRate, oriInvExchRate, flag) => {
  let oProduct = sku.oProduct || sku.product;
  let oProductAuxUnit = sku.oProductAuxUnit || sku.productAuxUnit || (oProduct && oProduct.oProductAuxUnit) || (oProduct && oProduct.productAuxUnit);
  let unit = oProduct ? (oProduct.oUnit || oProduct.unit) : sku.oUnit || sku.unit;
  let auxUnit = oProductAuxUnit.oUnit || oProductAuxUnit.unit;
  let invPriceExchRate = oriInvPriceExchRate || (oProduct && oProduct.invPriceExchRate) || sku.invPriceExchRate;
  let invExchRate = oriInvExchRate || (oProduct && oProduct.invExchRate) || sku.invExchRate;
  if(!unit){
    unit = {
      unitPrecision: 2,
      unitRoundType: 4
    }
  }
  if(unit && auxUnit && unit.id && auxUnit.id && unit.id === auxUnit.id){
    return flag ===  false ? iAuxUnitQuantity : toFixed(iAuxUnitQuantity, (unit && unit.unitPrecision) || 2, (unit && unit.unitRoundType) || 4)
  }else{
    return flag ===  false ? numDiv(numMulti(iAuxUnitQuantity, invExchRate), invPriceExchRate) : toFixed(numDiv(numMulti(iAuxUnitQuantity, invExchRate), invPriceExchRate), unit && unit.unitPrecision, unit && unit.unitRoundType)
  }
};
/**
 * 文件大小方法
 */
const fileSize = (limit) => {
  var size = '';
  if (limit < 0.1 * 1024) {
    // 如果小于0.1KB转化成B
    size = Number(limit).toFixed(2) + 'B';
  } else if (limit < 0.1 * 1024 * 1024) {
    // 如果小于0.1MB转化成KB
    size = (limit / 1024).toFixed(2) + 'KB';
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {
    // 如果小于0.1GB转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + 'MB';
  } else {
    // 其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
  }

  var sizestr = size + '';
  var len = sizestr.indexOf('.');
  var dec = sizestr.substr(len + 1, 2);
  if (dec === '00') {
    // 当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
  }
  return sizestr;
};
/**
 * 校验formtData是否合法
 */
const isLegalFormatData = (column) => {
  const objStrReg = new RegExp(/^[{].*[}]$/);
  return objStrReg.test(column.formatData);
};
/**
 * 解析formdata
 */
const makeFormatData = (column) => {
  if (!isLegalFormatData(column)) {
    return column;
  }
  try {
    const formatDataObj = JSON.parse(column.formatData);
    column.formatDataObj = formatDataObj;
  } catch (error) {
    console.error(' FormatData error-->' + column.formatData); //eslint-disable-line
  }
  return column;
};

/**
 * 根据某个key获取值
 */
const getFormatValueByKey = (data, key) => {
  return getPropByPath(data, key).v;
};

/**
 * 根据formatData来处理value
 */
const getNumberFormatDataValue = ({ column, data, value, getAllDataFn, needString }) => {
  if (!(column && column.formatDataObj) || _.isUndefined(value)) {
    return value;
  }
  let val = value;
  // const uuit = uuid();
  // console.time(uuit + column.showCaption);
  const formatDataObj = column.formatDataObj;
  let { precisionFieldName, roundTypeFieldName, globalData } = formatDataObj;
  if (globalData && typeof getAllDataFn === 'function') { // 从全局取
    data = getAllDataFn();
  }
  // console.timeEnd(uuit + column.showCaption);

  let precision = getFormatValueByKey(data, precisionFieldName);// 精度
  let roundType = getFormatValueByKey(data, roundTypeFieldName);// 四舍五入类型
  const params = { value, precision, roundType, needString };
  val = numberFormat(params);
  return val;
};
/**
 * 格式化数字
 */
const numberFormat = (params) => {
  let { value, precision = 2, roundType = 1, app, currencySign = '', needString = true } = params;
  if (!app) {
    app = window.__app.mainV;
  }
  let val = toFixed(value, precision, roundType, true); // "10000.05"
  if (!needString) {
    return val;
  }
  val = formatNumber(val); // "10,000.05"
  return currencySign + val;
};
/**
 * 获取浏览器类型
 */
const getBrowserType = () => {
  let userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
  let isOpera = userAgent.indexOf('Opera') > -1; // 判断是否Opera浏览器
  // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
  let isIE = window.ActiveXObject || 'ActiveXObject' in window;
  // var isEdge = userAgent.indexOf("Windows NT 6.1; Trident/7.0;") > -1 && !isIE; //判断是否IE的Edge浏览器
  let isEdge = userAgent.indexOf('Edge') > -1; // 判断是否IE的Edge浏览器
  let isFF = userAgent.indexOf('Firefox') > -1; // 判断是否Firefox浏览器
  let isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1; // 判断是否Safari浏览器
  let isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1 && !isEdge; // 判断Chrome浏览器

  if (isIE) {
    let reIE = new RegExp('MSIE (\\d+\\.\\d+);');
    reIE.test(userAgent);
    let fIEVersion = parseFloat(RegExp['$1']);
    if (userAgent.indexOf('MSIE 6.0') !== -1) {
      return 'IE6';
    } else if (fIEVersion === 7) {
      return 'IE7';
    } else if (fIEVersion === 8) {
      return 'IE8';
    } else if (fIEVersion === 9) {
      return 'IE9';
    } else if (fIEVersion === 10) {
      return 'IE10';
    } else if (userAgent.toLowerCase().match(/rv:([\d.]+)\) like gecko/)) {
      return 'IE11';
    } else {
      return '0';
    } // IE版本过低
  } // isIE end

  if (isFF) {
    return 'FF';
  }
  if (isOpera) {
    return 'Opera';
  }
  if (isSafari) {
    return 'Safari';
  }
  if (isChrome) {
    return 'Chrome';
  }
  if (isEdge) {
    return 'Edge';
  }
};

/* 多语相关方法开始 */
const setLocal = (value) => {
  localStorage.setItem('lang', value);
  Cookies.set('langCode', value);
};
const getLocal = () => {
  // ys企业端从url 上取 locale
  const ysUrlLocale = getUrlParam('locale');
  if (ysUrlLocale) {
    const locale = localeKeyMapping[ysUrlLocale];
    if (!locale) {
      // eslint-disable-next-line no-console
      console.error('no mapping->' + ysUrlLocale);
    }
    const res = locale || 'zh-cn';
    if (res !== localStorage.getItem('lang')) {
      localStorage.removeItem('md5');
      localStorage.removeItem('AllLocaleResource');
      sessionStorage.removeItem('AllLocaleResource');
    }
    return res;
  }
  return localStorage.getItem('lang');
};

/**
 * 解析formdata
 */
/**
 * upload数据处理
 */
const uploadDataFormat = (data) => {
  if (data.cExtName) {
    data.extName = data.cExtName;
  } else {
    data.cExtName = data.extName;
  }
  if (data.cFileName) {
    data.fileName = data.cFileName;
  } else {
    data.cFileName = data.fileName;
  }
  if (data.cFileSize) {
    data.fileSize = data.cFileSize;
  } else {
    data.cFileSize = data.fileSize;
  }
  if (data.cFolder) {
    data.folder = data.cFolder;
  } else {
    data.cFolder = data.folder;
  }
  if (data.cHash) {
    data.hash = data.cHash;
  } else {
    data.cHash = data.hash;
  }
  if (data.cKey) {
    data.key = data.cKey;
  } else {
    data.cKey = data.key;
  }
  if (data.cOriginalName) {
    data.originalName = data.cOriginalName;
  } else {
    data.cOriginalName = data.originalName;
  }
  if (data.cUrl) {
    data.url = data.cUrl;
    data.cFullUrl = data.cUrl;
    data.fullUrl = data.cUrl;
  } else {
    data.cUrl = data.url;
    data.cFullUrl = data.url;
    data.fullUrl = data.url;
  }
  if (data.dCreated) {
    data.createdDate = data.dCreated;
  } else {
    data.dCreated = data.createdDate;
  }
  return data;
};

/**
 * 下载文件
 */
const downloadFile = (str, fileName) => {
  let blob = new Blob([str]);
  let link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
// 默认图片上传
const formatResource = (text) => {
  if (_.startsWith(text, '/public')) {
    text = text.replace('/public/stylesheets', '/css');
    text = text.replace('/public/images', '/images');
    return window.__app.context.vendorsHome + text;
  } else {
    return text;
  }
};

/* 多语相关方法开始 */
function getLangCode() {
  var langCode = getLocal();
  if (!langCode) {
    // if (navigator.language) {
    //   langCode = navigator.language;
    // } else {
    //   langCode = navigator.browserLanguage;
    // }
    langCode = window.__app.context.defaultLang;
    if (langCode) {
      setLocal(langCode);
    }
  }
  if (!langCode) langCode = 'zh-cn';
  Cookies.set('langCode', langCode);

  return langCode;
}
function getLocaleResource() {
  return window.__app.context.i18nResources; // 全局多语资源对象
}

const getIsMultiLang = () => {
  return window.__app.context.isMultiLang;
};
const getPropByPath = (obj, path) => {
// let tempObj = deepCopy(obj);
  let tempObj = obj;
  path = path.replace(/\[(\w+)\]/g, '.$1');
  path = path.replace(/^\./, '');

  let keyArr = path.split('.');
  let i = 0;

  for (let len = keyArr.length; i < len - 1; ++i) {
    let key = keyArr[i];
    if (key in tempObj) {
      tempObj = tempObj[key];
    } else {
      tempObj = {};
    }
  }
  tempObj = tempObj || {};
  return {
    keyArr,
    o: tempObj,
    k: keyArr[i],
    v: tempObj[keyArr[i]]
  };
};
const getLocaleMessage = (key) => {
  var result = ''; //  对应的资源的内容
  // var paramsObj = arguments; //  参数对象
  var localeResource = getLocaleResource();
  if (typeof key !== 'undefined' && typeof localeResource !== 'undefined') {
    // 根据key取得对应的资源内容，如果没有找到则返回key值
    if (localeResource[key] !== undefined) {
      result = localeResource[key];
    } else {
      result = key;
    }

    // 替换对应参数为value的值
    // if (paramsObj.length > 1) {
    //   paramsObj.shift();
    //   for (var k in paramsObj) {
    //     regExp = '/{' + k + '}/g';
    //     result = result.replace(regExp, paramsObj[k]);
    //   }
    // }
    // 如果没有找到对应的资源则返回 "No Value"
    if (/{{:[a-zA-Z]+}}/.test(result)) {
      result = result.replace(/{{:[a-zA-Z]+}}/g, 'No Value');
    }
  } else {
    result = key;
  }
  return result;
};
// 业务选项
const getBo = (store, code, bizId) => {
  if (!_.isString(code)) {
    console.error('key must be  string'); // eslint-disable-line
  }
  const __globalBO = store.getters.BO;
  const currentUser = store.getters.currentUser;
  try {
    if (!__globalBO[0] || __globalBO[0].length === 0 || __globalBO.length === 0) return;
    if (!bizId) {
      let { __isAgentSide, __isRepresent, __upBizId, bizId: currentBizId } = currentUser;
      if (__isAgentSide) {
        if (__isRepresent) {
          bizId = __upBizId;
        } else {
          if (!getBo(store, 'ORDERSHOWWAY', __globalBO[0].bizId)) {
            bizId = localStorage.upBizId;// 单商家模式
          }
          if (!bizId) {
            bizId = __globalBO[0].bizId;
          }
        }
      } else {
        bizId = currentBizId;
      }
    }

    var bizBo = __globalBO.find((v) => {
      return v.bizId === bizId;
    });
    if (!bizBo) {
      console.log('bizBo error'); // eslint-disable-line
      return;
    }
    const cloneGlobalBO = _.cloneDeep(__globalBO).pop();
    const cloneBizBoKeyVal = _.cloneDeep(bizBo.pubOptionListData);
    const mdfArr = _.unionWith(cloneGlobalBO, cloneBizBoKeyVal, (arrVal, othVal) => {
      return arrVal.code === othVal.code;
    });
    const res = mdfArr.find((v) => {
      return v.code === code;
    });
    if (res) {
      var returnVal = res.value;
      if (res.value === 'true') {
        returnVal = true;
      } else if (res.value === 'false') {
        returnVal = false;
      } else if (res.value === '1') {
        returnVal = 1;
      } else if (res.value === '0') {
        returnVal = 0;
      }
      return returnVal;
    } else {
      return undefined;
    }
  } catch (e) {
    console.log('getBO error'); // eslint-disable-line
    return;
  }
  return false;
};
const extendKey = (routeName) => {
  return `${routeName}_VM_Extend.js`;
};
// 同步载入script
const extendSet = new Set();
const getScript = (source, async = 1) => {
  return new Promise(function(resolve, reject) {
    if (extendSet.has(source)) {
      resolve();
      return;
    }
    let script = document.createElement('script');
    const prior = document.getElementsByTagName('script')[0];
    script.async = 1;
    prior.parentNode.insertBefore(script, prior);
    script.onerror = reject;
    script.onload = script.onreadystatechange = function(_, isAbort) {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = script.onreadystatechange = null;
        script = undefined;
        if (!isAbort) {
          extendSet.add(source);
          resolve();
        }
      }
    };
    script.src = source;
  });
};

// 给异步函数做缓存包装
export function asyncFuncMemoize(func, resolver) {
  if (typeof func !== 'function' || (resolver !== null && typeof resolver !== 'function')) {
    throw new TypeError('Expected a function');
  }
  const memoized = async function(...args) {
    const isMemoize = typeof args[args.length - 1] === 'boolean' ? args[args.length - 1] : false;
    const key = resolver ? resolver.apply(this, args) : args[0];
    const cache = memoized.cache;

    if (isMemoize && cache.has(key)) {
      return cache.get(key);
    }
    const result = await func.apply(this, args);
    memoized.cache = cache.set(key, result) || cache;
    return result;
  };
  memoized.cache = new (asyncFuncMemoize.Cache || Map)();
  return memoized;
}

asyncFuncMemoize.Cache = Map;

// 主辅计量是否可用的控制
// returnPro -- 被赋值的对象
// product -- 带isAuxUnitOrder的对象
const addUnitEnable = (returnPro, product) => {
  returnPro.__auxEnable = product.isAuxUnitOrder === 'UNIT' || product.isAuxUnitOrder === 'AUXUNIT'; // 允许辅计量订货
  returnPro.__onlyAuxEnable = product.isAuxUnitOrder === 'AUXUNIT'; // 仅允许辅计量订货
  returnPro.__mainEnable = product.isAuxUnitOrder === 'UNIT' || product.isAuxUnitOrder === 'ONLYUNIT'; // 允许主计量订货
  return returnPro;
};
// 权限
const hasAuth = (store, authNames) => {
  // 由于涉及权限影响的代码广泛
  // 所以权限接口中删除的权限全部放到白名单中 UDH-60163
  // 如果命中说明该功能已经不被权限收录默认返回true
  const whiteList = ['agentOrderReturnExeute', 'agentOrderSubmit', 'agentPrintOrder', 'agentExpenseOrderExport', 'agentExpenseOrderPay', 'agentExpenseOrderList'];
  const hit = whiteList.find(item => {
    return item === authNames;
  });
  if (hit) return true;

  const auths = store.getters.auth;
  if (!authNames) return true;
  if (!_.isString(authNames)) {
    console.error('authNames must be  string'); // eslint-disable-line
  }
  try {
    const auth = auths.find((v) => {
      return v.name === authNames;
    });
    return !!auth;
  } catch (e) {
    console.error('userAuth error'); // eslint-disable-line
  }
  return false;
};

// 根据属性键值对获取在数组中的索引
const indexOfArray = (array, pre, val) => {
  var result = -1;
  if (isNoValue(array)) {
    return result;
  }
  for (var index = 0; index < array.length; index++) {
    var value = array[index];
    if (!_.isObject(value) && value === val) {
      result = index;
      break;
    } else if (value[pre] === val) {
      result = index;
      break;
    }
  }
  return result;
};

// 把双字节转换成单字节
const getBLen = (str) => {
  if (str === null) return 0;
  if (typeof str !== 'string') {
    str += '';
  }
  // \x00-\xff 匹配双字节
  // eslint-disable-next-line no-control-regex
  return str.replace(/[^\x00-\xff]/g, '01').length;
};
// 订货端改造，iframe子页面点击链接跳转父页面
const linkTo = (content) => {
  window.parent.location.href = content;
};
  /**
    *生成formatData
    *
    * @param {*} numPoint
  */
const generateFormatFormat = (numPoint = 2) => {
  let formatData = '0,0.';
  let index = numPoint;
  if (index) {
    while (index-- > 0) {
      formatData += '0';
    }
  } else {
    formatData = '0,0';
  }
  return formatData;
};
// iview 日历控件格式化需要yyyy dd小写，需要转一次
const viewDatePickFormat = (ctrlType, currentUser) => {
  let format;
  if (ctrlType === 'Date') {
    format = currentUser.dataformat.dateFormat;
  } else if (ctrlType === 'DateTime') {
    format = currentUser.dataformat.dateTimeFormat;
  }
  return format;
};

/**
 * 系统默认格式化参数
 * dataformat: {"dateTimeFormat":"yyyy-MM-dd HH:mm:ss","numberFormat":"+#.###.###.###.###.###[,]########","dateFormat":"yyyy-MM-dd","timeFormat":"HH:mm:ss"},
 */
const defaultSysFormat = {
  timezone: 'UTC+08:00',
  dataformat: { dateTimeFormat: 'yyyy-MM-dd HH:mm:ss', numberFormat: '+#,###,###,###,###,###[.]########', dateFormat: 'yyyy-MM-dd', timeFormat: 'HH:mm:ss' }
};
const getSysDateFormat = () => {
  if (!window.globalization) {
    return defaultSysFormat.dataformat;
  }
  return window.globalization.dataformat;
};
/**
 * 获取数字格式化
 */
const sysNumberFormat = () => {
  if (!window.globalization) {
    return defaultSysFormat.dataformat.numberFormat;
  }
  return window.globalization.dataformat.numberFormat;
};
/**
 * 设置默认全局格式化
 */
const initSysDefaultFormat = () => {
  window.globalization = defaultSysFormat;
};
/**
 * 获取dayjs datetime格式化
 */
const dayJsDateTimeFormat = () => {
  let format = getSysDateFormat().dateTimeFormat;
  format = format.replace('yyyy', 'YYYY').replace('dd', 'DD');
  return format;
};
/**
 * 获取dayjs date格式化参数
 */
const dayJsDateFormat = () => {
  let format = getSysDateFormat().dateFormat;
  format = format.replace('yyyy', 'YYYY').replace('dd', 'DD');
  return format;
};
/**
 * iview 日历控件格式化需要yyyy dd小写，需要转一次
 */
const sysDateTimeFormat = () => {
  let format = getSysDateFormat().dateTimeFormat;
  format = format.replace('YYYY', 'yyyy').replace('DD', 'dd');
  return format;
};
const sysDateFormat = () => {
  let format = getSysDateFormat().dateFormat;
  format = format.replace('YYYY', 'yyyy').replace('DD', 'dd');
  return format;
};
/**
 * 上下文utc7转utc8,年月日时分秒
 */
const formatToUtc8DateTime = (v, valueUtc = null) => {
  if (!v) return v;
  const formatValueObj = acFormat.getGlobalizationDateFormatString(v, valueUtc, 'UTC+08:00', 'datetime', null, 'YYYY-MM-DD HH:mm:ss');
  const value = (formatValueObj && formatValueObj.value) || v;
  return value;
};
const formatToUtc8Date = (v) => {
  if (!v) return v;
  const formatValueObj = acFormat.getGlobalizationDateFormatString(v, 'UTC+08:00', 'UTC+08:00', 'date', null, 'YYYY-MM-DD');
  const value = (formatValueObj && formatValueObj.value) || v;
  return value;
};
/**
 * 格式化成上下文年月日时分秒
 */
const formatToCtxDateTime = (v) => {
  if (!v) return v;
  const formatValueObj = acFormat.getGlobalizationDateFormatString(v, 'UTC+08:00', null, 'datetime', 'YYYY-MM-DD HH:mm:ss');
  const value = (formatValueObj && formatValueObj.value) || v;
  return value;
};
// 获取数量精度
const getPrecision = (unit) => {
  let precision = 8;
  if (unit && unit.unitPrecision !== undefined) {
    precision = unit.unitPrecision;
  }
  return precision;
};
// 获取数量舍位方式
const getRoundType = (unit) => {
  let roundType = 0;
  if (unit && unit.unitRoundType !== undefined) {
    roundType = unit.unitRoundType;
  }
  return roundType;
};

/**
 * 格式化年月日
 */
const formatToCtxDate = (v) => {
  if (!v) return v;
  const formatValueObj = acFormat.getGlobalizationDateFormatString(v, 'UTC+08:00', 'UTC+08:00', null, 'YYYY-MM-DD');
  const value = (formatValueObj && formatValueObj.value) || v;
  return value;
};

/**
 * 格式化数字
 */
const formatNumber = (v) => {
  if (!v) return v;
  return acFormat.getGlobalizationFormatNumber(v);
};

/**
 * 还原格式化数字
 */
const revertFormatNumber = (num) => {
  if (!num) return num;
  return typeof num === 'number' ? num : num.replace(/,/g, '');
};

/**
 * 精度缺少的位补零
 */
const addZero = (val, exact) => {
  val += '';
  let dotIdx = val.indexOf('.');
  let len = 0;
  if (dotIdx > 0) {
    len = exact - (val.length - dotIdx - 1);
    if (len < 0) len = 0; // 小数点后的个数多于精度，不用补零
  } else if (dotIdx === -1 && exact > 0) {
    val += '.';
    len = exact;
  }
  while (len--) val += '0';
  return val;
};

// 商品卖方信息
/**
 *  1、单商家模式：什么都不显示
    2、平台模式：
      1）商家商品：显示商家名
      2）租户商品：多组织显示组织名   单组织显示租户名
      3）判断是否有组织id
  */
const sellerInfo = (currentUser, product) => {
  if (product.orgId && product.orgId > 0) { // 租户商品
    return currentUser.__isMutiOrg ? product.orgName : product.bizName;
  } else { // 商家商品
    return product.bizName;
  }
};

// 用于判断是否冻结当前账号
// 该功能用于订货端，非订货端上下文不会返回交易关系，所以直接给false;
const isfreezeAccount = (bizId) => {
  let app = window.__app.mainV;
  let __user = app.$store.getters.currentUser;
  if (!__user || !Array.isArray(__user.relations)) {
    return false;
  }
  // 单商家单组织可以确定只要一条交易关系
  if (__user.bizMode === 1) {
    let relation = __user.relations[0];
    if (relation) return relation.freezeAccountFlag;
    // eslint-disable-next-line no-console
    console.error('has not relation for single Biz single org');
    return false;
  }
  if (bizId) {
    let relation = __user.relations.find(relation => relation.bizId === bizId);
    if (relation) return relation.freezeAccountFlag;
    // eslint-disable-next-line no-console
    console.error(`has not relations for biz ${bizId}`);
    return false;
  }
  let ORDERSHOWWAY = getBo(app.$store, 'ORDERSHOWWAY');
  if (ORDERSHOWWAY === true) return false; // 平台模式不冻结
  // 商家模式根据当前交易关系判断是否冻结
  let relationId = localStorage.upAgentRelationId;
  let relation = __user.relations.find(relation => relation.id === relationId);
  if (relation) return relation.freezeAccountFlag;
  // eslint-disable-next-line no-console
  console.error(`has not relation for ${relationId}`);
  return false;
};

const isNonfreezeAccount = (bizId) => {
  return !isfreezeAccount(bizId);
};
/**
 * 格式化数字方法，提供给inputnumber 控件使用
 */
const formatNumberFn = acFormat.getGlobalizationFormatNumber;
const agentPageUrl = (url = '') => {
  // let urlArr = url.split('?');
  // let other = '';
  // if (urlArr && urlArr.length > 1) {
  //   other = url.split('?')[1];
  // }
  // urlArr = [urlArr[0], '?nolayout=1&', other];
  return url;
};
const randomNum = (minNum, maxNum) => {
  return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
};
const setLocalStorageAndTime = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify({ data: value, time: new Date().getTime() }));
};
const getLocalStorageAndTime = (key) => {
  let data = window.localStorage.getItem(key);
  if (!data) return null;
  let dataObj = JSON.parse(data);
  if (new Date().getTime() - dataObj.time > 3600000) { // 超过一小时删除返回null
    window.localStorage.removeItem(key);
    return null;
  } else {
    return dataObj.data;
  }
};
const loadScript = (url, callback) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.defer = true;
  // 处理IE
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
      }
    };
  }
  script.src = url;
  document.body.append(script);
};

// JSEncrypt 加密
const encrypt = (txt) => {
  const publicKey = window.__app.context.publicKey;
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对数据进行加密
};
// CryptoJS 解密
const decrypt = (txt) => {
  const bytes = CryptoJS.AES.decrypt(txt, 'udhservice');
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
const setStorageExpire = (key, value, expire) => {
  let obj = {
    data: value,
    time: Date.now(),
    expire: expire
  };
  // localStorage 设置的值不能为对象,转为json字符串
  localStorage.setItem(key, JSON.stringify(obj));
};

const getStorageExpire = key => {
  let val = localStorage.getItem(key);
  if (!val) {
    return val;
  }
  val = JSON.parse(val);
  if (Date.now() - val.time > val.expire) {
    localStorage.removeItem(key);
    return null;
  }
  return val.data;
};

let util = {
  getIAuxUnitQuantity,
  getIQuantity,
  setLocalStorageAndTime,
  getLocalStorageAndTime,
  randomNum,
  constant,
  title,
  isPromise,
  getBLen,
  getPropByPath,
  getPrecision,
  getRoundType,
  addUnitEnable,
  getScript,
  agentOrderDetailHref,
  agentOpenNewTabEdit,
  getExcelFilePath,
  numberFormat,
  sellerInfo,
  isfreezeAccount,
  isNonfreezeAccount,
  getMenuItemByName,
  indexOfArray,
  getMenuItemTitleByName,
  getRouterObjByName,
  setCurrentPath,
  openNewPage,
  showTitle,
  routeEqual,
  routeEqualNew,
  toFrameProductDetail,
  getRouteTitleHandled,
  combine,
  routeHasExist,
  routeExistModule,
  getNewTagList,
  getHomeRoute,
  getNextRoute,
  menuClickOldPage,
  getCurrentRouteHash,
  clearUpAgentInfo,
  reduceChildren,
  createDateRange,
  compileFlatState,
  flattenDeepData,
  random,
  getDeviceId,
  setDeviceUUid,
  getAgentUrl,
  getRedirectUrl,
  getUrlParam,
  uuid,
  goLogin,
  numAdd,
  numMulti,
  numSub,
  numDiv,
  toFixed,
  toFixedString: _toFixed,
  addZero,
  isNoValue,
  isShowCurrency,
  onSelectedAgent,
  param2Obj,
  stringifyQuery,
  fileSize,
  getBrowserType,
  extendKey,
  setLocal,
  getLocal,
  uploadDataFormat,
  downloadFile,
  getLang: getLangCode,
  getFormatValueByKey,
  getIsMultiLang,
  formatResource,
  getLocaleMessage,
  getBo,
  hasAuth,
  agentLink,
  generateFormatFormat,
  agentPageUrl,
  linkTo,
  viewDatePickFormat,
  initSysDefaultFormat,
  isLegalFormatData,
  makeFormatData,
  getNumberFormatDataValue,
  sysNumberFormat,
  sysDateFormat,
  sysDateTimeFormat,
  dayJsDateFormat,
  dayJsDateTimeFormat,
  formatToCtxDateTime,
  formatToCtxDate,
  formatToUtc8DateTime,
  formatToUtc8Date,
  formatNumber,
  revertFormatNumber,
  formatNumberFn,
  loadScript,
  encrypt,
  decrypt,
  setStorageExpire,
  getStorageExpire,
  getIndexedDBManager,
  clearStorage
};
window.getLocaleMessage = getLocaleMessage;

/* 多语相关方法结束 */
export default util;

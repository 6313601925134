<template>
  <div class="contractRef">
    <Row>
      <!-- 左侧合同列表 -->
      <div class="pull-left product-ref-center" style="width:200px">
        <Input
        ref="input"
        slot="input"
        v-model="contractKeyword"
        :placeholder="contracQueryPlaceeholder"
        icon="md-search"
        class="contractQueryInput"
        @on-enter="queryschemaSearch"></Input>
        <Table
          ref="mainTable"
          :meta-setting="bizMeta"
          :width="mainTableWidth"
          :columns="mainColumns"
          :data="mainTableData"
          :loading="mainTableLoading"
          :height="height"
          @on-row-click="onMainTableRowClick"
          @on-current-change="onMainTableChange">
          <div slot="footer">
            <div style="float: right;">
              <Page
                :total="pager.totalCount"
                :current.sync="pager.currentPage"
                simple
                @hook:mounted="pageMounted"
                @on-change="changePage"></Page>
            </div>
          </div>
        </Table>
      </div>
      <!-- 右侧某一合同明细 -->
      <div
        v-if="cloneMeta.isAllowSelectSku && !cloneMeta.hideSkuTable"
        class="pull-right product-ref-right"
        style="width:600px">
        <div v-if="flattenQueryschemaArr2.length">
          <QuerySchema
          ref="queryschema2"
          :b-refer="true"
          :biz-meta="cloneMeta"
          class="queryschema2"
          :filters="flattenQueryschemaArr2"
          @on-queryschema-search="queryschemaSearch2"></QuerySchema>
        </div>
        <Table
          :meta-setting="bizMeta"
          :columns="subTableColumn"
          :loading="subTableLoading"
          :highlight-row="subHighlightRow"
          :height="height"
          :data="subTableData"
          @on-selection-change="subTableSectionChange">
          <div slot="footer">
            <div style="float: right;">
              <Page
                :total="pager2.totalCount"
                :current.sync="pager2.currentPage"
                simple
                @on-change="changePage2"></Page>
            </div>
          </div>
        </Table>
      </div>
    </Row>
    <!-- 选取规格/商品弹窗 -->

  <Modal
      v-model="isShow"
      class-name="orderSelected-modal"
      :title="mlang('selectSku')"
      width="900"
      @on-cancel="selectSkuCancel"
    >
      <!-- 商品列表 -->
      <div class="pull-left product-ref-right" style="width:450px; border: 1px solid #f0f0f0;">
        <div v-if="flattenQueryschemaArr3.length && isSelectProduct">
          <QuerySchema
          ref="queryschema3"
          :b-refer="true"
          :biz-meta="cloneMeta"
          :filters="flattenQueryschemaArr3"
          @on-queryschema-search="queryschemaSearch3"></QuerySchema>
        </div>
        <Table
            :columns="productTableHeader"
            :loading="productTableLoading"
            :height="height"
            :witdh="450"
            :data="productTableData"
            highlight-row
            @on-selection-change="productTableSectionChange"
            @on-row-click="productTableClick"
        >
          <div slot="footer">
            <div style="float: right;">
              <Page
                :total="productPage.totalCount"
                :current.sync="productPage.currentPage"
                simple
                @hook:updated="productPageUpdated"
                @on-change="changeProductPage"/>
            </div>
          </div>
        </Table>
      </div>
      <!-- 规格列表 -->
      <div class="pull-right product-ref-center" :style="{width:'450px', border:'1px solid #f0f0f0',marginTop: `${isSelectProduct ? '62px' : '0'}`}">
        <Table
          :columns="skuTableHeader"
          :loading="skuTableLoading"
          :highlight-row="subHighlightRow"
          :height="height"
          :witdh="450"
          :data="skuTableData"
          @on-selection-change="skuTableSectionChange"
        >
        </Table>
      </div>

      <div slot="footer">
        <Button
          type="text"
          class="cancel-btn"
          @click="selectSkuCancel">
          {{ mlang('cancel') }}
        </Button>
        <Button
          type="primary"
          @click="selectSkuSubmit">
          {{ mlang('confirmation') }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { oneOf, deepCopy } from '@/u-components/utils/assist';
import CommonMixin from '@/mixin/common.js';
import PropsyncMixin from '@/mixin/propsync.js';
import Mixin from '@/mixin/archiveList.js';
import _ from 'lodash';
import { getSrcProducts, selectSrcBillNo, getProducts } from '@/api/refer-modal';
import { getDiscountPrice } from '@/api/product';
import { upHideString } from '@/filters/index';
const prefixCls = 'ivu-select';
export default {
  name: 'SrcBillRefTable',
  mixins: [CommonMixin, PropsyncMixin, Mixin],
  props: {
    selectedProductGroupId: Number,
    bizMeta: {
      type: Object,
      required: true
    },
    referType: {
      type: String,
      default: 'Table',
      validator(value) {
        return oneOf(value, ['Table', 'TreeTable']);
      }
    },
    isQueryschema: {
      type: [Boolean],
      default: true
    },
    billMeta: {
      default: {},
      type: Object
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: ''
    },
    showInput: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      selectedMainRow: null,
      selectedRows: [],
      flattenQueryschemaArr: [],
      mainTableCheckedArr: [],
      subTableCheckedArr: [],
      queryschema: null,
      cloneMeta: null,
      mainTableLoading: false,
      subTableIsSelectAll: false,
      subTableLoading: false,
      queryschemaObj: {},
      selectedProducts: [],
      hasMouseHoverHead: false,
      prefixCls: prefixCls,
      showValue: '',
      height: 0,
      modal: false,
      pager: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      },
      pager2: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      },
      productPage: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      },
      specReferParams: '', // 规格/商品参照点击的参数
      isShow: false, // 是否展示选择规格弹窗
      skuTableLoading: false, // 是否启动规格弹窗加载动画
      productTableLoading: false, // 是否启动商品列表弹窗加载动画
      mainTableData: [], // 销售合同列表的数据
      mainColumns: [], // 销售合同列表的表头
      subTableColumn: [], // 销售合同的表头
      subTableData: [], // 销售合同的数据
      skuTableHeader: [], // 选择规格表头
      skuTableData: [], // 选择规格数据
      skuSelectRows: [], // 被选中的规格项
      productTableHeader: [], //  选择商品表头
      productTableData: [], //  选择商品数据
      clickRowIndex: 0, // 被点击的合同的数组下标
      fillErrRejectSubmit: false, // 填写销售数量数值验证
      isSelectProduct: false, // 是否是点击商品参照按钮
      tempSpecsProductArr: [], // 临时多规格商品
      tempNoSpecsProductArr: [], // 临时单规格商品
      tempContractDetailsArr: [], // 临时合同明细
      tempSubTableDataArr: [], // 临时的存储的每一页合同明细
      rowno: 0 ,// 临时合同明细标识
      cacheContractDetails: [], // 缓存的合同明细
      cacheSactId: 0, // 记录已经在下单好的合同id
      contractKeyword: "",
      contracQueryPlaceeholder: this.mlang('orders.srcNameSrcCode')
    };
  },
  computed: {
    mainTableWidth() {
      return this.cloneMeta.isAllowSelectSku && !this.cloneMeta.hideSkuTable ? 500 : 900;
    },
    subHighlightRow() {
      return this.cloneMeta && this.cloneMeta.isSingleSku;
    }
  },
  watch: {
    billMeta: {
      handler() {
        this.init();
      },
      deep: true
    }
  },
  created() {
    this.init();
    this.setPager();
    this.loadProductData()
  },
  mounted(){
    const iconSearch = document.querySelector('.product-ref-center .ivu-icon-md-search ')
    iconSearch.addEventListener('click',this.queryschemaSearch)
  },
  methods: {
    pageMounted() {
      let vm = this;
      if (document.getElementsByClassName('ivu-page-simple-pager') && document.getElementsByClassName('ivu-page-simple-pager')[0]) {
        document.getElementsByClassName('ivu-page-simple-pager')[0].firstChild.onblur = function(e) {
          vm.pager.pageIndex = Number(e.target.value) || 1;
          vm.loadProductData();
        };
      }
    },
    // 商品参展分页通过手动输入数字修改，增加失去焦点事件进行页面更新
    productPageUpdated() {
      let vm = this;
      if (document.getElementsByClassName('ivu-page-simple-pager') && document.getElementsByClassName('ivu-page-simple-pager')[0]) {
        document.getElementsByClassName('ivu-page-simple-pager')[2].firstChild.onblur = function(e) {
          vm.changeProductPage(Number(e.target.value) || 1);
        };
      }
    },
    init() {
      // this.loadProductData();
      this.makeMeta();
      this.makeQueryschema();
      this.makeMainColumns();
      this.setSubColumns();
    },
    makeMeta() {
      /**
        isSingleSku -- 是否只选择一个SKU
        isMustSelectSku --是否必须选择一个SKU
        isAllowSelectSku  --选择商品,不能选择SKU
        isAllowSelectProduct --可以选择商品,不选择SKU
      */
      this.cloneMeta = deepCopy(this.billMeta);
    },
    queryschemaSearch() {
      this.pager.pageIndex = 1;
      this.pager.currentPage = 1;
      this.loadProductData();
    },
    queryschemaSearch2(queryschema) {
      this.queryschema = queryschema;
      this.pager2.pageIndex = 1;
      this.pager2.currentPage = 1;
      this.getSrcDetails();
    },
    queryschemaSearch3(queryschema) {
      this.queryschema = queryschema;
      if(queryschema.conditions && queryschema.conditions.length > 0){
        queryschema.conditions.forEach(v => {
          if(v.name === "productKeyword"){
            this.specReferParams.keyword = v.v1
          }
        })
      }else{
        this.specReferParams.keyword = ''
      }
      this.productPage =  { // 重置商品参照的分页信息
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      }
      this.setProductTableData(this.specReferParams);
    },
    // 是否有合同明细缓存
    getCacheOrder() {
      this.cacheContractDetails = [];
      let order = this.cloneMeta.order;
      order && order.oOrderDetails.map((v) => {
        delete (v.id);
        if (v.actionStatus !== 'D') {
          this.cacheContractDetails.push(v);
        }
      });
      if (this.cacheContractDetails.length > 0) {
        this.cacheSactId = this.cacheContractDetails[0].sactId;
      }
    },
    loadProductData() {
      this.getCacheOrder();
      let query = {
        conditions: [],
        pager: {
          pageIndex: this.pager.pageIndex,
          pageSize: this.pager.pageSize
        }
      };
      query.conditions.push({
        name: 'keyword',
        controlType: 'String',
        groupType: 2,
        valueType: 'STRING',
        conditionType: 'eq',
        isShowIt: true,
        v1: this.contractKeyword,
      });
      query.conditions.push({
        name: 'agentId',
        controlType: 'String',
        valueType: 'STRING',
        groupType: 2,
        conditionType: 'eq',
        v1: this.$store.getters.currentUser.agentId
      });
      query.conditions.push({
        name: 'saleOrgId',
        controlType: 'String',
        groupType: 2,
        valueType: 'STRING',
        conditionType: 'eq',
        v1: this.$getBO('ORDERSHOWWAY') ? this.cloneMeta?.order?.oAgent?.orgId : localStorage.getItem('upOrgId')
      });
      if (this.$route.query.titleType === 'editor') {
        query.conditions.push({
          name: 'id',
          controlType: 'Long',
          valueType: 'LONG',
          groupType: 2,
          conditionType: 'eq',
          v1: this.cacheSactId
        });
      }

      this.mainTableLoading = true;
      selectSrcBillNo(query).then((res) => {
        res.data.map((product) => {
          product.contractName = product.name;
          product.contractCode = product.code;
        });
        this.mainTableData = res.data;
        this.pager.totalCount = res.totalCount;
        this.mainTableLoading = false;
      });
    },
    // 左侧销售合同列表行点击事件
    onMainTableRowClick(currentRow, index) {
      // 每次切换合同重制合左侧合同明细分页
      this.pager2.pageIndex = 1;
      this.pager2.currentPage = 1;
      this.$refs.queryschema2.resetViewData();
      this.queryschema = {};
      const dataRow = this.mainTableData.find((v) => {
        return v.id === currentRow.id;
      });
      if (dataRow) {
        if (this.subTableCheckedArr.length > 0 && this.selectedMainRow.id !== this.cacheSactId) {
          if (this.selectedMainRow.id !== currentRow.id) {
            this.$Modal.confirm({
              title: '',
              content: this.mlang('orders.changeSrcMessage'),
              onOk: () => {
                this.subTableCheckedArr = [];
                this.$refs.mainTable.handleCurrentRow('highlight', index);
                // 将临时存储的多规格商品和单规格商品置空
                this.tempNoSpecsProductArr = [];
                this.tempSpecsProductArr = [];
                this.tempContractDetailsArr = [];
              },
              onCancel: () => {

              }
            });
          }
        } else {
          this.$refs.mainTable.handleCurrentRow('highlight', index);
        }
      }
    },
    // 左侧合同列表点击事件
    onMainTableChange(currentRow) {
      this.subTableCheckedArr = [];
      this.selectedMainRow = deepCopy(currentRow);
      this.subTableLoading = true;
      // 切换合同清除临时的存储的每一页合同明细
      this.tempSubTableDataArr = [];
      this.getSrcDetails();
    },
    // 右侧销售合同被选中事件
    subTableSectionChange(selection, row, status) {
      selection.forEach(item => {
        item.page = this.pager2.pageIndex
      })
      this.subTableCheckedArr = this.subTableCheckedArr.filter(item => item.page != this.pager2.pageIndex);
      this.subTableCheckedArr.push(...selection);
      // 以分页数据进行升序排列
      this.subTableCheckedArr = _.sortBy(this.subTableCheckedArr, (item) => {
        return item.page
      })
      this.selectedSubTableData(this.subTableCheckedArr);
    },
    // 设置商品表头
    setProductTableHeader(productId) {
      const noWidthColumn = {
        key: 'nowidth',
      };
      const selectionColumn = {
        type: 'selection',
        width: 40,
        align: 'center',
        isHideCheckAll: true,
      };
      const productName = {
        "showCaption": "商品",
        "isShowIt": true,
        "fieldName": "cName",
        "width": 100
      }
      const productUnit = {
        "showCaption": "计量单位",
        "isShowIt": true,
        "fieldName": "oUnit.cName",
        "width": 70
      }
      const productCode = {
        showCaption: this.mlang('productCode'),
        isShowIt: true,
        width: 100,
        fieldName: 'cCode'
      }
      const modelDescription = {
        showCaption: this.mlang('productDescription'),
        isShowIt: false,
        width: 100,
        fieldName: 'modelDescription'
      }
      const model = {
        showCaption: this.mlang('productModel'),
        isShowIt: true,
        width: 100,
        fieldName: 'model'
      }

      this.productTableHeader = productId ?
      [ noWidthColumn, productName, productCode, modelDescription, model, productUnit, noWidthColumn ]:
      [ selectionColumn, productName, productCode, modelDescription, model, productUnit, noWidthColumn ];

      this.productTableHeader = this.productTableHeader.filter((item, index) => {
        if (item.fieldName === 'cCode' && !this.$getBO('QUICKORDERISSHOWPRODUCTCODEPC')) {
          return false
        }
        if (item.fieldName === 'modelDescription' && !this.$getBO('PRODUCTSPECIFICATION')) {
          return false
        }
        if (item.fieldName === 'model' && !this.$getBO('PACKAGESPECIFICATIONS')) {
          return false
        }
        return item;
      })
    },
    // 商品列表分页改变
    changeProductPage(page) {
      this.tempSpecsProductArr = this.tempSpecsProductArr.filter(item => item.page != this.productPage.pageIndex);
      this.tempNoSpecsProductArr = this.tempNoSpecsProductArr.filter(item => item.page != this.productPage.pageIndex);
      this.productPage.pageIndex = page;
      this.productTableLoading = true;
      this.setProductTableData(this.specReferParams)
      // 规格列表为空
      this.skuTableData = [];
      this.skuTableHeader = [];
      const obj = this.subTableData[this.clickRowIndex];
      let newArr = [];
      this.tempSpecsProductArr.push(...this.productTableData.filter(item => item.hasSpecs && item._checked));
      this.tempNoSpecsProductArr.push(...this.productTableData.filter(item => !item.hasSpecs && item._checked));
      if (this.tempNoSpecsProductArr.length) {
        this.tempNoSpecsProductArr.forEach(productSelectRowItem => {
          newArr.push({
            ...obj,
            productId: productSelectRowItem.id || '',
            productName: productSelectRowItem.cName || '',
            skuId: productSelectRowItem.lsProductSkus[0].id || '',
            priceQty: 0,
            iConversionRate: productSelectRowItem.oProductAuxUnit.iConversionRate || 1,
            invExchRate: productSelectRowItem.invExchRate || 1,
            invPriceExchRate: productSelectRowItem.invPriceExchRate || 1,
            subQty: 0,
            bizId: productSelectRowItem.bizId || '',
            bizProductId: productSelectRowItem.bizProductId || '',
            specNames: '', // SKU名称
            cqtUnitName: productSelectRowItem.oUnit.cName || '', // 计价单位
            saleunitName: productSelectRowItem.oProductAuxUnit.oUnit.cName || '', //销售单位
            _checked: true,
            _disabled: false
          })
        })
      }

      // 被选中商品是多规格
      if (this.tempSpecsProductArr.length) {
        for (let i = 0; i < this.tempSpecsProductArr.length; i++) {
          const selectedSkuArr = this.tempSpecsProductArr[i].lsProductSkus.filter(item => item._checked);
          if (!selectedSkuArr.length) {
            this.$Message.warning({ content: `请选择${this.tempSpecsProductArr[i].cName}的规格` });
            // return false;
          }
          selectedSkuArr.forEach((item, index) => {
            newArr.push({
              ...obj,
              productId: item.iProductId || '',
              productName: this.tempSpecsProductArr[i].cName || '',
              skuId: item.id || '',
              specNames: item.cSpecDescription || '',
              priceQty: 0,
              iConversionRate: item.iConversionRate || 1,
              invExchRate: this.tempSpecsProductArr[i].invExchRate || 1,
              invPriceExchRate: this.tempSpecsProductArr[i].invPriceExchRate || 1,
              subQty: 0,
              bizId: item.bizId || '',
              bizProductId: item.bizProductId || '',
              skuName: item.name || '', // SKU名称
              cqtUnitName: this.tempSpecsProductArr[i].oUnit.cName || '', // 计价单位
              saleunitName: this.tempSpecsProductArr[i].oProductAuxUnit.oUnit.cName || '', //销售单位
              _checked: true,
              _disabled: false
            })
          })
        }
      }

      this.tempContractDetailsArr.forEach(item => {
        if (this.rowno == item.rowno) {
          item.contractDetails = newArr;
        }
      })
    },
    // 设置商品表体数据
    setProductTableData(params) {
      getProducts({
        currentPage: this.productPage.pageIndex,
        pageSize: this.productPage.pageSize,
        keyword: params.keyword ? params.keyword : params.row.productName || [],
        isKeyWordExactSearch: false,
        status: 1,
        orgId: params.row.salesOrgId,
        bizId: localStorage.getItem('upBizId') || '',
        classid: params.row.productClass || ''
     }).then((res) => {
        res.data.forEach(item => {
          item._checked = false;
          item.page = this.productPage.pageIndex;
        })
        this.productTableData = res.data;
        this.productPage.totalCount = res.totalCount;
        this.productTableLoading = false;

        this.productTableData.forEach(item => {
          const tempObj = this.tempContractDetailsArr.find(ContractDetailsItem => ContractDetailsItem.rowno == this.rowno);
          if (tempObj) {
            tempObj.contractDetails && tempObj.contractDetails.forEach(cItem => {
              item.lsProductSkus.forEach(skuItem => {
                if (cItem.skuId == skuItem.id) {
                  item._checked = true;
                  skuItem._checked = true;
                }
              })
            })
          }
        })
        if (!this.isSelectProduct) {
          this.setSkuTable(this.productTableData[0])
          this.skuTableLoading = false;
        }
      });
    },
    productTableClick(row, index) {
      if (row.lsProductSkus && row.lsProductSkus.length && row.hasSpecs) {
        this.setSkuTable(this.productTableData[index])
      } else {
        this.skuTableData = [];
        this.skuTableHeader = [];
      }
    },
    productTableSectionChange(selection, row, status) {
      // 设置高亮
      this.productTableData.forEach(item => {
        row.forEach(selectedItem => {
          if (item.id == selectedItem.id) {
            item._highlight = true;
          } else {
            item._highlight = false;
          }
        })
      })
      // 设置商品的哪些选中
      this.productTableData.forEach(item => {
        row.forEach(selectedItem => {
          if (item.id == selectedItem.id) {
            item._checked = status;
          }
        })
      })
      if (status) {
        const tempSpecsProductArr = this.productTableData.filter(item => item.hasSpecs && item._checked);
        if (tempSpecsProductArr.length) {
          for (let i = 0; i < tempSpecsProductArr.length; i++) {
            const skuArr = tempSpecsProductArr[i].lsProductSkus.filter(subItem => subItem._checked)
            if (row[0].id != tempSpecsProductArr[i].id && !skuArr.length) {
              this.$Message.warning({ content: `请选择${tempSpecsProductArr[i].cName}的规格` });
              this.productTableData.forEach(item => {
                if (item.id == tempSpecsProductArr[i].id) {
                  item._highlight = true;
                } else {
                  item._highlight = false;
                }
              })
              this.setSkuTable(tempSpecsProductArr[i])

              return
            }
          }
        }
        if (row[0].hasSpecs) {
          this.setSkuTable(row[0])
        } else {
          this.skuTableData = [];
          this.skuTableHeader = [];
        }
      }
      // 如果取消选中某一商品则其规格列表也一并全部取消勾选
      if (!status && row[0].hasSpecs) {
        this.productTableData.forEach(item => {
          row.forEach(selectedItem => {
            if (item.id == selectedItem.id) {
              item._checked = false;
              item.lsProductSkus.forEach(subItem => {
                subItem._checked = false;
              })
              this.skuTableData = [];
              this.skuTableHeader = [];
              this.$nextTick(() => {
                this.setSkuTable(item)
              })
            }
          })
        })
      }
    },
    // 选择规格/商品的弹窗取消操作
    selectSkuCancel() {
      this.isShow = false;
      this.queryschema = {};
      this.$refs.queryschema3.resetViewData();
      // 将临时存储的多规格商品和单规格商品置空
      this.tempNoSpecsProductArr = [];
      this.tempSpecsProductArr = [];
    },
    // 勾选规格事件
    skuTableSectionChange(selection, row, status) {// selection 被选中的行 row当前选择的行 status（true/false）
      this.productTableData.forEach(item => {
        item._highlight = false;
        if (item.hasSpecs) {
          item.lsProductSkus.forEach(subItem => {
            row.forEach(selectedItem => {
              if (subItem.id == selectedItem.id) {
                subItem._checked = status;
                item._checked ? '' : item._checked = status;
                item._highlight ? '' : item._highlight = status;
              }
            })
          })
        }
      })
    },
    // 规格参照点击
    async specReferClick(params, isSelectProduct) {
      params.keyword = '';
      this.specReferParams = params;
      this.queryschema = {};
      this.rowno = params.row.rowno;
      if (!this.tempContractDetailsArr.find(item => item.rowno == params.row.rowno)) {
        this.tempContractDetailsArr.push({rowno: params.row.rowno})
      }
      this.productPage =  { // 重置商品参照的分页信息
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      }
      this.clickRowIndex = params.index;
      this.isShow = true;
      this.selectedRows = [];
      // 每次弹窗弹起商品列表、规格列表表头、规格列表数据设置为空
      this.productTableData = [];
      this.skuTableHeader = [];
      this.skuTableData = [];
      this.isSelectProduct = isSelectProduct;
      // 选择商品/选择规格
      if (this.isSelectProduct) {
        this.productTableLoading = true;
        this.setProductTableHeader(params.row.productId);
        this.setProductTableData(params);
      } else {
        this.skuTableLoading = true;
        this.productTableLoading = true;
        this.setProductTableHeader(params.row.productId);
        this.setProductTableData(params);
      }
    },
    // 选择规格/商品的弹窗提交操作
    selectSkuSubmit() {
      const obj = this.subTableData[this.clickRowIndex];
      let newArr = [];
      // 点击的是商品参照按钮还是规格参照的按钮
      if (this.isSelectProduct) {
        this.tempSpecsProductArr = this.tempSpecsProductArr.filter(item => item.page != this.productPage.pageIndex);
        this.tempNoSpecsProductArr = this.tempNoSpecsProductArr.filter(item => item.page != this.productPage.pageIndex);

        this.tempSpecsProductArr.push(...this.productTableData.filter(item => item.hasSpecs && item._checked));
        this.tempNoSpecsProductArr.push(...this.productTableData.filter(item => !item.hasSpecs && item._checked));

        // 是否勾选商品
        if (!this.tempSpecsProductArr.length && !this.tempNoSpecsProductArr.length) {
          this.$Message.warning({ content: this.mlang('pleaseSelectProduct') });
          this.isShow = true;
          return
        }
        // 被选中商品是单规格
        if (this.tempNoSpecsProductArr.length) {
          this.tempNoSpecsProductArr.forEach(productSelectRowItem => {
            newArr.push({
              ...obj,
              productId: productSelectRowItem.id || '',
              productName: productSelectRowItem.cName || '',
              productCode: productSelectRowItem.cCode || '',
              skuId: (productSelectRowItem.lsProductSkus && productSelectRowItem.lsProductSkus[0].id) || '',
              priceQty: 0,
              iConversionRate: productSelectRowItem.oProductAuxUnit.iConversionRate || 1,
              invExchRate: productSelectRowItem.invExchRate || 1,
              invPriceExchRate: productSelectRowItem.invPriceExchRate || 1,
              subQty: 0,
              bizId: productSelectRowItem.bizId || '',
              bizProductId: productSelectRowItem.bizProductId || '',
              specNames: '', // SKU名称
              cqtUnitName: productSelectRowItem.oUnit.cName || '', // 计价单位
              saleunitName: productSelectRowItem.oProductAuxUnit.oUnit.cName || '', //销售单位
              model: productSelectRowItem.model || '', // 型号
              modelDescription: productSelectRowItem.modelDescription || '', // 规格说明
              _checked: true,
              _disabled: false
            })
          })
        }

        // 被选中商品是多规格
        if (this.tempSpecsProductArr.length) {
          for (let i = 0; i < this.tempSpecsProductArr.length; i++) {
            const selectedSkuArr = this.tempSpecsProductArr[i].lsProductSkus.filter(item => item._checked);
            if (!selectedSkuArr.length) {
              this.$Message.warning({ content: `请选择${this.tempSpecsProductArr[i].cName}的规格` });
              return false;
            }
            selectedSkuArr.forEach((item, index) => {
              newArr.push({
                ...obj,
                productId: item.iProductId || '',
                productName: this.tempSpecsProductArr[i].cName || '',
                productCode: this.tempSpecsProductArr[i].cCode || '',
                skuId: item.id || '',
                specNames: item.cSpecDescription || '',
                priceQty: 0,
                iConversionRate: item.iConversionRate || 1,
                invExchRate: this.tempSpecsProductArr[i].invExchRate || 1,
                invPriceExchRate: this.tempSpecsProductArr[i].invPriceExchRate || 1,
                subQty: 0,
                bizId: item.bizId || '',
                bizProductId: item.bizProductId || '',
                skuName: item.name || '', // SKU名称
                cqtUnitName: this.tempSpecsProductArr[i].oUnit.cName || '', // 计价单位
                saleunitName: this.tempSpecsProductArr[i].oProductAuxUnit.oUnit.cName || '', //销售单位
                model: this.tempSpecsProductArr[i].model || '', // 型号
                modelDescription: this.tempSpecsProductArr[i].modelDescription || '', // 规格说明
                _checked: true,
                _disabled: false
              })
            })
          }
        }

        const rownoArr = this.subTableData.filter(item => item.rowno === this.rowno).slice(1);
        newArr.forEach((newItem, index) => {
          if (rownoArr.length) {
            const pacth = rownoArr.filter(item => item.skuId === newItem.skuId)
            if (pacth.length) {
              newArr[index] = pacth[0]
            }
          }
        })
        this.subTableData.splice(this.clickRowIndex + 1, rownoArr.length, ...newArr);
        this.tempContractDetailsArr.forEach(item => {
          if (this.rowno == item.rowno) {
            item.contractDetails = newArr;
          }
        })
        const selectedArr = this.subTableData.filter(item => item._checked)
        this.subTableSectionChange(selectedArr, _, true); // 手动触发合同明细的被选中事件
        this.isShow = false;
      } else {
        const selectedSkuArr = []
        this.productTableData.forEach(item => {
          item.lsProductSkus.forEach(subItem => {
            subItem.model = item.model;
            subItem.modelDescription = item.modelDescription;
            subItem.invExchRate = item.invExchRate;
            subItem.invPriceExchRate = item.invPriceExchRate;
          })
          selectedSkuArr.push(...item.lsProductSkus.filter(subItem => subItem._checked));
        })
        if (selectedSkuArr.length) {
          selectedSkuArr.forEach(item => {
            newArr.push({
              ...obj,
              productId: item.iProductId || '',
              productName: item.productName || '',
              skuId: item.id || '',
              specNames: item.cSpecDescription || '',
              skuName: item.name || '',
              priceQty: 0,
              iConversionRate: item.iConversionRate || 1,
              invExchRate: item.invExchRate || 1,
              invPriceExchRate: item.invPriceExchRate || 1,
              subQty: 0,
              bizId: item.bizId || '',
              bizProductId: item.bizProductId || '',
              modelDescription: item.modelDescription || '',
              model: item.model || '',
              _checked: true,
              _disabled: false
            });
          })
        } else {
          this.$Message.warning({ content: this.mlang('pleaseSelectSku') });
          this.isShow = true;
        }
        let rownoArr = this.subTableData.filter(item => item.rowno === this.rowno).slice(1);
        newArr.forEach((newItem, index) => {
          if (rownoArr.length) {
            let pacth = rownoArr.filter(item => item.skuId === newItem.skuId)
            if (pacth.length) {
              newArr[index] = pacth[0]
            }
          }
        })
        this.subTableData.splice(this.clickRowIndex + 1, rownoArr.length, ...newArr);
        this.tempContractDetailsArr.forEach(item => {
          if (this.rowno == item.rowno) {
            item.contractDetails = newArr;
          }
        })
        let selectedArr = this.subTableData.filter(item => item._checked)
        this.subTableSectionChange(selectedArr, _, true); // 手动触发合同明细的被选中事件
        this.isShow = false;
      }
    },
    // 对销售合同明细从被选中的明细中进行查询判断其是否勾选
    selectedSubTableData(selectedArr) {
      this.subTableData.forEach(item => {
        // 查询当前销售合同明细是否在临时的被选中的skuId数组中
        let flag = selectedArr.find(subitem => item.skuId == subitem.skuId && item.rowno == subitem.rowno);
        if (flag) {
          item._checked = true;
        } else {
          item._checked = false;
        }
      });
    },
    // 手动修改销售数量并通过主辅计算率对计价数量进行赋值
    changeSubQtyValue(e, params) {
      const { row, index } = params;
      const { target } = e;
      if (!Number(target.value)) {
        target.value = 0;
        this.subTableData.forEach((item, index) => {
          if (params.index === index) {
              item.subQty = target.value;
              item.priceQty = target.value;
          }
        });
        this.$Message.warning({ content: this.mlang('PleaseFillSalesQuantity') });
        return false;
      }
      if (row.oriTaxUnitPrice === undefined || row.oriUnitPrice === undefined) {
        const data = {
          isArchive: 1,
          skuId: row.skuId,
          count: Number(target.value),
          bizId: row.bizId,
          saleOrgId: row.salesOrgId,
          bizProductId: row.bizProductId,
          productId: row.productId
        }
        this.subTableLoading = true;
        getDiscountPrice(data).then(res => {
          const oriTaxUnitPrice = res ? res/Number(target.value) : 0;
          // 监测对象变化，使其触发页面视图更新
          this.$set(this.subTableData, index, {...this.subTableData[index], oriTaxUnitPrice})
          this.subTableLoading = false;
        }).catch(err => {
          target.value = row.subQty;
          this.subTableLoading = false;
        })
      }
      this.subTableData.forEach((item, index) => {
        if (params.index === index) {
          if (e.target.value >= 0) {
            item.subQty = +e.target.value;
            item.priceQty = this.$util.toFixed(this.$util.numDiv(this.$util.numMulti(e.target.value, item.invExchRate), item.invPriceExchRate), item.cqtPrecision, item.cqtUnitRoundType);
          } else {
            item.subQty = 0;
            item.priceQty = 0;
            this.$Message.warning({ content: this.mlang('PleaseFillSalesQuantity') });
          }
        }
      });
      this.selectedSubTableData(this.subTableCheckedArr);
      let selected = this.subTableData.filter(item => item._checked)
      this.subTableSectionChange(selected, _, true); // 手动触发合同明细的被选中事件
    },
    // 设置规格表格
    setSkuTable(data) {
      // 设置规格表头
      this.skuTableHeader = [{ type: 'selection', width: 40, align: 'center', isHideCheckAll: false }, { title: this.mlang('guige'), key: 'cSpecDescription' }]
      // 设置规格表体数据
      this.skuTableData = data.lsProductSkus;
      this.skuTableData.forEach(item => item.productName = data.cName);
    },
    // 提交合同时的校验
    getSelectRow() {
      if (!this.selectedMainRow) {
        this.$Message.warning({ content: this.mlang('pleaseSelectSaleBillOrder') });
        return false;
      }
      if (!this.subTableCheckedArr.length) {
        this.$Message.warning({ content: this.mlang('pleaseCheckProduct') });
        return false;
      }
      // 检查被选中的销售合同是否没有选择规格/商品
      const ifHasProdductOrSku = this.subTableCheckedArr.some(item => {
        return item.productId === undefined || item.skuId === undefined
      })
      if (ifHasProdductOrSku) {
        this.$Message.warning({ content: `${this.mlang('pleaseSelectProduct')}或${this.mlang('pleaseSelectSku')}` });
        return false;
      }

      // 是否没有填写销售数量
      const ifFillSalesQuantity = this.subTableCheckedArr.some(item => {
        return item.subQty == 0 || item.priceQty == 0
      })

      if (ifFillSalesQuantity) {
        this.$Message.warning({ content: this.mlang('PleaseFillSalesQuantity') });
        return false;
      }

      if (this.cloneMeta.multiple) {
        return this.selectedRows;
      } else {
        return this.getSingleSelectRow();
      }
    },
    getSingleSelectRow() {
      const srcBillOrder = this.selectedMainRow;
      Object.assign(srcBillOrder, { bizId: this.billMeta.bizId });
      const srcBillDetails = this.subTableCheckedArr;
      srcBillOrder.orderDetails = srcBillDetails;
      return [srcBillOrder];
    },
    setSubColumns() {
      const vm = this;
      const subColumns = [];
      let column = [
        {
          showCaption: vm.mlang('orders.productClassName'),
          isShowIt: true,
          width: 100,
          fieldName: 'productClassName',
          render(_, params) {
            return (
              <div>{ params.row.productClassName || '_' }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('orders.productName'),
          isShowIt: true,
          width: 100,
          fieldName: 'productName',
          render(_, params) {
            return (
              params.row.productId ? (
                <div title={params.row.productName} class="two-line-overflow-hide">
                  { params.row.productName }
                </div>
              ) : (
                <div onClick={() => vm.specReferClick(params, true)} style="cursor: pointer;">
                  选择商品
                  <Icon type="navicon-round" style="color: #808695; paddingLeft: 10px" />
                </div>
              )
            )
          }
        },
        {
          showCaption: vm.mlang('productCode'),
          isShowIt: true,
          width: 130,
          fieldName: 'productCode'
        },
        {
          showCaption: vm.mlang('guige'),
          isShowIt: true,
          width: 150,
          fieldName: 'specNames',
          render(_, params) {
            return (
              params.row.productId ? (
                params.row.skuId ? (
                  <div>{ params.row.specNames || '_' }</div>
                ) : (
                  <div onClick={() => vm.specReferClick(params, false)} style="cursor: pointer;">
                    选择规格
                    <Icon type="navicon-round" style="color: #808695; paddingLeft: 10px" />
                  </div>
                )
              ) : ''
            )
          }
        },
        {
          showCaption: vm.mlang('orders.subQty'),
          isShowIt: true,
          width: 80,
          fieldName: 'subQty',
          render(_, params){
            return (
              params.row._disabled ? (
                <div style="padding-left: 7px">
                  {params.row.subQty}
                </div>
              ) : (
                <input
                  class="ivu-input-number-input"
                  value={params.row.subQty}
                  onChange={(e) => vm.changeSubQtyValue(e, params)}
                  type="number"
                  spellcheck="false"
                  autocomplete="off"
                />
              )
            )
          }
        },
        {
          showCaption: vm.mlang('orders.availableQuantity'),
          isShowIt: true,
          width: 100,
          fieldName: 'orderSurplusNum'
        },
        {
          showCaption: vm.mlang('orders.availableAmount'),
          isShowIt: true,
          width: 100,
          fieldName: 'orderSurplusMoney',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.orderSurplusMoney, 'fSalePrice') }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('productDescription'),
          isShowIt: false,
          width: 100,
          fieldName: 'modelDescription'
        },
        {
          showCaption: vm.mlang('productModel'),
          isShowIt: true,
          width: 100,
          fieldName: 'model'
        },
        {
          showCaption: vm.mlang('orders.salesOrgName'),
          isShowIt: true,
          width: 150,
          fieldName: 'salesOrgName'
        },
        {
          showCaption: vm.mlang('orders.skuName'),
          isShowIt: true,
          width: 150,
          fieldName: 'skuName'
        },
        {
          showCaption: vm.mlang('orders.cqtUnitName'),
          isShowIt: true,
          width: 150,
          fieldName: 'cqtUnitName'
        },
        {
          showCaption: vm.mlang('orders.priceQty'),
          isShowIt: true,
          width: 100,
          fieldName: 'priceQty'
        },
        {
          showCaption: vm.mlang('orders.saleunitName'),
          isShowIt: true,
          width: 100,
          fieldName: 'saleunitName'
        },
        {
          showCaption: vm.mlang('orders.oriTaxUnitPrice'),
          isShowIt: true,
          width: 150,
          fieldName: 'oriTaxUnitPrice',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.oriTaxUnitPrice, 'fSalePrice') }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('orders.oriUnitPrice'),
          isShowIt: true,
          width: 150,
          fieldName: 'oriUnitPrice',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.oriUnitPrice, 'salePriceOrigTaxfree') }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('orders.oriSum'),
          isShowIt: true,
          width: 150,
          fieldName: 'oriSum',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.oriSum, 'fSaleCost') }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('orders.oriMoney'),
          isShowIt: true,
          width: 150,
          fieldName: 'oriMoney',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.oriMoney, 'salePayMoneyOrigTaxfree') }</div>
            )
          }
        }
      ];
      if (this.cloneMeta.isSingleSku) {
        subColumns.push(this.c_radioColumn, ...column);
      } else {
        subColumns.push(this.c_selectionColumn, ...column);
      }
      // 销售合同明细支持企业端闪定页面设置中商品模板业务选项控制UDH-60504
      const filterSubColumns = subColumns.filter((item, index) => {
        if (item.fieldName === 'productCode' && !this.$getBO('QUICKORDERISSHOWPRODUCTCODEPC')) {
          return false
        }
        if (item.fieldName === 'modelDescription' && !this.$getBO('PRODUCTSPECIFICATION')) {
          return false
        }
        if (item.fieldName === 'model' && !this.$getBO('PACKAGESPECIFICATIONS')) {
          return false
        }
        return item;
      })
      this.subTableColumn = filterSubColumns;
    },

    makeMainColumns() {
      const noWidthColumn = {
        key: 'nowidth',
        title: ''
      };
      const selectionColumn = {
        type: 'selection',
        width: 40,
        align: 'center',
        isHideCheckAll: this.cloneMeta.isMustSelectSku
      };
      if (this.cloneMeta.multiple) {
        this.cloneMeta.columns = [selectionColumn, ...this.cloneMeta.columns, noWidthColumn];
      } else {
        this.cloneMeta.columns = [...this.cloneMeta.columns, noWidthColumn];
      }
      this.cloneMeta.columns.forEach(item => {
        if (item.fieldName == 'contractName' || item.fieldName == 'contractCode') {
          item.width = 220
        }
      })
      this.mainColumns = this.cloneMeta.columns;
    },
    makeQueryschema() {
      let queryschemas = deepCopy(this.billMeta.queryschema);
      let queryschemas2 = deepCopy(this.billMeta.queryschema2);
      let queryschemas3 = deepCopy(this.billMeta.queryschema3);
      this.flattenQueryschemaArr = queryschemas.filter((v) => {
        return v.isHidden !== true;
      });
      this.flattenQueryschemaArr2 = queryschemas2.filter((v) => {
        return v.isHidden !== true;
      });
      this.flattenQueryschemaArr3 = queryschemas3.filter((v) => {
        return v.isHidden !== true;
      });
    },

    getQueryschemaData() {
      const schema = this.$refs.queryschema.getQueryschemaRealData();
      schema.pager = this.pager;
      return schema;
    },
    queryschemaLoaded() {
      this.height = 434;
      const querySchema = this.getQueryschemaData();
      this.queryschema = querySchema;
      this.loadProductData();
    },
    changePage(page) {
      this.pager.pageIndex = page;
      this.loadProductData();
    },
    changePage2(page) {
      // 存储每一页销售合同明细数据
      const flag = this.tempSubTableDataArr.find(item => item.page == this.pager2.pageIndex);
      if (!flag) {
        this.tempSubTableDataArr.push({page: this.pager2.pageIndex, subTableData: this.subTableData})
      }
      this.pager2.pageIndex = page;
      this.getSrcDetails();
    },
    getSrcDetails() {
      let postData = {
        conditions: [],
        pager: {
          pageIndex: this.pager2.pageIndex,
          pageSize: this.pager2.pageSize
        }
      };
      if (this.queryschema && this.queryschema.conditions && this.queryschema.conditions.length) {
        this.queryschema.conditions.forEach((v) => {
          if(v.name === "contractDetailKeyword"){
            postData.conditions.push({
            name: 'keyWord',
            controlType: 'String',
            groupType: 2,
            valueType: 'STRING',
            conditionType: 'eq',
            v1: v.v1,
          });
          }
        });
      }
      const salesContractConditionOBj = {
        name: 'salesContractId',
        controlType: 'Long',
        valueType: 'LONG',
        groupType: 2,
        conditionType: 'eq',
        v1: this.selectedMainRow.id || ''
      };
      const orgIdConditionOBj = {
        name: 'orgId',
        controlType: 'Long',
        valueType: 'LONG',
        groupType: 2,
        conditionType: 'eq',
        v1: this.selectedMainRow.salesOrgId || ''
      };
      postData.conditions.push(salesContractConditionOBj, orgIdConditionOBj);
      // 如果临时的存储的合同明细存在直接用临时存储的数据就行返回
      const flag = this.tempSubTableDataArr.find(item => item.page == this.pager2.pageIndex);
      if (flag) {
        this.subTableData = flag.subTableData;
        return
      }
      this.subTableLoading = true;
      getSrcProducts(postData).then((res) => {
        if (this.cacheContractDetails.length > 0) {
          const processedData = this.assemblyData(this.cacheContractDetails, res.content);
          this.subTableData = processedData;
          let selectedArr = this.subTableData.filter(item => item._checked)
          this.subTableSectionChange(selectedArr, _, true); // 手动触发合同明细的被选中事件
        } else {
          res.content.forEach(item => {
            item.page = this.pager2.pageIndex
          })
          this.subTableData = res.content;
        }

        this.subTableData.forEach(item => {
          if ( !item.productId || !item.skuId ) {
            item._disabled = true;
          }
          // 对被选中的合同明细进行查找如果存在将合同明细进行勾选
          const flag = this.subTableCheckedArr.find(selectedItem => selectedItem.skuId == item.skuId)
          if (flag) {
            item._checked = true;
          } else {
            item._checked = false;
          }
        })
        this.pager2.totalCount = res.totalElements;
        this.subTableLoading = false;
      });
    },
    // 合同参照下单再次点击销售合同参照将原有数据组装
    assemblyData(cacheData, newData) {
      this.tempContractDetailsArr = [];
      const cloneNewData = JSON.parse(JSON.stringify(newData));

      newData.forEach(newItem => {
        const hasCache = cacheData.findIndex(cacheItem => newItem.id == cacheItem.sacoId);
        if (hasCache != -1) {
          if (newItem.productId && newItem.skuId) {
            const cloneMacthIndex = cloneNewData.findIndex(item => item.id == newItem.id);
            const cacheMacthIndex = cacheData.findIndex(cacheItem => cacheItem.sacoId == newItem.id);
            cloneNewData[cloneMacthIndex]._checked = true;
            cloneNewData[cloneMacthIndex].subQty = cacheData[cacheMacthIndex].iAuxUnitQuantity;
            cloneNewData[cloneMacthIndex].priceQty = cacheData[cacheMacthIndex].iQuantity;
          }
          if (newItem.productId == undefined || newItem.skuId == undefined) {
            const macthIndex = cloneNewData.findIndex(item => item.id == newItem.id);
            const filterCacheData = cacheData.filter(cacheItem => cacheItem.sacoId == newItem.id);
            const MapFilterCacheData = filterCacheData.map(item => {
              return {
                ...newItem,
                productId: item.iProductId || '',
                productName: item.cProductName || '',
                productCode: item.cProductCode || '',
                skuId: item.iSKUId || '',
                specNames: item.cSpecDescription || '',
                skuName: item.oSKU && item.oSKU.name || '',
                iConversionRate: item.iConversionRate || 1,
                invExchRate: item.invExchRate || 1,
                invPriceExchRate: item.invPriceExchRate || 1,
                subQty: item.iAuxUnitQuantity, // 销售数量
                priceQty: item.iQuantity || '', // 计价数量
                cqtUnitName: item.cProductUnitName || '', // 计价单位
                saleunitName: item.cProductAuxUnitName || '', // 销售单位
                bizId: item.bizId || '',
                bizProductId: item.bizProductId || '',
                oriTaxUnitPrice: item.fSalePrice, // 含税单价
                oriUnitPrice: item.salePriceOrigTaxfree, // 无税单价
                oriSum: item.fSalePayMoney, // 含税金额
                oriMoney: item.salePayMoneyOrigTaxfree, // 无税金额
                model: (item.oSKU && item.oSKU.oProduct.model) || '', // 型号
                modelDescription: (item.oSKU && item.oSKU.oProduct.modelDescription) || '', // 规格说明
                _checked: true,
                _disabled: false
              }
            })
            cloneNewData.splice(macthIndex + 1, 0, ...MapFilterCacheData)
          }
        }
      })

      cloneNewData.forEach(newItem => {
        if (!this.tempContractDetailsArr.find(item => item.rowno == newItem.rowno)) {
          this.tempContractDetailsArr.push({ rowno: newItem.rowno })
        }
        if (newItem.productId === undefined || newItem.skuId === undefined) {
          const fData = cloneNewData.filter(fItem => fItem.id == newItem.id && fItem.productId && fItem.skuId)
          if (fData.length > 0) {
            this.tempContractDetailsArr.forEach(item => {
              if (newItem.rowno == item.rowno) {
                item.contractDetails = fData;
              }
            })
          }
        }
      })
      return cloneNewData
    },
    setPager() {
      if (this.cloneMeta.pagerSizeOpts) {
        this.pager.pageSizeOpts = this.cloneMeta.pagerSizeOpts;
      }
      this.pager.pageSize = this.pager.pageSizeOpts[0];
    }

  }
};
</script>
<style lang="less" scoped>
.contractRef{
  .product-ref-center{
    border-right:transparent !important;
  }
  .contractQueryInput{
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 16px;
  }

  .orderSelected-modal {
    /deep/ .ivu-modal-content {
      .ivu-modal-body {
        height: 514px;
      }
    }
  }
}

/deep/ .ivu-modal-content {
  .ivu-modal-body {
    display: flex;
  }
}
.product-ref-right {
  /deep/ .main-body {
    height: 340px;
  }
}
</style>

<template>
  <div class="agent-recommend">
    <div
      :id="childId"
      class="agent-recommed-title">
      <span class="title-ch">{{ productsInfo.ch_title }}</span><br>
      <!-- <span
      v-if="!isMultiLang"
      class="title-en">{{ productsInfo.en_title }}</span> -->
      <a
        class="check-all"
        href="javascript:void(0)"
        :fieldid="`fieldid_productList_showAll_${childId}`"
        @click="agentLink({type: 'agentProductMenu'})">
        <span class="text">{{ mlang('showALl') }}</span>
      </a>
    </div>
    <div class="agent-recommed-lists">
      <ul class="list">
        <li
          v-for="(product,index) in products"
          :key="index"
          class="listChildren">
          <a
            class="list-link"
            href="javascript:void(0)"
            @click="toProductDetail(product)">
            <ul>
              <li class="listChildren-img">
                <u-image :src="product.imgUrl" lazy />
              </li>
              <li class="listChildren-text">
                <span
                  :title="product.cName"
                  v-html="product.cName">
                </span>
              </li>
              <!-- 标签 -->
              <li v-if="$getBO('ENABLEPRODUCTLABELING')" class="two-line-overflow-hide" style="padding: 0 10px;">
                <Tag v-for="tag in product.productTags" :key="tag.id" :color="tag.backGroundColor" :name="tag.name" size="small">
                  <span :style="{ color: tag.fontColor }">
                    {{ tag.name }}
                  </span>
                </Tag>
              </li>
              <li class="listChildren-price">
                <p class="price" fieldid="products-price" v-if="PRODUCTLISTPRICEDISPLAYMMODE !== 'rangePrice' || product.iMaxPrice === product.iMinPrice">
                  <span :class="['price-symbol',{'hidesymbol':testStr(PRODUCTLISTPRICEDISPLAYMMODE === 'portalPrice' ? product.fSalePrice : (PRODUCTLISTPRICEDISPLAYMMODE === 'highestPrice' ? product.iMaxPrice : product.iMinPrice))}]">{{ '' | upCoinOperater | priceFree | upHideOperater('fSalePrice') }}</span>
                  <span style="width: auto;" :class="['price','bitifuling', {'nosetPrice':testStr(PRODUCTLISTPRICEDISPLAYMMODE === 'portalPrice' ? product.fSalePrice : (PRODUCTLISTPRICEDISPLAYMMODE === 'highestPrice' ? product.iMaxPrice : product.iMinPrice))}]">{{ PRODUCTLISTPRICEDISPLAYMMODE === 'portalPrice' ? product.fSalePrice : (PRODUCTLISTPRICEDISPLAYMMODE === 'highestPrice' ? product.iMaxPrice : product.iMinPrice) | upCoinDigit('price') | priceFree | upHideString('fSalePrice') }}</span>
                  <span style="font-size: 14px;">/{{ product.oUnit.cName }}</span>
                </p>
                <p class="price rangePriceWrapper" fieldid="products-price" v-if="PRODUCTLISTPRICEDISPLAYMMODE === 'rangePrice' && product.iMaxPrice !== product.iMinPrice">
                  <span :class="['price-symbol',{'hidesymbol':testStr(product.iMinPrice)}]">{{ '' | upCoinOperater | priceFree | upHideOperater('fSalePrice') }}</span>
                  <span :class="['price ','rangePrice',{'nosetPrice':testStr(product.iMinPrice)}]">{{ product.iMinPrice | upCoinDigit('price') | priceFree | upHideString('fSalePrice') }}</span>
                  <span style="margin:0 3px">-</span>
                  <span :class="['price-symbol',{'hidesymbol':testStr(product.iMaxPrice)}]">{{ '' | upCoinOperater | priceFree | upHideOperater('fSalePrice') }}</span>
                  <span :class="['price','rangePrice', {'nosetPrice':testStr(product.iMaxPrice)}]">{{ product.iMaxPrice | upCoinDigit('price') | priceFree | upHideString('fSalePrice') }}</span>
                </p>
                <p
                  v-if="product.cInventoryDisplay && product.virtualProductAttribute !== 9"
                  :title="product.cInventoryDisplay"
                  :class="['num',{'noFloat': PRODUCTLISTPRICEDISPLAYMMODE === 'rangePrice' && product.iMaxPrice !== product.iMinPrice}]">
                  {{ product.cInventoryDisplay }}
                </p>
              </li>
              <li
                v-if="$getBO('ORDERSHOWWAY') && currentUser.__isMutiBiz && !currentUser.__showBiz"
                class="listChildren-name">
                {{ $util.sellerInfo(currentUser,product) }}
              </li>
              <li
                v-if="promotionFlags.sale"
                class="jian">
                {{ mlang('subtract') }}
              </li>
              <li
                v-if="promotionFlags.discount"
                class="zhe">
                {{ mlang('discount') }}
              </li>
              <li
                v-if="promotionFlags.gifts"
                class="zeng">
                {{ mlang('gifts') }}
              </li>
            </ul>
          </a>
        </li>
        <li
          v-if="products && products.length<10"
          class="listChildren moreChild">
          <span class="tip-text">{{ mlang('commingSoon') }}</span>
          <img :src="imgSrc" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import IMG from '@/assets/images/commingSoon.png';
import { priceFree } from '@/filters/index.js';
export default {
  name: 'ProductList',
  props: {
    products: {
      type: Array
    },
    promotionFlags: {
      type: Object
    },
    productsInfo: {
      type: Object
    },
    childId: {
      type: String
    }
  },
  data() {
    return {
      imgSrc: IMG,
      PRODUCTLISTPRICEDISPLAYMMODE: this.$getBO('PRODUCTLISTPRICEDISPLAYMMODE')
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
  },
  methods: {
    toProductDetail(product) {
      if (window.top === window.self) {
        this.agentLink({ type: 'productDetail', query: { menuid: 'agentProductDetail', id: product.id, bizProductId: product.bizProductId }, newWindow: true });
      } else {
        this.$util.toFrameProductDetail(product.id, product.bizProductId);
      }
    },
    testStr(content) {
      return !this.$util.isNoValue(priceFree(content)) && isNaN(priceFree(content));
    }

  }
};
</script>

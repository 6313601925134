<template>
  <div class="agent-tp">
    <ul class="agent-top">
      <li class="agent-logo">
        <a href="#" class="agent-logo-a" fieldid="fieldid_searchbar_logo">
          <img
            v-if="currentUser.corpLogo"
            :alt="currentUser.corpration"
            :src="currentUser.corpLogo"
            :class="{'img-width':imgWidth,'img-height':!imgWidth }" />
        </a>
      </li>
      <li class="agent-search">
        <input
          v-model="keyword"
          type="text"
          :placeholder="mlang('searchPlaceholdre')"
          fieldid="fieldid_searchbar_input"
          @keydown="keySearch" />
        <p class="icon" fieldid="fieldid_searchbar_search" @click="search">
          <i class="iconfont sousuo1"></i>
        </p>
      </li>
      <li v-if="$hasAuth('agentBuyProduct') && $util.isNonfreezeAccount()" class="agent-newOrder">
        <div v-if="isTourist" class="text">
          <a class="Neworder" fieldid="fieldid_searchbar_quickOrder" @click="toQuickOrder"><span>{{ mlang('quickOrder') }}1</span></a>
        </div>
        <div v-else class="text">
          <a class="Neworder" fieldid="fieldid_searchbar_addOrder" @click="agentLink({type: 'orderDetail',add: true, query:{source: 'newOrder'}})">{{ mlang('addOrder') }}</a>
          <a class="Quickorder" fieldid="fieldid_searchbar_quickOrder" @click="toQuickOrder">
            <span>{{ mlang('quickOrder') }}</span>
          </a>
          <span class="icon"></span>
        </div>
      </li>
      <li v-if="$hasAuth('agentBuyProduct')" class="agent-cart">
        <a fieldid="fieldid_searchbar_shoppingCart" @click="toShoppingCart">
          <span class="icon">
            <i class="iconfont gouwuche"></i>
          </span>
          <Badge class="text" :count="shoppingNum">
            <span class="shopping-name">{{ mlang('cart') }}</span>
          </Badge>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SearchBar',
  components: {},
  data() {
    return {
      keyword: '',
      imgWidth: true
    };
  },
  computed: {
    ...mapGetters(['shoppingNum']),
    currentUser() {
      let _this = this;
      var img = new Image();
      img.src = this.$store.getters.currentUser.corpLogo;
      img.onload = function() {
        if (img.width / img.height < 144 / 54) {
          _this.imgWidth = false;
        } else {
          _this.imgWidth = true;
        }
      };
      return this.$store.getters.currentUser;
    },
    isTourist() {
      return localStorage.tourist === 'true';
    }
  },
  mounted() {},
  methods: {
    toQuickOrder() {
      let text = this.$router.resolve({
        path: '/agent/quickOrder'
      });
      // 打开一个新的页面
      window.open(text.href, '_self');
    },
    toShoppingCart() {
      this.$router.push({ name: 'shoppingCart' });
      // this.agentLink({ type: 'noRegister', url: '/agentpage/shoppingcarts/list' });
    },
    keySearch() {
      if (event.which === 13) {
        this.search();
      }
    },
    search() {
      let query = {};
      if (this.$route.name === 'products') { // 商品目录搜商品，不能去掉已选的筛选条件,修改currentPage为第一页
        if (this.$route.query && this.$route.query.currentPage) {
          this.$route.query.currentPage = 1;
        }
        query = Object.assign({}, this.$route.query, { keyword: encodeURIComponent(this.keyword), currentPage: 1 });
      } else {
        query = Object.assign({}, { keyword: encodeURIComponent(this.keyword) });
      }
      this.agentLink({ type: 'agentProductMenu', query });
    }
  }
};
</script>

<style lang="less" scoped>
.agent-logo-a{
  width: 144px;
    height: 54px;
    min-width: 144px;
    display: block;
    float: left;
    text-align: center;
    position: relative;
}
.img-width{
  width: 100%;
  height: auto;
  max-height: 54px !important;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.img-height{
  width: auto;
  height: 100%;
  max-width: 100%!important;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
</style>

import ajax from '@/libs/ajax';
import { oneOf } from '@/u-components/utils/assist';
import dayjs from 'dayjs';
import _ from 'lodash';
export const selectBizs = (data) => {
  // const { pageIndex, pageSize } = data.pager;
  var conditions = [
    {
      name: 'activate',
      v1: true,
      valueType: 'BOOLEAN',
      conditionType: 'eq'
    }
  ];
  if (data.conditions && data.conditions.length) {
    conditions = [...data.conditions];
  }

  const bizType = data.conditions.find((v) => {
    return v.name === 'bizType';
  });
  if (bizType) {
    conditions.push(bizType);
  }
  _.remove(conditions, function(n) {
    return n.name === 'value';
  });
  let queryData = {
    data: {
      pager: {
        pageIndex: data.pager.pageIndex,
        pageSize: data.pager.pageSize
      },
      conditions
    },
    isArchive: 1,
    datatype: 'json'
  };
  return ajax.request({
    url: '/orginazation/merchant/query/list',
    data: queryData,
    method: 'post'
  });
};
export const selectChanelAgents = (data) => {
  // const { pageIndex, pageSize } = data.pager;
  const valueCondition =
    data.conditions &&
    data.conditions.find((v) => {
      return v.name === 'name';
    });
  // let value = '';
  // if (valueCondition) {
  //   value = valueCondition.v1;
  // }
  // let queryData = {
  //   value,
  //   pageIndex,
  //   pageSize
  // };
  var conditions = (data && data.conditions) || [];
  // 商家端：商家客户ERP编码搜索过滤
  let idx = conditions.findIndex((v) => {
    return v.name === 'erpCodeInBiz';
  });
  if (valueCondition) {
    if (idx !== -1) {
      conditions[idx].v1 = data.conditions[0].v1;
    }
  } else {
    if (idx !== -1) {
      conditions.splice(idx, 1);
    }
  }
  _.remove(conditions, function(n) {
    return n.name === 'value';
  });
  let queryData = {
    data: {
      pager: {
        pageIndex: data.pager.pageIndex,
        pageSize: data.pager.pageSize
      },
      conditions
    },
    isArchive: 1,
    datatype: 'json'
  };
  return ajax
    .request({
      url: '/agent/infrastructure/channel/agents',
      data: queryData,
      method: 'post'
    })
    .then((res) => {
      res &&
        res.content &&
        res.content.map((v) => {
          v.id = v.agentId;
        });
      const result = {
        totalCount: res.totalElements,
        data: res.content
      };
      return result;
    });
};
export const selectAllAgents = (data) => {
  var conditions = (data && data.conditions) || [];
  let queryData = {
    data: {
      pager: {
        pageIndex: data.pager.pageIndex,
        pageSize: data.pager.pageSize
      },
      conditions
    },
    isArchive: 1,
    datatype: 'json'
  };
  return ajax
    .request({
      url: '/agent/infrastructure/agents/all',
      data: queryData,
      method: 'post'
    })
    .then((res) => {
      res &&
        res.content &&
        res.content.map((v) => {
          v.id = v.agentId;
        });
      const result = {
        totalCount: res.totalElements,
        data: res.content
      };
      return result;
    });
};

export const selectAgents = (data) => {
  var conditions = [
    {
      name: 'activate',
      v1: true,
      valueType: 'BOOLEAN',
      conditionType: 'eq'
    },
    {
      name: 'isContainOrg',
      v1: 'true',
      isSkip: false,
      isOuterField: false,
      valueType: 'BOOLEAN',
      conditionType: 'eq'
    }
  ];
  if (data.conditions && data.conditions.length) {
    conditions = [...conditions, ...data.conditions];
  }
  if (data.noActivate) {
    _.remove(conditions, v => v.name === 'activate');
    conditions.push(
      {
        'name': 'deletedValue',
        'conditionType': 'eq',
        'valueType': 'Short',
        'v1': '2'
      });
  }

  let queryData = {
    data: {
      pager: {
        pageIndex: data.pager.pageIndex,
        pageSize: data.pager.pageSize
      },
      conditions
    },
    isArchive: 1,
    datatype: 'json'
  };
  return ajax
    .request({
      url: '/agent/infrastructure/agents/current/1',
      data: queryData,
      method: 'post'
    })
    .then((res) => {
      res &&
        res.content &&
        res.content.map((v) => {
          v.id = v.agentId;
        });
      const result = {
        totalCount: res.totalElements,
        data: res.content
      };
      return result;
    });
};

// 客户交易关系
export const selectAgentRelations = (data) => {
  var conditions = [
    {
      name: 'agent.activate',
      v1: true,
      valueType: 'BOOLEAN',
      conditionType: 'eq'
    },
    {
      name: 'isContainOrg',
      v1: 'true',
      isSkip: false,
      isOuterField: false,
      valueType: 'BOOLEAN',
      conditionType: 'eq'
    }
  ];
  if (data.conditions && data.conditions.length) {
    conditions = [...conditions, ...data.conditions];
  }
  _.remove(conditions, function(n) {
    return n.name === 'value';
  });
  let queryData = {};
  if (data.pager) {
    queryData = {
      data: {
        pager: {
          pageIndex: data.pager.pageIndex,
          pageSize: data.pager.pageSize
        },
        conditions
      },
      isArchive: 1,
      datatype: 'json'
    };
  } else {
    queryData = {
      data: {
        conditions
      },
      isArchive: 1,
      datatype: 'json'
    };
  }
  return ajax
    .request({
      url: '/agent/infrastructure/agents/current/1',
      data: queryData,
      method: 'post'
    })
    .then((res) => {
      res &&
        res.content &&
        res.content.map((v) => {
          v.id = v.agentRelationId;
        });
      const result = {
        totalCount: res.totalElements,
        data: res.content
      };
      return result;
    });
};
// 获取所有业务员
export const salesman = (data) => {
  data.conditions.forEach((v) => {
    if (v.name && v.name !== 'activate' && v.name !== 'orgId') {
      v.name = 'fullName';
    }
  });
  let queryData = {
    datatype: 'json',
    data,
    isArchive: 1
  };
  return ajax
    .request({
      url: '/orginazation/stockmanager/contacter/query/list',
      method: 'post',
      data: queryData
    })
    .then((res) => {
      const result = {
        totalCount: res.totalElements,
        data: res.content
      };
      return result;
    });
};
// 订货端订单联系人参照
export const contacterForOrder = (data) => {
  const valueCondition = data.conditions.find((v) => {
    return v.name === 'value';
  });
  let value = '';
  if (valueCondition) {
    value = valueCondition.v1;
  }
  return ajax
    .request({
      url: '/agent/infrastructure/agentContactsForOrder' + (value ? `?isArchive=1&value=${value}` : '?isArchive=1'),
      method: 'get'
    })
    .then((res) => {
      return res;
    });
};

export const invoiceAgentName = (data) => {
  return ajax
    .request({
      url: '/marketingbill/order/agent/invoicing',
      method: 'get',
      data: {
        agentId: data.agentId,
        orgId: data.orgId,
        isMarket: 1
      }
    })
    .then((res) => {
      if (Array.isArray(res) && res.length > 0) {
        res.forEach(item => {
          item.invoiceAgentName = item.invoicingCustomersName;
        });
      }
      return res;
    });
};

// 获取收款协议列表数据
export const receiveAgreementList = (data, vm) => {
  let salesOrgId = data.orgId;
  // 新增订单平台模式取商家上面的组织id
  if (vm.$getBO('ORDERSHOWWAY')) {
    salesOrgId = localStorage.getItem('OrderOrgId') || data.orgId;
  }
  return ajax
    .request({
      url: '/marketingbill/order/getReceiveAgreements',
      method: 'get',
      data: {
        salesOrgId,
        isMarket: 1
      }
    })
    .then((res) => {
      if (Array.isArray(res) && res.length > 0) {
        res.forEach(item => {
          item.receiveAgreementName = item.name;
          item.instalment = item.instalment ? '是' : '否';
        });
      }
      return res;
    });
};

export const deliveryStockList = ({ tableIndex }, data, productInfo = {}) => {
  const filterProductList = data?.oOrderDetails?.filter(item => item.actionStatus !== 'D' && item.cOrderProductType !== undefined);
  let targetProductInfo = {};
  let url = '/stock/getRoutingStock';
  if (Array.isArray(filterProductList)) {
    targetProductInfo = filterProductList[tableIndex];
    url = data.iTransactionTypeId ? `${url}?transactionTypeId=${data.iTransactionTypeId}` : url;
  } else {
    targetProductInfo = productInfo;
    url = targetProductInfo.transactionTypeId ? `${url}?transactionTypeId=${targetProductInfo.transactionTypeId}` : url;
  }
  return ajax
    .request({
      url,
      method: 'post',
      data: {
        isArchive: 1,
        datatype: 'json',
        data: targetProductInfo
      }
    })
    .then((res) => {
      if (Array.isArray(res) && res.length > 0) {
        res.forEach(item => {
          item.cStockName = item.stockName;
          item.id = item.stockId;
        });
      }
      return res;
    });
};

// 租户登录时,专管业务员使用 salesman 这个接口
// 商家登录时,专管业务员使用以下接口
export const contacterForBiz = (data) => {
  data.conditions.forEach((v) => {
    if (v.name && v.name !== 'activate' && v.name !== 'orgId') {
      v.name = 'fullName';
    }
  });

  let queryData = {
    datatype: 'json',
    data,
    isArchive: 1
  };
  return ajax
    .request({
      url: '/orginazation/stockmanager/contacter/query/list',
      method: 'post',
      data: queryData
    })
    .then((res) => {
      const result = {
        totalCount: res.totalElements,
        data: res.content
      };
      return result;
    });
};

// 非本系统自身的销售组织
export const ncSaleOrg = (data) => {
  const { pageIndex, pageSize } = data.pager;
  const valueCondition = data.conditions.find((v) => {
    return v.name === 'value';
  });
  let value = '';
  if (valueCondition) {
    value = valueCondition.v1;
  }
  let queryData = {
    name: value,
    pageIndex,
    pageSize
  };
  return ajax
    .request({
      url: window.__app.context.productLine === 'u8c' ? '/archives/erpsystem/organize/page?isArchive=1' : '/pc/ErpServiceSrv/getOrganizePage',
      data: queryData,
      method: 'get'
    })
    .then((res) => {
      const result = {
        totalCount: res.total,
        data: res.content
      };
      return result;
    });
};

// export const submiters = (data) => {
//   const { pageIndex, pageSize } = data.pager;
//   const valueCondition = data.conditions.find((v) => {
//     return v.name === 'value';
//   });
//   let value = '';
//   if (valueCondition) {
//     value = valueCondition.v1;
//   }
//   let queryData = {
//     iCorpRegister: -1,
//     value: value,
//     pageIndex,
//     pageSize
//   };
//   return ajax
//     .request({
//       url: '/pc/user/UserSrv/getAllUsersPage',
//       data: queryData,
//       method: 'get'
//     })
//     .then((res) => {
//       const result = {
//         totalCount: res.totalElements,
//         data: res.content
//       };
//       return result;
//     });
// };

export const submiters = (data) => {
  return ajax
    .request({
      url: '/passport/operators',
      data: {
        data,
        isArchive: 1,
        datatype: 'json'
      },
      method: 'post'
    })
    .then((res) => {
      const result = {
        totalCount: res.totalElements,
        data: res.content
      };
      return result;
    });
};

export const productPromotions = (data) => {
  return ajax
    .request({
      url: '/goods/promotion/product/list',
      method: 'post',
      data: {
        data,
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((res) => {
      res.totalCount = res.totalElements;
      return res;
    });
};

export const combinationPromotions = (data) => {
  return ajax
    .request({
      url: '/goods/promotion/combination/list',
      method: 'post',
      data: {
        data,
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((res) => {
      res.totalCount = res.totalElements;
      return res;
    });
};

export const entirePromotions = (data) => {
  return ajax
    .request({
      url: '/goods/promotion/entire/list',
      method: 'post',
      data: {
        data,
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((res) => {
      res.totalCount = res.totalElements;
      return res;
    });
};

// 商品参照
export const getProducts = (data, ifProductionOptimize = false) => {
  return ajax
    .request({
      url: `/archives/product/${ifProductionOptimize ? 'getPopProductsByAuthPager' : 'getPopProducts'}?isArchive=1`,
      method: 'post',
      data
    })
    .then((res) => {
      res.data &&
        res.data.map((v) => {
          v.__uuid = v.id + '__' + v.bizProductId;
        });
      return res;
    });
};

// UDH-75997 返利商品客户费用使用时敏感订单商品
export const getRebateProducts = (data) => {
  return ajax
    .request({
      url: '/archives/product/getRebateProducts',
      method: 'post',
      data: {
        data,
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((res) => {
      res.data &&
        res.data.map((v) => {
          v.__uuid = v.id + '__' + v.bizProductId;
        });
      return res;
    });
};

export const getSrcProducts = (querySchema) => {
  return ajax
    .request({
      url: '/marketingbill/salescontract/getSalesContractObject',
      method: 'post',
      data: {
        data: querySchema,
        isMarket: 1,
        datatype: 'json'
      }
    })
    .then((res) => {
      return res;
    });
};
export const getPromotionProducts = (data = {}) => {
  data.pageIndex = data.currentPage;
  delete data.currentPage;
  return ajax
    .request({
      url: '/goods/product/product/addonproducts?isArchive=1',
      method: 'get',
      data
    })
    .then((res = []) => {
      res.data &&
        res.data.map((v) => {
          v.__uuid = v.id + '__' + v.bizProductId;
        });
      return res;
    });
};
export const getDefineRefs = (data) => {
  return ajax
    .request({
      url: '/meta/bill/define/archives/page',
      method: 'post',
      data: {
        data,
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((res) => {
      res.totalCount = res.totalElements;
      return res;
    });
};

export const getBasicArchivesMetadata = (defineId) => {
  return ajax
    .request({
      url: `/marketingbill/bill/billSetting/getRefMeta/${defineId}`,
      method: 'post',
      data: {
        datatype: 'json',
        isMarket: 1
      }
    });
};

export const getBasicArchivesData = (data) => {
  return ajax
    .request({
      url: '/marketingbill/bill/billSetting/getRefData',
      method: 'post',
      data: {
        data,
        datatype: 'json',
        isMarket: 1
      }
    });
};

export const getProductGroups = (data) => {
  return ajax.request({
    url: '/goods/infrastructure/productgroup/product',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  }).then((res) => {
    res.totalCount = res.totalElements;
    return res;
  });
};

export const getCustomerGroups = (data) => {
  // let condition = {
  //   conditions: [],
  //   notControlDataAuth: true,
  //   pager: { pageIndex: 1, pageSize: 9999 }
  // };
  // let v1 = id === -1 ? '*NULL*' : id;
  // condition.conditions.push({
  //   name: 'agentGroupId',
  //   conditionType: 'eq',
  //   isDefine: false,
  //   isOuterField: true,
  //   outerEntity: 'cn.udinghuo.model.group.agent.AgentGroupAgentRelation',
  //   valueType: 'LONG',
  //   v1: v1
  // });
  return ajax
    .request({
      url: '/auth/infrastructure/agentgroup/agentinfo', // `/agent/infrastructure/agentinfos/${id}`,
      method: 'post',
      data: {
        data: data,
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((res) => {
      res.totalCount = res.totalElements;
      return res;
    });
};

export const getAgentGroup = (data) => {
  return ajax
    .request({
      url: '/auth/infrastructure/agentgroup/queryschema',
      method: 'post',
      data: {
        data: data,
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((res) => {
      res.totalCount = res.totalElements;
      return res;
    });
};

export const users = (data) => {
  return ajax
    .request({
      url: '/passport/operators',
      method: 'post',
      data: {
        data: data,
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((res) => {
      res && res.content && res.content.map((v) => {
        v.id = v.userId;
        v.roleName = v.roles && v.roles[0] && v.roles[0].name;
      });
      res.totalCount = res.totalElements;
      return res;
    });
};

// 客户级别
export const getAgentLevel = () => {
  return ajax.request({ url: '/agent/infrastructure/agentlevel/list?isArchive=1', method: 'get' }).then((data) => {
    data.map((v) => {
      v.value = v.id;
    });
    return {
      data
    };
  });
};

// 获取仓库
export const selectStocks = (query) => {
  return ajax.request({
    url: '/goods/inventory/stock/list/page',
    method: 'post',
    data: {
      datatype: 'json',
      data: query,
      isArchive: 1
    }
  })
    .then((res) => {
      if (res.content && res.content.length > 0) {
        res.content.map((v) => {
          v.cName = v.name;
          v.cCode = v.code;
          v.iStockType = v.stockType;
          v.iBizId = v.bizId;
        });
      }
      return res;
    });
};
// export const selectStocks = (data) => {
//   let cName = '';
//   let orgId = '';
//   data.conditions &&
//     data.conditions.forEach(function(v) {
//       if (v.name === 'value') {
//         cName = v.v1;
//       }
//       if (v.name === 'orgId') {
//         orgId = v.v1;
//       }
//     });
//   return ajax
//     .request({
//       url: '/pc/StockSrv/getStockList?pageIndex=' + data.pager.pageIndex + '&pageSize=' + data.pager.pageSize + '&cName=' + cName + '&orgId=' + orgId,
//       method: 'get'
//     })
//     .then((res) => {
//       const result = {
//         totalCount: res.totalCount,
//         data: res.data
//       };
//       return result;
//     });
// };

// 获取仓库机构
export const selectStockOrg = (data) => {
  let cName = '';
  data.conditions &&
    data.conditions.forEach(function(v) {
      if (v.name === 'value') {
        cName = v.v1;
      }
    });
  return ajax
    .request({
      url: '/pc/StockSrv/getStockOrganList?pageSize=' + data.pager.pageSize + '&pageIndex=' + data.pager.pageIndex + '&cName=' + cName,
      method: 'get'
    })
    .then((res) => {
      const result = {
        totalCount: res.totalCount,
        data: res.data
      };
      return result;
    });
};

// 获取交易类型
export const selectTransactionType = (data) => {
  return ajax
    .request({
      url: '/basic/TransactionTypeSrv/getTransactionTypeList?pageSize=' + data.pager.pageSize + '&pageIndex=' + data.pager.pageIndex,
      method: 'get'
    })
    .then((res) => {
      return res;
    });
};
// 获取规格模板
export const selectSptemplates = (data) => {
  let keyword = null;
  data.conditions &&
    data.conditions.forEach(function(v) {
      if (v.name === 'value') {
        keyword = v.v1;
      }
    });
  let queryData = {
    pageindex: data.pager.pageIndex,
    pagesize: data.pager.pageSize,
    keyword: keyword,
    isArchive: 1
  };
  return ajax
    .request({
      url: '/goods/infrastructure/specitemplates/list/page',
      data: queryData,
      method: 'get'
    })
    .then((res) => {
      const result = {
        totalCount: res.totalElements,
        data: res.content
      };
      return result;
    });
};
// 币种
export const getCurrency = () => {
  return ajax.request({
    url: '/archives/currency/list',
    method: 'get',
    data: {
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 信用检测规则
export const getCreditRule = (data) => {
  if (data && data.conditions) {
    data.conditions.push({
      name: 'isEnabled',
      v1: true,
      valueType: 'BOOLEAN',
      conditionType: 'eq'
    });
  }
  return ajax.request({
    url: '/credit/checkrule/query',
    method: 'post',
    data: {
      data: data,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 预售活动参照
export const productPresaleList = (querySchema) => {
  let dimensions = {
    1: 'agentId',
    3: 'agentCategoryId',
    4: 'agentLevelId',
    2: 'agentAreaId',
    8: 'agentGroupId'
  };
  querySchema.conditions.map((v) => {
    if (oneOf(v.name, Object.values(dimensions))) {
      v.name = 'dimensionObjId';
    }
  });

  // query.querySchema.conditions.push({
  //   name: 'isProductClass',
  //   conditionType: 'eq',
  //   isDefine: false,
  //   valueType: 'Short',
  //   v1: 0
  // });

  //   4.获取组合促销列表
  // /combination/list   POST  querySchema
  // 条件：
  // {
  //   "dimensionObjId"://客户各种维度ID
  //   "dimensionType"://维度类型
  //   "classId"://分类信息
  //   "name"://促销名称
  // }
  return ajax.request({
    url: '/goods/promotion/presale/query',
    // url: '/goods/promotion/product/list',
    method: 'POST',
    data: {
      data: querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 仓库参照 带库存
export const stockSkuInventories = (data = {}) => {
  delete data.pager;
  return ajax
    .request({
      url: '/marketingbill/inventory/getSkuInventories',
      method: 'post',
      data: {
        data: data,
        datatype: 'json',
        isMarket: 1
      }
    })
    .then((res = []) => {
      if (res.length) {
        res.map((v) => {
          v.stockName = v.oStock.cName;
          v.cProductCode = data.cProductCode;
          v.cSkuCode = data.cSkuCode;
        });
      }
      return res;
    });
};
export const getProductsList = (data) => {
  return ajax
    .request({
      url: '/archives/product/getProductsWithSku?isArchive=1',
      method: 'post',
      data
    })
    .then((res) => {
      // res.data &&
      //   res.data.map((v) => {
      //     v.__uuid = v.id + '__' + v.bizProductId;
      //   });
      return res;
    });
};
export const getProductGroupsReal = (querySchema) => {
  return ajax
    .request({
      url: '/goods/infrastructure/productgroup/queryschema',
      method: 'post',
      data: {
        datatype: 'json',
        data: querySchema,
        isArchive: 1
      }
    });
};
export const returnReason = (querySchema) => {
  querySchema.condition = Object.assign({
    isExtend: true,
    simpleVOs: [
      {
        field: 'reasonType',
        op: 'eq',
        value1: 1
      },
      { field: 'stopstatus', op: 'eq', value1: false }
    ]
  }, querySchema.conditions);
  return ajax.request({
    url: '/bill/ref/getRefData?terminalType=1',
    method: 'post',
    data: {
      isMarket: 1,
      data: {
        condition: querySchema.condition,
        refCode: 'productcenter.aa_reasonref',
        dataType: 'grid',
        cSelfDefineType: 'attrext2',
        billnum: 'voucher_salereturn',
        page: {
          pageSize: 10,
          pageIndex: 1,
          totalCount: 1
        }
      },
      datatype: 'json'
    }
  })
    .then((val) => {
      var other = {
        reason: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800041', '其他') /* "其他" */,
        isDeleted: false,
        id: 2377619750999999,
        reasonType: 1,
        pubts: '2021-08-07 14:40:06',
        tenant: 22633090999999,
        iOrder: 999
      };
      val.recordList.push(other);
      let content = val.recordList;
      let pageable = {
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true,
        sort: { sorted: false, unsorted: true },
        unpaged: false
      };
      let res = {
        content: content,
        pageable: pageable,
        first: true,
        last: false,
        number: 0,
        numberOfElements: 10,
        size: 10,
        sort: { sorted: false, unsorted: true },
        totalElements: val.recordList.length
      };
      return res;
    });
};

// 获取发货信息列表
export const deliveryAddressList = (querySchema) => {
  return ajax.request({
    url: '/agent/infrastructure/shiptoaddress/queryschema/list',
    method: 'post',
    data: {
      data: querySchema,
      datatype: 'json',
      isArchive: 1
    }
  }).then(res => {
    res.content.forEach(item => {
      item.deliveryInfo = `${item.receiver || ''}  ${item.mergerName || ''}${item.address || ''}  ${item.mobile || ''}`;
    });
    const result = {
      totalCount: res.totalElements,
      content: res.content
    };
    return result;
  });
};

export const signconfirmationReason = () => {
  return ajax.request({
    url: '/bill/ref/getRefData?terminalType=1',
    method: 'post',
    data: {
      isMarket: 1,
      data: {
        condition: {
          isExtend: true,
          simpleVOs: [
            { field: 'reasonType', op: 'eq', value1: '10' },
            { field: 'stopstatus', op: 'eq', value1: false }
          ]
        },
        refCode: 'productcenter.aa_reasonref',
        dataType: 'grid',
        cSelfDefineType: 'attrext2',
        billnum: 'usp_signconfirmation',
        page: {
          pageSize: 10,
          pageIndex: 1,
          totalCount: 1
        }
      },
      datatype: 'json'
    }
  })
    .then((val) => {
      let content = val.recordList || [];
      let pageable = {
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true,
        sort: { sorted: false, unsorted: true },
        unpaged: false
      };
      let res = {
        content: content,
        pageable: pageable,
        first: true,
        last: false,
        number: 0,
        numberOfElements: 10,
        size: 10,
        sort: { sorted: false, unsorted: true },
        totalElements: val.recordList.length
      };
      return res;
    });
};
export const noticeName = (querySchema) => {
  const keywordCondition = querySchema.conditions.find((v) => {
    return v.name === 'keyword';
  });
  let keyword = '';
  if (keywordCondition) {
    keyword = keywordCondition.v1;
  }
  return ajax.request({
    url: '/marketingbill/notice/list/ref',
    method: 'post',
    data: {
      isMarket: 1,
      data: {
        pageIndex: querySchema.pager.pageIndex,
        pageSize: querySchema.pager.pageSize,
        keyword
      },
      datatype: 'json'
    }
  }).then((val) => {
    val.recordList.forEach(item => {
      item.endDate = dayjs(item.endDate).format('YYYY-MM-DD');
    });
    let content = val.recordList;
    let pageable = {
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
      sort: { sorted: false, unsorted: true },
      unpaged: false
    };
    let res = {
      content: content,
      pageable: pageable,
      first: true,
      last: false,
      number: 0,
      numberOfElements: 10,
      size: 10,
      sort: { sorted: false, unsorted: true },
      totalElements: val.recordCount
    };
    return res;
  });
};
export const productOrg = (querySchema) => {
  return ajax.request({
    url: '/orginazation/org/user/list?isArchive=1',
    method: 'get'
  }).then((val) => {
    let content = val;
    let pageable = {
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
      sort: { sorted: false, unsorted: true },
      unpaged: false
    };
    let res = {
      content: content,
      pageable: pageable,
      first: true,
      last: false,
      number: 0,
      numberOfElements: 10,
      size: 10,
      sort: { sorted: false, unsorted: true },
      totalElements: val.length
    };
    return res;
  });
};
// 销售合同参照接口地址
export const selectSrcBillNo = (querySchema) => {
  return ajax.request({
    url: '/marketingbill/salescontract/getSalesContract',
    method: 'post',
    data: {
      data: querySchema,
      isMarket: 1,
      datatype: 'json'
    }
  })
    .then((res) => {
      const result = {
        totalCount: res.totalElements,
        data: res.content
      };
      return result;
    });
};

// 销售报价列表数据
export const salesQuotationList = (querySchema) => {
  return ajax.request({
    url: '/marketingbill/quotequery/getQuoteList',
    method: 'post',
    data: {
      data: querySchema,
      isMarket: 1,
      datatype: 'json'
    }
  })
    .then((res) => {
      const result = {
        totalCount: res.recordCount,
        data: res.salesQuotationList
      };
      return result;
    });
};

// 通过销售报价获取商品列表信息
export const getProductListByQuotationId = (querySchema) => {
  return ajax
    .request({
      url: '/marketingbill/quotequery/getQuoteDetailByMainId',
      method: 'post',
      data: {
        data: querySchema,
        isMarket: 1,
        datatype: 'json'
      }
    })
    .then((res) => {
      return {
        totalCount: res.recordCount,
        data: res.salesQuotationList
      };
    });
};

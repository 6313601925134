<template>
  <div>
    <VoucherList
      ref="voucherList"
      :data="data"
      :query="query"
      :biz-meta="meta">
    </VoucherList>
  </div>
</template>

<script>
import VoucherList from '_c/common/voucher-list';
import Mixin from '@/mixin/archiveList.js';
// import { bizRefer } from '@/meta/refer-common';
import Emitter from '@/u-components/mixins/emitter';
import ArchiveListMixin from '@/mixin/archive-list.js';
import { getAgentPayments, exportAgentPayments, getPayStatus, checkPaymentStatus, delPayment, getNotUsedPaymentsPager, getPaymentListBtn, rePush } from '@/api/payment';
import { getBizs, getSaleOrgs } from '@/api/agentPayment/payment.js';
import { getOptionValueByCode } from '@/api/bo';
import { getPayWayTree } from '@/api/refer-tree';
import { getCurrency } from '@/api/refer-select.js';
import { getAgentInfoVo } from '@/api/user.js';
// import { getStyle } from '@/u-components/utils/assist';
import dayjs from 'dayjs';
export default {
  name: 'Payments',
  components: { VoucherList },
  mixins: [Emitter, Mixin, ArchiveListMixin],
  data() {
    const meta = this.getDefaultMeta();
    return {
      data: {},
      query: this.queryList,
      meta,
      isLoaing: false,
      agentVoucherEdit: this.$hasAuth('agentVoucherEdit'),
      agentVoucherDel: this.$hasAuth('agentVoucherDel'),
      agentPaymentBalance: this.$hasAuth('agentPaymentBalance'),
      isFromSumary: this.$route.query.formSumary,
      summaryParams: {}
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  methods: {
    // bizReferAction(filterMeta) {
    //   return bizRefer(filterMeta, this);
    // },
    bizAction(filterMeta) {
      return getBizs().then((data = []) => {
        data.forEach((item) => {
          item.title = item.name;
          item.value = item.id;
        });
        return data;
      });
    },
    getDefaultMeta() {
      const agentNewVoucher = this.$hasAuth('agentNewVoucher');
      const agentReturnPayShow = this.$hasAuth('agentReturnPayShow');
      const meta = {
        billId: 321,
        isAgent: false,
        domainKey: 'marketingbill',
        actions: {
          bizAction: this.bizAction,
          //  bizReferAction: this.bizReferAction,
          payTypeAction: getPayWayTree,
          currencyAction: getCurrency,
          RowAction: this.RowAction,
          statusAction: getPayStatus,
          cPayTypeAction: this.cPayTypeAction,
          iPayMentStatusCodeAction: this.iPayMentStatusCodeAction
        },
        setBillMeta: this.setBillMeta,
        async: true
      };
      if (this.$getBO('ALLOWORDERPAYMENT') && agentNewVoucher) {
        meta.addBtn = {
          route: {
            name: 'payment'
          },
          vue: true
        };
      }
      if (agentReturnPayShow && !this.$route.query.formSumary) {
        meta.export = {
          beforeExport: this.beforeExport,
          isSaleExport: true,
          saleService: exportAgentPayments
        };
      }
      return meta;
    },
    setBillMeta(billMeta) {
      let filters = billMeta.currentFilter.currentGroups[0].currentFilterItems;
      let biz = filters.find(item => item.name === 'bizId');
      biz.controlType = 'TreeSelect';
      let originalPk = filters.find(item => item.name === 'originalPk');
      let cOrderNo = filters.find(item => item.name === 'cOrderNo');
      let voucherType = filters.find(item => item.name === 'voucherType');
      let payMentStatusCode = filters.find(item => item.name === 'payMentStatusCode');
      biz.isShowIt = this.currentUser.__isMutiBiz && !this.isFromSumary;
      payMentStatusCode.isShowIt = cOrderNo.isShowIt = voucherType.isShowIt = !this.isFromSumary;
      originalPk.isShowIt = this.currentUser.__isU8C;
      originalPk.isHidden = !this.currentUser.__isU8C;
      billMeta.currentTemplate.currentTplGroups[0].currentBillItems.forEach((v) => {
        // if (v.fieldName === 'dPayFinishDate') {
        //   v.isShowIt = this.currentUser.__isU8C;
        // }
        if (v.fieldName === 'iPayMentStatusCode') {
          v.action = 'iPayMentStatusCodeAction';
        }
        if (['cOrderNo', 'cSettlementWay', 'iPayMentStatusCode', 'action'].includes(v.fieldName) && this.isFromSumary) {
          v.isShowIt = false;
        }
        if (['settlementOrgName', 'salesOrgName'].includes(v.fieldName) && !this.currentUser.__isMutiOrg) {
          v.isShowIt = false;
        }
      });
      billMeta.currentFilter.currentGroups[0].currentFilterItems.forEach((v) => {
        if (['bizId', 'originalPk'].includes(v.name)) {
          v.isShowIt = false;
        }
        if (['payMentStatusCode'].includes(v.name)) {
          v.controlType = 'TreeSelect';
        }
      });
      return billMeta;
    },
    beforeExport(query, exportFn) {
      this.beforeQuery(query);
      let params = {
        exportExcel: 1,
        isIncludeVerification: true
      };
      let simpleVOs = [
        { 'field': 'agentId', 'op': 'eq', 'value1': this.currentUser.agentId }
      ];
      query.querySchema.conditions.forEach(({ name, v1 }) => {
        if (name === 'payMentStatusCode' && v1 === 6) v1 = 2;
        if (name === 'payMentStatusCode') name = 'status123';
        if (name === 'cOrderNo') name = 'searchOrderNo';
        if (name === 'payNo') name = 'searchPayNo';

        params[name] = v1;
      });
      let conditions = [];
      query.querySchema.conditions.map((v) => {
        conditions.push({
          itemName: v.name,
          value1: v.v1,
          value2: v.v2
        });
      });
      params.condition = {
        commonVOs: conditions, simpleVOs
      };
      getOptionValueByCode('ENABLINGSERVICECOLLECTION').then(res => {
        if (res) {
          params.isIncludeVerification = true;
          exportFn(params);
        } else {
          this.$Modal.confirm({
            content: this.mlang('shifoutongshidaochuhexiaojilu'),
            okText: this.mlang('yes'),
            cancelText: this.mlang('no'),
            onOk: () => {
              params.isIncludeVerification = true;
              exportFn(params);
            },
            onCancel: () => {
              params.isIncludeVerification = false;
              params.isSum = true;
              exportFn(params);
            }
          });
        }
      });
      return false;
    },
    async queryList(query) {
      query.datatype = 'json';
      if (this.isLoaing) {
        return;
      }
      this.isLoaing = true;
      this.beforeQuery(query);
      this.summaryParams = this.$store.getters.GET_SUMMARYPARAMS;
      if (this.$getBO('ORDERSHOWWAY')) {
        if (!this.summaryParams.salesOrgId) {
          let [salesOrgs, agentInfo] = await Promise.all([getSaleOrgs(localStorage.getItem('upBizId')), getAgentInfoVo(localStorage.upAgentRelationId)]);
          this.summaryParams.salesOrgId = salesOrgs[0].id;
          this.summaryParams.originalPk = agentInfo.currencyId;
        }
      } else {
        let agentInfo = await getAgentInfoVo(localStorage.upAgentRelationId);
        this.summaryParams.salesOrgId = localStorage.getItem('upOrgId');
        this.summaryParams.originalPk = agentInfo.currencyId;
      }
      if (query.querySchema && Array.isArray(query.querySchema.conditions) && (this.summaryParams.salesOrgId || this.summaryParams.settlementOrgId)) {
        this.summaryParams.salesOrgId && query.querySchema.conditions.push({
          name: 'salesOrgId',
          conditionType: 'eq',
          isDefine: false,
          isOuterField: false,
          valueType: 'LONG',
          v1: this.summaryParams.salesOrgId
        });
        this.summaryParams.settlementOrgId && query.querySchema.conditions.push({
          name: 'settlementOrgId',
          conditionType: 'eq',
          isDefine: false,
          isOuterField: false,
          valueType: 'LONG',
          v1: this.summaryParams.settlementOrgId
        });
        this.summaryParams.originalPk && query.querySchema.conditions.push({
          name: 'originalPk',
          conditionType: 'eq',
          isDefine: false,
          isOuterField: false,
          valueType: 'STRING',
          v1: this.summaryParams.originalPk
        });
      }
      if (this.isFromSumary) {
        let params = { ...query.querySchema.pager };
        if (params.payMentStatusCode === undefined) {
          query.querySchema.conditions.push({
            conditionType: 'eq',
            name: 'payMentStatusCode',
            v1: -1,
            valueType: 'LONG'
          });
        }
        if (params.paytype === undefined) {
          query.querySchema.conditions.push({
            conditionType: 'eq',
            name: 'payType',
            v1: -1,
            valueType: 'INTEGER'
          });
        }
        query.querySchema.conditions.push(
          {
            conditionType: 'eq',
            name: 'bizId',
            v1: this.$route.query.bizId,
            valueType: 'LONG'
          },
          {
            conditionType: 'eq',
            name: 'salesOrgId',
            v1: this.$route.query.orgId,
            valueType: 'LONG'
          },
          {
            conditionType: 'eq',
            name: 'originalPk',
            v1: this.$route.query.currencyId,
            valueType: 'STRING'
          }
        );
        // getNotUsedPaymentsPager(this.toParams(query)).then((res) => {
        getNotUsedPaymentsPager(query.querySchema).then((res) => {
          const paymentIds = [];
          res.content.forEach((v) => {
            paymentIds.push(v.id);
            // 将单据日期改为日期型，不需要时分秒
            v.dPayFinishDate = dayjs(v.dPayFinishDate).format('YYYY-MM-DD');
            v.clonePayNo = v.cPayNo;
            v.cPayTypeName = v.payTypeName;
            v.cPayType = v.cPayVoucherType;
            Object.assign(v, { oPayType: { cName: v.oPayTypeVo.name } });
            let to = {};
            if (v.cPayType === 'PAYMENT') {
              to = {
                name: 'payment',
                query: {
                  cPayNo: v.cPayNo,
                  payMentId: v.id,
                  settlementOrgId: v.settlementOrgId,
                  settlementOrgName: v.settlementOrgName,
                  salesOrgId: v.salesOrgId,
                  salesOrgName: v.salesOrgName
                }
              };
            } else {
              to = {
                path: '/agent/frame',
                query: {
                  uri: this.$util.agentPageUrl(`/agentpage/Refunds/detail?cPayNo=${v.cPayNo}`)
                }
              };
            }
            if (v.iPayMentStatusCode !== 2 && this.currentUser.__isU8C && v.iPayType === 1) {
              v.hidePayNo = true;
              v.cPayNo = '';
            }
            v.cPayNoTo = to;
          });
          if (paymentIds.length) {
            getPaymentListBtn(paymentIds).then((btnRes) => {
              res.content.map((v) => {
                const id = v.id;
                const btn = btnRes.find((item) => {
                  return item.id === id;
                });
                v = Object.assign(v, btn);
              });
              res.total = res.totalElements;
              this.isLoaing = false;
              this.data = res;
            });
          } else {
            res.total = res.totalElements;
            this.isLoaing = false;
            this.data = res;
          }
        });
      } else {
        getAgentPayments(query.querySchema).then((res) => {
          // 向商家付款结算方式Id传递参清空
          if (res.isBiz) {
            this.$refs.voucherList.billMeta.currentTemplate.currentTplGroups[0].currentBillItems.map(v => {
              if (v.fieldName === 'cSettlementWay') {
                v.isShowIt = false;
              }
            });
            this.$refs.voucherList.makeColumns();
          }

          let paymentIds = [];
          res.content.forEach((v) => {
            paymentIds.push(v.id);
            // 将单据日期改为日期型，不需要时分秒
            v.dPayFinishDate = dayjs(v.dPayFinishDate).format('YYYY-MM-DD');
            v.clonePayNo = v.cPayNo;
            v.cPayTypeName = v.payTypeName;
            v.cPayType = v.cPayVoucherType;
            Object.assign(v, { oPayType: { cName: v.oPayTypeVo.name } });
            let to = {};
            if (v.cPayType === 'PAYMENT') {
              to = {
                name: 'payment',
                query: {
                  cPayNo: v.cPayNo,
                  payMentId: v.id,
                  settlementOrgId: v.settlementOrgId,
                  settlementOrgName: v.settlementOrgName,
                  salesOrgId: v.salesOrgId,
                  salesOrgName: v.salesOrgName
                }
              };
            } else {
              to = {
                path: '/agent/frame',
                query: {
                  uri: this.$util.agentPageUrl(`/agentpage/Refunds/detail?cPayNo=${v.cPayNo}`)
                }
              };
            }
            if (v.iPayMentStatusCode !== 2 && this.currentUser.__isU8C && v.iPayType === 1) {
              v.hidePayNo = true;
              v.cPayNo = '';
            }
            v.cPayNoTo = to;
          });
          if (paymentIds.length) {
            getPaymentListBtn(paymentIds).then((btnRes) => {
              res.content.map((v) => {
                const id = v.id;
                const btn = btnRes.find((item) => {
                  return item.id === id;
                });
                v = Object.assign(v, btn);
              });
              res.total = res.totalElements;
              this.isLoaing = false;
              this.data = res;
            });
          } else {
            res.total = res.totalElements;
            this.isLoaing = false;
            this.data = res;
          }
        });
      }
    },
    getAgentPaymentsCallBack(res) {
      // 向商家付款结算方式Id传递参清空
      if (res.isBiz) {
        this.$refs.voucherList.billMeta.currentTemplate.currentTplGroups[0].currentBillItems.map(v => {
          if (v.fieldName === 'cSettlementWay') {
            v.isShowIt = false;
          }
        });
        this.$refs.voucherList.makeColumns();
      }

      let paymentIds = [];
      res.content.forEach((v) => {
        paymentIds.push(v.id);
        // 将单据日期改为日期型，不需要时分秒
        v.dPayFinishDate = dayjs(v.dPayFinishDate).format('YYYY-MM-DD');
        v.clonePayNo = v.cPayNo;
        v.cPayTypeName = v.payTypeName;
        v.cPayType = v.cPayVoucherType;
        Object.assign(v, { oPayType: { cName: v.oPayTypeVo.name } });
        let to = {};
        if (v.cPayType === 'PAYMENT') {
          to = {
            name: 'payment',
            query: {
              cPayNo: v.cPayNo,
              payMentId: v.id,
              settlementOrgId: v.settlementOrgId,
              settlementOrgName: v.settlementOrgName,
              salesOrgId: v.salesOrgId,
              salesOrgName: v.salesOrgName
            }
          };
        } else {
          to = {
            path: '/agent/frame',
            query: {
              uri: this.$util.agentPageUrl(`/agentpage/Refunds/detail?cPayNo=${v.cPayNo}`)
            }
          };
        }
        if (v.iPayMentStatusCode !== 2 && this.currentUser.__isU8C && v.iPayType === 1) {
          v.hidePayNo = true;
          v.cPayNo = '';
        }
        v.cPayNoTo = to;
      });
      if (paymentIds.length) {
        getPaymentListBtn(paymentIds).then((btnRes) => {
          res.content.map((v) => {
            const id = v.id;
            const btn = btnRes.find((item) => {
              return item.id === id;
            });
            v = Object.assign(v, btn);
          });
          res.total = res.totalElements;
          this.isLoaing = false;
          this.data = res;
        });
      } else {
        res.total = res.totalElements;
        this.isLoaing = false;
        this.data = res;
      }
    },
    beforeQuery(query) {
      // 用于修改支付方式的参数
      // 当为具体的支付方式时，变量要改成 cyberbankId, conditionType要改成 in
      if (query.querySchema && Array.isArray(query.querySchema.conditions)) {
        query.querySchema.conditions.forEach((v) => {
          if (v.name === 'payMentStatusCode' && v.v1 === 6) {
            v.v1 = 2;
          }
        });
        let cond = query.querySchema.conditions.find((cond) => cond.name === 'paytype');
        if (cond && !['1', '2'].includes(cond.v1)) {
          cond.name = 'cyberbankId';
          cond.conditionType = 'in';
        }
      }
    },
    toParams(query) {
      let params = { ...query.querySchema.pager };
      query.querySchema.conditions.forEach(({ name, v1 }) => { params[name] = v1; });
      if (params.payMentStatusCode === undefined) {
        params.payMentStatusCode = -1;
      }
      if (params.paytype === undefined) {
        params.paytype = -1;
      }
      if (this.isFromSumary) params.bizId = this.$route.query.bizId;
      if (this.isFromSumary) params.orgId = this.$route.query.orgId;
      if (this.isFromSumary && this.currentUser.__isU8C) params.originalPk = this.$route.query.originalPk;
      return params;
    },
    cPayTypeAction(item) {
      const list = [{
        title: this.mlang('payBill'),
        value: 'PAYMENT'
      }];
      if (!this.currentUser.__isU8C) {
        list.push({
          title: this.mlang('refundBill'),
          value: 'REFUND'
        });
      }
      return new Promise((resolve, reject) => {
        resolve(list);
      });
    },
    statusAction(item) {
      return getPayStatus().then((data = []) => {
        if (this.currentUser.__isU8C) {
          data.filter(item => {
            return item.value !== 5;
          });
          return data;
        } else {
          data.push({ title: this.mlang('ENDCONFIRM'), value: 6 });
        }
        return data;
      });
    },
    iPayMentStatusCodeAction(item) {
      item.render = (h, params) => {
        if (params.row.cPayType === 'REFUND') {
          return (<span>{params.row.oPaymentStatus.refundStatusName}</span>);
        } else if (params.row.cPayType === 'PAYMENT') {
          let obj = params.column.enumArray.find(v => v.value === params.row.iPayMentStatusCode);
          let statusClassName = 'status-' + obj.name;
          return (<span class="status-ctrl"><span class={statusClassName}></span>{params.row.oPaymentStatus.title}</span>);
        }
      };
      return item;
    },
    RowAction(item) {
      item.render = (h, params) => {
        const Row = 'Row';
        let row = params.row;
        const del = () => {
          return this.del(row);
        };
        const edit = () => {
          return this.edit(row);
        };
        const verification = () => {
          return this.verification(row);
        };
        const checkAccounts = () => {
          return this.checkAccounts(row);
        };
        // 门户端在线支付生成收款单生成财务收款单失败时支持手工重发UDH-59872
        const syncStatus = () => {
          return this.syncStatus(row);
        };
        return (
          <div class="action">
            <Row class-name="text" type="flex" justify="center">
              {row.iPayMentStatusCode === 1 && (<span on-click={checkAccounts}>{this.mlang('checkAccounts')}</span>)}
              {(this.agentVoucherEdit && row.isShowModifyBtn && !row.hidePayNo) && (<span on-click={edit}>{this.mlang('edit')}</span>)}
              {this.agentVoucherDel && (row.isShowDeletedBtn || row.iPayMentStatusCode === 1 || row.iPayMentStatusCode === 3 || row.iPayMentStatusCode === 5) && (<span on-click={del}>{this.mlang('delete')}</span>)}
              {row.isShowVerificationBtn && !row.hidePayNo && (<span on-click={verification}>{this.mlang('verification')}</span>)}
              {row.isShowSyncFinanceBtn && <span on-click={syncStatus}>{this.mlang('syncStatus')}</span>}
            </Row>
          </div>
        );
      };
      return item;
    },
    del(row) {
      const vm = this;
      this.$Modal.confirm({
        content: vm.mlang('sureDel'),
        className: 'modal-confrim',
        okText: vm.mlang('ok'),
        title: '',
        onOk: (res, instance) => {
          const postData = {
            'paymentId': row.id
          };
          delPayment(postData.paymentId).then((res) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.broadcast('VoucherList', 'reload');
          });
        }
      });
    },
    edit(row) {
      this.$route.query['id'] = row.id;
      this.$route.query['settlementOrgId'] = row.settlementOrgId;
      let routerObj = {
        name: 'payment',
        query: this.$route.query
      };
      this.editClick(routerObj);
    },
    verification(row) {
      const url = `/agentpage/Pays/verificationPaymentBalance?payno=${row.cPayNo}`;
      // this.$open(url);
      this.agentLink({ type: 'noRegister', url });
    },
    checkAccounts(row) {
      let vm = this;
      checkPaymentStatus(row.clonePayNo).then(function(data) {
        let payStatus = data.paymentStatusEnum;
        let msg = data.message;
        if (msg) vm.$Message.warning(msg);
        // if(payStatus === 3) payment.isShowDeletedBtn = true;
        if (payStatus === 2) {
          vm.broadcast('VoucherList', 'reload');
        }
      });
    },
    // 同步状态
    syncStatus(row) {
      const { cPayNo } = row;
      rePush(cPayNo).then(res => {
        this.broadcast('VoucherList', 'reload');
      });
    }
  }
};
</script>
<style lang="less" scoped>
.voucher-list /deep/ .ivu-table-footer {
  .bottom-page {
    box-shadow: none;
    border-bottom: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>


// 多语
import accounts from './accounts';
import agentHome from './agenthome';
import agentPayment from './agentPayment';
import agentProduct from './agentproduct';
import auth from './auth';
import basebill from './basebill';
import channelcenter from './channelcenter';
import corpHomeIndex from './corp_home_index';
import creditManagement from './creditManagement';
import customer from './customer';
import header from './header/header';
import integrationextension from './integrationextension';
import iview from './iview';
import message from './message';
import order from './order';
import orderDetail from './orderDetail/orderDetail';
import organization from './organization';
import platform from './platform';
import priceCenter from './priceCenter';
import product from './product';
import promotionZTE from './promotionZTE';
import quickOrder from './quickOrder';
import rebateCenter from './rebateCenter';
import settings from './settings';
import settle from './settle';
import shoppingCart from './shoppingCart';
import stockcenter from './stockcenter';
import title from './title';
/**
 */
const zhCN = {
  ...iview, // 从iview包中拷贝出来的
  home: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000884', '首页') /* "首页" */,
  switchLangTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000885', '切换语言') /* "切换语言" */,
  international: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000886', '多语言切换') /* "多语言切换" */,
  iviewComponentTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000887', 'iview组件多语言切换') /* "iview组件多语言切换" */,
  tip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000888', '注：iview-admin只是为了示范如何实现多语言切换，所以只对当前页做了翻译。') /* "注：iview-admin只是为了示范如何实现多语言切换，所以只对当前页做了翻译。" */, // notranslate
  intro: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000889', 'iview目前支持15种语言，只要你看得到的iview组件出现iview内置文字的地方都会根据你设置的语言类型自动切换对应的语言。') /* "iview目前支持15种语言，只要你看得到的iview组件出现iview内置文字的地方都会根据你设置的语言类型自动切换对应的语言。" */, // notranslate
  placeholderText: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500088A', '请输入文字...') /* "请输入文字..." */,
  placeholderDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500088B', '选择日期') /* "选择日期" */,
  company: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500088C', '公司') /* "公司" */,
  btnText: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500088D', '点击查看模态框') /* "点击查看模态框" */,
  goOldPage: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500088E', '进入旧版') /* "进入旧版" */,
  modalText: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500088F', '在这里你可以看到iview模态框默认的确定和取消按钮会切换语言') /* "在这里你可以看到iview模态框默认的确定和取消按钮会切换语言" */,
  poptip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000890', '国际化的气泡提示') /* "国际化的气泡提示" */,
  showPoptipText: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000891', '点击显示气泡提示') /* "点击显示气泡提示" */,
  common: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000892', '常用') /* "常用" */,
  seeExeStatus: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000893', '查看执行情况') /* "查看执行情况" */,
  yes: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000894', '是') /* "是" */,
  no: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000895', '否') /* "否" */,
  more: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000896', '更多') /* "更多" */,
  hello: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000897', '您好') /* "您好" */,
  delete: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000898', '删除') /* "删除" */,
  del: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000898', '删除') /* "删除" */,
  batchDel: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000899', '批量删除') /* "批量删除" */,
  add: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500089A', '新增') /* "新增" */,
  detail: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500089B', '详情') /* "详情" */,
  addLine: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500089C', '增行') /* "增行" */,
  save: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500089D', '保存') /* "保存" */,
  cancel: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500089E', '取消') /* "取消" */,
  zhuanguanywy: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500089F', '专管业务员') /* "专管业务员" */,
  specialBizMessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A0', '特殊商家（作为客户身份时无有效交易关系）') /* "特殊商家（作为客户身份时无有效交易关系）" */,
  forgetPwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A1', '忘记密码') /* "忘记密码" */,
  checkAccounts: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A2', '查账') /* "查账" */,
  failedTocheckPaymentStatus: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A3', '查账失败') /* "查账失败" */,
  paymentSuccessFul: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A4', '支付成功') /* "支付成功" */,
  select: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A5', '选择') /* "选择" */,
  saveAndback: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A6', '保存并返回') /* "保存并返回" */,
  saveAndAdd: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A7', '保存并新增') /* "保存并新增" */,
  saveAndHold: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A8', '保存并继续') /* "保存并继续" */,
  back: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008A9', '返回') /* "返回" */,
  ok: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008AA', '确定') /* "确定" */,
  limitGroup: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008AB', '限购组') /* "限购组" */,
  quanping: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008AC', '全屏') /* "全屏" */,
  shouqiquanping: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008AD', '收起全屏') /* "收起全屏" */,
  pleaseEnter: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008AE', '请输入') /* "请输入" */,
  none: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008AF', '无') /* "无" */,
  name: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B0', '名称') /* "名称" */,
  position: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B1', '职位') /* "职位" */,
  telphone: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B2', '电话') /* "电话" */,
  upload: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B3', '上传') /* "上传" */,
  image: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B4', '图片') /* "图片" */,
  file: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B5', '文件') /* "文件" */,
  account: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B6', '账号') /* "账号" */,
  accountType: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B7', '账号性质') /* "账号性质" */,
  proName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B8', '项目名称') /* "项目名称" */,
  creator: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008B9', '创建人') /* "创建人" */,
  expenseItemtypeName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008BA', '项目费用类型') /* "项目费用类型" */,
  accentityName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008BB', '组织名称') /* "组织名称" */,
  proCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008BC', '编码') /* "编码" */,
  loginAccount: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800023', '登录账号') /* "登录账号" */,
  mail: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008BD', '邮箱') /* "邮箱" */,
  mobile: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008BE', '手机') /* "手机" */,
  description: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008BF', '描述') /* "描述" */,
  code: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008BC', '编码') /* "编码" */,
  enable: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C0', '启用') /* "启用" */,
  personInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C1', '个人信息') /* "个人信息" */,
  accountSafe: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C2', '账户安全') /* "账户安全" */,
  freeze: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C3', '冻结') /* "冻结" */,
  unFreeze: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C4', '解冻') /* "解冻" */,
  freezeAgentTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C5', '您的账号已被冻结，不能提交单据!') /* "您的账号已被冻结，不能提交单据!" */,
  disable: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C6', '停用') /* "停用" */,
  status: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C7', '状态') /* "状态" */,
  erpCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C8', 'ERP编码') /* "ERP编码" */,
  upClass: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C9', '上级分类') /* "上级分类" */,
  className: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008CA', '分类名称') /* "分类名称" */,
  classCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008CB', '分类编码') /* "分类编码" */,
  addClass: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008CC', '新增分类') /* "新增分类" */,
  saleorgName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008CD', '销售组织名称') /* "销售组织名称" */,
  groupCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008CE', '集团编码') /* "集团编码" */,
  saleOrgCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008CF', '销售组织ERP编码') /* "销售组织ERP编码" */,
  addFirstClass: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D0', '新增一级分类') /* "新增一级分类" */,
  addSubClass: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D1', '新增子类') /* "新增子类" */,
  edit: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D2', '编辑') /* "编辑" */,
  finishEdit: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D3', '编辑完成') /* "编辑完成" */,
  maxLength: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D5', '最多输入') /* "最多输入" */ + '{txt}' + lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D4', '个字符') /* "个字符" */,
  operate: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D6', '操作') /* "操作" */,
  useRebate: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D7', '使用费用') /* "使用费用" */,
  overRebateMoneyConfirm: lang.templateByUuid('UID:P_UDHWN_1A8ED29605880009', '客户费用单余额不足，超出费用单余额部分需补差支付，是否确认使用！') /* "客户费用单余额不足，超出费用单余额部分需补差支付，是否确认使用！" */,
  oppose: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D8', '驳回') /* "驳回" */,
  deliveryApply: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D9', '发货申请') /* "发货申请" */,
  close: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008DA', '关闭') /* "关闭" */,
  return: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008DB', '回退') /* "回退" */,
  sure: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008DC', '确认') /* "确认" */,
  delivery: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008DD', '发货') /* "发货" */,
  pay: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008DE', '付款') /* "付款" */,
  receivePay: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008DF', '收款') /* "收款" */,
  littleSum: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E0', '小计') /* "小计" */,
  totalSum: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E1', '合计') /* "合计" */,
  logisticInfo: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800015', '物流信息') /* "物流信息" */,
  pleaseAddlogisticNo: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800016', '请填写物流单号') /* "请填写物流单号" */,
  logisticNoTooLang: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800018', '物流单号过长请重新填写物流单号') /* "物流单号过长请重新填写物流单号" */,
  logisticInfoModifySucess: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580001A', '物流信息修改成功！') /* "物流信息修改成功！" */,
  operateSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E2', '操作成功') /* "操作成功" */,
  operateFail: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E3', '操作失败') /* "操作失败" */,
  saveSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E4', '保存成功') /* "保存成功" */,
  promotionSaveWarring: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E5', '此促销已被使用，只有活动时间、维度、最大优惠数量/金额、赠品最大优惠数量和启用状态的修改会生效') /* "此促销已被使用，只有活动时间、维度、最大优惠数量/金额、赠品最大优惠数量和启用状态的修改会生效" */,
  saveSucessAndContinueTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E6', '保存成功，可以继续维护其他信息！') /* "保存成功，可以继续维护其他信息！" */,
  delSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E7', '删除成功') /* "删除成功" */,
  setSuccess: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580001E', '设置成功') /* "设置成功" */,
  batchDelSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E8', '批量删除成功') /* "批量删除成功" */,
  delConfirmTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E9', '您确定要删除这条数据吗?') /* "您确定要删除这条数据吗?" */,
  paySucess: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008EA', '付款成功') /* "付款成功" */,
  to: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008EB', '至') /* "至" */,
  setting: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580001F', '设置') /* "设置" */,
  activityMsInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008EC', '秒杀活动') /* "秒杀活动" */,
  groupBuy: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008ED', '团购') /* "团购" */,
  type: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008EE', '类型') /* "类型" */,
  gotoCD: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008EF', '去凑单') /* "去凑单" */,
  mustSelectProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F0', '必选商品') /* "必选商品" */,
  noResults: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F1', '暂无数据哦~') /* "暂无数据哦~" */,
  dimensionReason: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F2', '促销维度为客户时请先选择组织') /* "促销维度为客户时请先选择组织" */,
  feeItemName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F3', '费用项目') /* "费用项目" */,
  payrecord: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F4', '付款记录') /* "付款记录" */,
  pleaseSelectProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F5', '请选择商品') /* "请选择商品" */,
  pleaseCheckProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F6', '请勾选商品') /* "请勾选商品" */,
  pleaseSelectSaleBillOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F7', '请选择销售合同') /* "请选择销售合同" */,
  PleaseFillSalesQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F8', '请填写正确销售数量') /* "请填写正确销售数量" */,
  skuDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F9', '具体规格') /* "具体规格" */,
  pleaseSelectSku: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008FA', '请选择规格') /* "请选择规格" */,
  loading: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008FB', '加载中') /* "加载中" */,
  search: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008FC', '搜索') /* "搜索" */,
  selectAgent: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008FD', '选择客户') /* "选择客户" */,
  noAuth: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008FE', '无权限') /* "无权限" */,
  deleteAuth: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008FF', '剔除权限') /* "剔除权限" */,
  myAccount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000900', '账户管理') /* "账户管理" */,
  createBizName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000901', '创建商家') /* "创建商家" */,
  logout: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580002C', '退出') /* "退出" */,
  experienceVersion: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000902', '体验版') /* "体验版" */,
  goAgent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000903', '进入订货端') /* "进入订货端" */,
  agent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000904', '客户') /* "客户" */,
  agentName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000905', '客户名称') /* "客户名称" */,
  agentCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000906', '客户编码') /* "客户编码" */,
  agentArea: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000907', '客户区域') /* "客户区域" */,
  agentIndustry: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000908', '客户行业') /* "客户行业" */,
  agentCategory: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000909', '客户分类') /* "客户分类" */,
  agentLevel: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500090A', '客户级别') /* "客户级别" */,
  receiveAgreementCode: lang.templateByUuid('UID:P_UDHWN_1920D1B204500009', '收款协议编码') /* "收款协议编码" */,
  receiveAgreementName: lang.templateByUuid('UID:P_UDHWN_1920D1B20450000A', '收款协议名称') /* "收款协议名称" */,
  installmentPayment: lang.templateByUuid('UID:P_UDHWN_1920D1B20450000B', '分期收款') /* "分期收款" */,
  protocolSpecification: lang.templateByUuid('UID:P_UDHWN_1920D1B20450000C', '协议说明') /* "协议说明" */,
  customerGroup: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500090B', '客户分组') /* "客户分组" */,
  agentRank: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500090C', '客户等级') /* "客户等级" */,
  customize: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500090D', '自定义项') /* "自定义项" */,
  levelCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500090E', '级别编码') /* "级别编码" */,
  agentAddress: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500090F', '客户行政区划') /* "客户行政区划" */,
  professionalSaler: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500089F', '专管业务员') /* "专管业务员" */,
  agentSource: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000910', '客户来源') /* "客户来源" */,
  productLine: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000911', '产品线') /* "产品线" */,
  unit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000912', '单位') /* "单位" */,
  productClass: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000913', '商品分类') /* "商品分类" */,
  brand: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000914', '品牌') /* "品牌" */,
  insteadOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000915', '代下单') /* "代下单" */,
  agentNameOrCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000916', '名称/编码') /* "名称/编码" */,
  nameOrCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000916', '名称/编码') /* "名称/编码" */,
  stockOrgAgnetName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000917', '机构名') /* "机构名" */,
  sptemplatesName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000918', '模板名称') /* "模板名称" */,
  startDateTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000919', '开始时间') /* "开始时间" */,
  endDateTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500091A', '结束时间') /* "结束时间" */,
  orderNo: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500091B', '订单编码') /* "订单编码" */,
  deliveryNo: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500091C', '发货单号') /* "发货单号" */,
  SaleReturnNo: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500091D', '退货单号') /* "退货单号" */,
  ptoName: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500091E', 'PTO母件商品') /* "PTO母件商品" */,
  salereturnType: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500091F', '退货类型') /* "退货类型" */,
  onlyEnableReturn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000920', '已发货退货') /* "已发货退货" */,
  approvalRemarks: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000921', '审批备注') /* "审批备注" */,
  orderDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000922', '订单日期') /* "订单日期" */,
  truelyReturnMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000923', '实际退货金额') /* "实际退货金额" */,
  productNameWithSku: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000924', '商品名称（属性)') /* "商品名称（属性)" */,
  transactionPrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000925', '成交价') /* "成交价" */,
  orderQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000926', '订货计价数量') /* "订货计价数量" */,
  following: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000927', '跟踪') /* "跟踪" */,
  money: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000928', '金额') /* "金额" */,
  orderPrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000929', '订货金额') /* "订货金额" */,
  activityEndDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500092A', '活动截止时间') /* "活动截止时间" */,
  hasSendQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500092B', '已发货计价数量') /* "已发货计价数量" */,
  hasSendPrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500092C', '已发货金额') /* "已发货金额" */,
  hasReceiveQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500092D', '已收货计价数量') /* "已收货计价数量" */,
  hasReceivePrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500092E', '已收货金额') /* "已收货金额" */,
  noReceiveQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500092F', '未发货计价数量') /* "未发货计价数量" */,
  noReceivePricce: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000930', '未发货金额') /* "未发货金额" */,
  changeRealQuantityTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000931', '确认退货发出数量与退货一致吗？如不一致可在退货单详情页修改实际退货数量”，控制实际退货数量的值不能大于“退货计价数量') /* "确认退货发出数量与退货一致吗？如不一致可在退货单详情页修改实际退货数量”，控制实际退货数量的值不能大于“退货计价数量" */,
  preQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000932', '预计退货计价数量') /* "预计退货计价数量" */,
  realQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000933', '实际退货计价数量') /* "实际退货计价数量" */,
  preOrderRebte: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000934', '预计整单费用') /* "预计整单费用" */,
  preCashRebate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000935', '预计抵现费用') /* "预计抵现费用" */,
  perPayMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000936', '预计退货金额') /* "预计退货金额" */,
  realCashRebte: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000937', '实际抵现费用') /* "实际抵现费用" */,
  realOrderRebte: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000938', '实际整单费用') /* "实际整单费用" */,
  realPayMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000923', '实际退货金额') /* "实际退货金额" */,
  returnBackTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000939', '确认已经发出该退货单的货物吗?') /* "确认已经发出该退货单的货物吗?" */,
  managerOrg: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500093A', '管理组织') /* "管理组织" */,
  ALL: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500093B', '全部') /* "全部" */,
  all: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500093B', '全部') /* "全部" */,
  find: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500093C', '查询') /* "查询" */,
  year: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500093D', '年') /* "年" */,
  month: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500093E', '月') /* "月" */,
  oneday: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500093F', '日') /* "日" */,
  onehour: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000940', '点') /* "点" */,
  oneminute: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000941', '分') /* "分" */,
  sureToDelete: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000942', '确认要删除此订单吗?') /* "确认要删除此订单吗?" */,
  Annotation: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000943', '批注') /* "批注" */,
  audit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000944', '审核') /* "审核" */,
  auditSure: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000945', '确认审核么?') /* "确认审核么?" */,
  withdrawalPay: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000946', '提现') /* "提现" */,
  withdrawalPaySure: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000947', '确认提现么?') /* "确认提现么?" */,
  opposeMsg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000948', '请填写驳回意见,当前订单将被自动关闭!') /* "请填写驳回意见,当前订单将被自动关闭!" */,
  returnOpposeMsg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000949', '请填写驳回意见,当前退货单将被自动关闭!') /* "请填写驳回意见,当前退货单将被自动关闭!" */,
  opposePromotionMsg: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500094A', '请填写驳回原因,当前促销活动将被自动停用!') /* "请填写驳回原因,当前促销活动将被自动停用!" */,
  opposeSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500094B', '驳回成功') /* "驳回成功" */,
  PromptInformation: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500094C', '提示信息') /* "提示信息" */,
  productNameOrCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000916', '名称/编码') /* "名称/编码" */,
  xingming: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500094D', '姓名') /* "姓名" */,
  updateLog: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500094E', '更新日志') /* "更新日志" */,
  workOrderSystem: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500094F', '工单系统') /* "工单系统" */,
  logoutSystem: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800034', '退出系统') /* "退出系统" */,
  startHubbleTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000950', '开始录制') /* "开始录制" */,
  endHubbleTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000951', '结束录制') /* "结束录制" */,
  refurbishStart: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000952', '页面刷新后，录制开始！') /* "页面刷新后，录制开始！" */,
  refurbishEnd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000953', '页面刷新后，录制结束！') /* "页面刷新后，录制结束！" */,
  hubbleUId: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000954', '请保存操作分析报告ID') /* "请保存操作分析报告ID" */,
  accountInformation: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000955', '账户信息') /* "账户信息" */,
  batchoperate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000956', '批量操作') /* "批量操作" */,
  import: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000957', '导入') /* "导入" */,
  importFileDown: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000958', '下载导入模板') /* "下载导入模板" */,
  uploadErrorMsg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000959', '上传失败，请稍后重试') /* "上传失败，请稍后重试" */,
  shouqi: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500095A', '收起') /* "收起" */,
  showALl: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500095B', '查看全部') /* "查看全部" */,
  userbaseWarring1: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500095C', '注：请到') /* "注：请到" */,
  userbaseWarring2: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C2', '账户安全') /* "账户安全" */,
  userbaseWarring3: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500095D', '中去修改账号(手机/邮箱)信息') /* "中去修改账号(手机/邮箱)信息" */,
  lock: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500095E', '锁定') /* "锁定" */,
  deblock: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500095F', '解锁') /* "解锁" */,
  deblockTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000960', '订单已同步至ERP系统，解锁可能造成数据不一致，请确认是否继续？') /* "订单已同步至ERP系统，解锁可能造成数据不一致，请确认是否继续？" */,
  refundDeblockTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000961', '退款订单已同步至ERP系统，解锁可能造成数据不一致，请确认是否继续？') /* "退款订单已同步至ERP系统，解锁可能造成数据不一致，请确认是否继续？" */,
  paymentsDeblockTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000962', '收款订单已同步至ERP系统，解锁可能造成数据不一致，请确认是否继续？') /* "收款订单已同步至ERP系统，解锁可能造成数据不一致，请确认是否继续？" */,
  salereturnsDeblockTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000963', '退货订单已同步至ERP系统，解锁可能造成数据不一致，请确认是否继续？') /* "退货订单已同步至ERP系统，解锁可能造成数据不一致，请确认是否继续？" */,
  selectSelfTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000964', '上级组织不能选择为当前组织本身！') /* "上级组织不能选择为当前组织本身！" */,
  assign: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000965', '分配') /* "分配" */,
  potentialAgents: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000966', '潜在客户') /* "潜在客户" */,
  approval: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000967', '核准') /* "核准" */,
  sureDel: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000968', '确定要删除么') /* "确定要删除么" */,
  sureApproval: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000969', '确定要核准么') /* "确定要核准么" */,
  officalAgents: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500096A', '已转正式客户') /* "已转正式客户" */,
  viewParam: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500096B', '查看参数') /* "查看参数" */,
  notNull: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500096C', '不能为空') /* "不能为空" */,
  stockTipWords: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500096D', '库存提示文字') /* "库存提示文字" */,
  notBeyondFive: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500096E', '不能超过5个字') /* "不能超过5个字" */,
  ruleSetting: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500096F', '规则设置') /* "规则设置" */,
  pleaseselectRules: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000970', '请选择规则维度') /* "请选择规则维度" */,
  takeInOrgs: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000971', '任职组织') /* "任职组织" */,
  org: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000972', '组织') /* "组织" */,
  funcAuth: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000973', '功能权限') /* "功能权限" */,
  diyongquan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000974', '抵用券') /* "抵用券" */,
  quanhao: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000975', '券号') /* "券号" */,
  useTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000976', '使用时间') /* "使用时间" */,
  orderno: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000977', '订单号') /* "订单号" */,
  youxiaoqi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000978', '有效期') /* "有效期" */,
  role: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000979', '角色') /* "角色" */,
  canotEdit: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500097A', '完成') /* "完成" */,
  pleaseSelectDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500097B', '请选择明细') /* "请选择明细" */,
  pleaseSelectAgent: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500097C', '请选择客户') /* "请选择客户" */,
  selectDefaultProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500097D', '选择默认商品') /* "选择默认商品" */,
  export: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500097E', '导出') /* "导出" */,
  beingExport: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500097F', '正在导出') /* "正在导出" */,
  exportAll: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000980', '导出全部') /* "导出全部" */,
  exportSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000981', '导出完成') /* "导出完成" */,
  exportFail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000982', '导出失败') /* "导出失败" */,
  exportResult: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000983', '导出查询结果') /* "导出查询结果" */,
  exportRecord: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000984', '导出所选记录') /* "导出所选记录" */,
  beingImport: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000985', '正在导入') /* "正在导入" */,
  importWarning: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000986', '导入失败') /* "导入失败" */,
  importSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000987', '导入完成') /* "导入完成" */,
  count: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000988', '第') /* "第" */,
  bar: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000989', '条') /* "条" */,
  total: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500098A', '共') /* "共" */,
  piece: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500098B', '件') /* "件" */,
  warn: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500098C', '个错误') /* "个错误" */,
  warnning: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500098D', '警告') /* "警告" */,
  axiosContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500098E', '登录失效，即将跳转登陆页面') /* "登录失效，即将跳转登陆页面" */,
  axiosMsg: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500098F', '服务异常，请稍后重试。') /* "服务异常，请稍后重试。" */,
  scopeOfApplication: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000990', '适用范围') /* "适用范围" */,
  trasfwezfdx: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000991', '适用范围asdf') /* "适用范围asdf" */,
  scopeOfOrg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000992', '适用组织') /* "适用组织" */,
  scopeOfChannel: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000993', '适用渠道') /* "适用渠道" */,
  basicInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000994', '基本信息') /* "基本信息" */,
  daikexiadan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000995', '代客户下单') /* "代客户下单" */,
  shoujixiadan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000996', '移动下单') /* "移动下单" */,
  yushoudingdan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000997', '预售订单') /* "预售订单" */,
  baseView: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000998', '基础信息') /* "基础信息" */,
  signIn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000999', '签收') /* "签收" */,
  signInRecord: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500099A', '签收记录') /* "签收记录" */,
  changeChannel: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500099B', '选择渠道') /* "选择渠道" */,
  selectBiz: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500099C', '选择商家') /* "选择商家" */,
  selectOrg: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500099D', '选择组织') /* "选择组织" */,
  selectOrgFirst: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500099E', '请先选择组织') /* "请先选择组织" */,
  corpMenu: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500099F', '企业端菜单') /* "企业端菜单" */,
  searchInResults: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A0', '在结果中搜索') /* "在结果中搜索" */,
  bizMenu: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A1', '商家端菜单') /* "商家端菜单" */,
  agentMenu: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A2', '订货端菜单') /* "订货端菜单" */,
  push: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A3', '推送') /* "推送" */,
  receiveComplete: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A4', '收货完成') /* "收货完成" */,
  allOrderClose: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A5', '整单关闭') /* "整单关闭" */,
  abandorSure: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A6', '确定要放弃么?') /* "确定要放弃么?" */,
  bizName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A7', '商家') /* "商家" */,
  tenant: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A8', '人员') /* "人员" */,
  clientName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000904', '客户') /* "客户" */,
  zheProductIsNotHas: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A9', '此商品已下架') /* "此商品已下架" */,
  tenantTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009AA', '不包括商品主管角色的人员') /* "不包括商品主管角色的人员" */,
  clientNameTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009AB', '不包括管理员组的客户') /* "不包括管理员组的客户" */,
  personnel: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A8', '人员') /* "人员" */,
  publish: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009AC', '发布') /* "发布" */,
  surePub: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009AD', '确定发布么?') /* "确定发布么?" */,
  invalid: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009AE', '失效') /* "失效" */,
  sureInv: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009AF', '确定失效么?') /* "确定失效么?" */,
  view: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500089B', '详情') /* "详情" */,
  aliasAsUniqueness: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B0', '英文名称会作为客户登录端账号的后缀来体现其唯一性') /* "英文名称会作为客户登录端账号的后缀来体现其唯一性" */,
  secondLoginMsg: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B1', '该账户需要进行短信验证，现在立即验证') /* "该账户需要进行短信验证，现在立即验证" */,
  delContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B2', '确定删除么?') /* "确定删除么?" */,
  clearInvalidInventory: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B3', '请确认是否要重算订单占用的库存?') /* "请确认是否要重算订单占用的库存?" */,
  clearInvalidInventorybtn: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B4', '重算占用量') /* "重算占用量" */,
  selectDataBtn: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B5', '选择数据') /* "选择数据" */,
  WDchange: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B6', '切换纬度将会重置所选的维度中的数据,确定切换吗?') /* "切换纬度将会重置所选的维度中的数据,确定切换吗?" */,
  syncAllowRange: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B7', '同步允销范围') /* "同步允销范围" */,
  sureBatchsyncAllowRange: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B8', '确定此操作么吗?') /* "确定此操作么吗?" */,
  successBatchsyncAllowRange: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E2', '操作成功') /* "操作成功" */,
  platform: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009B9', '平台') /* "平台" */,
  merchant: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009A7', '商家') /* "商家" */,
  takeEffect: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009BA', '整单生效') /* "整单生效" */,
  noAttachments: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009BB', '暂无附件') /* "暂无附件" */,
  corpErpIntegration: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009BC', 'ERP集成') /* "ERP集成" */,
  maxCanBuyCount: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009BD', '最大可购买数量') /* "最大可购买数量" */,
  youhuixinxi: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009BE', '优惠信息') /* "优惠信息" */,
  zuhe: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009BF', '组合') /* "组合" */,
  fenzu: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C0', '分组') /* "分组" */,
  leiji: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C1', '累计') /* "累计" */,
  perman: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C2', '每满') /* "每满" */,
  zhengdan: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C3', '整单') /* "整单" */,
  fenlei: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C4', '分类') /* "分类" */,
  zhe: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C5', '折') /* "折" */,
  jian: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C6', '减') /* "减" */,
  zeng: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C7', '赠') /* "赠" */,
  xian: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C8', '限') /* "限" */,
  yikoujia: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C9', '一口价') /* "一口价" */,
  ci: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009CA', '次') /* "次" */,
  danjiajian: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009CB', '单价减') /* "单价减" */,
  bixuantiaojian: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009CC', '必选条件') /* "必选条件" */,
  zengpinjineyuan: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009CD', '赠品金额') /* "赠品金额" */,
  zengpinjijiashuliang: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009CE', '赠品计价数量') /* "赠品计价数量" */,
  zengpinxiaoshoushuliang: lang.templateByUuid('UID:P_UDHWN_18F8EBB604F80009', '赠品销售数量') /* "赠品销售数量" */,
  man: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009CF', '满') /* "满" */,
  proDa: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D0', '打') /* "打" */,
  xiaofeiman: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D1', '消费满') /* "消费满" */,
  chukujine: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D2', '出库金额满') /* "出库金额满" */,
  chukushuliang: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D3', '出库数量满') /* "出库数量满" */,
  zhongdeshangpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D4', '中的商品') /* "中的商品" */,
  koulv: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D5', '扣率') /* "扣率" */,
  yuandanwei: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D6', '(单价)') /* "(单价)" */,
  print: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D7', '打印') /* "打印" */,
  stockName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D8', '仓库') /* "仓库" */,
  deliveryStockName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008DD', '发货') + lang.templateByUuid('UID:P_UDHWN_17DD50DE0500056A', '仓库'),
  skuIfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D9', 'sku信息') /* "sku信息" */,
  stockOrgName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009DA', '库存组织') /* "库存组织" */,
  warehouseName: lang.templateByUuid('UID:P_UDHWN_19333D5004080002', '仓库名称') /* "仓库名称" */,
  stockOrgCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009DB', '库存组织编码') /* "库存组织编码" */,
  productCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009DC', '商品编码') /* "商品编码" */,
  skuCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009DD', 'sku编码') /* "sku编码" */,
  skuName: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009DE', 'sku名称') /* "sku名称" */,
  totalInventory: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009DF', '库存总量') /* "库存总量" */,
  availableInventory: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E0', '可用量') /* "可用量" */,
  ruleDimDimension: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E1', '规则维度') /* "规则维度" */,
  belongOrg: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E2', '所属组织') /* "所属组织" */,
  batchContentA: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E3', '是否要对选中的') /* "是否要对选中的" */,
  batchContentB: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E4', '记录执行操作吗？') /* "记录执行操作吗？" */,
  setDefault: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800021', '设为默认') /* "设为默认" */,
  yingfumoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E5', '应付金额') /* "应付金额" */,
  default: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E6', '默认') /* "默认" */,
  selectPromotion: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E7', '选择促销') /* "选择促销" */,
  promotionType: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E8', '促销方式') /* "促销方式" */,
  autoJumpRepeatRow: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009E9', '已自动过滤重复数据') /* "已自动过滤重复数据" */,
  adPicHelpTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009EA', '促销广告仅在移动订货端首页支持跳转') /* "促销广告仅在移动订货端首页支持跳转" */,
  stockAuthTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009EB', '主管拥有全部权限') /* "主管拥有全部权限" */,
  retry: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009EC', '重试') /* "重试" */,
  bank: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009ED', '银行') /* "银行" */,
  bankNo: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009EE', '银行卡号') /* "银行卡号" */,
  qpayConfirm: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009EF', '快捷支付身份确认') /* "快捷支付身份确认" */,
  addMsgComplete: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F0', '请将信息填写完整') /* "请将信息填写完整" */,
  corpFinancial: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F1', '收款银行账户') /* "收款银行账户" */,
  accountUser: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F2', '开户名称') /* "开户名称" */,
  chooseOther: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F3', '选择其他') /* "选择其他" */,
  serviceRate: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F4', '手续费率') /* "手续费率" */,
  payOtherServiceRate: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F5', '另付手续费') /* "另付手续费" */,
  selectPayBank: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F6', '选择付款银行') /* "选择付款银行" */,
  pleaseSelectOrg: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F7', '请选择财务组织') /* "请选择财务组织" */,
  recommandProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F8', '推 / 荐 / 商 / 品') /* "推 / 荐 / 商 / 品" */,
  allProducts: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009F9', '全 / 部 / 商 / 品') /* "全 / 部 / 商 / 品" */,
  maxBuy: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009FA', '最大购买数量') /* "最大购买数量" */,
  selectActive: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009FB', '活动选择') /* "活动选择" */,
  corpBank: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009FC', '企业银行') /* "企业银行" */,
  personalBank: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009FD', '个人银行') /* "个人银行" */,
  pleseSelectBank: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009FE', '请选择银行') /* "请选择银行" */,
  accountOpeningBranch: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009FF', '开户支行') /* "开户支行" */,
  accountOpeningArea: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A00', '开户地区') /* "开户地区" */,
  bankAccount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A01', '银行账号') /* "银行账号" */,
  latitude: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A02', '纬度') /* "纬度" */,
  longitude: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A03', '经度') /* "经度" */,
  repeeatCheck: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A04', '重复检测') /* "重复检测" */,
  dimensionsNotCanNull: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A05', '维度不能为空') /* "维度不能为空" */,
  dimensionsRepeat: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A06', '维度重复') /* "维度重复" */,
  limitSaveTip1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A07', '请输入商品/SKU的限购量') /* "请输入商品/SKU的限购量" */,
  viewGoods: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A08', '查看商品') /* "查看商品" */,
  viewCustomer: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A09', '查看客户') /* "查看客户" */,
  clearAllAuth: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A0A', '清空所有权限') /* "清空所有权限" */,
  demensionName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A0B', '促销名称') /* "促销名称" */,
  activityRange: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A0C', '活动范围') /* "活动范围" */,
  discountType: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A0D', '优惠类型') /* "优惠类型" */,
  activityType: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A0E', '活动类型') /* "活动类型" */,
  bizAgentErpCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A0F', '商家客户ERP编码') /* "商家客户ERP编码" */,
  submitalereturn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A10', '待退货审批') /* "待退货审批" */,
  saleReturning: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A11', '退货中') /* "退货中" */,
  endSaleReturn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A12', '已完成') /* "已完成" */,
  opposeSaleReturn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A13', '已驳回') /* "已驳回" */,
  CONFIRMSALERETURNORDER: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A14', '待退货') /* "待退货" */,
  sendQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A15', '发货计价数量') /* "发货计价数量" */,
  saleReturnQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A16', '已退计价数量') /* "已退计价数量" */,
  saleReturnSaleQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A17', '已退销售数量') /* "已退销售数量" */,
  returnQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A18', '退货计价数量') /* "退货计价数量" */,
  returnAuxQuantity: lang.templateByUuid('UID:P_UDHWN_1848C14004A00006', '退货销售数量') /* "退货销售数量" */,
  returnQuantityBeyond: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A19', '退货数量不能超过发货数量！') /* "退货数量不能超过发货数量！" */,
  selectDeliveryAgain: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A1A', '不能选择不同人员的发货单进行退货，请重新选择!') /* "不能选择不同人员的发货单进行退货，请重新选择!" */,
  returnQuantityCorrect: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A1B', '请正确填写要退货的商品数量!') /* "请正确填写要退货的商品数量!" */,
  refundBeyond: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A1C', '退货金额不能高于原始退货金额!') /* "退货金额不能高于原始退货金额!" */,
  fRebateToOrderMoneyBeyond: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A1D', '整单费用金额不能高于原始整单费用金额!') /* "整单费用金额不能高于原始整单费用金额!" */,
  fRebateToCashMoneyBeyond: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A1E', '抵现费用金额不能高于原始抵现费用金额!') /* "抵现费用金额不能高于原始抵现费用金额!" */,
  norefund: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A1F', '全部未退款') /* "全部未退款" */,
  PARTNONECONFIRM: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A20', '部分退款待审核') /* "部分退款待审核" */,
  PARTNONE: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A21', '部分退款') /* "部分退款" */,
  ConfirmToRefund: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A22', '退款待审批') /* "退款待审批" */,
  FinishReund: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A23', '已退款') /* "已退款" */,
  pleseSelectReturnProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A24', '请选择要退货商品!') /* "请选择要退货商品!" */,
  presaleType: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008EE', '类型') /* "类型" */,
  presaleStatus: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A25', '预售') /* "预售" */,
  secKill: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A26', '秒杀') /* "秒杀" */,
  bookStatus: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A27', '预定') /* "预定" */,
  creditcalculation: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A28', '信用正在后台计算') /* "信用正在后台计算" */,
  being: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A29', '正在') /* "正在" */,
  credit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A2A', '信用') /* "信用" */,
  calculation: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A2B', '计算') /* "计算" */,
  backstage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A2C', '后台') /* "后台" */,
  complete: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500097A', '完成') /* "完成" */,
  page: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A2D', '页面') /* "页面" */,
  related: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A2E', '相关') /* "相关" */,
  lookOver: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A2F', '查看') /* "查看" */,
  saleReturn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A30', '退货') /* "退货" */,
  saleReturnPart: lang.templateByUuid('UID:P_UDHWN_1952C1B40558000D', '部分退货') /* "部分退货" */,
  buyagain: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A31', '再次购买') /* "再次购买" */,
  printorderexcel: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A32', '输出可打印文件') /* "输出可打印文件" */,
  createSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A33', '创建成功!') /* "创建成功!" */,
  output: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A34', '输出') /* "输出" */,
  demorenshangpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A35', '的默认商品') /* "的默认商品" */,
  please: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A36', '请') /* "请" */,
  success: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A37', '成功') /* "成功" */,
  fail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A38', '失败') /* "失败" */,
  download: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A39', '下载') /* "下载" */,
  effect: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A3A', '生效') /* "生效" */,
  product: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A3B', '商品') /* "商品" */,
  saleContract: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A3C', '销售合同') /* "销售合同" */,
  CNY: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A3D', '人民币') /* "人民币" */,
  tableNotNull: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A3E', '表格中的信息不能为空') /* "表格中的信息不能为空" */,
  tableHeader: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A3F', '表头') /* "表头" */,
  tableDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A40', '明细') /* "明细" */,
  attributes: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A41', '属性') /* "属性" */,
  price: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A42', '价格') /* "价格" */,
  lowLimit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A43', '数量下限') /* "数量下限" */,
  saleOrderTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A44', '销售订单') /* "销售订单" */,
  saleOrderReturnTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A45', '销售退货') /* "销售退货" */,
  dinghuoTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A46', 'BIP订货门户') /* "BIP订货门户" */,
  orderDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A47', '订单详情') /* "订单详情" */,
  deliveryDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A48', '发货单详情') /* "发货单详情" */,
  paymentDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A49', '收款详情') /* "收款详情" */,
  saleReturnDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A4A', '退货单详情') /* "退货单详情" */,
  refundDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A4B', '退款单详情') /* "退款单详情" */,
  expenseDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A4C', '费用单详情') /* "费用单详情" */,
  shareRuleDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A4D', '分摊规则详情') /* "分摊规则详情" */,
  verificationDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A4E', '核销详情') /* "核销详情" */,
  rebateDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A4F', '费用详情') /* "费用详情" */,
  selectStock: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A50', '选择仓库') /* "选择仓库" */,
  combinationPromDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A51', '组合促销详情') /* "组合促销详情" */,
  productPromDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A52', '商品促销详情') /* "商品促销详情" */,
  singleDiscountPromDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A53', '整单优惠详情') /* "整单优惠详情" */,
  productPriceSettingDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A54', '商品价格设置详情') /* "商品价格设置详情" */,
  agentPriceSettingDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A55', '客户价格设置详情') /* "客户价格设置详情" */,
  theProductNotExistOrBeenRemoved: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A56', '该商品不存在或已下架') /* "该商品不存在或已下架" */,
  noTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A57', '无标题') /* "无标题" */,
  refreshCurrentPage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A58', '刷新当前页面') /* "刷新当前页面" */,
  closeOtherPage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A59', '关闭其它页面') /* "关闭其它页面" */,
  closeAllPage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A5A', '关闭所有页面') /* "关闭所有页面" */,
  UNIT: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A5B', '计价单位') /* "计价单位" */,
  AUXUNIT: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A5C', '销售单位') /* "销售单位" */,
  selectCustomerFirst: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A5D', '请先选择客户') /* "请先选择客户" */,
  abandon: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A5E', '弃审') /* "弃审" */,
  confirm: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000944', '审核') /* "审核" */,
  useRecord: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A5F', '使用记录') /* "使用记录" */,
  useDetails: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A60', '使用明细') /* "使用明细" */,
  abandonOperationPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A61', '请确认是否执行弃审？') /* "请确认是否执行弃审？" */,
  auditOperationPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A62', '请确认是否执行审批？') /* "请确认是否执行审批？" */,
  orderNum: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000977', '订单号') /* "订单号" */,
  usageTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000976', '使用时间') /* "使用时间" */,
  useRebateQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A63', '使用费用数量') /* "使用费用数量" */,
  usageStatus: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A64', '使用状态') /* "使用状态" */,
  serverError: lang.templateByUuid('UID:P_UDHWN_17DD50DE0500098F', '服务异常，请稍后重试。') /* "服务异常，请稍后重试。" */,
  overdueError: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A65', '贵公司的租约已过期啦！如需继续使用，请火速和您的客户经理联系续约事项。') /* "贵公司的租约已过期啦！如需继续使用，请火速和您的客户经理联系续约事项。" */,
  loginError: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800028', '登录失效，即将跳转登录页面') /* "登录失效，即将跳转登录页面" */,
  reLogin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A66', '请重新登录') /* "请重新登录" */,
  activeCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A67', '激活码') /* "激活码" */,
  notHasCurrencyMessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A68', '商家未设置与您交易的币种，请联系商家人员维护！') /* "商家未设置与您交易的币种，请联系商家人员维护！" */,
  notHasRelationsMesage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A69', '企业销售组织和当前客户之间没有有效的交易关系，请联系企业管理员设置') /* "企业销售组织和当前客户之间没有有效的交易关系，请联系企业管理员设置" */,
  limitBizName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A6A', '商家名称') /* "商家名称" */,
  limitOrderTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A6B', '下单时间为') /* "下单时间为" */,
  limitToTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008EB', '至') /* "至" */,
  pleaseEnterActCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A6C', '请输入激活码') /* "请输入激活码" */,
  hasExistActCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A6D', '已有激活码?') /* "已有激活码?" */,
  pleaseGoYunMarket: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A6E', '请到云市场或云商务') /* "请到云市场或云商务" */,
  myOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A6F', '我的订单') /* "我的订单" */,
  zhongchaxunjihuoma: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A70', '中查询激活码。') /* "中查询激活码。" */,
  nojihuoma: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A71', '没有激活码？') /* "没有激活码？" */,
  qingdaoyunshichanghuoyunshangwuzhong: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A72', '请到云市场或云商务中') /* "请到云市场或云商务中" */,
  goumaichanpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A73', '购买产品') /* "购买产品" */,
  jikehuoqujihuoma: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A74', '，即可获取激活码。') /* "，即可获取激活码。" */,
  qingshuruyanzhengma: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A75', '请输入验证码') /* "请输入验证码" */,
  qingshurushangjiayaoqingma: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A76', '请输入商家邀请码') /* "请输入商家邀请码" */,
  shangjiayaoqingma: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A77', '商家邀请码') /* "商家邀请码" */,
  shangjiayaoqingmaNotNull: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A78', '商家邀请码不能为空') /* "商家邀请码不能为空" */,
  pleaseSelectAsalesOrganization: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A79', '请选择组织') /* "请选择组织" */,
  commingSoon: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A7A', '敬请期待') /* "敬请期待" */,
  jihuo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A7B', '激 活') /* "激 活" */,
  expend: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A7C', '展开') /* "展开" */,
  sureToDeleteOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000942', '确认要删除此订单吗?') /* "确认要删除此订单吗?" */,
  zhiyouguanliyuancaijihuo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A7D', '只有超级管理员才激活哦') /* "只有超级管理员才激活哦" */,
  qiehuanzhanghao: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A7E', '切换账号') /* "切换账号" */,
  xiacizaishuo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A7F', '下次再说') /* "下次再说" */,
  chongxinjihuo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A80', '重新激活') /* "重新激活" */,
  aiyajihuomacuowu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A81', '哎呀，您的激活码输入错误！') /* "哎呀，您的激活码输入错误！" */,
  aiyayijingjihuo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A82', '哎呀，您的激活码已经激活，') /* "哎呀，您的激活码已经激活，" */,
  qingshuaxinyemian: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A83', '请刷新页面重试') /* "请刷新页面重试" */,
  zhidaola: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A84', '知道啦') /* "知道啦" */,
  jihuochenggong: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A85', '激活成功！') /* "激活成功！" */,
  gongxijihuochenggong: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A86', '恭喜，激活成功啦!') /* "恭喜，激活成功啦!" */,
  preview: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A87', '预览') /* "预览" */,
  pleaseEnterRight: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A88', '请正确填写!') /* "请正确填写!" */,
  pleaseSelectByOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A89', '请按顺序选择!') /* "请按顺序选择!" */,
  daoruwenjian: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A8A', '导出文件...') /* "导出文件..." */,
  pleaseSelectExportData: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A8B', '请选择要导出的数据') /* "请选择要导出的数据" */,
  containNextOrg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A8C', '包含下级组织') /* "包含下级组织" */,
  jinzhiding: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A8D', '仅指定') /* "仅指定" */,
  noUpate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A8E', '该字段不允许修改') /* "该字段不允许修改" */,
  guige: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A8F', '规格') /* "规格" */,
  canshu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A90', '参数') /* "参数" */,
  pleaseSelect: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A91', '请选择') /* "请选择" */,
  jutiguige: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008F9', '具体规格') /* "具体规格" */,
  addExtAgent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A92', '添加例外客户') /* "添加例外客户" */,
  weidumingcheng: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A93', '维度名称') /* "维度名称" */,
  promotionFinish: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A94', '促销活动已经结束！') /* "促销活动已经结束！" */,
  watchPromotionGoods: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A95', '查看促销商品') /* "查看促销商品" */,
  weiduAgentExist: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A96', '规则维度[客户],已存在') /* "规则维度[客户],已存在" */,
  uploadFile: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A97', '上传文件') /* "上传文件" */,
  uploadSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A98', '上传成功') /* "上传成功" */,
  pleaseRightFormatFile: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A99', '请上传规定类型的文件') /* "请上传规定类型的文件" */,
  open: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A9A', '开启') /* "开启" */,
  addExtraAgent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A9B', '新增例外客户') /* "新增例外客户" */,
  kehuzhifushi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A9C', '客户支付时') /* "客户支付时" */,
  pushmessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A9D', '消息') /* "消息" */,
  nomessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A9E', '没有消息了') /* "没有消息了" */,
  customerCertification: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A9F', '客户认证') /* "客户认证" */,
  cancelCertification: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA0', '取消认证') /* "取消认证" */,
  verifyCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA1', '验证码') /* "验证码" */,
  getVerifyCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA2', '获取验证码') /* "获取验证码" */,
  houchongfa: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA3', '后重发') /* "后重发" */,
  productName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA4', '商品名称') /* "商品名称" */,
  specDescription: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA5', '商品属性') /* "商品属性" */,
  yongjinbaifenbi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA6', '佣金(百分比)') /* "佣金(百分比)" */,
  huoququanjupeizhi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA7', '获取全局配置,需要传入配置键!') /* "获取全局配置,需要传入配置键!" */,
  haikeyishuru: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA8', '还可以输入') /* "还可以输入" */,
  gezi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA9', '个字') /* "个字" */,
  pleaseEnterSearchContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AAA', '请输入查询内容') /* "请输入查询内容" */,
  clickEnter: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AAB', '点击进入') /* "点击进入" */,
  ninquedingyaoqingchu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AAC', '您确认要清除所有') /* "您确认要清除所有" */,
  clearshangpinquanxiantip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AAD', '的商品数据权限吗? 注意：一旦清除,不可恢复!') /* "的商品数据权限吗? 注意：一旦清除,不可恢复!" */,
  contact: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AAE', '联系人') /* "联系人" */,
  shouhuodizhi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AAF', '收货地址') /* "收货地址" */,
  fahuodizhi: lang.templateByUuid('UID:P_UDHWN_190B79B204280007', '发货地址') /* "发货地址" */,
  fahuoren: lang.templateByUuid('UID:P_UDHWN_190B79B204280008', '发货人') /* "发货人" */,
  yinhangzhanghuxinxi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB0', '银行账户信息') /* "银行账户信息" */,
  invoicemessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB1', '发票信息') /* "发票信息" */,
  pointdetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB2', '积分交易明细') /* "积分交易明细" */,
  coupon: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB3', '优惠券') /* "优惠券" */,
  setShareVaildTime: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800030', '设置验证码有效期') /* "设置验证码有效期" */,
  shoujiyaoyihouzhanghao: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB4', '该手机号已有账号，将沿用原账号密码。') /* "该手机号已有账号，将沿用原账号密码。" */,
  chushimima: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB5', '初始登录密码为88888888') /* "初始登录密码为88888888" */,
  pleaseEnterbrandName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB6', '请输入品牌名称') /* "请输入品牌名称" */,
  genggaihouzhuiyonghuming: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB7', '更换后缀名将导致用户名改变!') /* "更换后缀名将导致用户名改变!" */,
  resetExpiredPeriodTips: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800031', '调整失效日期不会自动启用客户，如有需要请手动启用') /* "调整失效日期不会自动启用客户，如有需要请手动启用" */,
  sureChange: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB8', '确认更改') /* "确认更改" */,
  youuse: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB9', '您使用的是') /* "您使用的是" */,
  xianzhiyonghushu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ABA', '限制用户数为') /* "限制用户数为" */,
  gemuqianyiyong: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ABB', '个,目前已有用户数为') /* "个,目前已有用户数为" */,
  hasTop: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ABC', '已达到上限') /* "已达到上限" */,
  haiketianjia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ABD', '还可添加') /* "还可添加" */,
  cancelCouponTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ABE', '点击空白处，取消选中的优惠券!') /* "点击空白处，取消选中的优惠券!" */,
  geyonghuqingheli: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ABF', '个用户,请合理设置') /* "个用户,请合理设置" */,
  gaikehuquerenbukongzhi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC0', '该客户确定不控制信用?') /* "该客户确定不控制信用?" */,
  yicunzaimima: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC1', '已存在密码将与原来一致') /* "已存在密码将与原来一致" */,
  notUsed: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC2', '未使用') /* "未使用" */,
  hasUsed: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC3', '已使用') /* "已使用" */,
  hasAbandon: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC4', '已作废') /* "已作废" */,
  addAgent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC5', '添加客户') /* "添加客户" */,
  other: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC6', '其他') /* "其他" */,
  wan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC7', '万') /* "万" */,
  sureApprove: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC8', '确认要核准么') /* "确认要核准么" */,
  refund: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC9', '退款') /* "退款" */,
  treeImg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ACA', '树形图') /* "树形图" */,
  relationImg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ACB', '关系图') /* "关系图" */,
  addProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ACC', '新增商品') /* "新增商品" */,
  salesContract: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A3C', '销售合同') /* "销售合同" */,
  appendProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ACD', '添加商品') /* "添加商品" */,
  pleaseEnterDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ACE', '请填入明细') /* "请填入明细" */,
  brandUploadDesc: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ACF', '仅支持JPG、JPEG、BMP、PNG格式，文件小于500KB（尺寸680*280px）') /* "仅支持JPG、JPEG、BMP、PNG格式，文件小于500KB（尺寸680*280px）" */,
  brandLogoUploadDesc: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD0', '仅支持JPG、JPEG、BMP、PNG格式，文件小于500KB（尺寸62*62px）') /* "仅支持JPG、JPEG、BMP、PNG格式，文件小于500KB（尺寸62*62px）" */,
  skuInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD1', 'SKU信息') /* "SKU信息" */,
  descInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD2', '描述信息') /* "描述信息" */,
  mainProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD3', '主商品') /* "主商品" */,
  canGetGiveaway: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD4', '本次促销合计可享受赠品') /* "本次促销合计可享受赠品" */,
  accmulateGiveawayTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD5', '本次使用促销的剩余量，将生成客户费用单，您可在后续交易中通过使用客户费用单兑付的行时享受剩余权益。') /* "本次使用促销的剩余量，将生成客户费用单，您可在后续交易中通过使用客户费用单兑付的行时享受剩余权益。" */,
  numIs: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD6', '数量为') /* "数量为" */,
  moneyIs: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD7', '金额为') /* "金额为" */,
  addAccessorieGroup: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD8', '新增配件组') /* "新增配件组" */,
  accessorieGroup: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AD9', '配件组') /* "配件组" */,
  accessorieName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ADA', '配件组名称') /* "配件组名称" */,
  pleaseSelectMainProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ADB', '请选择主商品!') /* "请选择主商品!" */,
  checkAccessoriesByIdWarn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ADC', '所选商品已设置推荐商品，请重新选择') /* "所选商品已设置推荐商品，请重新选择" */,
  bunengxuanzehezhushangpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ADD', '不能选择和主商品相同的商品，已自动过滤') /* "不能选择和主商品相同的商品，已自动过滤" */,
  TOORDER: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ADE', '整单折扣') /* "整单折扣" */,
  TOPRODUCT: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000ADF', '分摊折扣') /* "分摊折扣" */,
  TOCASH: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE0', '直接抵现') /* "直接抵现" */,
  NOTCONFIRM: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE1', '未审核') /* "未审核" */,
  ENDCONFIRM: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE2', '已审核') /* "已审核" */,
  hasEnable: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE3', '已启用') /* "已启用" */,
  hasDisable: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE4', '已禁用') /* "已禁用" */,
  saving: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE5', '省') /* "省" */,
  MOSTPERCENTAGE: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE6', '不超过总订货金额') /* "不超过总订货金额" */,
  LEASTORDERMONEY: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE7', '订货金额达到') /* "订货金额达到" */,
  pleseAddReturnProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE8', '请添加退货商品!') /* "请添加退货商品!" */,
  pleseAddMsgComplete: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AE9', '请将必填信息填写完整!') /* "请将必填信息填写完整!" */,
  pleseSetReturnProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AEA', '请选择退货商品数量!') /* "请选择退货商品数量!" */,
  yuancaikeshiyongfanli: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AEB', '才可使用费用') /* "才可使用费用" */,
  yuancaikeshiyongfanli1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AEB', '才可使用费用') /* "才可使用费用" */,
  yuan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AEC', '元') /* "元" */,
  MOSTREBATEMONEY: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AED', '使用的费用额不超过') /* "使用的费用额不超过" */,
  MOSTREBATEMONEYYS: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AED', '使用的费用额不超过') /* "使用的费用额不超过" */,
  jiaoxiaowenjianyouzhu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AEE', '较小的文件大小有助于加快页面的加载速度') /* "较小的文件大小有助于加快页面的加载速度" */,
  zuiduoshezhi6ge: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AEF', '最多设置6个轮播图，如需更换，请编辑或删除后重新添加') /* "最多设置6个轮播图，如需更换，请编辑或删除后重新添加" */,
  pleaseSelectPromotion: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF0', '请选择促销') /* "请选择促销" */,
  previewEffect: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF1', '预览效果') /* "预览效果" */,
  discount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF2', '打折') /* "打折" */,
  reducePrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF3', '减价') /* "减价" */,
  giveProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF4', '赠商品') /* "赠商品" */,
  onePrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C9', '一口价') /* "一口价" */,
  ORDEREDIT: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF5', '订单改价') /* "订单改价" */,
  ORDERCONFIRM: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF6', '订单确认') /* "订单确认" */,
  ORDERCOMMIT: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF7', '订单提交') /* "订单提交" */,
  CONSTRAINT: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF8', '严格控制') /* "严格控制" */,
  IGNORE: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF9', '不控制') /* "不控制" */,
  HINT: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AFA', '仅提示') /* "仅提示" */,
  redpackrule: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AFB', '红包规则') /* "红包规则" */,
  clickchangecode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AFC', '点击更换验证码') /* "点击更换验证码" */,
  openTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AFD', '开通时间') /* "开通时间" */,
  dueTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AFE', '到期时间') /* "到期时间" */,
  immediatelyActive: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AFF', '立即激活') /* "立即激活" */,
  shiyongrenshu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B00', '使用人数') /* "使用人数" */,
  zengzhifuw: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B01', '增值服务') /* "增值服务" */,
  servername: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B02', '服务名称') /* "服务名称" */,
  nesetting: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580001D', '不设置') /* "不设置" */,
  max10m: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B03', '（文件大小：不超过10M）') /* "（文件大小：不超过10M）" */,
  taxratetypehelp: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B04', '注意：若不同商品、客户适用的税率不一致不要在此设置税率，只有当税率一致时才可在此处设置税率。') /* "注意：若不同商品、客户适用的税率不一致不要在此设置税率，只有当税率一致时才可在此处设置税率。" */,
  daifabu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B05', '待发布') /* "待发布" */,
  yifabu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B06', '已发布') /* "已发布" */,
  zhifuqiyong: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B07', '支付启用') /* "支付启用" */,
  shoukuanzhanghao: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B08', '收款账号') /* "收款账号" */,
  jingxiaoshangorzuzhi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B09', '经销商/组织') /* "经销商/组织" */,
  pcduan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B0A', '电脑端') /* "电脑端" */,
  mobileduan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B0B', '手机端') /* "手机端" */,
  shezhiqudaogezuzhi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B0C', '设置渠道/经销商或各级组织') /* "设置渠道/经销商或各级组织" */,
  nengfoushiyonggaizhifu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B0D', '能否使用该支付通道来收款') /* "能否使用该支付通道来收款" */,
  addTemplate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B0E', '新增模板') /* "新增模板" */,
  defaultTemplate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B0F', '默认模板') /* "默认模板" */,
  design: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B10', '设计') /* "设计" */,
  copy: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B11', '复制') /* "复制" */,
  addDataItem: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B12', '添加数据项') /* "添加数据项" */,
  pleaseEnterKeyword: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B13', '请输入关键字') /* "请输入关键字" */,
  merchantName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A6A', '商家名称') /* "商家名称" */,
  hasSelectedItem: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B14', '已选数据项') /* "已选数据项" */,
  jinzhichi500k: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B15', '仅支持JPG、JPEG、BMP、PNG格式，文件小于500KB（尺寸') /* "仅支持JPG、JPEG、BMP、PNG格式，文件小于500KB（尺寸" */,
  allCheck: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B16', '全选') /* "全选" */,
  mobileno: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B17', '手机号') /* "手机号" */,
  yibei: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B18', '已被') /* "已被" */,
  decorate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B19', '布局') /* "布局" */,
  shiyongqinggenghuan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B1A', '使用，请更换。') /* "使用，请更换。" */,
  genghuanhouhuiming: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B1B', '更换后缀名为新的公司代码？若更换，则登录用户名将改变!') /* "更换后缀名为新的公司代码？若更换，则登录用户名将改变!" */,
  gaishoujihaoyiyouqitadenglu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B1C', '该手机号已有其他登录账号，账户信息:[') /* "该手机号已有其他登录账号，账户信息:[" */,
  jiangyanyongyuanzhanghaomima: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B1D', '] 将沿用原账号的登录密码!') /* "] 将沿用原账号的登录密码!" */,
  shifoutongshidaochuhexiaojilu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B1E', '是否同时导出核销记录?') /* "是否同时导出核销记录?" */,
  error404: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B1F', '404-页面不存在') /* "404-页面不存在" */,
  accountmobilemail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B20', '手机号/账号/邮箱') /* "手机号/账号/邮箱" */,
  updatepwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B21', '修改密码') /* "修改密码" */,
  jiaoyanma: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B22', '校验码') /* "校验码" */,
  getValidCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B23', '获取校验码') /* "获取校验码" */,
  miaohouhuoqu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B24', '秒后获取') /* "秒后获取" */,
  pleaseEnterNewPwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B25', '请输入新密码') /* "请输入新密码" */,
  pleaseEnterPicCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B26', '请输入图片验证码') /* "请输入图片验证码" */,
  pictureCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B27', '图片验证码') /* "图片验证码" */,
  sendVerifiSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B28', '验证码发送成功！') /* "验证码发送成功！" */,
  pleaseEnteSmsCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B29', '请输入短信验证码') /* "请输入短信验证码" */,
  twoPwdNotEq: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B2A', '两次密码输入不一致') /* "两次密码输入不一致" */,
  newPwdLength6: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B2B', '新密码长度不能少于6位') /* "新密码长度不能少于6位" */,
  next: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B2C', '下一个') /* "下一个" */,
  bizClient: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B2D', '商家端') /* "商家端" */,
  agentClient: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B2E', '订货端') /* "订货端" */,
  corpClient: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B2F', '企业端') /* "企业端" */,
  pleaseSelectLoginAccount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B30', '请选择登录账号') /* "请选择登录账号" */,
  findLoginPwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B31', '找回登录密码') /* "找回登录密码" */,
  tianxiedengluming: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B32', '填写登录名') /* "填写登录名" */,
  shenfenyanzheng: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B33', '身份验证') /* "身份验证" */,
  resetPwd: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800033', '重置密码') /* "重置密码" */,
  findSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B34', '找回成功') /* "找回成功" */,
  zhanghu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B35', '账户') /* "账户" */,
  choicezhanghu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B36', '选择账户') /* "选择账户" */,
  noTransaction: lang.templateByUuid('UID:P_UDHWN_18E6FDC804680007', '未查询到可用交易类型，请检查是否设置适用订货门户交易类型！') /* "未查询到可用交易类型，请检查是否设置适用订货门户交易类型！" */,
  needConfirmPackageProduct: lang.templateByUuid('UID:P_UDHWN_18F6B56A04F80006', '您还有需要选配的赠品，请先选择赠品后再提交订单。') /* "您还有需要选配的赠品，请先选择赠品后再提交订单。" */,
  transaction: lang.templateByUuid('UID:P_UDHWN_18E6FDC804680008', '交易类型') /* "交易类型" */,
  applyToJoin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B37', '申请加盟') /* "申请加盟" */,
  yanzhengmabuzhengque: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B38', '验证码不正确') /* "验证码不正确" */,
  cishoujihaowushenqingjilu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B39', '此手机号无申请记录') /* "此手机号无申请记录" */,
  login: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800014', '登录') /* "登录" */, // window.getLocaleMessage('MC_UOrder_agent_newOrder_002477'),
  pwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B3A', '密码') /* "密码" */,
  woyiyuedu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B3B', '我已阅读') /* "我已阅读" */,
  rememberUsername: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B3C', '记住用户名') /* "记住用户名" */,
  browseAround: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B3D', '随便看看') /* "随便看看" */,
  leaseExpiresPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B3E', '哎呀，贵公司的租约到期啦～') /* "哎呀，贵公司的租约到期啦～" */,
  serviceRenewalPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B3F', '如需继续使用，请与您的客户经理联系续约事项') /* "如需继续使用，请与您的客户经理联系续约事项" */,
  hotlineInformation: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B40', '致电免费客服热线：010-86393388 / 转5') /* "致电免费客服热线：010-86393388 / 转5" */,
  YonyouNetworkCoLtd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B41', '用友网络科技股份有限公司') /* "用友网络科技股份有限公司" */,
  workTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B42', '工作时间') /* "工作时间" */,
  attachement: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B43', '附件信息') /* "附件信息" */,
  adjunct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B44', '附件') /* "附件" */,
  or: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B45', '或') /* "或" */,
  and: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B46', '和') /* "和" */,
  enter: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B47', '输入') /* "输入" */,
  invalidParamRequest: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B48', '无效的参数请求') /* "无效的参数请求" */,
  changePwdPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B49', '账户不安全或密码为默认密码,请修改密码!') /* "账户不安全或密码为默认密码,请修改密码!" */,
  changePwdSuccessfullyPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B4A', '密码修改成功，请重新登录') /* "密码修改成功，请重新登录" */,
  loginImmediately: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE2', '立即登录') /* "立即登录" */,
  regTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B4B', '注册U订货 享受新服务') /* "注册U订货 享受新服务" */,
  haveAccountDoubt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B4C', '已有账号？') /* "已有账号？" */,
  registrationSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B4D', '注册成功') /* "注册成功" */,
  joinTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B4E', '感谢您的选择，请填写以下信息，我们将尽快与您联系！') /* "感谢您的选择，请填写以下信息，我们将尽快与您联系！" */,
  joinTitle0: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B4F', '感谢您的选择，当您的加盟申请未审核，可修改申请信息！') /* "感谢您的选择，当您的加盟申请未审核，可修改申请信息！" */,
  joinTitle1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B50', '感谢您的选择，您的加盟申请已审核，可直接登陆！') /* "感谢您的选择，您的加盟申请已审核，可直接登陆！" */,
  joinTitle2: lang.templateByUuid('UID:P_UDHWN_1848C14004A00005', '欢迎您的参与，请填写信息。') /* "欢迎您的参与，请填写信息。" */,
  selectRequireMessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B51', '查询申请信息') /* "查询申请信息" */,
  joinToggleShow1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B52', '仅显示简要信息') /* "仅显示简要信息" */,
  joinToggleShow2: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B53', '填写更多企业信息，让我们更多的了解您的业务...') /* "填写更多企业信息，让我们更多的了解您的业务..." */,
  joinToggleShow3: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B54', '查看更多申请信息……') /* "查看更多申请信息……" */,
  joinImmediately: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B55', '立即申请') /* "立即申请" */,
  quickRegist: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B56', '快速注册') /* "快速注册" */,
  enterpriseRegist: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B57', '企业注册') /* "企业注册" */,
  hasJoinMessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B58', '您好，您已经申请了该公司，等待审核中，可以在申请后的30个工作日后再次申请。') /* "您好，您已经申请了该公司，等待审核中，可以在申请后的30个工作日后再次申请。" */,
  loginPwd: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800029', '登录密码') /* "登录密码" */,
  confirmPwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B59', '确认密码') /* "确认密码" */,
  corpInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B5A', '企业信息') /* "企业信息" */,
  corpName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B5B', '公司名称') /* "公司名称" */,
  corpAlias: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B5C', '公司英文名') /* "公司英文名" */,
  activityDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B5D', '活动时间') /* "活动时间" */,
  limitProductPromotion: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B5E', '例外商品') /* "例外商品" */,
  accmulateDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B5F', '累计周期') /* "累计周期" */,
  registrationSuccessful: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B60', '注册成功啦！') /* "注册成功啦！" */,
  verifyMobileNumPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B61', '请输入正确的手机号码') /* "请输入正确的手机号码" */,
  verifyEmailPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B62', '请输入正确的邮箱') /* "请输入正确的邮箱" */,
  nextStep: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B63', '下一步') /* "下一步" */,
  existingMobileNumRegisterPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B64', '该手机号已经注册，请勿重复注册！') /* "该手机号已经注册，请勿重复注册！" */,
  sentSuccessfully: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B65', '发送成功！') /* "发送成功！" */,
  sentSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B66', '发送成功') /* "发送成功" */,
  UFCloudPlatformServiceAgreement: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B67', '《用友云平台服务协议》') /* "《用友云平台服务协议》" */,
  UFCloudPrivacyPolicy: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B68', '《用友云隐私条款》') /* "《用友云隐私条款》" */,
  haveReadAndAcceptPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B69', '我已阅读并接受') /* "我已阅读并接受" */,
  pwdLenLessThanSixPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B6A', '密码长度不能低于6位') /* "密码长度不能低于6位" */,
  corpEngNameErrorPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B6B', '公司英文名称不能有特殊字符且长度不大于40位') /* "公司英文名称不能有特殊字符且长度不大于40位" */,
  pleaseEnterCheckCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B6C', '请输入校验码') /* "请输入校验码" */,
  pleaseAgreeToAgreement: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B6D', '请同意协议') /* "请同意协议" */,
  setAsDefaultPaymentBank: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800032', '设置为付款默认银行') /* "设置为付款默认银行" */,
  exceptAgentEditArchive: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B6E', '例外客户') /* "例外客户" */,
  pleaseSetDefaultPrintTemplate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B6F', '请设置默认打印模板') /* "请设置默认打印模板" */,
  recommendPro: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B70', '推荐商品') /* "推荐商品" */,
  allPro: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B71', '全部商品') /* "全部商品" */,
  top: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B72', '顶部') /* "顶部" */,
  toBeConfirm: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B73', '待确认') /* "待确认" */,
  toBeDelivery: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B74', '待发货') /* "待发货" */,
  toBePay: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B75', '待支付') /* "待支付" */,
  toBeGoods: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B76', '待收货') /* "待收货" */,
  thisWeek: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B77', '本周') /* "本周" */,
  thisMounth: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B78', '本月') /* "本月" */,
  mustGreaterThanMinOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B79', '购买数量应大于最小起订量！') /* "购买数量应大于最小起订量！" */,
  searchPlaceholdre: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B7A', '商品名称/编码/条形码') /* "商品名称/编码/条形码" */,
  quickOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B7B', '快速下单') /* "快速下单" */,
  cart: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B7C', '购物车') /* "购物车" */,
  country: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B7D', '国家') /* "国家" */,
  province: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B7E', '省份') /* "省份" */,
  city: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B7F', '城市') /* "城市" */,
  area: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B80', '县区') /* "县区" */,
  china: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B81', '中国') /* "中国" */,
  citySelectPlaceHolder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B82', '请选择地区') /* "请选择地区" */,
  clickSelectProductSpecifications: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B83', '点击选择商品规格') /* "点击选择商品规格" */,
  clickSelectProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B84', '点击选择商品') /* "点击选择商品" */,
  changeGiveWayProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B85', '更换赠品') /* "更换赠品" */,
  buyQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B86', '购买数量') /* "购买数量" */,
  buyMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B87', '购买金额') /* "购买金额" */,
  maxPreQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B88', '最大优惠数量') /* "最大优惠数量" */,
  rebateNo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B89', '费用单号') /* "费用单号" */,
  singlediscountshow: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B8A', '整单优惠') /* "整单优惠" */,
  productpromotion: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B8B', '商品促销') /* "商品促销" */,
  zuhexiaoshou: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B8C', '组合销售') /* "组合销售" */,
  allProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B8D', '所有商品') /* "所有商品" */,
  partProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B8E', '部分商品') /* "部分商品" */,
  dazhe: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF2', '打折') /* "打折" */,
  jianjia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AF3', '减价') /* "减价" */,
  zengpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B8F', '赠品') /* "赠品" */,
  dazhehuodong: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B90', '打折活动') /* "打折活动" */,
  jianjiahuodong: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B91', '减价活动') /* "减价活动" */,
  zengsongshangpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B92', '赠送商品') /* "赠送商品" */,
  jiliangdanwei: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B93', '计量单位') /* "计量单位" */,
  commonProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B94', '普通商品') /* "普通商品" */,
  originalProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B95', '原品') /* "原品" */,
  wholeElection: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B96', '全场任选') /* "全场任选" */,
  onlySelectOneProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B97', '只能选择一个商品') /* "只能选择一个商品" */,
  addCondition: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B98', '添加条件') /* "添加条件" */,
  shangpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A3B', '商品') /* "商品" */,
  shangjia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B99', '上架') /* "上架" */,
  weishangjia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B9A', '未上架') /* "未上架" */,
  jigoumingcheng: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B9B', '机构名称') /* "机构名称" */,
  stockCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B9C', '仓库编码') /* "仓库编码" */,
  stockErpCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B9D', '仓库ERP编码') /* "仓库ERP编码" */,
  jiaoyileixingName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B9E', '交易类型名称') /* "交易类型名称" */,
  jiaoyileixingCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B9F', '交易类型编码') /* "交易类型编码" */,
  jiaoyileixingErpCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA0', '交易类型ERP编码') /* "交易类型ERP编码" */,
  sureDelAuth: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA1', '确认要清空权限么?') /* "确认要清空权限么?" */,
  leftTreeStatusLoadFail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA2', '权限树状态更新失败') /* "权限树状态更新失败" */,
  sureAbandonAudit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA3', '确定要放弃审核么?') /* "确定要放弃审核么?" */,
  sureAudit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA4', '确定要审核么?') /* "确定要审核么?" */,
  sureDelete: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA5', '确定要删除么?') /* "确定要删除么?" */,
  geshi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA6', '格式') /* "格式" */,
  comprehisive: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA7', '综合') /* "综合" */,
  wenjiandaxiaobuchaoguo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA8', '文件大小：不超过') /* "文件大小：不超过" */,
  zongwenjianbuchaoguo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BA9', '总文件：不超过') /* "总文件：不超过" */,
  overFileLimitSize: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BAA', '超过文件大小限制') /* "超过文件大小限制" */,
  fen: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BAB', '份') /* "份" */,
  reSelect: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BAC', '重新选择') /* "重新选择" */,
  tooBig: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BAD', '太大') /* "太大" */,
  cantover: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BAE', '不能超过') /* "不能超过" */,
  maxUpload: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BAF', '最多上传') /* "最多上传" */,
  uploadError: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB0', '上传失败') /* "上传失败" */,
  fileFormatError: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB1', '文件格式错误') /* "文件格式错误" */,
  errorFormatqingchongxuan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB2', '格式不正确，请重新选择') /* "格式不正确，请重新选择" */,
  uploading: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB3', '正在上传...') /* "正在上传..." */,
  intoPrintTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB4', '进入打印功能 ...') /* "进入打印功能 ..." */,
  verifyAccount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB5', '账号验证') /* "账号验证" */,
  waitForSubmit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB6', '待提交') /* "待提交" */,
  title,
  show: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB7', '展示') /* "展示" */,
  bifu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB8', '¥') /* "¥" */,
  leaveMsg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BB9', '留言') /* "留言" */,
  switchText: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BBA', '订') /* "订" */, //  window.getLocaleMessage('MC_UOrder_agent_newOrder_002477'),
  productGroup: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BBB', '商品分组') /* "商品分组" */,
  productGroupName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BBC', '商品分组名称') /* "商品分组名称" */,
  enableContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BBD', '确定执行整单生效?') /* "确定执行整单生效?" */,
  operationSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008E2', '操作成功') /* "操作成功" */,
  item: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000989', '条') /* "条" */,
  delContent2: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000898', '删除') /* "删除" */,
  editContent2: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D2', '编辑') /* "编辑" */,
  effectContent2: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A3A', '生效') /* "生效" */,
  jinruguanliduan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BBE', '进入管理端') /* "进入管理端" */,
  switchAgent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BBF', '切换客户') /* "切换客户" */,
  handleBatchOperationWarning: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC0', '请至少选择一条数据！') /* "请至少选择一条数据！" */,
  phoneNo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B17', '手机号') /* "手机号" */,
  allowedAddressIncompleteTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC1', '国家、省、市、区需填写对应的值') /* "国家、省、市、区需填写对应的值" */,
  shelves: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B99', '上架') /* "上架" */,
  notShelves: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC2', '下架') /* "下架" */,
  xiandingshuliang: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC3', '限定数量') /* "限定数量" */,
  ge: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC4', '个') /* "个" */,
  again: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC5', '再次') /* "再次" */,
  renew: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC6', '重新') /* "重新" */,
  newPwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC7', '新密码') /* "新密码" */,
  loginAgain: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800024', '重新登录') /* "重新登录" */,
  resetSuccessful: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800026', '登录成功啦！') /* "登录成功啦！" */,
  pleaseRememberNewLoginPwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC8', '请牢记新的登录密码！') /* "请牢记新的登录密码！" */,
  district: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BC9', '区域') /* "区域" */,
  productDetails: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BCA', '商品明细') /* "商品明细" */,
  personal: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BCB', '个人') /* "个人" */,
  truncation: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BCC', '舍位') /* "舍位" */,
  entry: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BCD', '入位') /* "入位" */,
  rounding: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BCE', '四舍五入') /* "四舍五入" */,
  weixiaoshu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BCF', '位小数') /* "位小数" */,
  currencyAbbr: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD0', '币种简称') /* "币种简称" */,
  currencyFieldName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD1', '币种') /* "币种" */,
  disEnableContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C6', '停用') /* "停用" */,
  directOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD2', '直接下单') /* "直接下单" */,
  species: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD3', '种类') /* "种类" */,
  quantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD4', '计价数量') /* "计价数量" */,
  quantityUnitName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD5', '计价数量单位') /* "计价数量单位" */,
  auxiliary: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD6', '销售数量') /* "销售数量" */,
  auxiliaryUnitName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD7', '销售数量单位') /* "销售数量单位" */,
  jichubaojia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD8', '基础报价') /* "基础报价" */,
  jichubaojiajine: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD9', '基础报价金额') /* "基础报价金额" */,
  baojiazhekou: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BDA', '报价扣率') /* "报价扣率" */,
  baojiakoue: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BDB', '报价扣额') /* "报价扣额" */,
  youhui: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BDC', '优惠') /* "优惠" */,
  hanshuijine: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BDD', '含税金额') /* "含税金额" */,
  baojiahanshuijine: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BDE', '报价含税金额') /* "报价含税金额" */,
  baojiawushuijine: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BDE', '报价含税金额') /* "报价含税金额" */,
  wushuijine: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BDF', '无税金额') /* "无税金额" */,
  shuie: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE0', '税额') /* "税额" */,
  actualPaymentAmount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE1', '实付金额') /* "实付金额" */,
  lijidenglu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE2', '立即登录') /* "立即登录" */,
  lijitijiao: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE3', '立即提交') /* "立即提交" */,
  amount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000928', '金额') /* "金额" */,
  dingdan: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE4', '订单') /* "订单" */,
  dixian: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE5', '抵现') /* "抵现" */,
  dixianfanli: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE6', '抵现费用') /* "抵现费用" */,
  commodity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE7', '现货') /* "现货" */,
  presell: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A25', '预售') /* "预售" */,
  package: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE8', '套餐') /* "套餐" */,
  packageNum: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE9', '套餐数量') /* "套餐数量" */,
  checkedNum: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BEA', '已选数量') /* "已选数量" */,
  packageSaleprice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BEB', '套餐价') /* "套餐价" */,
  pack_order: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BEC', '套餐名称') /* "套餐名称" */,
  modifyPackage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BED', '修改套餐') /* "修改套餐" */,
  deletePackageTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BEE', '确定删除该组合吗?') /* "确定删除该组合吗?" */,
  deletePackage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BEF', '删除套餐') /* "删除套餐" */,
  preferential: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF0', '折优惠') /* "折优惠" */,
  packagePrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF1', '套餐减价') /* "套餐减价" */,
  endActiveity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF2', '活动结束') /* "活动结束" */,
  expect: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF3', '预计') /* "预计" */,
  startDelivery: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF4', '开始发货') /* "开始发货" */,
  deliveryDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF5', '发货日') /* "发货日" */,
  endDelivery: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF6', '天后结束发货') /* "天后结束发货" */,
  play: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009D0', '打') /* "打" */,
  productBarCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF7', '商品条形码') /* "商品条形码" */,
  sales: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF8', '销量') /* "销量" */,
  limit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BF9', '限购') /* "限购" */,
  presaleAct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BFA', '预售活动') /* "预售活动" */,
  activityInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BFB', '活动信息') /* "活动信息" */,
  priceSteps: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BFC', '价格阶梯') /* "价格阶梯" */,
  inventory: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BFD', '库存') /* "库存" */,
  minimumQuatity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BFE', '起订量') /* "起订量" */,
  multiplier: lang.templateByUuid('UID:P_UDHWN_1908A95805800003', '倍量') /* "倍量" */,
  originalPrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BFF', '原价') /* "原价" */,
  orderImmediatly: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C00', '立即订购') /* "立即订购" */,
  productDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C01', '商品详情') /* "商品详情" */,
  productSpecs: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C02', '商品参数') /* "商品参数" */,
  goodsAccessories: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C03', '商品附件') /* "商品附件" */,
  preferentialPrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C04', '优惠价') /* "优惠价" */,
  spare: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C05', '节省') /* "节省" */,
  addShoppingCart: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C06', '加入购物车') /* "加入购物车" */,
  collected: lang.templateByUuid('UID:P_UDHWN_1835FC2804E0000B', '已收藏') /* "已收藏" */,
  collect: lang.templateByUuid('UID:P_UDHWN_1835FC2804E0000C', '收藏') /* "收藏" */,
  viewPaymentDetails: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C07', '查看支付明细') /* "查看支付明细" */,
  imageShow: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C08', '显示图片') /* "显示图片" */,
  saleReturnEdit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C09', '修改') /* "修改" */,
  saleReturnInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C0A', '退货信息') /* "退货信息" */,
  deletecONFIRM: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C0B', '确认要删除此退货单吗') /* "确认要删除此退货单吗" */,
  saleOrderReturn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A30', '退货') /* "退货" */,
  alreadySentReturn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000939', '确认已经发出该退货单的货物吗?') /* "确认已经发出该退货单的货物吗?" */,
  waitForSignIn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C0C', '货物已发出，等待签收确认!') /* "货物已发出，等待签收确认!" */,
  returnStock: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C0D', '退货仓库') /* "退货仓库" */,
  receivingStock: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C0E', '收货仓库') /* "收货仓库" */,
  setreturnStock: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580002D', '设置退货仓库') /* "设置退货仓库" */,
  noSelectReturnStock: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C0F', '请先选择退货仓库！') /* "请先选择退货仓库！" */,
  setReceivingStock: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580002F', '设置收货仓库') /* "设置收货仓库" */,
  noSelectReceivingStock: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C10', '请先选择收货仓库！') /* "请先选择收货仓库！" */,
  pleaseSetStockFiles: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C11', '当前已启用分仓，请先设置仓库档案！') /* "当前已启用分仓，请先设置仓库档案！" */,
  addOrderReturn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C12', '新增退货单') /* "新增退货单" */,
  realTimePromotion: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C13', '实时促销') /* "实时促销" */,
  wholeOrderOfferForAmount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C14', '整单优惠(金额类)') /* "整单优惠(金额类)" */,
  wholeOrderOfferForCommodity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C15', '整单优惠(商品类)') /* "整单优惠(商品类)" */,
  template: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C16', '模板') /* "模板" */,
  actual: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C17', '实际') /* "实际" */,
  modify: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C09', '修改') /* "修改" */,
  taojianshangpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C18', '套件商品') /* "套件商品" */,
  PTOProducts: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C19', 'PTO商品') /* "PTO商品" */,
  crossTokenErrorMessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C1A', '检测到用户登录身份发现变化，新的身份无法继续使用订货门户业务，请退出其他友户通应用或者更换另外的浏览器后再操作！') /* "检测到用户登录身份发现变化，新的身份无法继续使用订货门户业务，请退出其他友户通应用或者更换另外的浏览器后再操作！" */,
  quickSearchProdPlaceHolder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C1B', '输入商品编码/名称查询') /* "输入商品编码/名称查询" */,
  quickSearchTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B84', '点击选择商品') /* "点击选择商品" */,
  quickSearchNoProdTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C1C', '查找不到数据，请重新输入商品编码/名称') /* "查找不到数据，请重新输入商品编码/名称" */,
  nonReceiptInfoTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C1D', '请填写收货信息!') /* "请填写收货信息!" */,
  expectedReceiptDateNotLessThanOrderDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C1E', '期望收货日期不能小于订单日期') /* "期望收货日期不能小于订单日期" */,
  expectedReceiptDateNotLessThanEstimatedDeliveryDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C1F', '期望收货日期不能小于预计发货日期') /* "期望收货日期不能小于预计发货日期" */,
  maxProductCountTipsA: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C20', '订单最多支持') /* "订单最多支持" */,
  maxProductCountTipsB: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C21', '条商品,请重新调整！') /* "条商品,请重新调整！" */,
  quantityExceedSKULimit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C22', '数量超过了SKU限购量') /* "数量超过了SKU限购量" */,
  quantityExceedLimit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C23', '数量超过限购量') /* "数量超过限购量" */,
  editContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008D2', '编辑') /* "编辑" */,
  deleListInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000898', '删除') /* "删除" */,
  time: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C24', '时间') /* "时间" */,
  lianxikefu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C25', '联系客服') /* "联系客服" */,
  youhuifangshi: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C26', '优惠方式') /* "优惠方式" */,
  disableCorrespondingSalesman: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C27', '是否要停用对应业务员') /* "是否要停用对应业务员" */,
  enableCorrespondingSalesman: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C28', '是否要启用对应业务员') /* "是否要启用对应业务员" */,
  disabledCorrespondingPersonnel: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C29', '是否要停用对应人员') /* "是否要停用对应人员" */,
  enabledCorrespondingPersonnel: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C2A', '是否要启用对应人员') /* "是否要启用对应人员" */,
  wxapiTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C2B', '是否作为微信公众号API交互默认配置') /* "是否作为微信公众号API交互默认配置" */,
  switchToEmail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C2C', '账号登录') /* "账号登录" */,
  mobilLogin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C2D', '手机登录') /* "手机登录" */,
  switchToPhone: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C2E', '短信快捷登录') /* "短信快捷登录" */,
  accountmobile: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B17', '手机号') /* "手机号" */,
  weChat: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C2F', '微信') /* "微信" */,
  qq: 'QQ',
  otherContactOne: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C30', '其他联系信息1') /* "其他联系信息1" */,
  otherContactTwo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C31', '其他联系信息2') /* "其他联系信息2" */,
  resend: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C32', 's后重发') /* "s后重发" */,
  pleasePhone: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C33', '请输入手机号！') /* "请输入手机号！" */,
  accountEmail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C34', '账号/邮箱') /* "账号/邮箱" */,
  validCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA1', '验证码') /* "验证码" */,
  allBrand: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C35', '所有品牌') /* "所有品牌" */,
  loginToPwd: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800025', '密码登录') /* "密码登录" */,
  loginToPhone: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800027', '短信验证码登录') /* "短信验证码登录" */,
  pleaseSelectedWithin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C36', '请选择套餐内商品') /* "请选择套餐内商品" */,
  signInMustNotNull: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C37', '请正确设置签收数量！') /* "请正确设置签收数量！" */,
  setOrder: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580002A', '套起订') /* "套起订" */,
  buy: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C38', '购买') /* "购买" */,
  set: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580002B', '套') /* "套" */,
  sureModify: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C39', '确认修改') /* "确认修改" */,
  canSelected: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C3A', '可选') /* "可选" */,
  unitPrice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C3B', '单价') /* "单价" */,
  canUseNum: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C3C', '可选商品数量') /* "可选商品数量" */,
  manyChooseOne: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C3D', '可以任选') /* "可以任选" */,
  denoUnit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD4', '计价数量') /* "计价数量" */,
  auxUnit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD6', '销售数量') /* "销售数量" */,
  littleAmount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C3E', '金额小计') /* "金额小计" */,
  canSelectedTip1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C3F', '所选计价数量与可选计价数量不相等(需选') /* "所选计价数量与可选计价数量不相等(需选" */,
  thisGroupProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C40', '该组合商品') /* "该组合商品" */,
  thisPackage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C41', '该套餐') /* "该套餐" */,
  pleaseSelectedCount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C42', '请设置组合计价数量') /* "请设置组合计价数量" */,
  ableContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008C0', '启用') /* "启用" */,
  addOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C43', '新增订单') /* "新增订单" */,
  retrievePwdByAccount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C44', '账号找回密码') /* "账号找回密码" */,
  updateSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C45', '修改成功') /* "修改成功" */,
  nearly1weeks: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C46', '近一周') /* "近一周" */,
  nearly1months: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C47', '近一月') /* "近一月" */,
  nearly2months: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C48', '近两月') /* "近两月" */,
  nearly3months: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C49', '近三月') /* "近三月" */,
  nearly6months: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C4A', '近半年') /* "近半年" */,
  nearly1year: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C4B', '近一年') /* "近一年" */,
  bizErpCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C4C', '商家商品ERP编码') /* "商家商品ERP编码" */,
  channelAgentRank: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C4D', '渠道客户等级') /* "渠道客户等级" */,
  channelAgentLevel: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C4E', '渠道客户级别') /* "渠道客户级别" */,
  channelAgentCategory: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C4F', '渠道客户分类') /* "渠道客户分类" */,
  channelAgentArea: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C50', '渠道客户区域') /* "渠道客户区域" */,
  channelAgentGroup: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C51', '渠道客户分组') /* "渠道客户分组" */,
  defaultPCTemplate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C52', '电脑默认') /* "电脑默认" */,
  defaultAppTemplate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C53', '手机默认') /* "手机默认" */,
  setPCDefault: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800017', '设置电脑默认') /* "设置电脑默认" */,
  setAPPDefault: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800019', '设置手机默认') /* "设置手机默认" */,
  setPCAgentDefault: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580001B', '设置订货端默认') /* "设置订货端默认" */,
  pleaseSelectCurreny: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C54', '请先选择币种') /* "请先选择币种" */,
  accountTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C55', '互联网账号存在被盗风险，建议您定期修改密码以保护账号安全') /* "互联网账号存在被盗风险，建议您定期修改密码以保护账号安全" */,
  noAddMobileRootMenu: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C56', '移动端不能新增根级目录！') /* "移动端不能新增根级目录！" */,
  update: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C09', '修改') /* "修改" */,
  openDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AFD', '开通时间') /* "开通时间" */,
  deadline: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AFE', '到期时间') /* "到期时间" */,
  useNumber: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C57', '客户规格') /* "客户规格" */,
  bizModeName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C58', '租户类型') /* "租户类型" */,
  valueAddService: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B01', '增值服务') /* "增值服务" */,
  serviceName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B02', '服务名称') /* "服务名称" */,
  activeSuccesslly: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A85', '激活成功！') /* "激活成功！" */,
  active: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C59', '激活') /* "激活" */,
  activationCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A67', '激活码') /* "激活码" */,
  signUpForFastPayment: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C5A', '签约快捷支付') /* "签约快捷支付" */,
  PleaseEnterVerificationCodeCorrectly: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C5B', '请正确填写验证码!') /* "请正确填写验证码!" */,
  firstTimeSignUpForFastPaymentTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C5C', '您还没有可以快捷支付的银行卡，请添加！') /* "您还没有可以快捷支付的银行卡，请添加！" */,
  IDCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C5D', '身份证') /* "身份证" */,
  MilitaryOfficerCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C5E', '军官证') /* "军官证" */,
  Passport: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C5F', '护照') /* "护照" */,
  HouseholdRegister: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C60', '户口簿') /* "户口簿" */,
  SoldierCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C61', '士兵证') /* "士兵证" */,
  HongKongAndMacauInlandPass: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C62', '港澳来往内陆通行证') /* "港澳来往内陆通行证" */,
  TaiwanCompatriotPassToMainland: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C63', '台湾同胞来往大陆通行证') /* "台湾同胞来往大陆通行证" */,
  TemporaryIDCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C64', '临时身份证') /* "临时身份证" */,
  ForeignResidentCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C65', '外国人居留证') /* "外国人居留证" */,
  PoliceOfficerCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C66', '警官证') /* "警官证" */,
  Others: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AC6', '其他') /* "其他" */,
  SavingsCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C67', '储蓄卡') /* "储蓄卡" */,
  CreditCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C68', '信用卡') /* "信用卡" */,
  QuasiCreditCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C69', '准贷记卡') /* "准贷记卡" */,
  PrepaidCard: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C6A', '预付费卡') /* "预付费卡" */,
  sign: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C6B', '签约') /* "签约" */,
  hasActivationCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C6C', '已有激活码？') /* "已有激活码？" */,
  hasNodeActivationCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A71', '没有激活码？') /* "没有激活码？" */,
  pleaseTomarketCloudOrbusiness: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C6D', '请到云市场或云商务') /* "请到云市场或云商务"" */,
  queryActivationCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C6E', '中查询激活码。') /* ""中查询激活码。" */,
  getActivationCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C6F', '，即可获取激活码。') /* ""，即可获取激活码。" */,
  buyProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A73', '购买产品') /* "购买产品" */,
  verificationCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AA1', '验证码') /* "验证码" */,
  pleaseInput: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008AE', '请输入') /* "请输入" */,
  clickChange: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C70', '点击更换') /* "点击更换" */,
  validPhone: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C71', '手机验证') /* "手机验证" */,
  validEmail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C72', '邮箱验证') /* "邮箱验证" */,
  validPhoneTip1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C73', '您验证的手机号是：') /* "您验证的手机号是：" */,
  validPhoneTip2: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C74', '您还未验证手机！') /* "您还未验证手机！" */,
  immediatlyVerify: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C75', '立即验证') /* "立即验证" */,
  validEmailTip1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C76', '您验证的邮箱是：') /* "您验证的邮箱是：" */,
  validEmailTip2: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C77', '您还未验证邮箱！') /* "您还未验证邮箱！" */,
  verify: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C78', '验证') /* "验证" */,
  pleaseEnterOldPwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C79', '请输入原密码') /* "请输入原密码" */,
  pleaseEnterConfirmPwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C7A', '请输入确认密码') /* "请输入确认密码" */,
  oldPwd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C7B', '原密码') /* "原密码" */,
  retrievePwdByPhone: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C7C', '手机号找回密码') /* "手机号找回密码" */,
  orderList: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE4', '订单') /* "订单" */,
  receivingNote: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C7D', '收货单') /* "收货单" */,
  expenseNote: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C7E', '费用单') /* "费用单" */,
  returnNotes: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C7F', '退货单') /* "退货单" */,
  signNotes: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C80', '签收单') /* "签收单" */,
  pleaseEnterVerifyCodeFirst: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C81', '请先输入验证码') /* "请先输入验证码" */,
  sendingTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C82', '正在发送...') /* "正在发送..." */,
  secondsToRegain: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C83', '秒后重获') /* "秒后重获" */,
  editHeadImg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C84', '编辑头像') /* "编辑头像" */,
  bankAccountLengthPrompt: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C85', '银行账号长度为9—25位数字!') /* "银行账号长度为9—25位数字!" */,
  leftAndRightNeedComplete: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C86', '左右范围需填写完整！') /* "左右范围需填写完整！" */,
  endDateTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C87', '超过该时间以后本分组不再更新，包括客户档案的增删改变化不再影响分组包含的客户，分组本身不可修改') /* "超过该时间以后本分组不再更新，包括客户档案的增删改变化不再影响分组包含的客户，分组本身不可修改" */,
  endDateProductTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C88', '超过该时间以后本分组不再更新，包括商品档案的增删改变化不再影响分组包含的商品，分组本身不可修改') /* "超过该时间以后本分组不再更新，包括商品档案的增删改变化不再影响分组包含的商品，分组本身不可修改" */,
  addMainBankConfig: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C89', '新增银行') /* "新增银行" */,
  addSubBankConfig: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C8A', '新增支行') /* "新增支行" */,
  sureCleaAll: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C8B', '确定全部删除吗？') /* "确定全部删除吗？" */,
  notHasDefaultPrintContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C8C', '没有设置默认打印模板，将不会自动打印本次发货单。如需打印这些发货单，您可以到【销售管理-发货单】中操作。') /* "没有设置默认打印模板，将不会自动打印本次发货单。如需打印这些发货单，您可以到【销售管理-发货单】中操作。" */,
  rejectTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C8D', '请填写驳回意见，填写完成之后将被自动关闭!') /* "请填写驳回意见，填写完成之后将被自动关闭!" */,
  rejectTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C8E', '驳回原因') /* "驳回原因" */,
  rejectReason: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C8F', '请填写驳回原因') /* "请填写驳回原因" */,
  delContentBiz: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C90', '删除商家操作仅删除其商家职能，删除后对应账号可作为普通客户进行购买相关行为。您是否确定删除商家') /* "删除商家操作仅删除其商家职能，删除后对应账号可作为普通客户进行购买相关行为。您是否确定删除商家" */,
  // 批量操作
  MarkAsRead: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C91', '一键已读') /* "一键已读" */,
  markAsReadConfirmTitleC: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C92', '是否将全部消息标记为已读') /* "是否将全部消息标记为已读" */,
  handleBatchClickWarning: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C93', '请选择需要批量操作的消息') /* "请选择需要批量操作的消息" */,
  handleReadBatchClickWarning: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C94', '请选择至少一条未读的消息') /* "请选择至少一条未读的消息" */,
  markAsReadConfirmTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C95', '是否将选中的消息标记为已读') /* "是否将选中的消息标记为已读" */,
  markAsReadConfirmTitleA: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C96', '是否将选中的') /* "是否将选中的" */,
  markAsReadConfirmTitleB: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C97', '条消息标记为已读') /* "条消息标记为已读" */,
  batchDelConfirmTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C98', '您确定要删除选中的数据吗?') /* "您确定要删除选中的数据吗?" */,
  markSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C99', '消息已读标记成功') /* "消息已读标记成功" */,
  // 时间查询的条件
  nearlyOneMonthMessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C9A', '近一个月消息') /* "近一个月消息" */,
  oneMonthAgoMessage: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C9B', '一个月以前消息') /* "一个月以前消息" */,
  buyAgain: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A31', '再次购买') /* "再次购买" */,
  receiveAndConfirm: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C9C', '收货确认') /* "收货确认" */,
  confirmAndReceive: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C9D', '确认收货') /* "确认收货" */,
  signable: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C9E', '可签收') /* "可签收" */,
  receivable: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000C9F', '可收货') /* "可收货" */,
  comment: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA0', '评价') /* "评价" */,
  submit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA1', '提交') /* "提交" */,
  approvalflow: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA2', '审批流') /* "审批流" */,
  withdrawflow: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA3', '撤回') /* "撤回" */,
  payBatch: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA4', '批量付款') /* "批量付款" */,
  receiveStoreInfo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA5', '门店信息') /* "门店信息" */,
  receiveStoreName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA6', '门店名称') /* "门店名称" */,
  storeAddress: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA7', '门店地址') /* "门店地址" */,
  storeCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA8', '门店编码') /* "门店编码" */,
  address: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA9', '地址') /* "地址" */,
  moreaddress: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CAA', '更多地址') /* "更多地址" */,
  receiver: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CAB', '收货人') /* "收货人" */,
  zipCode: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CAC', '邮编') /* "邮编" */,
  moreivocice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CAD', '更多发票') /* "更多发票" */,
  invoiceType: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CAE', '发票类型') /* "发票类型" */,
  invoiceTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CAF', '发票抬头') /* "发票抬头" */,
  invoiceTitleType: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB0', '发票抬头类型') /* "发票抬头类型" */,
  invoiceContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB1', '发票内容') /* "发票内容" */,
  TIN: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB2', '纳税人识别号') /* "纳税人识别号" */,
  accountOpeningBank: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB3', '开户行') /* "开户行" */,
  businessPhone: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB4', '营业电话') /* "营业电话" */,
  businessAddress: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB5', '营业地址') /* "营业地址" */,
  invoice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB6', '发票') /* "发票" */,
  shippingAddrSearchPlaceHolder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB7', '收货人姓名/手机号/地址') /* "收货人姓名/手机号/地址" */,
  storeShippingAddrSearchPlaceHolder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CA6', '门店名称') /* "门店名称" */,
  FailedtoObtainGroupInfoBasedOnGroupID: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB8', '根据分组ID获取分组信息失败！') /* "根据分组ID获取分组信息失败！" */,
  notFindInvoice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CB9', '未找到电子发票信息') /* "未找到电子发票信息" */,
  automaticUse: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CBA', '自动使用') /* "自动使用" */,
  fanlijine: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CBB', '费用金额') /* "费用金额" */,
  xiandingjine: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CBC', '限定金额') /* "限定金额" */,
  availableBalance: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CBD', '可用余额') /* "可用余额" */,
  moneyUsedThisTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CBE', '本次使用金额') /* "本次使用金额" */,
  useWay: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CBF', '应用方式') /* "应用方式" */,
  rebateUnit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC0', '费用单位') /* "费用单位" */,
  rebateAmount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC1', '本次费用数量') /* "本次费用数量" */,
  nowUse: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC2', '本次使用量') /* "本次使用量" */,
  availableRebateAmount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC3', '可用费用数量') /* "可用费用数量" */,
  availableRebate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC4', '可用费用量') /* "可用费用量" */,
  availableRebateMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC5', '可用费用金额') /* "可用费用金额" */,
  productRebate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC6', '商品类费用') /* "商品类费用" */,
  cashAndDiscountRebate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC7', '现金/折扣类费用') /* "现金/折扣类费用" */,
  rebateMoneyExceededAvailableRebateMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC8', '费用金额已超出可用费用金额') /* "费用金额已超出可用费用金额" */,
  afterRebateMoneyExceededAvailableRebateMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CC9', '，请重新填写！') /* "，请重新填写！" */,
  rebateMoneyGreaterThanOrderMoneyTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CCA', '费用金额大于订单金额，请重新选择！') /* "费用金额大于订单金额，请重新选择！" */,
  telephoneIllegalTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CCB', '营业电话不合法!') /* "营业电话不合法!" */,
  phoneNumberIllegalTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CCC', '手机号不合法') /* "手机号不合法" */,
  rebateMoneyExceededpayMoneyTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CCD', '费用金额已超出实付金额，请重新填写！') /* "费用金额已超出实付金额，请重新填写！" */,
  rebateMoneyExceededBalanceTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CCE', '费用金额已超余额，请重新填写！') /* "费用金额已超余额，请重新填写！" */,
  apportionRebateMoneyExceededMaximumAmountOfRebateForOrderTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CCF', '分摊费用金额已超过订单最大可分摊费用金额，请重新填写！') /* "分摊费用金额已超过订单最大可分摊费用金额，请重新填写！" */,
  cutoff: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD0', '截止') /* "截止" */,
  shouldpay: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD1', '应付整单总金额的') /* "应付整单总金额的" */,
  orAutoRturn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD2', '否则订单自动驳回') /* "否则订单自动驳回" */,
  watchInvoice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD3', '查发票') /* "查发票" */,
  notDeliver: 'deliveyNotAudit',
  hasReceived: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD4', '已收货') /* "已收货" */,
  systemConfirm: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD5', '未及时收货，由系统确认') /* "未及时收货，由系统确认" */,
  hasSent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD6', '已发货') /* "已发货" */,
  deliveyNotAudit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD7', '发货待审') /* "发货待审" */,
  batchPayTipOne: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD8', '您对') /* "您对" */,
  batchPayTipTwo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CD9', '张订单进行批量支付，总额:') /* "张订单进行批量支付，总额:" */,
  batchPayTipThree: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CDA', ',是否进行支付?') /* ",是否进行支付?" */,
  stockNotEoughForEx: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CDB', '订单中某些商品库存不足，如:') /* "订单中某些商品库存不足，如:" */,
  yourCreditTipA: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CDC', '您的信用额度是:') /* "您的信用额度是:" */,
  surplusTipB: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CDD', ',当前余额是:') /* ",当前余额是:" */,
  subSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CDE', '提交成功') /* "提交成功" */,
  continueSubmitTipC: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CDF', ',该订单提交会超出信用余额，是否仍然提交该订单？') /* ",该订单提交会超出信用余额，是否仍然提交该订单？" */,
  continueConfirmTipC: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE0', ',该订单确认会超出信用余额，是否仍然确认该订单？') /* ",该订单确认会超出信用余额，是否仍然确认该订单？" */,
  sureToConfrim: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE1', '确定要确认订单') /* "确定要确认订单" */,
  creditBalance: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE2', '信用余额') /* "信用余额" */,
  askEnd: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE3', '吗？') /* "吗？" */,
  useRebateDiscount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE4', '使用费用单折扣') /* "使用费用单折扣" */,
  mostUsableRebateMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE5', '最多可使用费用金额') /* "最多可使用费用金额" */,
  afterMostUsableRebateMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE6', '，其中最多可折扣') /* "，其中最多可折扣" */,
  thisTimeMostAvailableDiscountRebateMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE7', '本次最多可用折扣费用') /* "本次最多可用折扣费用" */,
  mostAvailableCashRebateMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE8', '最多可用抵现费用') /* "最多可用抵现费用" */,
  discountPoints: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CE9', '积分') /* "积分" */,
  avaliablePoints: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CEA', '可用积分') /* "可用积分" */,
  useRebateDeDuction: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CEB', '使用费用单抵扣') /* "使用费用单抵扣" */,
  pointDeduction: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CEC', '积分抵扣') /* "积分抵扣" */,
  couponDeduction: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CED', '优惠券抵扣') /* "优惠券抵扣" */,
  deduction: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CEE', '抵扣') /* "抵扣" */,
  cashDeduction: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BE5', '抵现') /* "抵现" */,
  pointsBalance: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CEF', '积分余额') /* "积分余额" */,
  usedThisTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF0', '本次使用') /* "本次使用" */,
  orderMoneyNotReached: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF1', '订单金额未达到') /* "订单金额未达到" */,
  notUseRebate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF2', '不可使用费用') /* "不可使用费用" */,
  satisfy: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009CF', '满') /* "满" */,
  minus: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009C6', '减') /* "减" */,
  usedDeduction: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF3', '抵用') /* "抵用" */,
  rebateDetailTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF4', '费用使用规则详情') /* "费用使用规则详情" */,
  getcExpenseOrderNoError: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF5', '获取费用单号失败！') /* "获取费用单号失败！" */,
  expenseOrderNo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B89', '费用单号') /* "费用单号" */,
  payStatus: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF6', '付款状态') /* "付款状态" */,
  giveway: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B8F', '赠品') /* "赠品" */,
  policy: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF7', '政策') /* "政策" */,
  actuallyGivenGifts: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF8', '实赠商品') /* "实赠商品" */,
  UsedPreferentialMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CBE', '本次使用金额') /* "本次使用金额" */,
  UsedPreferentialNum: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CF9', '本次使用数量') /* "本次使用数量" */,
  notUsedPreferentialMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CFA', '剩余金额') /* "剩余金额" */,
  notUsedPreferentialNum: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CFB', '剩余数量') /* "剩余数量" */,
  policyNumOfGivenGifts: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CFC', '政策数量') /* "政策数量" */,
  policyMoneyOfGivenGifts: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CFD', '政策金额') /* "政策金额" */,
  actualNumOfGivenGifts: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CFE', '实赠数量') /* "实赠数量" */,
  actualMoneyOfGivenGifts: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CFF', '实赠金额') /* "实赠金额" */,
  usableNumOfGivenGifts: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D00', '可赠数量') /* "可赠数量" */,
  usableNumOfMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D01', '可赠金额') /* "可赠金额" */,
  allusableNumOfMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D02', '总共可享受赠品金额') /* "总共可享受赠品金额" */,
  allusableNumOfGivenGifts: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D03', '总共可享受赠品数量') /* "总共可享受赠品数量" */,
  noUpperLimit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D04', '上不封顶') /* "上不封顶" */,
  thisGradientCanGiveawayNum: lang.templateByUuid('UID:P_UDHWN_1818CEBE05B00003', '本梯度最大可赠数量') /* "本梯度最大可赠数量" */,
  thisGradientCanGiveawayMoney: lang.templateByUuid('UID:P_UDHWN_1818CEBE05B00004', '本梯度最大可赠金额') /* "本梯度最大可赠金额" */,
  quantityToBeMet: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D05', '满件') /* "满件" */,
  quantityToAccumulate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D06', '出库数量') /* "出库数量" */,
  moneyToAccumulate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D07', '出库金额') /* "出库金额" */,
  moneyToBeMet: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D08', '满额') /* "满额" */,
  yuanpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B95', '原品') /* "原品" */,
  selectProduct: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D09', '选择商品') /* "选择商品" */,
  productCanotUse: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D0A', '商品未上架不可用') /* "商品未上架不可用" */,
  selectDelivery: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D0B', '选择要执行退货的发货单') /* "选择要执行退货的发货单" */,
  ninhaiyou: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D0C', '您还有') /* "您还有" */,
  day: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D0D', '天') /* "天" */,
  hour: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D0E', '小时') /* "小时" */,
  minute: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D0F', '分钟') /* "分钟" */,
  second: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D10', '秒') /* "秒" */,
  agentFinancial: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D11', '汇款银行账户') /* "汇款银行账户" */,
  comSurereceive: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D12', '来确认收货,超时订单将自动确认收货') /* "来确认收货,超时订单将自动确认收货" */,
  mainClass: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D13', '主分类') /* "主分类" */,
  mainBrand: lang.templateByUuid('UID:P_UDHWN_1A822EF80588000D', '主品牌') /* "主品牌" */,
  purchaseMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B87', '购买金额') /* "购买金额" */,
  purchaseQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B86', '购买数量') /* "购买数量" */,
  maximumOfferQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B88', '最大优惠数量') /* "最大优惠数量" */,
  remainingOffers: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D14', '剩余优惠次数') /* "剩余优惠次数" */,
  selectGiveawayTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D15', '您可以只选择一种梯度，也可以搭配选择多种梯度进行折抵') /* "您可以只选择一种梯度，也可以搭配选择多种梯度进行折抵" */,
  giveawaysOverLimitTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D16', '赠品数量已超过限制') /* "赠品数量已超过限制" */,
  giveawaysOverLimitTips2: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D17', '只可选择一个梯度的赠品') /* "只可选择一个梯度的赠品" */,
  selected: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D18', '已选') /* "已选" */,
  packageSelected: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D19', '已选商品清单') /* "已选商品清单" */,
  notArriveMin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D1A', '未达到最低起订量') /* "未达到最低起订量" */,
  exceedCanChoose: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D1B', '已超过可选商品数量') /* "已超过可选商品数量" */,
  thispro: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D1C', '此商品') /* "此商品" */,
  thisclass: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D1D', '此分类') /* "此分类" */,
  hasSave: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D1E', '已优惠') /* "已优惠" */,
  overMaxCanChoose: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D1F', '每套餐最多可选') /* "每套餐最多可选" */,
  confirmation: lang.templateByUuid('UID:P_UDHWN_17DD50DE050008AA', '确定') /* "确定" */,
  promotionNoticeSetting: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D20', '发布公告') /* "发布公告" */,
  orderDeductionsTitle: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D21', '使用费用/积分/优惠券抵扣') /* "使用费用/积分/优惠券抵扣" */,
  times: lang.templateByUuid('UID:P_UDHWN_17DD50DE050009CA', '次') /* "次" */,
  isMerchant: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D22', '是否商家身份') /* "是否商家身份" */,
  payBill: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D23', '支付单') /* "支付单" */,
  refundBill: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D24', '退款单') /* "退款单" */,
  verification: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D25', '核销') /* "核销" */,
  syncStatus: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D26', '同步状态') /* "同步状态" */,
  rebateIndex: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D27', '行号') /* "行号" */,
  selectRebateIndex: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D28', '已选行号') /* "已选行号" */,
  salesOrgs: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D29', '销售组织') /* "销售组织" */,
  financialOrgs: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D2A', '财务组织') /* "财务组织" */,
  openTicketOrgs: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D2B', '开票组织') /* "开票组织" */,
  allFree: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B96', '全场任选') /* "全场任选" */,
  valuationQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD4', '计价数量') /* "计价数量" */,
  notHaveCanDeleteItem: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D2C', '没有可删除的选项') /* "没有可删除的选项" */,
  clearAll: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D2D', '全部删除') /* "全部删除" */,
  shangpinyitianjiachenggong: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D2E', '商品已添加成功！') /* "商品已添加成功！" */,
  dangqiangouwuchegongyou: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D2F', '当前购物车共有') /* "当前购物车共有" */,
  zhongshangpin: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D30', '种商品') /* "种商品" */,
  zaiguangguang: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D31', '再逛逛') /* "再逛逛" */,
  goToPay: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D32', '去结算') /* "去结算" */,
  settlement: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580001C', '结算方式') /* "结算方式" */,
  shippingChoice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D33', '发运方式') /* "发运方式" */,
  remark: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D34', '备注信息') /* "备注信息" */,
  salereturnRemark: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D35', '备注') /* "备注" */,
  hanshuibaojia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D36', '含税报价') /* "含税报价" */,
  wushuibaojia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D37', '无税报价') /* "无税报价" */,
  hanshuichengjiaojia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D38', '含税成交价') /* "含税成交价" */,
  wushuichengjiaojia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D39', '无税成交价') /* "无税成交价" */,
  prepaidAccount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D3A', '预付款账户') /* "预付款账户" */,
  prepaidAccountBalance: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D3B', '预付款账户余额') /* "预付款账户余额" */,
  rebateAccount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D3C', '费用账户') /* "费用账户" */,
  rebateAccountBalance: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D3D', '费用账户余额') /* "费用账户余额" */,
  amountCountAccountBalance: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D3E', '分摊/抵现账户余额') /* "分摊/抵现账户余额" */,
  amountProductCountAccountBalance: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D3F', '金额返货账户余额') /* "金额返货账户余额" */,
  numberProductCountAccountBalance: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D40', '数量返货账户余量') /* "数量返货账户余量" */,
  customerRefund: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D41', '客户退款') /* "客户退款" */,
  amountCountLang: lang.templateByUuid('UID:P_UDHWN_18D903D80530001A', '分摊/抵现账户') /* "分摊/抵现账户" */,
  amountProductCountLang: lang.templateByUuid('UID:P_UDHWN_18D903D80530001B', '金额返货账户') /* "金额返货账户" */,
  numberProductCountLang: lang.templateByUuid('UID:P_UDHWN_18D903D80530001C', '数量返货账户') /* "数量返货账户" */,
  creditLang: lang.templateByUuid('UID:P_UDHWN_18D903D805300019', '高级版信用账户') /* "高级版信用账户" */,
  pointsTransactionDetails: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AB2', '积分交易明细') /* "积分交易明细" */,
  defaultPrintTemplate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D42', '默认打印模板') /* "默认打印模板" */,
  printTemplateSettings: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D43', '打印模板设置') /* "打印模板设置" */,
  printWithPreview: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D44', '预览打印') /* "预览打印" */,
  printTemplateSelection: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D45', '打印模板选择') /* "打印模板选择" */,
  setPrintTemplateTips: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800022', '请先设置打印模板') /* "请先设置打印模板" */,
  selectPrintTemplateTips: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D46', '请选择打印模板') /* "请选择打印模板" */,
  realDeliveryMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D47', '实际发货金额') /* "实际发货金额" */,
  pleaseGetGoodBeforeGet: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D48', '请收到货后，再确认收货！') /* "请收到货后，再确认收货！" */,
  showStockTipSetting: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D49', '库存提示设置') /* "库存提示设置" */,
  noValidDataFoundInTheTemplate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D4A', '模板内未发现有效数据') /* "模板内未发现有效数据" */,
  falidToImportTemplateContent: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D4B', '模板内容导入失败') /* "模板内容导入失败" */,
  returnedMessages: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D4C', '返回信息') /* "返回信息" */,
  overCanChoose: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D4D', '已超出可选商品数量') /* "已超出可选商品数量" */,
  overCanChooseGroup: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D4E', '已选数量超出可选数量') /* "已选数量超出可选数量" */,
  notmatchGroupNumber: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D4F', '已选套数与可选套数不匹配') /* "已选套数与可选套数不匹配" */,
  productGroupModalWarringFirst: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D50', '所选数量与可选数量不相等') /* "所选数量与可选数量不相等" */,
  PackageModalWarring1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D51', '请勾选') /* "请勾选" */,
  PackageModalWarringFirst: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D52', '已选数量与可选数量不相等') /* "已选数量与可选数量不相等" */,
  oldYearSelectChangetip1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D53', '您选择了一个历史年度，在订单时间中尽可进行对当前年度的时间进行细分选择') /* "您选择了一个历史年度，在订单时间中尽可进行对当前年度的时间进行细分选择" */,
  oldYearSelectChangetip2: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D54', '您选择了一个历史年度，在创建时间中尽可进行对当前年度的时间进行细分选择') /* "您选择了一个历史年度，在创建时间中尽可进行对当前年度的时间进行细分选择" */,
  productSKU: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D55', '商品SKU') /* "商品SKU" */,
  noInheritCustomerGroup: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D56', '不从客户分组继承') /* "不从客户分组继承" */,
  pleaseControlTime: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D57', '请缩小查询范围') /* "请缩小查询范围" */,
  seletAuthskuTip: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D58', '当前商品所有SKU均有权时仅需要勾选商品权限') /* "当前商品所有SKU均有权时仅需要勾选商品权限" */,
  metaData: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D59', '元数据') /* "元数据" */,
  chooseEnterprise: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D5A', '选择企业') /* "选择企业" */,
  couponSn: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D5B', '卡券编号') /* "卡券编号" */,
  couponType: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D5C', '卡券类型') /* "卡券类型" */,
  caption: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D5D', '标题') /* "标题" */,
  width: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D5E', '宽度') /* "宽度" */,
  isShow: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D5F', '是否显示') /* "是否显示" */,
  isEnum: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D60', '是否枚举') /* "是否枚举" */,
  enumName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D61', '枚举名称') /* "枚举名称" */,
  enumStoreType: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D62', '枚举存储类型') /* "枚举存储类型" */,
  enumerationName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D63', '枚举名') /* "枚举名" */,
  EnumerationValues: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D64', '枚举值') /* "枚举值" */,
  specialValues: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D65', '特殊值') /* "特殊值" */,
  titleRepeat: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D66', '标题重复啦，已重置') /* "标题重复啦，已重置" */,
  requireEditFinish: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D67', '请先核对信息并点击编辑完成再保存') /* "请先核对信息并点击编辑完成再保存" */,
  quickOrderNotull: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D68', '请选择商品（多属性商品需明确具体属性）') /* "请选择商品（多属性商品需明确具体属性）" */,
  promotionalOffers: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D69', '促销优惠') /* "促销优惠" */,
  allAmount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D6A', '商品全额') /* "商品全额" */,
  attachment: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000B44', '附件') /* "附件" */,
  rebateCheckbox: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D6B', '您当前有可使用的费用，但您并没有使用，需要帮您使用吗？') /* "您当前有可使用的费用，但您并没有使用，需要帮您使用吗？" */,
  rebateDiscount: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D6C', '费用折扣') /* "费用折扣" */,
  yunfei: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D6D', '运费') /* "运费" */,
  rebateSource: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D6E', '费用来源') /* "费用来源" */,
  notAllowedToUseRebate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D6F', '不允许这样的商品使用费用，请检查') /* "不允许这样的商品使用费用，请检查" */,
  notAllowedToOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D70', '不允许这样的商品下单，请检查') /* "不允许这样的商品下单，请检查" */,
  accountingEntity: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D71', '会计主体') /* "会计主体" */,
  paymentReminders: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D72', '支付提示') /* "支付提示" */,
  notRefreshBeforeFinishPayment: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D73', '支付完成前，请不要刷新当前页面!') /* "支付完成前，请不要刷新当前页面!" */,
  viewOrderAfterFinishPayment: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D74', '支付完成后，可查看订单!') /* "支付完成后，可查看订单!" */,
  paymentFinish: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D75', '支付完成') /* "支付完成" */,
  paymentProblems: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D76', '支付遇到问题') /* "支付遇到问题" */,
  documentDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D77', '单据日期') /* "单据日期" */,
  sort: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D78', '排序') /* "排序" */,
  otherReason: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D79', '其他原因') /* "其他原因" */,
  pleaseCheckOrganizationFirst: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D7A', '请先点击查询按钮查询下销售组织') /* "请先点击查询按钮查询下销售组织" */,
  noSaleOrg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D7B', '没有销售组织，直接点击确定去填写信息吧！') /* "没有销售组织，直接点击确定去填写信息吧！" */,
  refill: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D7C', '重填') /* "重填" */,
  groupName: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D7D', '组名') /* "组名" */,
  reset: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800020', '重置') /* "重置" */,
  theGroupNameIsNotSet: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D7E', '未设置组名') /* "未设置组名" */,
  zuhezhe: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D7F', '组合折') /* "组合折" */,
  zuhejian: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D80', '组合减') /* "组合减" */,
  zuhezeng: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D81', '组合赠') /* "组合赠" */,
  zuheyikoujia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D82', '组合一口价') /* "组合一口价" */,
  productzhe: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D83', '商品折') /* "商品折" */,
  productjian: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D84', '商品减') /* "商品减" */,
  productzeng: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D85', '商品赠') /* "商品赠" */,
  productyikoujia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D86', '商品一口价') /* "商品一口价" */,
  zhengdanzhe: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D87', '整单折') /* "整单折" */,
  zhengdanjian: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D88', '整单减') /* "整单减" */,
  zhengdanzeng: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D89', '整单赠') /* "整单赠" */,
  zhengdanyikoujia: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D8A', '整单一口价') /* "整单一口价" */,
  fromTheEndOfTheGame: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D8B', '距离本场结束还剩') /* "距离本场结束还剩" */,
  immediateBuy: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D8C', '立即购买') /* "立即购买" */,
  noPromotions: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D8D', '当前没有促销活动！') /* "当前没有促销活动！" */,
  corpOrg: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D8E', '企业级组织') /* "企业级组织" */,
  electronicInvoice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D8F', '电子发票') /* "电子发票" */,
  vatElectronicOrdinaryInvoice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D90', '增值税电子普通发票') /* "增值税电子普通发票" */,
  vatElectronicSpecialInvoice: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D91', '增值税电子专用发票') /* "增值税电子专用发票" */,
  DownloadAddressOfTheElectronicInvoiceWasNotFound: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D92', '未找到该电子发票的下载地址') /* "未找到该电子发票的下载地址" */,
  einvoiceNo: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D93', '税票号码') /* "税票号码" */,
  einvoiceType: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D94', '税票类型') /* "税票类型" */,
  einvoiceHm: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D95', '税票代码') /* "税票代码" */,
  totalAmountIncludingTaxOfTheProject: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D96', '项目价税合计') /* "项目价税合计" */,
  setStoreOrder: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580002E', '您有暂存销售订单，是否载入') /* "您有暂存销售订单，是否载入" */,
  isKeyWordExactSearch: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D97', '是否精确匹配') /* "是否精确匹配" */,
  orderDetailHadSame: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D98', '订单已有相同商品') /* "订单已有相同商品" */,
  isAddDetail: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D99', '是否新增商品行') /* "是否新增商品行" */,
  promotionActivity: lang.templateByUuid('UID:P_UDHWN_1865780C0468000C', '促销活动') /* "促销活动" */,
  mon: lang.templateByUuid('UID:P_UDHWN_17DD50DE050002F9', '一') /* "一" */,
  tue: lang.templateByUuid('UID:P_UDHWN_17DD50DE050002FA', '二') /* "二" */,
  wed: lang.templateByUuid('UID:P_UDHWN_17DD50DE050002FB', '三') /* "三" */,
  thu: lang.templateByUuid('UID:P_UDHWN_17DD50DE050002FC', '四') /* "四" */,
  fri: lang.templateByUuid('UID:P_UDHWN_17DD50DE050002FD', '五') /* "五" */,
  sat: lang.templateByUuid('UID:P_UDHWN_17DD50DE050002FE', '六') /* "六" */,
  sun: lang.templateByUuid('UID:P_UDHWN_17DD50DE050002F8', '日') /* "日" */,
  monthly: lang.templateByUuid('UID:P_UDHWN_18C7345A04580009', '每月') /* "每月" */,
  weekly: lang.templateByUuid('UID:P_UDHWN_18C7345A0458000A', '每周') /* "每周" */,
  urlReplace: lang.templateByUuid('UID:P_UDHWN_18D0FBC604E0000C', '您好，为统一互联网域名规范，现域名更换为') /* "您好，为统一互联网域名规范，现域名更换为" */,
  favoriteUrl: lang.templateByUuid('UID:P_UDHWN_18D0FBC604E0000D', '请您重新登录并收藏新链接地址，感谢您的理解与支持！') /* "请您重新登录并收藏新链接地址，感谢您的理解与支持！" */,
  zhubiaoqian: lang.templateByUuid('UID:P_UDHWN_18D9745E0558000E', '主标签') /* "主标签" */,
  batchReceiving: lang.templateByUuid('UID:P_UDHWN_191E6B9605900003', '批量收货') /* "批量收货" */,
  noMatchingData: lang.templateByUuid('UID:P_UDHWN_17DD50DE050002DE', '无匹配数据') /* "无匹配数据" */,
  accountName: lang.templateByUuid('UID:P_UDHWN_18FBCC920528000A', '账户名称') /* "账户名称" */,
  realNameAuthentication: lang.templateByUuid('UID:P_UDHWN_191E6F5404500009', '您的账号未进行实名认证，不符合相关法律法规要求，请及时完成实名认证。') /* "您的账号未进行实名认证，不符合相关法律法规要求，请及时完成实名认证。" */,
  realNameTips: lang.templateByUuid('UID:P_UDHWN_1920224E05900009', '温馨提示：您还可以登录') /* "温馨提示：您还可以登录" */,
  realNameTime: lang.templateByUuid('UID:P_UDHWN_17DD50DC0500001F', '次'),
  realNameTn: lang.templateByUuid('UID:P_UDHWN_1920224E0590000A', '本产品') /* "本产品" */,
  authenticateNow: lang.templateByUuid('UID:P_UDHWN_1910A81A0428000E', '立即认证') /* "立即认证" */,
  futureCertification: lang.templateByUuid('UID:P_UDHWN_1910A81A0428000F', '以后认证') /* "以后认证" */,
  LawsRegulations: lang.templateByUuid('UID:P_UDHWN_19256ED804500007', '了解法律法规') /* "了解法律法规" */,

  // 来款锁价
  endPayMentTimeNo: lang.templateByUuid("UID:P_UDHWN_1AA1835805C8000E","打款已截止") /* "打款已截止" */,
  lockPrice: lang.templateByUuid('UID:P_UDHWN_194093DC05580003', '来款锁价') /* "来款锁价" */,
  noGoodsNumAlert: lang.templateByUuid('UID:P_UDHWN_1948805204F00044', '购买计价数量不能为空!') /* "购买计价数量不能为空!" */,
  endPayMentTime: lang.templateByUuid('UID:P_UDHWN_1948805204F00045', '距离打款结束还剩') /* "距离打款结束还剩" */,
  policyName: lang.templateByUuid('UID:P_UDHWN_1948805204F00046', '政策名称：') /* "政策名称：" */,
  payInterval: lang.templateByUuid('UID:P_UDHWN_1948805204F00047', '来款期间：') /* "来款期间：" */,
  orderInterval: lang.templateByUuid('UID:P_UDHWN_1948805204F00048', '下单期间：') /* "下单期间：" */,
  policySubmit: lang.templateByUuid('UID:P_UDHWN_1948805204F00049', '政策下单') /* "政策下单" */,
  beginning: lang.templateByUuid('UID:P_UDHWN_1948805204F0004A', '期初') /* "期初" */,
  IsBeginning: lang.templateByUuid('UID:P_UDHWN_1948805204F0004B', '是否期初') /* "是否期初" */,
  noInpaymentAlert: lang.templateByUuid('UID:P_UDHWN_1948805204F0004C', '请选择来款记录下单！') /* "请选择来款记录下单！" */,
  // inpaymentAlertLeft: lang.templateByUuid('UID:P_UDHWN_1948805204F0004D', '请选择一张可用余额大于等于') /* "请选择一张可用余额大于等于" */,
  // inpaymentAlertRight: lang.templateByUuid('UID:P_UDHWN_1948805204F0004E', '的') /* "的收款记录!" */,
  inpaymentAlert1: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000A91', '请选择') /* "请选择" */,
  inpaymentAlert2: lang.templateByUuid('UID:P_UDHWN_197F191004D0000B', '一张') /* "一张" */,
  inpaymentAlert3: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000CBD', '可用余额') /* "可用余额" */,
  inpaymentAlert4: lang.templateByUuid('UID:P_UDHWN_197F191004D0000C', '大于等于') /* "大于等于" */,
  inpaymentPriceUnit: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000AEC', '元') /* "元" */,
  inpaymentAlert5: lang.templateByUuid('UID:P_UDHWN_197F191004D0000D', '收款记录!') /* "收款记录!" */,
  policySettlement: lang.templateByUuid('UID:P_UDHWN_17E3A78E0580001C', '结算方式') /* "结算方式" */,
  orderTimeEndAlert: lang.templateByUuid('UID:P_UDHWN_1948805204F0004F', '订货时间已结束!') /* "订货时间已结束!" */,
  payPolicyFinishDate: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000D77', '单据日期') /* "单据日期" */,
  payPolicyCode: lang.templateByUuid('UID:P_UDHWN_1948805204F00050', '单据编码') /* "单据编码" */,
  payPolicyPrice: lang.templateByUuid('UID:P_UDHWN_1948805204F00051', '付款金额（元）') /* "付款金额（元）" */,
  noUsePrice: lang.templateByUuid('UID:P_UDHWN_1948805204F00052', '可用金额（元）') /* "可用金额（元）" */,
  policyOrder: lang.templateByUuid('UID:P_UDHWN_1952A77404F0000E', '立即下单') /* "立即下单" */,
  policyAllPrice: lang.templateByUuid('UID:P_UDHWN_1952A77404F0000F', '当前选中来款金额：') /* "当前选中来款金额：" */,
  inPaymentList: lang.templateByUuid('UID:P_UDHWN_1952A77404F00010', '来款记录') /* "来款记录" */,
  capitalSwitchToMingxi: lang.templateByUuid('UID:P_UDHWN_1972DDCC04580019', '切换为明细支付') /* "切换为明细支付" */,
  capitalSwitchToCapital: lang.templateByUuid('UID:P_UDHWN_1972DDCC0458001A', '切换为资金支付') /* "切换为资金支付" */,
  verifyCapitalSubmitTips: lang.templateByUuid('UID:P_UDHWN_1972DDCC0458001B', '输入金额与支付金额不符') /* "输入金额与支付金额不符" */,
  capitalInputChangeTips: lang.templateByUuid('UID:P_UDHWN_1972DDCC0458sss01C', '输入金额大于当前资金账户') /* "输入金额大于当前资金账户" */,
  editLogisticslists: lang.templateByUuid('UID:P_UDHWN_198250E40498000B', '编辑物流信息') /* "编辑物流信息" */,
  cargoAllowance: lang.templateByUuid('UID:P_UDHWN_198CA75804F80009', '货补优惠') || '货补优惠', /* "货补优惠" */
  expenseSheetBalance: lang.templateByUuid('UID:P_UDHWN_198CA13004F80009', '费用单余额') || '费用单余额' /* "费用单余额" */,
  peie: lang.templateByUuid('UID:P_UDHWN_19C44E6A04780007', '配额') || '配额'/* "配额" */,
  noDataPaymentList: lang.templateByUuid('UID:P_UDHWN_19B1B5C805A8000C', '查询不到可用的来款记录，请打款后再选择商品要货') || '查询不到可用的来款记录，请打款后再选择商品要货' /* "查询不到可用的来款记录，请打款后再选择商品要货" */,
  paymentTypeName: lang.templateByUuid('UID:P_UDHWN_19C3F99004780008', '款项类型') || '款项类型' /* "款项类型" */,
  productTags: lang.templateByUuid('UID:P_UDHWN_19C9549405F00008', '商品标签') /* "商品标签" */,
  clearOrderDetailsSure: lang.templateByUuid('UID:P_UDHWN_1A1550040530000B', '切换交易类型会清空已选商品信息，是否需要切换？') /* "切换交易类型会清空已选商品信息，是否需要切换？" */,
  notYetCompleted: lang.templateByUuid('UID:P_UDHWN_1A6D875604500022', '您的企业尚未完成认证。') /* "您的企业尚未完成认证。" */,
  notCompletedPersonalCertification: lang.templateByUuid('UID:P_UDHWN_1A6D875604500023', '您尚未完成个人认证。') /* "您尚未完成个人认证。" */,
  cCompletedTip: lang.templateByUuid('UID:P_UDHWN_1A6D875604500024', '您的经营性质为个人，无需使用此功能。') /* "您的经营性质为个人，无需使用此功能。" */,
  coreCustomer: lang.templateByUuid('UID:P_UDHWN_1A6D875604500025', '您不是核心企业的直接客户，暂时不能使用此功能') /* "您不是核心企业的直接客户，暂时不能使用此功能" */,
  unavailable: lang.templateByUuid('UID:P_UDHWN_1A6D875604500026', '您的供应方尚未开通电子合同服务，此功能暂不可用。') /* "您的供应方尚未开通电子合同服务，此功能暂不可用。" */,
  inVerified: lang.templateByUuid('UID:P_UDHWN_1A6D875604500027', '已认证') /* "已认证" */,
  goInVerifing: lang.templateByUuid('UID:P_UDHWN_1AFD3EA204780008', '去认证') /* "去认证" */,
  unifiedSocialCreditCode: lang.templateByUuid('UID:P_UDHWN_1A6D875604500028', '社会统一信用代码') /* "社会统一信用代码" */,
  reauthenticate: lang.templateByUuid('UID:P_UDHWN_1A6D875604500029', '重新认证') /* "重新认证" */,
  IDNumber: lang.templateByUuid('UID:P_UDHWN_1A6D87560450002A', '证件号') /* "证件号" */,
  haveCompletedPersonalCertification: lang.templateByUuid('UID:P_UDHWN_1A6D87560450002B', '您已经完成个人认证，但未与当前企业绑定。') /* "您已经完成个人认证，但未与当前企业绑定。" */,
  goToBind: lang.templateByUuid('UID:P_UDHWN_1A6D87560450002C', '去绑定') /* "去绑定" */,
  bindingFailed: lang.templateByUuid('UID:P_UDHWN_1A6D87560450002D', '绑定失败') /* "绑定失败" */,
  enterpriseNotYetCompleted: lang.templateByUuid('UID:P_UDHWN_1A6D87560450002E', '您的企业还未做企业实名认证。') /* "您的企业还未做企业实名认证。" */,
  tryAgainLater: lang.templateByUuid('UID:P_UDHWN_1A6D87560450002F', '请稍后重试') /* "请稍后重试" */,
  draft: lang.templateByUuid('UID:P_UDHWN_1A69BB7005100025', '草稿') /* "草稿" */,
  loadingDraftFailed: lang.templateByUuid('UID:P_UDHWN_1A69BB7005100026', '加载草稿失败') /* "加载草稿失败" */,
  deleteFailed: lang.templateByUuid('UID:P_UDHWN_1A69BB7005100027', '删除失败') /* "删除失败" */,
  saveAsDraft: lang.templateByUuid('UID:P_UDHWN_1A69BB7005100028', '存为草稿') /* "存为草稿" */,
  savedAsDraftSuccessfully: lang.templateByUuid('UID:P_UDHWN_1A69BB7005100029', '存为草稿成功') /* "存为草稿成功" */,
  saveDraft: lang.templateByUuid('UID:P_UDHWN_1A69BB7005100021', '保存草稿') /* "保存草稿" */,
  draftName: lang.templateByUuid('UID:P_UDHWN_1A69BB7005100022', '草稿名称') /* "草稿名称" */,
  draftNameNotCanNull: lang.templateByUuid('UID:P_UDHWN_1A69BB7005100023', '草稿名称不能为空') /* "草稿名称不能为空" */,
  addSalesOrderDraft: lang.templateByUuid('UID:P_UDHWN_1A69BB7005100024', '新增销售订单草稿') /* "新增销售订单草稿" */,
  // 来款锁价分割线
  ...basebill,
  ...auth,
  ...settings,
  ...order,
  ...header,
  ...message,
  ...orderDetail,
  ...settle,
  ...organization,
  ...channelcenter,
  ...stockcenter,
  ...priceCenter,
  ...promotionZTE,
  ...customer,
  ...integrationextension,
  ...promotionZTE,
  ...rebateCenter,
  ...creditManagement,
  ...integrationextension,
  ...corpHomeIndex,
  ...platform,
  ...accounts,
  ...product,
  ...agentProduct,
  ...agentHome,
  ...agentPayment,
  ...agentHome,
  ...shoppingCart,
  ...quickOrder
};
export default zhCN;

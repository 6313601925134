import ajax from '@/libs/ajax';

// ue功能配置
export const ueConfig = () => {
  // 越权问题 此接口废弃直接使用固定数据
  // return ajax.request({
  //   url: '/ue',
  //   method: 'get'
  // });
  return Promise.resolve({
    enable: true,
    pages: {
      agentIndex: true, // 首页
      agentOrderShow: true, // 订单列表
      agentOrderList: true, // 订单
      agentDeliveryList: true, // 收货单
      agentReturnList: true, // 退货单
      agentOrderSignList: true, // 签收单
      amountCountAccount: true, // 分摊/抵现账户
      amountProductCount: true, // 金额返货账户
      numberProductCount: true, // 数量返货账户
      agentProductMenu: true, // 商品列表
      agentCapital: true, // 资金账户
      agentReportsShow: false, // 数据统计
      orderDetail: true, // 订单详情
      deliveryDetail: true, // 发货单详情
      expenseDetail: true, // 费用单详情
      saleReturnDetail: true, // 退货单详情
      payDetail: false, // 支付单详情
      productDetail: true, // 商品详情
      helpCenter: true, // 帮助中心
      SingleDiscountListShow: true, // 整单优惠详情
      ProductPromotion: true, // 商品促销详情
      CombinationPromotion: false, // 组合促销详情
      memberPoint: true, // 会员资产
      agentPromotionGroup: true, // 促销大全
      coupon: true,
      nccCredit: true
    }
  });
};

// 订货段菜单
export const getAgentMenus = () => {
  return ajax.request({
    url: '/archives/menu/1/menus',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 购物车数量
export const getShoppingNum = (agentId) => {
  return ajax.request({
    // url: '/pc/goods/ShoppingCartSrv/count',
    url: `/goods/shoppingcart/${agentId}/count`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 帮助中心列表
export const getHelpInfo = () => {
  return ajax.request({
    url: '/archives/help/article/list?isArchive=1',
    method: 'get'
  });
};

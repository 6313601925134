import { getShoppingNum } from '@/api/agent-home/agent-home.js';
import { getOrgRelationList } from '@/api/agents.js';
import { fetchCurrentUserAuth } from '@/api/auth/auth';
import { fetchUserSettingPrice, newFetchCurrentUserBo, getOptionValueByCode } from '@/api/bo';
import { getCurrentUserInfo, getMenuList, getUserNewsCount, loginByUsername, logout } from '@/api/user';
import { removeToken, setToken } from '@/libs/auth';
import menuRegister from '@/libs/menus';
import util from '@/libs/util';
import _ from 'lodash';

// import { stringify } from 'querystring';
const user = {
  state: {
    currentUser: {},
    auth: [], // 权限
    bo: [], // 业务选项
    newsCount: 0,
    newMsg: [],
    functionOptions: null,
    hidePriceExact: {
      pricePrecision: 2,
      priceRoundType: '',
      unitPricePrecision: 2,
      unitPriceRoundType: ''
    },
    userSettingPrice: null,
    menuList: [],
    flattenMenuList: [],
    user: '',
    status: '',
    code: '',
    token: '',
    name: '',
    avatar: '',
    introduction: '',
    roles: [],
    userAccountMenu: [],
    setting: {
      articlePlatform: []
    },
    shoppingNum: null,
    quickOrderSelectedProductList: { content: [] },
    orderActionBtnsStatus: true // 订单行操作按钮是否收起
  },
  getters: {
    token: (state, getters, rootState) => state.token,
    newsCount: (state, getters, rootState) => state.newsCount,
    newMsg: (state, getters, rootState) => state.newMsg,
    BO: (state, getters, rootState) => state.bo,
    menuList: (state, getters, rootState) => state.menuList,
    flattenMenuList: (state, getters, rootState) => state.flattenMenuList,
    auth: (state, getters, rootState) => state.auth,
    currentUser: (state, getters, rootState) => state.currentUser,
    hidePriceExact: (state, getters, rootState) => state.hidePriceExact,
    userSettingPrice: (state, getters, rootState) => state.userSettingPrice,
    shoppingNum: (state, getters, rootState) => state.shoppingNum,
    userAccountMenu: (state, getters, rootState) => state.userAccountMenu

  },

  mutations: {
    SET_CURRENTUSER: (state, user) => {
      state.currentUser = user;
    },
    SET_USERACCOUNTMENU: (state, menu) => {
      state.userAccountMenu = menu;
    },
    SET_FUNCTIONOPTIONS: (state, data) => {
      state.functionOptions = data;
    },
    SET_USERNEWSCOUNT: (state, count) => {
      state.newsCount = count;
    },
    SET_USERNEWMSG: (state, msg) => {
      state.newMsg = msg;
    },
    SET_MENULIST: (state, menuList) => {
      state.flattenMenuList = util.compileFlatState(menuList);
      state.menuList = menuList;
    },
    SET_AUTH: (state, payload) => {
      state.auth = payload;
    },
    SET_HIDEPRICEEXACT: (state, payload) => {
      state.hidePriceExact = payload;
    },
    SET_USERSETTINGPRICE: (state, payload) => {
      state.userSettingPrice = payload;
    },
    SET_BO: (state, payload) => {
      state.bo = payload;
    },
    SET_CODE: (state, code) => {
      state.code = code;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction;
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting;
    },
    SET_STATUS: (state, status) => {
      state.status = status;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_SHOPPINGNUM: (state, shoppingNum) => {
      state.shoppingNum = shoppingNum;
    },
    SET_QUICKORDERSELECTEDPRODUCTLIST: (state, selectedProductList) => {
      state.quickOrderSelectedProductList = selectedProductList;
    },
    SET_ORDERACTIONBTNSSTATUS(state, status) {
      state.orderActionBtnsStatus = status;
    }
  },

  actions: {
    // 用户名登录
    LoginByUsername({ commit }, userInfo) {
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        loginByUsername(username, userInfo.password)
          .then((response) => {
            const data = response.data;
            commit('SET_TOKEN', data.token);
            setToken(response.data.token);
            // Cookies.set('user', this.form.userName);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户菜单
    GetMenuList({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        const type = sessionStorage.isAgentSide === 'true' ? 1 : 0;
        if (type === 0) {
          getMenuList(type)
            .then((data) => {
              const home = [{ sysCode: 'corp_home_index', isSystem: true, name: window.__app.mainV.$t('home'), url: '/corp', children: [], bVue: true }];
              const menus = _.union(home, data);
              const setMenus = (menus, parent) => {
                menus.map((v, k) => {
                // v.parent = _.cloneDeep(parent);
                  v.oriSysCode = v.sysCode;
                  v.caption = v.name;
                  menuRegister.order(v);
                  menuRegister.customer(v);
                  menuRegister.platform(v);
                  menuRegister.channelcenter(v);
                  menuRegister.product(v);
                  menuRegister.organization(v);
                  menuRegister.settings(v);
                  menuRegister.settle(v);
                  menuRegister.extension(v);
                  menuRegister.basebill(v);
                  menuRegister.auth(v);
                  menuRegister.stockcenter(v);
                  menuRegister.pricecenter(v);
                  menuRegister.rebateCenter(v);
                  menuRegister.promotionZTE(v);
                  menuRegister.productNotice(v);
                  menuRegister.objectCredit(v);
                  menuRegister.tenantManagement(v);
                  menuRegister.operator(v);
                  menuRegister.history(v);
                  let { isSystem } = v;
                  if (isSystem === false) {
                    v.icon = v.imgClass || 'other';
                  } else {
                    v.icon = v.code || 'icon_Home';
                  }
                  v.iconSize = 16;
                  if (v.children && v.children.length > 0) {
                    setMenus(v.children, v);
                  }
                });
              };
              setMenus(menus);
              commit('SET_MENULIST', menus);
              resolve(menus);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    GetPriceExact: async({ state, commit }, payload = { forceReload: false }) => {
      // 这里是为了防止重复获取
      if (state.hidePriceExact.id && !payload.forceReload) return;
      let user = await getCurrentUserInfo();
      let currency = user && Array.isArray(user.currencys) && user.currencys.length && user.currencys[0];
      const list = _.merge(state.hidePriceExact, {
        pricePrecision: currency.moneyDigit || 2,
        priceRoundType: currency.moneyRount || 4,
        unitPricePrecision: currency.priceDigit || 2,
        unitPriceRoundType: currency.priceRount || 4,
        id: currency.id || null
      });
      commit('SET_HIDEPRICEEXACT', list);
    },
    GetUserSettingPrice: async({ state, commit }) => {
      // 这里是为了防止重复获取
      if (state.userSettingPrice) return;
      const list = await fetchUserSettingPrice().catch(e => false);
      if (list) commit('SET_USERSETTINGPRICE', list);
    },
    GetPermissionList: async({ state, commit }) => {
      // 这里是为了防止重复获取
      if (state.auth.length) return;
      const list = await fetchCurrentUserAuth().catch(e => {
        return [];
      });
      commit('SET_AUTH', list);
    },
    setUserInfo({ commit, state }, user) {
      let copyUser = _.cloneDeep(user);
      if (copyUser.singleBizRelation) delete copyUser.singleBizRelation;
      localStorage.setItem('defaultUserInfo', JSON.stringify(copyUser));
      commit('SET_CURRENTUSER', user);
    },
    setUserAccountMenu({ commit, state }, menu) {
      commit('SET_USERACCOUNTMENU', menu);
    },
    // 获取用户信息
    GetUserInfo: async({ commit, state }, payload = { forceReload: false }) => {
      if (state.currentUser.userId && !payload.forceReload) {
        return;
      }
      let isU8c = location.href.indexOf('source=u8c') > -1;
      let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
      if (!user || isU8c || payload.forceReload || sessionStorage.isAgentSide) { // 订货端防止是代下单，重新刷下上下文
        user = await getCurrentUserInfo();
      }
      window.document.title = user.corpName;
      if (_.isArray(user.relations) && user.relations.length && !localStorage.upAgentRelationId) {
        localStorage.upAgentRelationId = localStorage.storageRelationId || user.relations[0].id;
      }
      if (user.theme) localStorage.setItem('u-theme', user.theme);
      /**
       * bizMode:
       * 1:单组织单级经销商,
       * 2:单组织多级经销商,
       * 3:多组织单级经销商,
       * 4:多组织多级经销商
      */
      /**
      * bizType
      * 1: 平台
      * 2：经销商
      * **/
      const { bizMode, userType } = user;

      // 当前用户是否是商家
      user.__isBiz = userType > 3;
      // 当前模式是否是多商家
      user.__isMutiBiz = bizMode === 2 || bizMode === 4;
      // 当前模式是否是多组织
      user.__isMutiOrg = bizMode > 2;
      // 显示多组织下拉
      user.__showOrg = user.__isMutiOrg && (userType === 1 || userType === 3);
      // 显示多商家下拉
      user.__showBiz = user.__isMutiBiz && (userType === 1 || userType === 3);
      // 是否当前在订货端
      user.__isAgentSide = sessionStorage.isAgentSide === 'true';
      if (user.__isAgentSide) {
        // 单商家模式下商家下拉相关数据
        user.singleBizRelation = {
          isShowSingleBizSelect: false, // 是否显示单商家下拉、
          currentTradingBiz: null, // 当前交易对象
          singleBizSelectTreeData: []// 下拉树数据
        };
      }
      // 代下单
      user.__isRepresent = user.__isAgentSide && [1, 3, 9, 10].includes(userType);
      if (user.__isRepresent) {
        // 代下单客户id
        user.__upAgentId = localStorage.upAgentId;
        // 代下单交易关系id
        user.__upAgentRelationId = localStorage.upAgentRelationId !== 'undefined' ? localStorage.upAgentRelationId : null;
        // 代下单bizid
        user.__upBizId = localStorage.upBizId;
      }
      // 当前用户是否U8C租户
      user.__isU8C = user.tenantType === 4;
      // 订货端当前账号是子账号
      user.__isChildAccount = userType === 0;
      // 零售门店id
      if (localStorage.getItem('currentStoreId')) {
        user.__currentStoreId = localStorage.getItem('currentStoreId');
      }
      localStorage.setItem('defaultUserInfo', JSON.stringify(user));
      commit('SET_CURRENTUSER', user);
    },
    // 获取用户业务选项
    GetUserBo: async({ state, commit }, payload = { forceReload: false }) => {
      // 这里是为了防止重复获取
      // if (state.bo.length && !payload.forceReload) return;
      // 组织级则直接利用orgId，商家级由于bizId和orgId相同所以也可以直接使用orgId
      // const orgId = localStorage.getItem('upOrgId');
      // const list = await fetchCurrentUserBo();
      const newUserBoList = await newFetchCurrentUserBo();
      const obj = {
        bizId: localStorage.getItem('upBizId'),
        pubOptionListData: newUserBoList
      };
      // 如果agentId为undefined则说明是管理端，否则为订货端
      if (!payload.agentId) {
        obj.bizId = state.currentUser.bizId;
        const res = await getOptionValueByCode('AUDITBIZPROMOTION');
        // 此参数为营销参数，管理端多个页面使用该参数在此手动拼接一下
        obj.pubOptionListData.push({
          code: 'AUDITBIZPROMOTION',
          desc: '平台审核商家促销活动',
          value: res === 'true'
        });
      } else {
        const [res1, res2] = await Promise.all([getOptionValueByCode('MULTIPROMOTIONCALCULATION'), getOptionValueByCode('OPEN_CAPITALACCOUNT')]);
        // 此参数为多促销叠加营销参数，该参数在此手动拼接一下
        obj.pubOptionListData.push({
          code: 'MULTIPROMOTIONCALCULATION',
          desc: '多促销叠加',
          value: res1
        });
        obj.pubOptionListData.push({
          code: 'OPEN_CAPITALACCOUNT',
          desc: '开启资金账户',
          value: res2
        });
      }
      commit('SET_BO', [obj, newUserBoList]);
    },
    // 获取用户消息数量
    GetUserNewsCount({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserNewsCount()
          .then(([data, unreadMsgData]) => {
            var messages = data.content;
            commit('SET_USERNEWSCOUNT', unreadMsgData);
            commit('SET_USERNEWMSG', messages);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 第三方验证登录
    // LoginByThirdparty({ commit, state }, code) {
    //   return new Promise((resolve, reject) => {
    //     commit('SET_CODE', code)
    //     loginByThirdparty(state.status, state.email, state.code).then(response => {
    //       commit('SET_TOKEN', response.data.token)
    //       setToken(response.data.token)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    },

    // 动态修改权限
    ChangeRoles({ commit }, role) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', role);
        setToken(role);
        // getUserInfo(role).then((response) => {
        //   const data = response.data;
        //   commit('SET_ROLES', data.roles);
        //   commit('SET_NAME', data.name);
        //   commit('SET_AVATAR', data.avatar);
        //   commit('SET_INTRODUCTION', data.introduction);
        //   resolve();
        // });
      });
    },
    // 获取交易关系
    GetAgentRelations: async(args, payload = { forceReload: false }) => {
      const { state, dispatch, commit } = args;
      const currentUser = state.currentUser;
      const v1 = currentUser.agentId || currentUser.__upAgentId;
      // const ORDERSHOWWAY = util.getBo(args, 'ORDERSHOWWAY'); // false：单商家模式
      let orderShowWay = false;
      let agentInitData = [];
      // 加了个参数payload，选择商家的时候不要跳过
      if (currentUser.__isRepresent || (currentUser.singleBizRelation && currentUser.singleBizRelation.currentTradingBiz && !payload.forceReload)) { // 加载过了
        if (currentUser.__isRepresent) {
          let obj = currentUser.relations.find((v) => {
            return v.id === localStorage.upAgentRelationId;
          });
          if (obj) {
            localStorage.upBizId = obj.bizId;
            localStorage.upOrgId = obj.orgId;
            await dispatch('GetUserBo', { agentId: v1 }).catch((e) => console.error(e)); // eslint-disable-line no-console
          }
        }
        return;
      }
      let singleBizSelectTreeData = [];
      let flattenRelations = [];
      const isShowBizSelect = () => {
        let select = false;
        if (!currentUser.__isRepresent && (currentUser.__isMutiBiz || currentUser.__isMutiOrg) && agentInitData.length > 1) {
          select = true;
        } else {
          select = false;
        }
        return select;
      };
      const isShowSingleBizSelect = () => {
        let select = false;
        if (currentUser.__isRepresent || (!currentUser.__isMutiBiz && !currentUser.__isMutiOrg) || orderShowWay || agentInitData.length <= 1) {
          select = false;
        } else {
          select = true;
        }
        return select;
      };
      const isShowMultiBizSelect = () => {
        let show = false;
        if (!currentUser.__isRepresent && orderShowWay && (currentUser.__isMutiBiz || currentUser.__isMutiOrg) && agentInitData.length >= 1) {
          show = true;
        }
        return show;
      };

      let currentTradingBiz = null;
      const setUserInfo = () => {
        currentUser.singleBizRelation = {
          isShowBizSelect: isShowBizSelect(), // 是否显示下拉
          isShowMultiBizSelect: isShowMultiBizSelect(), // 不是单商家，显示商家下拉
          isShowSingleBizSelect: isShowSingleBizSelect(), // 单商家下拉、
          currentTradingBiz, // 当前交易对象
          singleBizSelectTreeData, // 交易关系下拉树数据,
          flattenRelations // 平铺的交易关系
        };
        let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
        if (user.relations && user.relations.length && currentTradingBiz && currentTradingBiz.agentRelationId) {
          let tempCurrency = user.relations.find((v) => {
            return v.id === currentTradingBiz.agentRelationId;
          });
          if (tempCurrency && tempCurrency.currency) {
            const list = _.merge(state.hidePriceExact, {
              pricePrecision: tempCurrency.currency.moneyDigit || 2,
              priceRoundType: tempCurrency.currency.moneyRount || 4,
              unitPricePrecision: tempCurrency.currency.priceDigit || 2,
              unitPriceRoundType: tempCurrency.currency.priceRount || 4,
              id: tempCurrency.currency.id || null
            });
            commit('SET_HIDEPRICEEXACT', list);
          }
        }
        dispatch('setUserInfo', currentUser);
      };
      if (v1) {
        const formatBizs = (data) => {
          let bizs = [];
          const copyData = _.cloneDeep(data); // 深拷贝，防止遍历的时候影响
          let relationStorge = localStorage.upAgentRelationId;
          data.forEach((item) => {
            let find = bizs.find((citem) => {
              return citem.bizId === item.bizId;
            });
            if (!find) {
              item.children = copyData.filter((citem, index) => {
                if (relationStorge) {
                  if (citem.agentRelationId === relationStorge) {
                    citem.selected = true;
                    currentTradingBiz = citem;
                  } else {
                    citem.selected = false;
                  }
                } else {
                  citem.selected = index === 0;
                }

                citem.title = citem.orgName || citem.bizName;
                if (citem.selected) {
                  localStorage.upBizId = citem.bizId; // 适配老页面
                  localStorage.upOrgId = citem.orgId;
                  localStorage.upBizTitle = citem.title;
                }
                return citem.bizId === item.bizId;
              });
              item.title = item.bizName;
              if (item.children && item.children.length === 1) {
                if (item.bizType === 3) {
                  delete item.children;
                }
                if (relationStorge && item.agentRelationId === relationStorge) {
                  if (item.bizType === 3) {
                    item.selected = true;
                  }
                  currentTradingBiz = item;
                }
              }
              if (item.children && item.children.length > 1) {
                item.agentRelationId = -1;// 特殊标示
              }
              item.id = item.agentRelationId;
              bizs.push(item);
            }
          });
          // 选中的分组展开
          bizs.forEach(item => {
            if (item.children) {
              item.disableSelect = true;
              item.children.forEach(citem => {
                if (citem.agentRelationId === relationStorge) {
                  item.expand = true;
                }
              });
            }
          });
          singleBizSelectTreeData = bizs;
        };
        // 处理商家数据
        await getOrgRelationList(v1).then(async(res) => {
          agentInitData = res.content;
          flattenRelations = _.cloneDeep(res.content);// 后面会改结构所以复制个
          formatBizs(res.content);
          await dispatch('GetUserBo', { agentId: v1 }).catch((e) => console.error(e) // eslint-disable-line no-console
          ); // 业务选项
          orderShowWay = util.getBo(args, 'ORDERSHOWWAY');
          if (!orderShowWay && localStorage.getItem('isRepresentToOrder') !== 'true' && localStorage.getItem('isCorpToOrder') !== 'true') {
            localStorage.storageRelationId = localStorage.upAgentRelationId;
          } else {
            localStorage.removeItem('storageRelationId');
          }
          setUserInfo();
        });
      } else {
        await dispatch('GetUserBo', { agentId: v1 }).catch((e) => console.error(e));// eslint-disable-line no-console
        setUserInfo();
      }
    },
    // 获取购物车数量
    GetShoppingNum: async({ commit, state }) => {
      const { currentUser } = state;
      if (currentUser.agentId) {
        const shoppingNum = await getShoppingNum(currentUser.agentId);
        commit('SET_SHOPPINGNUM', shoppingNum);
      }
    }
  }
};

export default user;

<template>
  <div class="agent-capital topvalue-20">
    <Breadcrumb v-if="isShowBreadcrumb">
      <BreadcrumbItem to="/agent/home">{{ mlang('home') }}</BreadcrumbItem>
      <BreadcrumbItem>{{ currentRouterName }}</BreadcrumbItem>
    </Breadcrumb>
    <Summary v-if="!isFromSumary && $route.name !== 'Credit'" class="capital-summary" />
    <div class="capital-list">
      <Tabs v-if="!['amountCount', 'amountProductCount', 'numberProductCount', 'PointTransaction', 'Coupon', 'Credit'].includes($route.name)" size="small" :value="name" class="capital-tabs" :animated="false" @on-click="toList">
        <TabPane v-for="(tab,index) in tabs" :key="index" :label="tab.title" :name="tab.name">
        </TabPane>
      </Tabs>
      <div class="bodyFour-router">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Summary from './components/summary/summary.vue';
import { mapGetters } from 'vuex';
import { isNewFinanceFi } from '@/api/agentPayment/payment.js';
export default {
  components: {
    Summary
  },
  data: function() {
    return {
      name: 'Pays',
      tabs: [],
      childTabs: new Map(),
      isShowPoint: false,
      isFromSumary: this.$route.query.formSumary,
      isRebate: this.$route.query.isRebate,
      isNewFinanceFied: false, // 财务新老架构
      currentRouterName: this.mlang('prepaidAccount'),
      isShowBreadcrumb: true
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  created() {
    if (window !== top && !window.localStorage.getItem('isDataCenter')) {
      top.location.href = `${window.location.origin}/v#/agent/agentCaptial/pays`;
    }
    if (window.localStorage.getItem('toUrlPage')) window.localStorage.setItem('toUrlPage', false);
    this.init();
  },
  methods: {
    init() {
      this.name = this.$route.name || 'Pays';
      if (this.name.includes('Rebates')) this.name = 'Rebates';
      this.initTab();
      switch (this.name) {
        case 'Pays':
          this.currentRouterName = this.mlang('prepaidAccount');
          break;
        case 'CustomerRefund':
          this.currentRouterName = this.mlang('customerRefund');
          break;
        case 'amountCount':
          this.currentRouterName = this.mlang('amountCountLang');
          break;
        case 'amountProductCount':
          this.currentRouterName = this.mlang('amountProductCountLang');
          break;
        case 'numberProductCount':
          this.currentRouterName = this.mlang('numberProductCountLang');
          break;
        case 'Credit':
          this.currentRouterName = this.mlang('creditLang');
          break;
        default:
          this.isShowBreadcrumb = false;
          break;
      }
    },
    async initTab() {
      this.isNewFinanceFied = await isNewFinanceFi();
      let arr = [];
      if (this.$hasAuth('agentReturnPayShow') && (!this.isFromSumary || (this.isFromSumary && !this.isRebate))) {
        arr = [{ title: this.mlang('prepaidAccount'), name: 'Pays' }];
      }
      if (this.$hasAuth('agentReturnAccountShow') && !this.currentUser.__isU8C && (!this.isFromSumary || (this.isFromSumary && this.isRebate))) {
        arr.push({ title: this.mlang('rebateAccount'), name: 'Rebates', childrens: ['DiscountRebates', 'GoodRebates'] });
      }
      // ys下增加客户退款
      if (!this.isNewFinanceFied) {
        arr.push({ title: this.mlang('customerRefund'), name: 'CustomerRefund' });
      }

      if (this.$getBO('AGENTSYNCUHY') && this.$getBO('OPENCOUPONS') && !this.isFromSumary) {
        arr.push({ title: this.mlang('coupon'), name: 'Coupon' });
      }
      for (let item of arr) {
        if (item.childrens && !this.isRebate) this.childTabs.set(item.name, item.childrens);
      }
      this.tabs = arr;
      if (!this.$hasAuth('agentReturnPayShow') && this.$hasAuth('agentReturnAccountShow')) {
        this.toList('Rebates');
      }
    },
    toList(name) {
      if (name === this.name) {
        return false;
      }
      this.name = name;
      if (this.childTabs.has(name)) name = this.childTabs.get(name)[0];
      this.$router.push({
        name
      });
    }
  }
};
</script>

<style lang="less" scoped>
.agent-capital {
  background: white;
  .ivu-breadcrumb {
    margin-top: 20px;
    padding-top: 10px;
    background: #fff;
    padding-left: 15px;
  }
}
.capital-tabs {
  background: #fff;
  padding: 15px 0 10px;
  /deep/ .ivu-tabs-bar{
    margin: 0;
  }
  /deep/ .ivu-tabs-content {
    padding: 0;
  }
  /deep/.ivu-tabs-nav .ivu-tabs-ink-bar{
    width: 14px !important;
    margin: 0 36px;
  }
}
</style>

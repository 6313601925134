import { render, staticRenderFns } from "./refer-table.vue?vue&type=template&id=b36b0d08&scoped=true"
import script from "./refer-table.vue?vue&type=script&lang=js"
export * from "./refer-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b36b0d08",
  null
  
)

export default component.exports
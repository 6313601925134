<template>
  <div>
    <div
      v-if="!cloneMeta.hideQueryschema"
      class="queryschema-area">
      <QuerySchema
        ref="queryschema"
        :b-refer="true"
        :biz-meta="cloneMeta"
        :filters="flattenQueryschemaArr"
        @on-queryschema-search="queryschemaSearch"
        @on-queryschema-loaded="queryschemaLoaded">
      </QuerySchema>
    </div>
    <slot name="table">
      <Row
        type="flex"
        justify="center"
        align="middle"
        class="advanced-router">
        <Col span="24">
        <Table
          ref="refer-table"
          :columns="tableColumns"
          :data="tableData"
          :loading="tableLoading"
          :height="height"
          :highlight-row="highlightRow"
          @on-current-change="selectedRowChange"
          @on-row-click="tableRowClick"
          @on-selection-change="tableSectionChange">
        </Table>
        <div
          v-if="cloneMeta.page && pager"
          class="modal-page"
          style="padding: 8px 15px;height: 50px !important;">
          <div style="float: right;">
            <Page
              :total="pager.totalCount"
              :current.sync="pager.currentPage"
              :page-size="pager.pageSize"
              :page-size-opts="pager.pageSizeOpts"
              show-sizer
              show-elevator
              show-total
              @on-change="changePage"
              @on-page-size-change="changePageSize"></Page>
          </div>
        </div>
        </Col>
      </Row>
    </slot>
  </div>
</template>

<script>
import CommonMixin from '@/mixin/common.js';
import Mixin from '@/mixin/archiveList.js';
import { deepCopy } from '@/u-components/utils/assist';
import { getBasicArchivesMetadata, getBasicArchivesData } from '@/api/refer-modal';
import _ from 'lodash';
export default {
  name: 'ReferTable',
  components: {},
  mixins: [CommonMixin, Mixin],
  props: {
    bizMeta: {
      required: true,
      type: Object
    },
    height: {
      default: 320,
      type: [Number, String]
    },
    tableIndex: {
      type: Number
    },
    realIndex: {
      type: Number
    }
  },
  data() {
    return {
      flattenQueryschemaArr: [],
      selectedRows: [],
      checkedArr: [],
      cloneMeta: deepCopy(this.bizMeta),
      selectedRow: null,
      queryschemaObj: {},
      searchConName3: 123,
      tableData: [],
      tableColumns: [],
      tableLoading: true,
      pager: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      },
      isPage: false, // 基础档案类型是否分页
      hasFilter: false, // 基础档案类型是否模糊搜索标识
      conditions: [] // 关键字条件查询
    };
  },
  computed: {
    highlightRow() {
      return !this.cloneMeta.multiple;
    }
  },
  watch: {
    bizMeta: {
      handler() {
        this.init();
      },
      deep: true
    }
  },
  created() {
    this.init();
    this.setPager();
  },
  mounted() {
    if (!this.flattenQueryschemaArr.length) {
      this.loadData();
    }
    this.$on('referLoad', (val) => {
      this.loadData();
    });
  },
  methods: {
    init() {
      this.checkedArr = [];
      if (this.bizMeta.fieldType === 21) {
        // 基础档案类型单独处理表头、表体数据、过滤项内容
        this.initBasicArchives();
      } else {
        this.makeColumns();
        this.makeQueryschema();
      }
    },
    makeColumns() {
      const columns = deepCopy(this.bizMeta.columns);
      this.setColumns(columns);
    },
    makeQueryschema() {
      let queryschemas = deepCopy(this.bizMeta.queryschema);
      this.flattenQueryschemaArr = queryschemas.filter((v) => {
        return v.isHidden !== true;
      });
    },
    loadData() {
      if (this.bizMeta.fieldType === 21) {
        return;
      }
      if (this.cloneMeta.queryschema && this.cloneMeta.queryschema.length) {
        let querySchema = this.getQueryschemaData();
        if (this.cloneMeta.passedparams && this.cloneMeta.passedparams.length) {
          querySchema.conditions.push(...this.cloneMeta.passedparams);
        }
        if (this.cloneMeta.beforeQuery) {
          const result = this.cloneMeta.beforeQuery(querySchema, this.realIndex);
          if (result === false) {
            this.cloneMeta.hideModal && this.cloneMeta.hideModal();
            return;
          } else {
            querySchema = result;
          }
        }
        this.queryList(querySchema);
      } else if (this.cloneMeta.initList !== false) {
        let data = {};
        if (this.cloneMeta.page) {
          data = deepCopy(this.pager);
        }
        if (this.cloneMeta.isDefine) {
          this.queryList(this.cloneMeta.defineId);
        } else {
          let conditions = [];
          if (this.cloneMeta.passedparams && this.cloneMeta.passedparams.length) {
            conditions.push(...this.cloneMeta.passedparams);
          }
          if (this.cloneMeta.hasTableIndex) {
            this.queryList({ conditions, pager: data, tableIndex: this.tableIndex });
          } else {
            this.queryList({ conditions, pager: data });
          }
        }
      }
    },
    getQueryschemaData() {
      const schema = (this.$refs.queryschema && this.$refs.queryschema.getQueryschemaRealData()) || {};
      schema.pager = this.pager;
      return schema;
    },
    queryschemaLoaded() {
      if (this.bizMeta.fieldType !== 21) {
        this.loadData();
        this.$emit('on-queryschema-loaded');
      }
    },
    selectedRowChange(currentRow) {
      this.selectedRow = currentRow;
    },
    changePage(page) {
      this.pager.pageIndex = page;
      if (this.bizMeta.fieldType === 21) {
        this.searchBasicArchivesData();
      } else {
        this.loadData();
      }
    },
    changePageSize(pageSize) {
      this.pager.pageSize = pageSize;
      if (this.bizMeta.fieldType === 21) {
        this.searchBasicArchivesData();
      } else {
        this.loadData();
      }
    },
    makeQueryschemaObj() {
      let queryschemaObj = {};
      if (!this.bizMeta.queryschema) {
        return {};
      }
      this.bizMeta.queryschema.forEach((filter, index) => {
        if (filter['isShowIt'] !== false) {
          const newFilter = deepCopy(filter); // todo 直接替换
          queryschemaObj[filter.name] = newFilter;
        }
      });
      this.queryschemaObj = queryschemaObj;
    },
    onModalShow() {
      this.$emit('on-modal-show');
    },
    tableRowClick(rowData, index) {
      // this.tableData[index]._checked = !this.tableData[index]._checked;
    },
    tableSectionChange(selection, row, status) {
      this.isSelectAll = this.$refs['refer-table'].isSelectAll();
      this.selectedRows = selection;
      if (status) {
        this.checkedArr = _.uniqBy(this.checkedArr.concat(selection), 'id');
      } else {
        row.forEach((v) => {
          if (!v._checked) {
            const arr = deepCopy(this.checkedArr);
            _.remove(arr, (n) => {
              return v.id === n.id;
            });
            this.checkedArr = arr;
          }
        });
      }
    },
    getSelectRow() {
      return this.cloneMeta.multiple ? this.checkedArr : this.selectedRow;
    },
    formChange(newVal, oldVal) {
      this.formData = deepCopy(newVal);
      this.$emit('on-form-change', newVal, oldVal);
    },
    hasAdd() {
      const btn =
        this.cloneMeta.headerActions &&
        this.cloneMeta.headerActions.length &&
        this.cloneMeta.headerActions.find((v) => {
          return v.action === 'add' && v.type === 'common';
        });
      return btn;
    },
    queryschemaSearch(queryschema) {
      this.checkedArr = [];
      if (this.bizMeta.fieldType === 21) {
        const conditions = queryschema.conditions.map(item => {
          return {
            field: item.name,
            op: item.conditionType,
            value1: item.v1,
            itemName: item.itemName
          };
        });
        this.conditions = conditions;
        if (this.pager) {
          this.pager.pageIndex = 1;
          this.pager.currentPage = 1;
        }
        this.searchBasicArchivesData();
      } else {
        this.pager.pageIndex = 1;
        this.pager.currentPage = 1;
        this.loadData();
      }
      return;
      if (this.cloneMeta.passedparams && this.cloneMeta.passedparams.length) {
        queryschema.conditions.push(...this.cloneMeta.passedparams);
      }

      if (this.cloneMeta.page) {
        this.pager.pageIndex = 1;
        this.pager.currentPage = 1;
        queryschema.pager = this.pager;
      }
      this.queryList(queryschema);
    },
    setCheckedData() {
      if (this.checkedArr && this.checkedArr.length) {
        this.tableData.map((v) => {
          const hasCheck = this.checkedArr.find((ck) => {
            return ck.id === v.id;
          });
          if (hasCheck) {
            v._checked = true;
          }
        });
      }
    },
    queryList(query) {
      this.tableLoading = true;
      this.cloneMeta.actions
        .queryList(query)
        .then((data) => {
          this.cloneMeta.afterGetList && (data = this.cloneMeta.afterGetList(data));
          if (this.cloneMeta.page) {
            this.tableData = data.data || data.content;
            this.pager.totalCount = data.totalCount || data.totalElements;
            if (this.cloneMeta.multiple) {
              this.setCheckedData();
            }
          } else {
            this.tableData = data;
          }
          if (this.cloneMeta.noId) {
            this.tableData.map((v) => {
              v.id = v.iSKUId;
            });
          }
          this.tableLoading = false;
          this.$emit('refer-table-loaded', this.tableData);
        })
        .catch((res) => {
          this.tableData = [];
          this.pager.totalCount = 0;
          this.tableLoading = false;
        });
    },
    // 初始化基础档案数据
    async initBasicArchives() {
      const { refEntities, refFilters, isPage, hasFilter } = await getBasicArchivesMetadata(this.bizMeta.defineId);
      this.isPage = isPage;
      this.hasFilter = hasFilter;
      const radioColumn = {
        type: 'radio',
        width: 30,
        align: 'center'
      };
      const noWidthColumn = {
        key: 'nowidth',
        title: ''
      };
      refEntities.forEach(item => {
        item.fieldName = item.itemName;
      });
      // 枚举类型需要单独处理
      const enumArray = refEntities?.filter(item => item.enum) || [];
      // 寻找哪个是要回显的名字
      const showNameObject = refEntities?.find(item => item.echoName) || {};
      const targetColumns = [radioColumn, ...refEntities, noWidthColumn];
      this.tableColumns = targetColumns;
      const targetFilterList = refFilters?.map(item => {
        return {
          name: item.fieldName,
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: item.showCaption || item.itemTitle,
          conditionType: item.compareLogic,
          itemName: item.itemName
        };
      });
      this.flattenQueryschemaArr = targetFilterList?.splice(0, 2) || [];
      const params = {
        defineId: this.bizMeta.defineId,
        isPage,
        hasFilter,
        billId: this.bizMeta.sourceBillId,
        terminal: 'pc',
        queryPagerVo: {
          pageSize: 10,
          totalCount: -1,
          pageIndex: 1
        },
        conditions: []
      };
      this.tableLoading = true;
      const { refData, queryPagerVo } = await getBasicArchivesData(params);
      // 对获取的数据进行处理（枚举类型匹配、展示哪个值）
      refData.forEach(item => {
        item[this.bizMeta.refName] = item[showNameObject.itemName];
        if (enumArray.length > 0) {
          enumArray.forEach(enumItem => {
            if (enumItem.itemName && enumItem.enumString) {
              item[enumItem.itemName] = JSON.parse(enumItem.enumString)[item[enumItem.itemName]];
            }
          });
        }
      });
      if (refData && Array.isArray(refData) && refData.length > 0) {
        this.tableData = refData;
      } else {
        this.tableData = [];
      }
      if (isPage) {
        this.pager = {
          pageIndex: 1,
          pageSize: 10,
          pageSizeOpts: [10, 20, 30, 50],
          currentPage: 1,
          totalCount: queryPagerVo.totalCount
        };
      } else {
        this.pager = null;
      }
      this.tableLoading = false;
    },
    // 搜索基础档案数据
    async searchBasicArchivesData() {
      const params = {
        defineId: this.bizMeta.defineId,
        isPage: this.isPage,
        hasFilter: this.hasFilter,
        billId: this.bizMeta.sourceBillId,
        terminal: 'pc',
        conditions: this.conditions
      };
      if (this.pager) {
        params.queryPagerVo = {
          pageSize: this.pager.pageSize,
          totalCount: this.pager.totalCount,
          pageIndex: this.pager.currentPage
        };
      }
      this.tableLoading = true;
      const { refData, queryPagerVo } = await getBasicArchivesData(params);
      if (refData && Array.isArray(refData) && refData.length > 0) {
        this.tableData = refData;
      } else {
        this.tableData = [];
      }
      if (this.isPage) {
        this.pager = {
          pageIndex: queryPagerVo.pageIndex,
          pageSize: queryPagerVo.pageSize,
          pageSizeOpts: [10, 20, 30, 50],
          currentPage: queryPagerVo.pageIndex,
          totalCount: queryPagerVo.totalCount
        };
      } else {
        this.pager = null;
      }
      this.tableLoading = false;
    },
    setColumns(columns = []) {
      const visibleColumns = columns.filter((v) => {
        return v.isShowIt;
      });
      let tableColumns = [];
      const noWidthColumn = {
        key: 'nowidth',
        title: ''
      };
      if (this.cloneMeta.isUncheck) {
        tableColumns = [this.c_indexColumn, ...visibleColumns]; // 不选
      } else {
        if (this.cloneMeta.multiple) {
          tableColumns = [this.c_selectionColumn, ...visibleColumns];
        } else {
          tableColumns = [this.c_radioColumn, ...visibleColumns];
        }
      }
      tableColumns.push(noWidthColumn);
      this.c_setColumns(tableColumns);

      tableColumns.map((column, k) => {
        if (!column.width) {
          // column.width = 150;
        }
        if (column.controlType && column.controlType !== 'Cell') {
          column.render = (h, params) => {
            return h(column.controlType, { props: { params } });
          };
        }
        return tableColumns;
      });
      this.tableColumns = tableColumns;
    },

    setPager() {
      if (this.cloneMeta.pagerSizeOpts) {
        this.pager.pageSizeOpts = this.cloneMeta.pagerSizeOpts;
      }
      this.pager.pageSize = this.pager.pageSizeOpts[0];
    }
  }
};
</script>

<style scoped>
</style>

import ajax from '@/libs/ajax';
import util from '@/libs/util';
import { getOptionValueByCode } from '@/api/bo';

export const getPaymentList = (data, isHistory) => {
  if (isHistory) {
    return ajax.request({
      url: '/report/history/payment/queryPaymentVoucherSchema',
      data: {
        data: data.querySchema,
        datatype: 'json',
        isReport: 1
      },
      method: 'post'
    });
  } else {
    return ajax.request({
      url: '/pc/PaymentSrv/getPaymentVoucherSchema',
      data,
      method: 'post'
    });
  }
};
export const getPaymentListBtn = (data) => {
  return ajax.request({
    url: '/voucher/payment/paymentvoucher/list/btn',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};
export const exportPayments = (data) => {
  return ajax.request({
    url: '/pc/PaymentSrv/exportPaymentVouchers',
    data,
    method: 'post'
  });
};

export const getPaymentVoucherMoney = (data) => {
  return ajax.request({
    url: '/pc/PaymentSrv/getPaymentVoucherMoney',
    data,
    method: 'post'
  });
};

export const delPayment = (payId) => {
  return ajax.request({
    url: `/voucher/payment/delete/payment/${payId}?isArchive=1`,
    method: 'get'
  });
};

export const backPayment = (data) => {
  return ajax.request({
    url: '/pc/PaymentSrv/backPayment',
    data,
    method: 'post'
  });
};
export const confirmPayment = (data) => {
  return ajax.request({
    url: '/pc/PaymentSrv/confirmPayment',
    data,
    method: 'post'
  });
};
export const opposePayment = (data) => {
  return ajax.request({
    url: '/pc/PaymentSrv/opposePayment',
    data,
    method: 'post'
  });
};

// 检查支付状态
export const checkPaymentStatus = (payNo) => {
  return ajax.request({ url: `/cyberbank/common/queryPaymentStatus/${payNo}?isArchive=1`, method: 'get' });
};

// 付款记录
export const getPayListByOrder = (data) => {
  return ajax.request({
    url: `/voucher/payment/paylist?voucherNo=${data.cVoucherNo}&pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 批量确认
export const batchConfirmPayments = (data) => {
  return ajax.request({
    url: '/pc/PaymentSrv/confirmPayments',
    data,
    method: 'post'
  });
};
// 批量弃审
export const batchbackPayments = (data) => {
  return ajax.request({
    url: '/pc/PaymentSrv/backPayments',
    data,
    method: 'post'
  });
};

// 批量删除
export const batchDelPayments = (data) => {
  return ajax.request({
    url: '/pc/PaymentSrv/dels',
    data,
    method: 'post'
  });
};

// 获取订货端预发款列表
export const getAgentPayments = (data) => {
  return ajax.request({
    url: '/voucher/payment/paymentvoucher/page',
    data: {
      data: data,
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};
// 获取订货端预发款列表
export const getNotUsedPaymentsPager = (data) => {
  return ajax.request({
    url: '/voucher/payment/paymentvoucher/getNotUsedPaymentsSchema',
    data: {
      data: data,
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};

// 导出订货端预发款列表
export const exportAgentPayments = async (data) => {
  const ENABLINGSERVICECOLLECTION = await getOptionValueByCode('ENABLINGSERVICECOLLECTION');
  return ajax.request({
    url: '/bill/export?action=output&domainKey=marketingbill',
    method: 'post',
    data: {
      data: Object.assign({}, {
        billnum: ENABLINGSERVICECOLLECTION ? 'payment_voucher_list_receipt' : 'paymentVoucherList',
        fileName: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580000D","收款单") /* "收款单" */,
        asyncKey: 'asyncOutput_' + util.uuid(32).replace('pc-', ''),
        externalData: { isAsync: true }
      }, data),
      isMarket: 1,
      datatype: 'json'
    }
  });
};

export const getPayStatus = () => {
  return ajax.request({
    url: '/voucher/payment/paymentstatus?isArchive=1',
    method: 'get'
  }).then((data = []) => {
    return data = data.filter(item => {
      item.id = item.value;
      item.name = item.title;
      return item.value !== 5;
    });
  });;
};

// ERP解锁
export const deblockPayMent = (data) => {
  return ajax.request({
    url: `/voucher/payment/openLockPaymentByNo/${data}?isArchive=1`,
    method: 'get'
  });
};

// 同步状态
export const rePush = (payNo) => {
  return ajax.request({
    url: `/cyberbank/payment/rePush?isArchive=1&payNo=${payNo}`,
    method: 'get'
  });
};

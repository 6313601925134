import util from './libs/util';
import axios from 'axios';
import lang from 'ac-lang-cn';
import md5LocalData from './md5LocalData.json';
const fetchCallback = (res) => {
  try {
    if (res && res.data && res.data.code === 200 && res.data.data) {
      if (res.data.data.resource) {
        localStorage.md5 = res && res.data && res.data.code === 200 && res.data.data && res.data.data.md5;
        window.__app.context.i18nResources = res && res.data && res.data.code === 200 && res.data.data && res.data.data.resource;
        localStorage.AllLocaleResource = JSON.stringify(window.__app.context.i18nResources);
        sessionStorage.AllLocaleResource = JSON.stringify(window.__app.context.i18nResources);
      } else {
        localStorage.AllLocaleResource = JSON.stringify(md5LocalData);
      }
    } else {
      localStorage.AllLocaleResource = JSON.stringify(md5LocalData);
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
};
async function awaitFetchI18nRes() {
  const langCode = util.getLang();
  util.setLocal(langCode);
  let url = `/api/resource/getResourcesByPackage/md5?packageName=JS&isArchive=1&lang=${langCode}`;
  if (location.pathname.indexOf('yonbip-mkt-udhwebnode') !== -1) {
    url = `/yonbip-mkt-udhwebnode/api/resource/getResourcesByPackage/md5?packageName=JS&isArchive=1&lang=${langCode}`;
  }
  if (localStorage.md5) {
    url += `&md5=${localStorage.md5}`;
  }
  await axios
    .request({
      url,
      method: 'get'
    })
    .then((res) => {
      fetchCallback(res);
      require('./app.js');
    })
    .catch((res) => {
      require('./app.js');
    });
}
function asyncFetchI18nRes() {
  window.__app.context.i18nResources = JSON.parse(localStorage.AllLocaleResource);
  sessionStorage.AllLocaleResource = localStorage.AllLocaleResource;
  // 异步处理把新的塞进去
  const langCode = util.getLang();
  util.setLocal(langCode);
  let url = `/api/resource/getResourcesByPackage/md5?packageName=JS&isArchive=1&lang=${langCode}`;
  if (location.pathname.indexOf('yonbip-mkt-udhwebnode') !== -1) {
    url = `/yonbip-mkt-udhwebnode/api/resource/getResourcesByPackage/md5?packageName=JS&isArchive=1&lang=${langCode}`;
  }
  if (localStorage.md5) {
    url += `&md5=${localStorage.md5}`;
  }
  axios
    .request({
      url,
      method: 'get'
    })
    .then((res) => {
      fetchCallback(res);
    });
  require('./app.js');
}
let hasInit = false;
let timer = null;
function initSys() {
  const langObj = {
    'zh-cn': 'zh_CN',
    'zh-tw': 'zh_TW',
    'en-us': 'en_US',
    'id-id': 'id_ID'
  };

  window.globalization.locale = langObj[util.getLocal() || window.localStorage.getItem('lang') || 'zh-cn'];
  lang.init({}, langObj[util.getLocal() || localStorage.getItem('lang')] || 'zh_CN');
  lang.ysJsonp('0', 'YS_SD_UDHWN', `${window.__app.context.publicDomain}/iuap-apcom-i18n`, (data) => {
    // eslint-disable-next-line no-console
    console.log(data, '-----------------------');
    hasInit = true;
    clearTimeout(timer);
    if (window.__app.context.isMultiLang) {
      util.getLocal();
      if (localStorage.AllLocaleResource) {
        asyncFetchI18nRes();
      } else {
        awaitFetchI18nRes();
      }
    } else {
      require('./app.js');
    }
  }, 'YS', false);
}
// 不在diwork环境下 设置默认值
if (window.jDiwork && util.getUrlParam('source') && util.getUrlParam('source') === 'u8c') {
  const acFormat = require('ac-format'); // ys formatNumber
  acFormat.initJDiwork(initSys);
  // 10秒，如果还不初始化 将初始化本系统默认
  timer = setTimeout(() => {
    if (!hasInit) {
      clearTimeout(timer);
      util.initSysDefaultFormat();
      initSys();
    }
  }, 10000);
} else {
  util.initSysDefaultFormat();
  initSys();
}

<template>
  <div class="account-main topvalue-20">
    <div>
      <ul class="orderList_tab">
        <li v-for="item in navs" v-show="showNav(item)" :key="item.key" :class="active(item)" @click="menuClick(item)">
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="account-page">
      <keep-alive :include="cacheList" :exclude="notCacheName">
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getAgentMainance } from '@/api/customer/agentBaseInfo';

export default {
  name: 'ParentView',
  data() {
    // const navs = [{ name: 'accountInfo', title: '' }];
    return {
      isCantmaintance: ''
      // navs,
      // currentNavName: navs[0].name
    };
  },
  computed: {
    ...mapGetters(['userAccountMenu']),
    navs() {
      const parentKey = this.$route.meta.module;
      const parent = this.userAccountMenu.find((v) => v.key === parentKey);
      return parent && parent.tabs;
    },
    tagNavList() {
      return this.$store.state.app.tagNavList;
    },
    notCacheName() {
      const list = [this.$route.meta && this.$route.meta.notCache ? this.$route.name : ''];
      return list;
    },
    cacheList() {
      const list = ['ParentView', ...(this.tagNavList.length ? this.tagNavList.filter((item) => !(item.meta && item.meta.notCache)).map((item) => item.name) : [])];
      return list;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getAgentMainance().then((data) => {
        let auths = '';
        data && Object.entries(data).forEach(function(v) { if (v[1]) { auths += (v[0] + ';'); } });
        this.isCantmaintance = auths;
      });
    },
    showNav(item) {
      // mutiAgentsBaseInfo 人员基本信息
      return !item.hide && item.key !== 'mutiAgentsBaseInfo';
    },
    active(item) {
      let redirectedFromPath = this.$route.redirectedFrom && this.$route.redirectedFrom.split('/')[4];
      let redirectedFromPathKey = redirectedFromPath && redirectedFromPath.split('?')[0];
      if (this.$route.name === item.key || (item.key === this.$route.meta.module && this.$route.name === 'agentsList') || item.key === ((this.$route.redirectedFrom && redirectedFromPathKey) || this.$route.meta.redirectFrom)) {
        return 'active-li';
      } else {
        return '';
      }
    },
    isActiveNav({ name }) {
      return this.$route.path.includes(name);
    },
    menuClick(item) {
      this.$router.push({
        name: item.key,
        query: {
          isCantmaintance: this.isCantmaintance
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.account-main {
  height: 100%;
  // display: flex;
  .orderList_tab{
    padding-top:5px ;
    background: #ffffff;
    border-bottom: 1px solid #e5e5e5;
    font-size: 16px;
    display: block;
    overflow: hidden;
    li{
      float: left;
      font-size: 14px;
      margin-right: 2px;
      border-radius: 4px 4px 0 0;
      padding: 10px 0 9px;
      margin: 0 20px;
      color: #666;
      position: relative;
      cursor: pointer;
      &:after{
        position: absolute;
        bottom: 0;
        height: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        content: '';
        width: 14px;
        border-bottom: solid 2px transparent;
      }
      &:hover:after{
        border-bottom-color: #505766 !important;
      }

    }
    .active-li{
      &:after{
        border-bottom-color: #505766 !important;
      }
    }

  }
  .account-navs {
    background: #fff;
    width: 163px;
    margin-right: 20px;
    padding: 10px;
    .account-nav {
      padding: 10px;
      font-size: 14px;
      cursor: pointer;
    }
    .nav-active {
      font-weight: bold;
      font-size: 16px;
      cursor: default;
      &:before {
        content: '';
        background: #ee2223;
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 8px;
        position: relative;
        top: 50%;
        transform: translateY(-3px);
      }
    }
  }
  .account-page {
    background: #fff;
    width: 100%;
    height: calc(~'100% - 46px');
    overflow: hidden;
    /deep/.account-agent-base{
      margin-top: 20px;
    }
    /deep/ .bottom-page {
      position: absolute;
      width: 100%;
    }
    iframe{
      width: 100% !important;
    }
  }
}
</style>

import brands from './brands';
import pays from './pays';
import products from './products';
import orders from './orders';
import mutOrder from './mutOrder';
import deliverys from './deliverys';
import order from './order';
import saleReturn from './saleReturn';
import accounts from './accounts';
import selfinfo from './selfInfo';
import notices from './notices';
import message from './message';
import agentMid from './agentMid';
import payment from './payment';
import refund from './refund';
import productDetail from './productDetail';
import frame from './frame';
import my from './my';
import expense from './expense';
import helpCenter from './helpCenter';
import signIn from './signIn';
import signInRecords from './signInRecords';
import agentCaptial from './agentCaptial';
import shoppingCart from './shoppingCart';
import corpCertification from './corpCertification';
import personCertification from './personCertification';
import authorizedSeals from './authorizedSeals';
import corpSeals from './corpSeals';
import personalSeals from './personalSeals';
import signatureFlowSetting from './signatureFlowSetting';
import quickOrder from './quickOrder';
import promotionGroup from './promotionGroup';
import entirePromotionDetail from './entirePromotionDetail';
import combinationPromotionDetail from './combinaionPromotionDetail';
import productPromotionDetail from './productPromotionDetail';
import policyPromotionDetail from './policyPromotionDetail';
export const agentRoute = [
  corpCertification,
  personCertification,
  authorizedSeals,
  corpSeals,
  personalSeals,
  signatureFlowSetting,
  brands,
  pays,
  // accounts,
  selfinfo,
  notices,
  message,
  agentMid,
  orders,
  mutOrder,
  order,
  products,
  saleReturn,
  deliverys,
  payment,
  refund,
  productDetail,
  helpCenter,
  frame,
  my,
  expense,
  signIn,
  signInRecords,
  agentCaptial,
  shoppingCart,
  quickOrder,
  promotionGroup,
  entirePromotionDetail,
  combinationPromotionDetail,
  productPromotionDetail,
  policyPromotionDetail
];
